import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar: {
    width: '300px',
  },
}));

const ViewProductComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();
  console.log(data);

  const name = data ? data.name : '';
  const description = data ? data.description : '';
  const amount = data ? data.amount : '';
  const image = '';


  return (
    <>
      <Formik
        initialValues={{
          name,
          description,
          image,
          amount,
          product_id: data?.id,
          profile_id: data?.ProfileId,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('Product name is required'),
          description: Yup.string().max(255).required('descriptio is required'),
          amount: Yup.number().required('Amount is required'),
        })}
        onSubmit={async (values) => {
          console.log(values);
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
              <p
                style={{
                  textAlign: 'center',
                  marginBottom: '30px',
                  fontWeight: 800,
                }}
              >
                {' '}
                Update Product Post
              </p>
            </div>
            <div>
              <div>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Product Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Amount"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      name="image"
                      type="file"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.image}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="center" p={2}>
                  <Button
                    color="primary"
                    className={classes.createButton}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    update Product
                  </Button>
                </Box>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

ViewProductComponent.propTypes = {
  className: PropTypes.string,
};

export default ViewProductComponent;
