import React from 'react'
import Page from '../ManagePages/Page';


const Pages = () => {
  return (
    <Page createButton={true} showRange={true} showSearch={false} />
  )
}

export default Pages;
