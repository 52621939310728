import React from 'react';

const transactionIcon = (props) => (
  <svg
    width="20"
    height="17"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ''}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 0H2C0.89 0 0.00999999 0.897822 0.00999999 2.01758L0 14.123C0 15.2428 0.89 16.1406 2 16.1406H18C19.11 16.1406 20 15.2428 20 14.123V2.01758C20 0.897822 19.11 0 18 0ZM18 14.123H2V8.07031H18V14.123ZM18 4.03516H2V2.01758H18V4.03516Z"
      fill={props.fill || '#828282'}
    />
  </svg>
);
export default transactionIcon;
