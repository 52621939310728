import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { getRequest, postRequest } from "../../../services/request";
import { success, error } from "../../../services/swal-mixin";
import Divider from "@mui/material/Divider";
import { Modal } from "react-bootstrap";
import LoadingComponent from "../../../components/loading/LoadingComponent";

export default function FollowTable(props) {
  const [users, setUsers] = useState([]);
  const { user, handleCloseGroups, handleOpenGroups } = props;

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    handleCloseGroups();
  };
  const handleOpen = () => {
    handleOpenGroups();
  };

  const userid = user.UserId;
  const getFollowers = async () => {
    setLoading(true);
    const response = await getRequest(
      `admin-friend/followers?user_id=${userid}`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/graph"
    );
    setLoading(false);

    const { status, message } = response.data;
    const follower = response.data.data.followers;
    setUsers(follower);
    if (status === false) {
      return error(message);
    }

    // setMetaData(data.metaInfo);
  };

  useEffect(() => {
    getFollowers();
  }, []);

  const blockFollowers = async (userid, username) => {
    setLoading(true);
    const response = await postRequest(
      `admin-friend/block`,
      {
        user_id: userid,
        username: username,
      },
      process.env.REACT_APP_BASE_URL + "/wayagram-service/graph"
    );
    setLoading(false);

    const { status, message } = response.data;

    if (status === false) {
      return error(message);
    }
    success(message);

    // setMetaData(data.metaInfo);
  };

  return (
    <>
      <Modal
        show={handleOpen}
        onHide={handleClose}
        backdrop="none"
        keyboard={false}
        size="lg"
        style={{ marginTop: 50, minWidth: "100%" }}
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            padding: "0 1rem",
            borderTop: "5px solid #FF6634",
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <h2>{user.displayName} followers</h2>
          <Divider />
          {users.map((row) => (
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                marginTop: "10px",
              }}
            >
              <p style={{ fontWeigth: 600, fontSize: "20px" }}>
                {row.username}
              </p>
              <p>{row.email}</p>
              <p>
                <Button
                  variant="contained"
                  style={{
                    textTransform: "lowercase",
                    backgroundColor: "red",
                    color: "white",
                    fontWeigth: 800,
                    padding: "2px",
                  }}
                  onClick={() => blockFollowers(row.UserId, row.username)}
                >
                  Block
                </Button>
              </p>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}
