import React from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import { TextField } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import { TablePagination } from "@mui/material";
import EditIcon from "@material-ui/icons/Edit";
import { getRequest } from "../../../services/request";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  text: {
    width: "70%",

    fontWeight: "800px",
    borderRadius: "1px red",

    "& .MuiFormLabel-root": {
      border: "none",
      color: "black",
      marginLeft: 5,
    },
  },

  textfield: {
    paddingTop: "5px",
    paddingRight: "100px",
    marginRight: "580px",
    marginBottom: "50px",
  },
});

const rows = [
  {
    name: "clever",
    phone: "08103612164",
    email: "clever@gmail.com",
  },
];

export default function FriendsTable() {
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getWayaFriends = async () => {
    const response = await getRequest(
      // `admin-friend?user_id=${}?page=${page}`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/graph"
    );

    // if (!response || response.error !== undefined) {
    //   return;
    // }
    // const { data } = response.data;
    // setUsers(data.profiles);
    // setMetaData(data.metaInfo);
  };

  // const filteredPersons = users?.filter((person) => {
  //   return person?.displayName
  //     ?.toLowerCase()
  //     .includes(values?.toLowerCase());
  // });

  return (
    <>
      <div className={classes.root}>
        <div className={classes.textfield}>
          <TextField
            className={classes.text}
            id="outlined-basic"
            label="Search Users by Name"
            variant="outlined"
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              },
            }}
            // onChange={handleChange}
          />
        </div>

        {/* <div>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#FF4B01',
              color: 'white',
              fontWeight: 800,
              marginBottom: '50px',
            }}
            onClick={() => handleOpenBill()}
          >
            create wayagram user
          </Button>
        </div> */}
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{ backgroundColor: "#f6b17f" }}>
            <TableRow>
              <TableCell>Full Name</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.name}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="left">{row.phone}</TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">
                    {" "}
                    <EditIcon style={{ color: "black", cursor: "pointer" }} />
                  </TableCell>
                  <TableCell align="left">
                    {" "}
                    <DeleteIcon style={{ color: "red", cursor: "pointer" }} />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          style={{ backgroundColor: "#f6b17f" }}
          component="div"
          count={rows.count}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15, 20]}
        />
      </TableContainer>
    </>
  );
}
