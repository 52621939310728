import React, { useState, useEffect } from "react";
import config from "../../services/config";
import { Modal, Form, Button, notification, Upload, Input, Table, Space, Switch } from "antd";
import { getRequest, patchRequest, postRequest, putRequest } from "../../services/request";
import { hideLoader, showLoader } from "../../utils/loader";
import { ToggleButton } from "@mui/material";

const StageSettings = ({hideModal, showModal, record, getContest}) => {
  const [loading, setLoading] = useState(false);
   const [reload, setReload] = useState(false);
  
  const handleUpdateSettings = async (e, stage) => {
    const postData = {
      contestId: record.id,
      stageNumber: stage,
      isEnabled: e
    }
    setLoading(true);
    showLoader();
    const url = 'contests/toggle-contest-stage'
    const res = await patchRequest(url, postData, config.contestUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      hideLoader();
      getContest();
      hideModal(false);
      notification.success({ description: message, message: "success!" });
      setReload(!reload);
    } else {
      hideLoader();
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };
  
  return (
    <div className="w-100">
      {/* <Button
        size="large"
        loading={loading}
        htmlType="submit"
        type="primary"
        className="rounded px-5"
        onClick={() => setSShowUpdateSettings(true)}
    >
        Create Settings
    </Button> */}
    <Modal
        visible={showModal}
        onCancel={() => {
          hideModal(false);
        }}
        footer={false}
        title={<p className="text-center"> Activate Contest Stage </p>}
      >
        <div className="my-2 mx-3">
        <label className="my-2 mx-3">Stage One</label>
        <Switch checked={ record.stageOneEnabled} onChange={(e) =>handleUpdateSettings(e,1)} />
        </div>
        <div className="my-2 mx-3">
        <label className="my-2 mx-3">Stage Two</label>
        <Switch checked={ record.stageTwoEnabled} onChange={(e) =>handleUpdateSettings(e,2)} />
        </div>
        <div className="my-2 mx-3">
        <label className="my-2 mx-3">Stage Three</label>
        <Switch checked={ record.stageThreeEnabled}  onChange={(e) =>handleUpdateSettings(e,3)}/>
        </div>
    </Modal>

    </div>
  );
};

export default StageSettings;
