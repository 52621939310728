
import React, { useState } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { Column, Row } from "simple-flexbox";
import CollapsibleCard from "../../../components/CustomCard/CollapsibleCard";
import CustomButton from "../../../components/CustomButton";
import { notification } from "antd";
import { Input } from "antd";
import SelectInput from "../../../components/CustomInput/SelectInput";
import config from "../../../services/config";
import { InputGroup } from "./Promotion";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequestData,
} from "../../../services/request";
import slugs from "../../../resources/slugs";

const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    padding: "56px 51px 15px 96px",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  container: {
    width: "100%",
    padding: "35px 21px",
    backgroundColor: theme.color.white,
    borderRadius: "10px",
  },
  mainText: {
    color: theme.color.brightOrange,
    fontSize: "25px",
    fontWeight: "700",
  },
  subText: {
    color: theme.color.gray,
    fontSize: "14px",
    fontWeight: "400",
  },
  button: {
    backgroundColor: theme.color.brightOrange,
    color: theme.color.white,
    marginTop: "45px",
    width: "269px",
    height: "55px",
    fontSize: "14px",
    borderRadius: "5px",
  },
  block: {
    width: "30px",
    height: "30px",
    backgroundColor: theme.color.black,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.color.white,
  },
  input: {
    width: "205px",
  },
  nairaInput: {
    padding: "0px",
  },
  nairaBox: {
    padding: "0px",
    width: "100%",
    margin: "0px 39px 0px 23px",
  },
  addButton: {
    width: "408px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    padding: "9px 0px 9px 19px",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    cursor: "pointer",
    color: theme.color.black,
    alignSelf: "start",
    justifySelf: "start",
  },
  inputGroup: {
    padding: "0px",
    margin: "0px",
    width: "100%",
    marginBottom: "24px",
  },
}));

const AddPricing = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [pricing, setPricing] = useState("");
  const [reach, setReach] = useState("");
  const [qty, setQty] = useState("");
  const [days, setDays] = useState("");
  const [interval, setInterval] = useState("");

  const handleSubmit = async () => {
    let formData = new FormData();
    formData.append("default_qty", qty);
    formData.append("total_reach", reach);
    formData.append("price", pricing);
    formData.append("interval", interval);
    formData.append("length_of_days", days);

    const postData ={
      "default_qty": qty,
      "total_reach": reach,
      "price": pricing,
      "interval": interval,
      "length_of_days": days,
    }
    let resp = await postRequest(
      "admin-create-promotion-pricing-config",
      postData,
      config.postUrl,
    );

    const { status, data, message } = resp?.data || {};

    if (status) {
      notification.success({ description: message, message: "success!" });
      navigate(slugs.edit_pricing);
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
  };

  return (
    <>
      <Column className={classes.container}>
        <Column className={classes.back} onClick={() => navigate(-1)}>
          <Row alignItems="center">
            <IoMdArrowBack style={{ marginRight: "23px" }} />
            Back
          </Row>
        </Column>
      </Column>

      <Column className={classes.container}>
        <Column horizontal="center" vertical="center">
          <h2 className={classes.mainText}>Add New Price Tag</h2>
        </Column>

        <Column
          className={classes.contentWrapper}
          horizontal="center"
          vertical="center"
        >
          <InputGroup
            custom={true}
            titleLabel="Custom Interval"
            onChangeInterval={(val) => setInterval(val)}
            onChangePrice={(val) => setPricing(val)}
            onChangeQuantity={(val) => setQty(val)}
            onChangeReach={(val) => setReach(val)}
            onChangeDays={(val) => setDays(val)}
            onChangeLength={(val) => setDays(val)}
          />
          <Column horizontal="center" vertical="center">
            <Column horizontal="center" vertical="center">
              <CustomButton
                loading={false}
                content="Save Changes"
                className={classes.button}
                onClick={handleSubmit}
              />
            </Column>
          </Column>
        </Column>
      </Column>
    </>
  );
};
export default AddPricing;
