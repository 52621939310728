import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getRequest, postRequestRole } from '../../../services/request';
import { success, error } from '../../../services/swal-mixin';
import { customBaseUrl, httpGet, httpPost } from '../../../services/http';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: 100,
  },
  tabContent3: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: 250,
  },

  link: {
    marginRight: 20,
  },
  textme: {
    minWidth: '400%',
  },
  textme2: {
    minWidth: '150%',
  },
  btnp3: {
    marginLeft: 585,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

const add = [
  {
    value: '--',
    label: 'none',
  },
  {
    value: 'true',
    label: 'add role',
  },
  {
    value: 'false',
    label: 'remove role',
  },
];

const RoleComponent = (props) => {
  const classes = useStyles();

  const { data, handleOpenEditRole, show, handleCloseEditRole } = props;

  const state = {
    add: '',
    roleName: '',
  };
  const [value, setValue] = React.useState(0);
  const [addRole, setAdd] = React.useState(state);
  const [roles, setRole] = React.useState([]);

  const handleViewClose = () => {
    handleCloseEditRole();
  };
  const handleViewOpen = () => {
    handleOpenEditRole();
  };

  const handleChange = (event, newValue) => {
    const { value, name } = event.target;
    setAdd((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  async function allRoles() {
    const response = await httpGet(
      '/api/v1/admin/manage-user/roles',
      customBaseUrl.authUrl
    );

    const { data } = response || {};

    setRole(data);
  }
  async function addRoles() {
    if (addRole.add === '' || addRole.roleName === '') {
      return error('all fields must be selected');
    }
    const response = await httpPost(
      `/api/v1/admin/manage-user/${data.userId}/roles/${addRole.add}/${addRole.roleName}`,
      {},
      customBaseUrl.authUrl
    );

    const { message, status } = response?.data;

    if (status === false) {
      return error(message);
    }
    handleViewClose();
    success(message);
    setAdd(state);
  }

  useEffect(() => {
    allRoles();
  }, []);

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 50, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6">
                Change{' '}
                <span style={{ color: 'blue', fontWeight: 800 }}>
                  {data.firstName} {data.lastName}
                </span>{' '}
                Role
              </Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            <div className={classes.link}>
              <TextField
                id="outlined-select-currency-native"
                select
                label="Role"
                name="roleName"
                // value={addRole.roleName}
                className={classes.textme2}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {(roles || []).map((option) => (
                  <option key={option.id} addRole={option.name}>
                    {option.description}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
          <div className={classes.tabContent3}>
            <div className={classes.phone}>
              <TextField
                id="outlined-select-currency-native"
                select
                label="ADD"
                name="add"
                // value={addRole.add}
                className={classes.textme}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {add.map((option) => (
                  <option key={option.value} addRole={option.value}>
                    {option.value}
                  </option>
                ))}
              </TextField>
            </div>
          </div>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => addRoles()}
          >
            Proceed
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RoleComponent;
