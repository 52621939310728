import { getUserData } from "../services/helpers";

const imageType = ["jpg", "jpeg", "png", "gif", "svg", "webp", "octet-stream"];
const videoType = [
  "mp4",
  "avi",
  "mov",
  "wmv",
  "flv",
  "mkv",
  "m4s",
  "m4u",
  "m4w",
  "m4x",
  "m4z",
  "m4v",
];

export const retrieveFileType = (fileUrl) => {
  const urlArray = fileUrl.split(".");
  const fileExt = urlArray.at(-1);

  if (imageType.includes(fileExt)) {
    return "img";
  } else if (videoType.includes(fileExt)) {
    return "video";
  }

  return null;
};

export const formatString = (str) => {
  return str.replaceAll('_', ' ');
}

export const urlQueryFormatter = (url) => {
  const finalUrl = url.includes('?') ? `${url}&` : `${url}?`
  return finalUrl;
}

export const addSsoIdToUrl = (url) => {
  let userData = getUserData();
  const formatedUrl = urlQueryFormatter(url)
  const sso = userData?.id ? `sso_id=${userData?.id}`: ''
  return `${formatedUrl}${sso}`;
}
