import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingComponent from '../../components/loading/LoadingComponent';
import CreatePinComponent from './CreatePin';
import ValidatePinComponent from './ValidatePin';
import ResetPinComponent from './ResetPin';
import ResetPasswordComponent from './ResetPassword';
import UpdateWayaComponent from './UpdateWaya';
import VirtualWayaComponent from './WayaVirtusl/VirtualAccount';
import CardWayaComponent from '../../views/users/UserListView/LinkCard/linkCard';
import AccountWayaComponent from '../../views/users/UserListView/BankAccount/BankAccount';
import { getToken } from '../../services/helpers';
import { putRequest } from '../../services/request';
import { success, error } from '../../services/swal-mixin';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
  },
}));

const DashboardComponent = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { data } = props;
  console.log(data);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let token = getToken();
  console.log(token);

  const updateUser = async (userData, id) => {
    setLoading(true);
    console.log(id, userData);
    const response = await putRequest(
      `api/v1/admin/update-personal-profile/${id}`,
      JSON.stringify(userData),
      'http://68.183.60.114:8059'
    );
    console.log(response);
    setLoading(false);

    if (response.ok === false) {
      return error('update error');
    }
    if (response.ok === true) {
      userData = '';
      success('updated successfully');
    }
  };

  return (
    <div className={classes.root}>
      <LoadingComponent loading={loading} />
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          centered
          variant="scrollable"
        >
          <Tab label="Create Pin" {...a11yProps(0)} />
          <Tab label="Validate Pin" {...a11yProps(1)} />
          <Tab label="Reset Waya Password" {...a11yProps(2)} />
          <Tab label="Reset Pin" {...a11yProps(3)} />

          <Tab label="Update Waya Details" {...a11yProps(4)} />
          <Tab label="Virtual Account" {...a11yProps(5)} />
          <Tab label="Link Card" {...a11yProps(6)} />
          <Tab label="Manage Account" {...a11yProps(7)} />
        </Tabs>
      </div>
      <div className={classes.tabContent}>
        <TabPanel value={value} index={0}>
          <CreatePinComponent
            datas={data}
            containerStyles={classes.unresolvedTickets}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ValidatePinComponent datas={data} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ResetPasswordComponent datas={data} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ResetPinComponent datas={data} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <UpdateWayaComponent datas={data} submitForm={updateUser} />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <VirtualWayaComponent datas={data} submitForm={updateUser} />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <CardWayaComponent
            userId={data.userId}
            data={data}
            submitForm={updateUser}
          />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <AccountWayaComponent
            data={data}
            userId={data.userId}
            submitForm={updateUser}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default DashboardComponent;
