import { createUseStyles, useTheme } from 'react-jss';

const useMatStyles = createUseStyles((theme) => ({
  searchRow: {
    display: 'flex',
    height: '46px',
    alignItems: 'center',
    marginBottom: '12px',
  },
  selectType: {
    marginRight: '10px',
    borderRadius: '6px !important',
    border: '0 !important',
    height: '100%',
    width: '171px',
    padding: '10px',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    color: '#FF4B01',
    background: '#FFEAE1',
    border: '1px solid #FF4B01 !important',
    boxSizing: 'border-box',
  },
  grow: {
    flexGrow: 1,
  },
  searchInput: {
    height: '100%',
    width: '279px',
    borderRadius: '6px',
    background: '#F5F5F5 !important',
    border: '1px solid #FF4B01',
    boxSizing: 'border-box',
    color: '#978C8A',
  },

  searchIcon: {
    background: '#F5F5F5',
    color: '#FF4B01',
  },
  updateButton: {
    height: '100%',
    borderRadius: '3px !important',
    textAlign: 'center',
    background: '#FF4B01',
    color: '#fff',
  },
  dashboardItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '12px',
    alignItems: 'center',
  },

  tierList: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  dashItem: {
    background: '#FBFBFB',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '3px',
    width: '24%',
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'start',
  },
  diTop: {
    marginBottom: '36px',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#1C1C1C',
  },
  dibot: {
    fontWeight: 'bold',
    fontSize: '27px',
    lineHeight: '33px',
    color: '#1C1C1C',
  },
  tierItem: {
    background: '#FFFFFF',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 10px',
    width: '24%',
    alignItems: 'center',
  },
  lti: {
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: '#263238',
  },
  rti: {
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '24px',
  },
  tableFilter: {
    display: 'flex',
    marginBottom: '20px',
  },
  tfItem: {
    background: '#FFFFFF',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.05)',
    borderRadius: '3px !important',
    border: '0 !important',
    marginRight: '10px',
    color: '#9E9E9E',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    padding: '8px',
    width: '203px',
  },
  modalTitle: {
    fontWeight: '600',
    fontSize: '20px',
    lineHeight: '24px',
    textAlign: 'center',
    color: '#000000',
    marginBottom: '22px',
  },
  inputWrap: {},
  inputCont: {
    dispaly: 'flex',
    width: '100%',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  inputCont2: {
    dispaly: 'flex',
    width: '48%',
    flexDirection: 'column',
  },
  inputLabel: {
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '12px',
    color: '#000000',
    marginBottom: '4.5px',
  },
  inputInput: {
    background: '#FFFFFF',
    border: '1px solid #000000',
    boxSizing: 'border-box',
    borderRadius: '5px !important',
    width: '100%',
    padding: '8px !important',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '12px',
  },
  inputContFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
}));

export default useMatStyles;
