import React from 'react'
import ManageGroupsComponent from '../GroupAccounts';

const Groups = () => {
  return (
    <ManageGroupsComponent createButton={true} showRange={true} showSearch={false} />
  )
}

export default Groups;
