import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Column, Row } from "simple-flexbox";
import InputBase from "@mui/material/InputBase";

const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    padding: "1px",
    border: "1px solid #E0E0E0",
    borderRadius: "5px",
  },
  rightBlock: {
    width: "118px",
    padding: "13px 0px",
    height: "100%",
    background: theme.color.darkerGray,
    color: theme.color.white,
    borderRadius: "0px 5px 5px 0px",
  },
  leftBlock: {
    width: "187px",
    padding: "13px 0px",
    background: theme.color.lightestGray,
    color: theme.color.black,
    borderRadius: "5px",
  },
  input: {
    padding: "16px 0 16px 43px",
    width: "100%",
    border: "0",
  },
  label: {
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "5px",
  },
  container: {
    marginBottom: "26px",
  },
  text: {
    fontSize: "25px",
    fontWeight: "400",
    margin: "auto 0",
  },
}));

const BlockInput = ({ placeholder, leftText, rightText, label, value, onChange }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Column className={classes.container}>
      <label className={classes.label}>{label}</label>
      <Column className={classes.contentWrapper}>
        <Row horizontal="center" vertical="center">
          {leftText && (
            <Row
              className={classes.leftBlock}
              horizontal="center"
              vertical="center"
            >
              <p className={classes.text}>{leftText}</p>
            </Row>
          )}
          <InputBase
            type="text"
            placeholder={placeholder}
            className={classes.input}
            value={value}
            onChange={(e) => {onChange(e)}}
          />
          {rightText && (
            <Row
              className={classes.rightBlock}
              horizontal="center"
              vertical="center"
            >
              <p className={classes.text}>{rightText}</p>
            </Row>
          )}
        </Row>
      </Column>
    </Column>
  );
};

export default BlockInput;
