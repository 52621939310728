import React from "react";
import ChatHeader from "./ChatHeader";
import IncomingChat from "./IncomingChat";
import OutgoingChat from "./OutgoingChat";
import MessageInput from "./MessageInput";
import { Card } from "antd";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "40vw",
    height: "100%",
    borderRadius: "10px",
    marginLeft: "5px",
    marginRight: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "space-between",
    background: theme.color.white,
  },
  messageContainer: {
    marginTop: "30px",
    height: "100vh",
    paddingLeft: "40px",
    paddingRight: "40px",
    width: "100%",
    overflowY: "scroll",
  },
  inputContainer: {
    width: "100%",
    alignSelf: "end",
    justifySelf: "end",
    marginBottom: '20px',
    paddingRight: "25px",
    paddingLeft: "25px",
  },
}));

const ChatScreen = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <ChatHeader />

      <div className={classes.messageContainer}>
        <IncomingChat />
        <OutgoingChat />
        <IncomingChat />
        <OutgoingChat />
        <IncomingChat />
        <OutgoingChat />
        <IncomingChat />
        <OutgoingChat />
        <OutgoingChat />
        <IncomingChat />
        <OutgoingChat />
        <OutgoingChat />
        <IncomingChat />
        <OutgoingChat />
      </div>

      <div className={classes.inputContainer}>
        <MessageInput />
      </div>
    </div>
  );
};

export default ChatScreen;
