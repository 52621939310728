import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../services/config";
import { Modal, Form, Button, notification, Upload, Input, Table, Space, Pagination } from "antd";
import { getRequest, postRequest, putRequest } from "../../services/request";
import { getUserData } from "../../services/helpers";
import AntDropdown from "../../components/dropdown/AntDropdown";
import useMatStyles from "../users/UserListView/WebPos/style";
import CsvDownload from "react-json-to-csv";

const ManageKYC = () => {
  const navigate = useNavigate();
  const styles = useMatStyles();
  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([]);
  const [createHostModal, setSShowUpdateSettings] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedUser, setSelectedUser] = useState([])
  const [contestsData, setContestsData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [stat, setStat] = useState({});
  const [inActiveContestData, setInactiveContestsData] = useState({});
  const [closedContestData, setClosedContestsData] = useState({});
  const [isInActive, setIsInActive] = useState(false);
  const [isClosed, setIsClosed] = useState(false);
  const [values, setValues] = useState('');
  const [contestCount, setContestCount] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 1000,
  });

  const getKYC = async (page, size) => {
    const pages = page? page : pagination.current;
    const limit = size ? size : pagination.pageSize;
    let response;
      response = await getRequest(
        `/users/kyc/all?field=all&pageNo=${pages}&pageSize=${limit}&value=all`,
        config.authUrl,
        true,
      );
    
      const { data, status, metadata } = response?.data || [];
      if (status) {
        setContestsData(data?.content);
      }    
  };
  const handleSearch = async (e) => {
    setValues(e.target.value);
    if (e.target.value !== '') {
      const response = await getRequest(
        `contests/search-contest?searchTerm=${e.target.value}`,
        config.contestUrl,
        true
      );
      const { data, status, metadata } = response || [];
      console.log({ hh: response.data});
      if (response.status) {
        setSearchData(response?.data?.data || response?.data || []);
      }
    }    
  }
  // const getInactiveContests = async () => {
  //   let userData = await getUserData();
  //   console.log({userData})
  //   const resp= await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);
  //   console.log({ resp})
  //   let response;
  //     response = await getRequest(
  //       `contests?profileId=${resp?.data?.data?.id}&status=INACTIVE`,
  //       config.contestUrl,
  //       true
  //     );
  //     const { data, status } = response?.data || [];
  //     if (status) {
  //       setContestsData(data);
  //     }    
  // };
  const handleDropdownAction = (item, row) => {
    if (item === "View more") {
      navigate(`/contest/details/${row?.contest?.id}`);
    }
  };
  const handleUpdateSettings = async () => {
    setLoading(true);
    let postData = {
        profileId: selectedData?.id,
        uploadLimit: selectedData?.uploadLimit,
        voteLimit: selectedData?.voteLimit
      }
    const url = '/users/update/settings'
    const res = await putRequest(url, postData, config.contestUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      notification.success({ description: message, message: "success!" });
      setSShowUpdateSettings(false);
      setReload(!reload);
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Name',
      dataIndex: 'referral',
      key: 'referral',
      render: (_, record) => record?.referral?.referralCode ?? 'N/A'
    },
    {
      title: 'Email',
      dataIndex: 'referenceCode',
      key: 'referenceCode',
      render: (_, record) => record?.referenceCode ?? 'N/A'
    },
    {
      title: 'Document type',
      dataIndex: 'stage',
      key: 'stage',
      render: (_, record) => record?.name || record?.organizationName
    },
    {
      title: 'Status',
      dataIndex: 'type',
      key: 'type',
      render: (_, record) => record?.referral?.name || record?.referral?.organizationName || 'NA'
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className="row">
          <AntDropdown
            dropDownOptions={['View', 'Approve', 'Reject']}
            handleDropdownAction={(item) => handleDropdownAction(item, record)}
            isArrow
          />
          {/* <AntDrop
          <EditFilled />
          <Switch
            checked={record?.status === 'ACTIVE' ? true : false}
            onClick={() => handleActivateDeactivate(record?.profileId, record?.status === 'ACTIVE' ? 'DISABLE' : 'ENABLE')}
          /> */}
        </div>
      ),
    },
  ];

  const handleChangePage = async (newPage) => {
    console.log(newPage);
    setPagination({ ...pagination, current: newPage-1 });
    getKYC(newPage, pagination.pageSize);
  };

  const handleChangeRowsPerPage = async (page, size) => {
    //alert(size)
    setPagination({ ...pagination, current: 0, pageSize: size });
    getKYC(0, size);
  };

  useEffect(() => {
    getKYC();
  }, []);
  return (
    <div className="w-100">
      {/* <Button
        size="large"
        loading={loading}
        htmlType="submit"
        type="primary"
        className="rounded px-5"
        onClick={() => setSShowUpdateSettings(true)}
    >
        Create Settings
    </Button> */}
    <Modal
        visible={createHostModal}
        onCancel={() => {
          setSShowUpdateSettings(false)
        }}
        footer={false}
        title={<p className="text-center">Update Settings </p>}
      >
        <div className="my-2">
        <label>Vote Limit</label>

          <input
            placeholder="Vote Limit"
            type='text'
            class='form-control'
            id='validationCustom01'
            value={selectedData?.voteLimit}
            required
            onChange={(e) => setSelectedData({...selectedData, voteLimit:e?.target.value})}
          />
        </div>
        <div className="my-2">
            <label>Upload Limit</label>
          <input
            placeholder="Upload Limit"
            type='text'
            class='form-control'
            id='validationCustom01'
            value={selectedData?.uploadLimit}
            required
            onChange={(e) => setSelectedData({...selectedData, uploadLimit:e?.target.value})}
          />
        </div>
        <div className="text-center">
        <Button
            size="large"
            loading={loading}
            htmlType="submit"
            type="primary"
            className="rounded px-5"
            onClick={handleUpdateSettings}
        >
            Update
        </Button>
        </div>
    </Modal>
    <div>
    {/* <div className={styles.dashboardItem}>
        <div className={`${styles.dashItem} mx-1`}>
          <div className={styles.diTop}>Total Number of Referrals</div>
          <div className={styles.dibot}>{stat?.actveContest + stat?.inActiveContest + stat?.closed}</div>
        </div>
        <div className={`${styles.dashItem} mx-1`}>
          <div className={styles.diTop}>Total Count of Transactions of Referrals</div>
          <div className={styles.dibot}>{stat?.actveContest}</div>
        </div>
      </div> */}
      <div className="text-start">
        <Button
        hidden
          size="large"
          htmlType="submit"
          type={!isInActive && !isClosed ? 'primary' : ''}
          className="rounded px-5 my-3"
          onClick={() => {
            setIsClosed(false);
            setIsInActive(false);
          }}
        >
          Active Contests
        </Button>
        <Button
        hidden
          size="large"
          htmlType="submit"
          type={isInActive ? 'primary' : ''}
          className="rounded px-5 mx-2 my-3"
          onClick={() => {
            setIsClosed(false);
            setIsInActive(true);
          }}
        >
          Inactive Contests
        </Button>
        <Button
          hidden
          size="large"
          htmlType="submit"
          type={isClosed ? 'primary' : ''}
          className="rounded px-5 mx-2 my-3"
          onClick={() => {
            setIsClosed(true);
            setIsInActive(false);
            
          }}
        >
          Closed Contests
        </Button>
          {/* <CsvDownload
            data={isInActive ? inActiveContestData : isClosed ? closedContestData : values.length > 0 ? searchData : contestsData.contest}
            filename='contests.csv'
            style={{
            background: '#ff6700',
            color: '#000',
            fontSize: '15px',
            height: '40px',
            fontWeight: 'bold',
            padding: '6px 24px',
            textDecoration: 'none',
          
          }}
        >
          Export contest
          </CsvDownload> */}
        <div style={{ position: 'relative', margin:20 }} class="col-md-4">
          <div className="my-2">
            <input
              placeholder="Search by title"
              type='text'
              class='form-control'
              id='validationCustom01'
              width={500}
              value={values}
              required
              onChange={handleSearch}
            />
        </div>
      </div>
    </div>
      <Table 
      pagination={false}
      columns={columns} dataSource={isInActive ? inActiveContestData : isClosed ? closedContestData : values.length > 0 ? searchData : contestsData} />
      <Pagination  onShowSizeChange={handleChangeRowsPerPage} pageSize={pagination.pageSize} defaultCurrent={pagination.current} onChange={handleChangePage}
        total={isInActive ? stat.inActiveContest : isClosed ? stat.closed : stat?.actveContest}
      />

    </div>

    </div>
  );
};

export default ManageKYC;
