import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import CardContent from '@mui/material/CardContent';

import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';

import MoreVertIcon from '@material-ui/icons/MoreVert';

import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import LoadingComponent from '../../../../components/loading/LoadingComponent';
import {
  getRequest,
  deleteRequest,
  postRequest,
} from '../../../../services/request';
import { getUserData } from '../../../../services/helpers';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { success, error } from '../../../../services/swal-mixin';
import CloseIcon from '@material-ui/icons/Close';
import UpdateAccount from './UpdateAccount';

const useBankStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    // display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',
    // display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 150,
  },
  tabContent3: {
    padding: '25px 0',
    // display: 'flex',
    justifyContent: 'center',
    marginLeft: 94,
  },

  link: {
    marginRight: 80,
  },
  link2: {
    marginLeft: 110,
    marginBottom: '25px',
  },
  phones: {
    marginBottom: '25px',
  },
  textme: {
    // minWidth: '20%',
  },
  textme2: {
    minWidth: '66%',
  },
  textme3: {
    minWidth: '65%',
  },
  btnp3: {
    marginLeft: 300,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  roots: {
    // display: 'flex',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1rem 1rem',
    // justifyContent: 'space-between',
  },
  root: {
    // minWidth: 350,
    display: 'flex',
    position: 'relative',
    background: '#FFFFFF',
  },
  media: {
    position: 'absolute',
    color: 'red',
    // height: '30px',
    marginLeft: '70px',
    bottom: 25,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  types: {
    fontSize: '15px',
  },
  name: {
    fontSize: '10px',
    marginBottom: '15px',
  },
  content: {
    flex: '1 0 auto',
  },
  head: {
    marginLeft: '130px',
    marginTop: 0,
  },
  headbtn: {
    backgroundColor: 'white',
  },
}));

export default function RecipeReviewCard({ userId }) {
  const classes = useStyles();
  const user = userId;
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openBank, setBank] = React.useState(false);
  const [account, setAccount] = React.useState([]);
  const [virtualAccount, setVirtualAccount] = React.useState(false);
  const [id, setId] = React.useState(-1);

  const handleOpenBank = () => {
    setBank(true);
  };
  const handleCloseBank = () => {
    setBank(false);
  };
  const handleOpenAccount = () => {
    // console.log('i am here', virtualAccount);
    setVirtualAccount(true);
  };
  const handleCloseAccount = () => {
    setVirtualAccount(false);
  };

  const handleToggle = (index) => {
    setId(index);
  };

  const handleClose = (event) => {
    setId(-1);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  console.log(user);
  const admin = getUserData();
  const adminId = admin.id;
  const getAccount = async () => {
    console.log('*******', 'working', user);
    setLoading(true);
    const response = await getRequest(
      `account-creation-service/api/account/getAccounts/${userId}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);

    console.log(response);
    if (!response || response.error !== undefined) {
      return;
    }

    const { data } = response?.data;
    setAccount(data);
    // setMetaData(data.metaInfo);
  };
  // const accountNumber = account.accountNumber

  const newAccounts = [];

  newAccounts.push(account);
  console.log(account?.bankName, newAccounts);

  const deleteAccount = async (users) => {
    console.log(users);
    setLoading(true);
    const response = await deleteRequest(
      `account-creation-service/api/account/deleteAccount/${users}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);

    console.log(response);
    const { data, message, status } = response.data;
    if (status === false) {
      return error(message);
    }
    success(message);
    getAccount();
    // setMetaData(data.metaInfo);
  };

  // return focus to the button when we transitioned from !open -> open

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <>
      <LoadingComponent loading={loading} />
      <div className={classes.roots}>
        {account ? (
          <>
            {newAccounts?.map((acct, index) => (
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.types}
                  >
                    Account Name
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    className={classes.name}
                  >
                    {acct.accountName}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.types}
                  >
                    Card Number
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    className={classes.name}
                  >
                    {acct.accountNumber}
                  </Typography>

                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    className={classes.name}
                  >
                    {acct.BankName}
                  </Typography>
                </CardContent>
                <div>
                  <div>
                    {id !== index ? (
                      <div
                        key={acct.accountNumber}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={() => handleToggle(index, acct.accountNumber)}
                        className={classes.headbtn}
                      >
                        <CardHeader
                          className={classes.head}
                          style={{ marginBottom: '-60px', marginRight: '0px' }}
                          action={
                            <IconButton key={acct.accountNumber}>
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                      </div>
                    ) : (
                      <div
                        key={acct.accountNumber}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={() => handleClose(index, acct.accountNumber)}
                        className={classes.headbtn}
                      >
                        <CardHeader
                          className={classes.head}
                          style={{ marginBottom: '-60px', marginRight: '0px' }}
                          action={
                            <IconButton key={acct.accountNumber}>
                              <CloseIcon />
                            </IconButton>
                          }
                        />
                      </div>
                    )}

                    {id === index ? (
                      <Paper
                        style={{
                          width: '40%',
                          marginTop: '30px',
                          left: '150px',
                          position: 'absolute',
                          zIndex: '9999',
                        }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            onClose={handleClose}
                            autoFocusItem={open}
                            id="menu-list-grow"
                            key={++index}
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              style={{ color: 'red', fontSize: '12px' }}
                              onClick={() => deleteAccount(acct.userId)}
                            >
                              Delete Account
                            </MenuItem>

                            <MenuItem
                              style={{ fontSize: '12px' }}
                              onClick={() => handleOpenAccount()}
                            >
                              Update Account
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    ) : null}
                  </div>
                  <Typography className={classes.media}>
                    {acct.bankName}
                  </Typography>
                </div>
              </Card>
            ))}
          </>
        ) : (
          'No virtual account yet'
        )}
      </div>
      <Button
        variant="outlined"
        color="textSecondary"
        style={{
          marginTop: '20px',
          width: '360px',
          height: '85px',
          background: '#FFFFFF',
          position: 'relative',
        }}
        onClick={() => handleOpenBank()}
      >
        ADD VIRTUAL ACCOUNT{' '}
        <span style={{ position: 'absolute', top: '17.44%' }}>+</span>
      </Button>
      {openBank ? (
        <AccountComponent
          userId={user}
          getAccount={getAccount}
          handleCloseBank={handleCloseBank}
        />
      ) : (
        ''
      )}
      {virtualAccount ? (
        <UpdateAccount
          userId={user}
          getAccount={getAccount}
          account={account}
          virtualAccount={virtualAccount}
          handleCloseAccount={handleCloseAccount}
        />
      ) : (
        ''
      )}
    </>
  );
}

const AccountComponent = (props) => {
  const admin = getUserData();
  const adminId = admin.id;
  const { userId, handleOpenBank, getAccount, handleCloseBank } = props;
  const state = {
    accountName: '',
    userId: userId,
  };
  const classes = useBankStyles();
  const [values, setValues] = React.useState(state);
  const [loading, setLoading] = React.useState(false);

  const handleViewClose = () => {
    handleCloseBank();
    getAccount();
  };
  const handleViewOpen = () => {
    handleOpenBank();
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const addAccount = async () => {
    setLoading(true);
    const response = await postRequest(
      `account-creation-service/api/account/createVirtualAccount`,

      values,
      'http://46.101.41.187:7090'
    );
    setLoading(false);

    if (!response || response.error !== undefined) {
      return;
    }
    console.log(response);
    const { data, message, status } = response.data;
    if (status === false) {
      return error(message);
    }
    success(message);
    setValues({ ...state, name: '' });
    handleViewClose();
    getAccount();
  };

  return (
    <>
      <div className={classes.root}>
        <Modal
          show={handleViewOpen}
          onHide={handleViewClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          style={{ marginTop: 150, minWidth: '105%', zIndex: 100000 }}
        >
          <Modal.Header
            style={{
              borderBottom: 'none',
              padding: '0 1rem',
              borderTop: '5px solid #FF6634',
            }}
            closeButton
          ></Modal.Header>
          <LoadingComponent loading={loading} />
          <Modal.Body>
            <div className={classes.tabContent}>
              <div className={classes.name2}>
                <Typography variant="h6" style={{ marginLeft: '270px' }}>
                  Add Virtual bank Account
                </Typography>
              </div>
            </div>
            <div className={classes.tabContent2}></div>
            <div className={classes.tabContent3}>
              <div className={classes.link2}>
                <TextField
                  className={classes.textme3}
                  id="outlined-select-currency-native"
                  label="Account Name"
                  name="accountName"
                  placeholder="please enter acct name"
                  value={values.accountName}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                ></TextField>
              </div>
            </div>
            <Button
              variant="contained"
              className={classes.btnp3}
              onClick={() => addAccount()}
            >
              Add virtual Account
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
