import React from 'react';

const acceptedIcon = (props) => (
  <svg
    width="24"
    height="14"
    viewBox="0 0 24 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ''}
      d="M17.9999 1.99984L16.5899 0.589844L10.2499 6.92984L11.6599 8.33984L17.9999 1.99984ZM22.2399 0.589844L11.6599 11.1698L7.47991 6.99984L6.06991 8.40984L11.6599 13.9998L23.6599 1.99984L22.2399 0.589844ZM0.409912 8.40984L5.99991 13.9998L7.40991 12.5898L1.82991 6.99984L0.409912 8.40984Z"
      fill={props.fill || '#828282'}
    />
  </svg>
);
export default acceptedIcon;
