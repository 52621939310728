import { Progress } from 'antd';
import React from 'react';

const DonationProgress = ({ description, current, total, title }) => {
  const currentPercent = Math.round(
    (parseInt(current, 10) / parseInt(total, 10)) * 100
  );
  return (
    <div className="mt-2 w-80">
      <Progress
        percent={currentPercent}
        strokeColor={
          currentPercent <= 0
            ? '#7C2B0A'
            : currentPercent <= 25 && currentPercent > 0
            ? '#38364F'
            : currentPercent <= 50 && currentPercent > 25
            ? '#993F1A'
            : currentPercent <= 75 && currentPercent > 50
            ? '#FF9858'
            : '#27AE60'
        }
        format={(percent) => `${title} ${percent}%`}
      />
      <p className="donation-detail-group">
        {description}
      </p>
    </div>
  );
};

export default DonationProgress;
