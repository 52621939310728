import React from "react";
import ManageEventComponent from "../ManageEvents";

const Events = () => {
  return (
    <ManageEventComponent 
      createButton={true}
      showRange={true} showSearch={false}
    />
  )
}
export default Events;
