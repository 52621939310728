import React from 'react'
import PushNotification from '../PushNotification';

const Notification = () => {
  return (
    <PushNotification createButton={true} showRange={true} showSearch={false} />
  )
}

export default Notification;
