import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";

import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import store from "../../../redux";
import Button from "@mui/material/Button";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { getRequest, postRequest } from "../../../services/request";
import TextField from "@mui/material/TextField";
import { success, error } from "../../../services/swal-mixin";
import { Link } from "react-router-dom";
import SLUGS from "../../../resources/slugs";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import Edit from "../../../assets/images/edit.png";
import Like from "../../../assets/images/like.png";
import Comment from "../../../assets/images/comment.png";
import Delete from "../../../assets/images/delete.png";

const useStyles = makeStyles((theme) => ({
  roots: {
    // display: 'flex',
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridGap: "1rem 1rem",
    // justifyContent: 'space-between',
  },
  root: {
    // minWidth: 350,
    // display: 'flex',
    borderRadius: "10px",
    display: "grid",
    position: "relative",
    background: "#FFFFFF",
  },
  text: {
    width: "30%",
  },
  media: {
    position: "absolute",
    color: "red",

    marginLeft: "0px",
    bottom: 250,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  types: {
    fontSize: "10px",
  },
  name: {
    fontSize: "10px",
    // marginBottom: '15px',
  },
  content: {
    flex: "1 0 auto",
  },
  head: {
    // marginLeft: '430px',
    marginTop: 0,
  },
  headbtn: {
    backgroundColor: "white",
  },
  money: {
    position: "absolute",
    bottom: "50px",
    marginLeft: "40px",
    marginBottom: "20px",
    fontSize: "30px",
    fontWeight: 800,
    color: "green",
  },
  moneys: {
    position: "absolute",
    bottom: "10px",
    marginLeft: "20px",
    marginTop: "20px",
    marginBottom: "30px",
    fontSize: "15px",
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();

  const profile = store.getState().postType;
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(-1);

  const [postUser, setPostUser] = React.useState([]);
  const anchorRef = React.useRef(null);

  const handleToggle = (index) => {
    setOpen(index);
  };

  const getLikedPages = async () => {
    setLoading(true);
    const response = await getRequest(
      `page/get-all-pages-a-user-liked?userId=${profile}`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/main"
    );
    setLoading(false);

    const { data, status } = response;
    if (status === false) return;

    setPostUser(data);
    // setMetaData({ count: data.totalElements, page: data.pageable.pageNumber });
  };

  useEffect(() => {
    getLikedPages();
  }, []);

  return (
    <>
      <LoadingComponent loading={loading} />
      <div className={classes.textfield}>
        <TextField
          className={classes.text}
          id="outlined-basic"
          label="Search Users by Name"
          variant="outlined"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          // onChange={handleChange}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        <Link to={SLUGS.waya_users}>
          <p>
            <ArrowLeftIcon />
            Back
          </p>
        </Link>
        <div>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "#489ffb",
              color: "white",
              fontWeight: 800,
              marginBottom: "50px",
              // marginLeft: "auto"
            }}
            // onClick={() => handleOpenBill()}
          >
            View As a user
          </Button>
        </div>
      </div>
      <div className={classes.roots}>
        {postUser.length > 0 ? (
          <>
            {postUser?.map((acct, index) => (
              <Card className={classes.root}>
                <div>
                  <div
                    ref={anchorRef}
                    key={1}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => handleToggle(index)}
                    className={classes.headbtn}
                  >
                    <CardHeader
                      avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          R
                        </Avatar>
                      }
                      className={classes.head}
                      style={{ marginBottom: "-60px", marginRight: "auto" }}
                      action={
                        <Button
                          variant="outlined"
                          style={{
                            backgroundColor: "#DEFFF3",
                            color: "#16B079",
                            fontWeight: 600,
                          }}
                        >
                          Active
                        </Button>
                      }
                    />
                  </div>
                </div>

                <Typography
                  component="h5"
                  variant="h6"
                  className={classes.moneys}
                >
                  {acct.description}
                  <p style={{ fontSize: "9px" }}>
                    {moment(acct.createdAt).format("MMM, Do YYYY")}
                    <span style={{ marginLeft: "10px" }}>
                      {moment(acct.createdAt).format("hh:mm:ss A")}
                    </span>
                  </p>
                </Typography>
                {/* <p>{moment(user.time).format('hh:mm:ss A')}</p> */}
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      marginTop: "110px",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <Typography
                        component="h5"
                        variant="h6"
                        style={{ color: "green" }}
                        className={classes.types}
                      >
                        <img src={Edit} alt="edit" />
                        Edit post
                      </Typography>
                    </div>
                    <div style={{ display: "grid" }}>
                      <Typography
                        component="h5"
                        variant="h6"
                        style={{ color: "#ed462f" }}
                        className={classes.types}
                      >
                        <img src={Like} alt="like" />
                        Like
                      </Typography>
                    </div>
                    <div style={{ display: "grid" }}>
                      <Typography
                        component="h5"
                        variant="h6"
                        style={{ color: "#bfa8fd" }}
                        className={classes.types}
                      >
                        <img src={Comment} alt="comment" />
                        comment
                      </Typography>
                    </div>
                    <div style={{ display: "grid" }}>
                      <Typography
                        component="h5"
                        variant="h6"
                        style={{ color: "#ed462f" }}
                        className={classes.types}
                      >
                        <img src={Delete} alt="delete" />
                        delete
                      </Typography>
                    </div>
                  </div>
                </CardContent>
                <div>
                  <div></div>
                </div>
              </Card>
            ))}
          </>
        ) : (
          <p style={{ color: "green", marginTop: "80px" }}>
            No Liked Pages Yet
          </p>
        )}
      </div>
    </>
  );
}
