import React from 'react'
import ManageDonationsComponent from '../ManageDonationsComponent';

const Donation = () => {
  return (
    <ManageDonationsComponent createButton={true} showRange={true} showSearch={false} />
  )
}

export default Donation;
