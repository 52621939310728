import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { postRequest } from '../../services/request';
import { error, success } from '../../services/swal-mixin';
import { Modal } from 'react-bootstrap';
import LoadingComponent from '../../components/loading/LoadingComponent';


const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    
    background: '#FF6634',
    color: '#ffffff',
    
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  cardContent: {
    padding: 15,
  },
}));

const CreateWayaUserComponent = (props) => {
  const { handleWayaCreateOpen, handleWayaCreateClose } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const state = {
    email: '',
    firstName: '',
    dateOfBirth: '',
    gender: '',
    surname: '',
    phoneNumber: '234',
    password: '',
    admin: true,
    wayaAdmin: true,
    referenceCode: '',
  };

  const [values, setValues] = useState(state);
  const [val, ] = useState([]);

  const errors = {
    nameError: '',
    passError: '',
    phoneError: '',
    emailError: '',
    birthError: '',
    genderError: '',
  };
  const [valError, setError] = useState(errors);

  const validate = () => {
    let regexp = /\S+@\S+\.\S+/;
    let nameError = '';
    let passError = '';
    let phoneError = '';
    let emailError = '';
    let birthError = '';
    let genderError = '';

    if (
      !values.firstName ||
      values.firstName.length < 3 ||
      !values.surname ||
      values.surname.length < 3
    ) {
      nameError = 'name can not be blank/too short';
    }
    if (!values.password || values.password.length < 8) {
      passError = 'password can not be blank/too short';
    }
    if (!values.phoneNumber || values.phoneNumber.length < 13) {
      phoneError = 'phone number can not be blank/too short';
    }
    if (!values.dateOfBirth) {
      birthError = 'bith date can not be blank/too short';
    }
    if (!values.gender) {
      genderError = 'gender can not be blank/too short';
    }

    let emails = regexp.test(String(values.email).toLowerCase());

    console.log(emails);

    if (!emails) {
      emailError = 'invalid email';
    }

    if (
      emailError ||
      nameError ||
      passError ||
      phoneError ||
      birthError ||
      genderError
    ) {
      setError({ emailError, nameError, passError, phoneError });
      return false;
    }

    return true;
  };

  const handleViewClose = () => {
    handleWayaCreateClose();
  };
  const handleViewOpen = () => {
    handleWayaCreateOpen();
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const creatWayaUser = async () => {
    const isValid = validate();
    console.log(isValid);
    if (isValid === false) {
      return error('please confirm your input');
    }
    setLoading(true);
    console.log(typeof val);
    
    const response = await postRequest('api/v1/admin/users/waya-account', values);
    
    setLoading(false);
    if (response) {
      success('user created successfully');
    } else {
      error('invalid number or phone number/phone/email already exist');
    }
    setValues(state);
  };
 

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 50, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <form autoComplete="off" noValidate>
            <Card>
              <CardHeader
                subheader="Create waya official account."
                title="Create waya account"
              />

              <Divider />
              <CardContent className={classes.cardContent}>
                <Grid container spacing={3}>
                  
                  <Grid item md={6} xs={12}>
                    <TextField
                      label="First name"
                      name="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                      key={1}
                      variant="outlined"
                      required
                      fullWidth
                    />
                    <Typography style={{ color: 'red' }}>
                      {valError.nameError}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last name"
                      name="surname"
                      onChange={handleChange}
                      required
                      value={values.surname}
                      key={2}
                      variant="outlined"
                    />
                    <Typography style={{ color: 'red' }}>
                      {valError.nameError}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Date of Birth"
                      name="dateOfBirth"
                      placeholder='yyyy-mm-dd'
                      onChange={handleChange}
                      required
                      value={values.dateOfBirth}
                      key={2}
                      variant="outlined"
                    />
                    <Typography style={{ color: 'red' }}>
                      {valError.birthError}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Gender"
                      name="gender"
                      onChange={handleChange}
                      required
                      value={values.gender}
                      key={2}
                      variant="outlined"
                    />
                    <Typography style={{ color: 'red' }}>
                      {valError.genderError}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      required
                      value={values.email}
                      key={3}
                      variant="outlined"
                    />
                    <Typography style={{ color: 'red' }}>
                      {valError.emailError}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phoneNumber"
                      onChange={handleChange}
                      value={values.phoneNumber}
                      key={4}
                      variant="outlined"
                      required
                    />
                    <Typography style={{ color: 'red' }}>
                      {valError.phoneError}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Password"
                      name="password"
                      type='password'
                      onChange={handleChange}
                      value={values.password}
                      key={5}
                      variant="outlined"
                    />
                    <Typography style={{ color: 'red' }}>
                      {valError.passError}
                    </Typography>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      key={6}
                      label="Reference Code"
                      name="referenceCode"
                      onChange={handleChange}
                      value={values.referenceCode}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              {/* <Divider /> */}

              <Box display="flex" justifyContent="center" p={2}>
                <Button
                  style={{ padding: '12px 40px', fontWeight: 800 }}
                  color="primary"
                  className={classes.createButton}
                  variant="contained"
                  onClick={() => creatWayaUser()}
                >
                  Create
                </Button>
              </Box>
            </Card>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

CreateWayaUserComponent.propTypes = {
  className: PropTypes.string,
};

export default CreateWayaUserComponent;
