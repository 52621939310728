import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getUserData } from '../../../services/helpers';

const groupTypes = [
  {
    value: true,
    label: 'true',
  },
  {
    value: false,
    label: 'false',
  },
];

const isMuted = [
  {
    value: false,
    label: 'false',
  },
  {
    value: true,
    label: 'true',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const UserFormComponent = ({ className, data, submitForm, name='update Page', ...rest }) => {
  const classes = useStyles();
  
  const userData = getUserData();
  
  const title = data ? data.title : '';
  const description = data ? data.description : '';
  const isPublic = data ? data.isPublic : false;
  const isDeleted = data ? data.isDeleted  : false;
  const headerImageUrl = data ? data.headerImageUrl : '';
  const imageUrl = data ? data.imageUrl : '';
  const userId = data ? data.userId : userData.user.id.toString();
  const groupId = data ? data.id : null;
  const website = data ? data.websiteUrl : null;
  const pageId = data ? data.id : null;

  return (
    <>
      <Formik
        initialValues={{
          title,
          description,
          isPublic,
          isDeleted,
          headerImageUrl,
          imageUrl,
          userId,
          groupId,
          website,
          pageId,
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().max(255).required('First name is required'),
          description: Yup.string().max(255).required('Last name is required'),
          isPublic: Yup.boolean().required('Group type is required'),
        })}
        onSubmit={async (values) => {
          
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
              <div>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <div className={classes.profileCard}>
                      Header Image
                      {values.headerImageUrl && (
                        <div className={classes.avatarWrapper}>
                          <img
                            src={values.headerImageUrl}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <div className={classes.profileCard}>
                      Group Image
                      {values.imageUrl && (
                        <div className={classes.avatarWrapper}>
                          <img
                            src={values.imageUrl}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Button variant="contained" component="label">
                      Upload Header Image
                      <input type="file" hidden />
                    </Button>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <Button variant="contained" component="label">
                      Upload Group Image
                      <input type="file" hidden />
                    </Button>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <p>Page Name</p>
                    <TextField
                      // label="Page Name"
                      name="title"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      value={values.title}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                  <p>Description</p>
                    <TextField
                      fullWidth
                      // label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      required
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Page Type"
                      name="isPublic"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.isPublic}
                      variant="outlined"
                      error={Boolean(touched.isPublic && errors.isPublic)}
                      helperText={touched.isPublic && errors.isPublic}
                    >
                      {groupTypes.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Status"
                      name="isDeleted"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.isDeleted}
                      variant="outlined"
                      error={Boolean(touched.isDeleted && errors.isDeleted)}
                      helperText={touched.isDeleted && errors.isDeleted}
                    >
                      {isMuted.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.createButton}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                    
                  >
                    {name}
                  </Button>
                </Box>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
