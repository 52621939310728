import CsvDownload from 'react-json-to-csv';
import React, { useEffect, useState } from 'react';
import config from '../../services/config';
import LoadingComponent from '../../components/loading/LoadingComponent';
import { Table, Select, Input, Modal, Form, Button, notification, Pagination } from 'antd';
import { deleteRequestData, getRequest, patchRequest, postRequest } from '../../services/request';
import {ExclamationCircleOutlined } from '@ant-design/icons';
import { productColumn } from '../../utils/dummyData';
import SearchBar from '../../components/SearchBar';
import { getUsers } from '../../services/helpers';
import ImagePreview from '../../components/ImagePreview';

const Product = () => {
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("username");
  const [query, setQuery] = useState("");
  const [metaData, setMetaData] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [imageModal, setShowImageModal] = useState(false);
  const [mode, setMode] = useState("");
  const [value, setValue] = useState("a");
  const { confirm } = Modal;
  const [form] = Form.useForm()
  const [commissionData, setCommission] =useState()

  const selectOptions = [
    {
      value: "username",
      label: "Username"
    },
    {
      value: "email",
      label: "Email"
    },
    {
      value: "phone",
      label: "Phone"
    },
  ]

  const getData = async (q) => {
    let response;
    const filter = q ? `&${filterType}=${q}` : '';
    // if(q) {
    //   response = await getRequest(`admin-search-products?query=${query}&page=${pages}&limit=${limits}`,
    //   config.postUrl
    //   );
    // }else{
    setLoading(true);
    response = await getRequest(
      `admin-get-all-products?page=${pages}&limit=${limits}&${filter}`,
      config.postUrl
    );
    // }
    const commission = await getRequest(
      `admin-get-commission`,
            config.postUrl
    );

console.log(commission)
    setLoading(false);
    const { data } = response?.data || {};
    const { metadata } = response?.data?.data || {};
    
    setMetaData(metadata?.total)
    setPosts(data?.data || []);
    setFilteredPosts(data?.data || []);
    setCommission(commission?.data?.data || {})
  };

  const deActivate = async (d) => {
    const datas = {
      product_id: d.id,
      profile_id: d.ProfileId
    };
    setLoading(true)
    const response = await deleteRequestData(
      'admin-delete-product',
      datas,
      config.postUrl
    );
    setLoading(false)
      
    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message,
        description:"deleted"
      })
      getData();
    } else {
      notification.warning({
        message,
        description:"error"
      })
    }
  };

  const handleConfirm = (d) =>{
    confirm({
      title: `Do you want to delete these items?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        deActivate(d)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  const fetchUsers = async (q, page) => {
    let response;
    if (q) {
      response = await getRequest(
        `admin-profile/all?page=1&username=${q}`,
        config.roleUrl
      );
    }
    if (!response || response.error !== undefined) {
      return;
    }
    setUsers(response?.data?.data?.AllWayAGramProfiles);
  };
  useEffect(() => {
    getData(query);
  }, [pages, query]);

  useEffect(() => {
    fetchUsers(value, setUsers);
  }, [value]);

  const handleChange = (event) => {
    const {value} = event.target
    if (value){
      setQuery(value);
    }
  };

  const handleModals=(type, value)=>{
    if(type==='newPost'){
      form.resetFields()
      setcreatePostModal(true)
      setMode("create")
    }
    if(type ==='images'){
      setShowImageModal(true)
    }
    if(type==='editPost'){
      setcreatePostModal(true)
      setMode("update")
      form.setFieldsValue({
        description:value.description,
        name:value.name,
        amount:value.amount,
        profile_id: value.ProfileId,
        product_id: value.id
      })
    }
  }

  const handleSubmitPost = async(values) =>{
    setLoading(true);
    const newForm = new FormData();
    const file = Object.values(images);
    newForm.append('image', file[0]);
    newForm.append('profile_id', values.profile_id);
    mode === 'update' && newForm.append('product_id', values.product_id);
    newForm.append('amount', values.amount);
    newForm.append('description', values.description);
    newForm.append('name', values.name);
    const url = `admin-${mode}-product`;
    let  res = {}
    if (mode === 'create') {
      res = await postRequest(url, newForm, config.postUrl);
    }else{
      res = await patchRequest(url, newForm, config.postUrl);
    }
    const {status, data, message} = res?.data || {}
    if (status) {
      // if(file.length > 0){
      //   file?.forEach(async (img) => {
      //     const newFormOpt = new FormData();
      //     newFormOpt.append('images', img);
      //     newFormOpt.append('profile_id', values.profile_id);
      //     newFormOpt.append('product_id', data?.id);
      //     await addImagesToPosts(newFormOpt);
      //   });
      // }
      notification.success({description:message, message:'success!'});
      setcreatePostModal(false)
      getData()
    } else {
      notification.error({description:message, message:'Oops!'});
    }
    setLoading(false);
  };

  // const addImagesToPosts = async (newFormOpt) => {
  //   await patchRequest('update', newFormOpt, config.postUrl);
  //   getData()
  // };

  const column = productColumn(handleModals, handleConfirm, setShowImageModal, setPreviewImages)

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="products.csv"
        style={{
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
        }}
      >
        Export Products
      </CsvDownload>
    );
  };

  const handlePreview = (e) => {
    const { files } = e.target;
    console.log(files)
    if (files.length > 10) {
      notification.warning('Oops!', `Upload exceeded limit of 10`, 'error');
      return;
    }
    setImages(files)
  };

  return (
    <div className="w-100">
      <SearchBar
        page="product"
        total={metaData}
        commissionData={commissionData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
      />
      <LoadingComponent loading={loading} />
      <div>
        <Table scroll={{ x: 1500 }} columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
        <Pagination
          total={metaData}
          responsive={true}
          defaultCurrent={1}
          onChange={(e)=>setPages(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </div>
      <Modal
        visible={createPostModal}
        onCancel={()=> setcreatePostModal(false)}
        footer={false}
        title={<p className='text-center'>{mode?.toUpperCase()} PRODUCT</p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Form.Item label="Images">
            <div className="input-group">
              <input type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                onChange={handlePreview}
                accept="image/*"
                multiple="multiple"
                aria-label="Upload" />
              {/* <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Browse</button> */}
            </div>
          </Form.Item>  
          <Form.Item label="Post Owner" name="profile_id" hidden={mode==="update"}>
            <Select
              showSearch
              value={value}
              placeholder="Search for user"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(e) => setValue(e)}
              // onChange={(e) => setValue(e)}
              notFoundContent={null}    
            >
              {users.map(user =>
                <Select.Option key={user.id}>{user.displayName}</Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="Product Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Product Amount" name="amount">
            <Input type="number"  min="1"/>
          </Form.Item>
          <Form.Item hidden name="product_id">
            <Input />
          </Form.Item>
          <Form.Item className='text-center mt-4'>
            <Button size='large' loading={loading} htmlType='submit' type='primary' className='rounded px-5'>
              {mode} product
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ImagePreview
        data={previewImages}
        visible={imageModal}
        setVisible={setShowImageModal}
      />
    </div>
  );
};

export default Product;
