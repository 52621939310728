import { Grid, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import LoadingComponent from '../../components/loading/LoadingComponent';
import DisplayTableComponent from '../../components/table/DisplayTableComponent';
import { getRequest } from '../../services/request';
import swalService from '../../services/swal-mixin';
import UserGroupsComponent from '../management/GroupAccounts/UserGroupComponent';
import UserPagesComponent from '../management/ManagePages/UserPagesComponent';
import BankAccountsComponent from '../transactions/BankAccountsComponent';
import BankTransactionComponent from '../transactions/BankTransactionsComponent';
import PaymentRequestsComponent from '../transactions/PaymentRequestsComponent';
import KYCComponent from './UserListView/KYCLevel/UserKYCComponent';
import LoginTracker from './LoginTracker';
import LinkedCard from './UserListView/LinkCard/linkCard';
import BankAccount from './UserListView/BankAccount/BankAccount';
import WalletCards from '../transactions/Wallet/WalletCards';
import Friends from './UserListView/Friends/Friends';
import VirtualBank from './UserListView/VirtualBank/VirtualAccount';
import UserActivities from '../logs/UserLogs';
import { customBaseUrl, httpGet } from '../../services/http';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: '25px 0',
  },

  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 20,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: '5px auto',
  },
  avatar: {
    height: 150,
    width: 150,
    minWidth: 150,
    borderRadius: 100,
    // border: `1px solid ${theme.color.lightGrayishBlue2}`,
  },
}));

const ViewUserComponent = (props) => {
  const classes = useStyles();
  console.log(props);
  const { data, show, handleViewClose } = props;
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [referrals, setReferrals] = React.useState([]);
  const [profiles, setProfiles] = React.useState([]);
  // const [paymentRequests, setPaymentRequests] = React.useState([]);
  const [headers] = React.useState(['Full Name', 'Phone', 'Email', 'City']);
  const handleClose = () => {
    handleViewClose();
  };

  console.log(data);
  const user = data.userId;
  console.log(user);

  const getReferrals = async () => {
    setLoading(true);
    const response = await getRequest(
      `profile-service/user-referrals/${370}`,
      'http://68.183.60.114:9086'
    );
    // setLoading(false);
    // if (!response) return;
    console.log(response);
    // const { data, status, message } = response.data;
    // if (status === false) {
    //   return swalService.error(message);
    // }
  };

  const getProfiles = async () => {
    console.log(user);
    setLoading(true);
    const response = await httpGet(
      `/api/v1/profile/${user}`,
      customBaseUrl.authUrl
    );
    setLoading(false);
    console.log(response);
    // if (!response) return;
    const { data, status, message } = response?.data || {};
    if (status === false) {
      return swalService.error(message);
    }
    console.log(data);
    setProfiles(data);
    // swalService.success(message);
  };

  // // console.log(data)

  useEffect(() => {
    if (user) getProfiles();
  }, [user]);

  console.log(profiles);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    if (newValue === 1) {
      await getReferrals(data.userId);
    }
  };

  return (
    <div className={classes.root}>
      <LoadingComponent loading={loading} />
      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        size="lg"
        id="user"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.profileCard}>
            <div className={classes.avatarWrapper}>
              <img
                src={
                  profiles
                    ? profiles.profileImage
                      ? profiles.profileImage
                      : 'https://www.minervastrategies.com/wp-content/uploads/2016/03/default-avatar.jpg'
                    : 'https://www.minervastrategies.com/wp-content/uploads/2016/03/default-avatar.jpg'
                }
                alt="avatar"
                className={classes.avatar}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    'https://www.minervastrategies.com/wp-content/uploads/2016/03/default-avatar.jpg';
                }}
              />
            </div>
          </div>
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              centered
              variant="scrollable"
              style={{ borderTop: '2px solid grey' }}
            >
              <Tab label="WALLET" {...a11yProps(0)} />
              <Tab label="BANK CARDS" {...a11yProps(1)} />
              <Tab label="BANK ACCOUNTS" {...a11yProps(2)} />
              <Tab label="SENT PAYMENT REQUEST" {...a11yProps(3)} />
              <Tab label="RECEIVED PAYMENT REQUEST" {...a11yProps(4)} />
              <Tab label="Referrals" {...a11yProps(5)} />
              <Tab label="USER PROFILE" {...a11yProps(11)} />
              <Tab label="GROUPS" {...a11yProps(12)} />
              <Tab label="PAGES" {...a11yProps(7)} />
              <Tab label="Virtual Accounts" {...a11yProps(8)} />
              <Tab label="Transactions" {...a11yProps(6)} />
              <Tab label="KYC" {...a11yProps(9)} />
              <Tab label="LOGIN TRACKER" {...a11yProps(10)} />
              <Tab label="FRIENDS" {...a11yProps(13)} />
              <Tab label="ACTIVITIES" {...a11yProps(14)} />
            </Tabs>
          </div>
          <div className={classes.tabContent}>
            <TabPanel value={value} index={0}>
              <WalletCards
                userId={data ? data.userId : null}
                users={data}
                title="Wallet"
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <LinkedCard
                userId={data ? data.userId : null}
                data={data ? data : null}
                title="Bank Cards"
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <BankAccount
                userId={data ? data.userId : null}
                title="Manage Accounts"
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <PaymentRequestsComponent
                userId={data ? data.userId : null}
                title="Sent Payment Request"
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <PaymentRequestsComponent
                userId={data ? data.userId : null}
                title="Received Payment Request"
                type="received"
              />
            </TabPanel>
            <TabPanel value={value} index={11}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Role"
                    name="role"
                    value={
                      data || data.roles.includes('ROLE_ADMIN')
                        ? 'admin & user'
                        : 'User'
                    }
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    label="First name"
                    name="firstName"
                    onChange={handleChange}
                    variant="outlined"
                    value={profiles ? profiles?.firstName : ''}
                    fullWidth
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Last name"
                    name="lastName"
                    value={profiles ? profiles?.surname : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Middle Name"
                    name="middleName"
                    value={profiles ? profiles?.middleName : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Email Address"
                    name="email"
                    value={profiles ? profiles?.email : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    value={profiles ? profiles?.phoneNumber : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="City"
                    name="city"
                    value={profiles ? profiles.district : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="status"
                    value={profiles ? profiles.status : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Verified"
                    name="verified"
                    value={profiles?.active ? 'verified' : 'unverified'}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Card Linked"
                    name="cardLinked"
                    value={profiles ? profiles.cardLinked : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="State"
                    name="state"
                    value={profiles ? profiles.district : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Waya ID"
                    name="wayaId"
                    value={profiles ? profiles.userId : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    value={profiles ? profiles.address : ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Followers"
                    name="followers"
                    value={profiles ? profiles.followers : 0}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Following"
                    name="following"
                    value={profiles ? profiles.following : 0}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={5}>
              <DisplayTableComponent data={referrals} headers={headers} />
            </TabPanel>
            <TabPanel value={value} index={12}>
              <UserGroupsComponent userId={data ? data.userId : null} />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <UserPagesComponent userId={data ? data.userId : null} />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <VirtualBank
                userId={data ? data.userId : null}
                title="Virtual Accounts"
              />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <BankTransactionComponent
                userId={data ? data.userId : null}
                title="Transactions"
              />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <KYCComponent userId={data ? data.userId : null} title="KYC" />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <LoginTracker
                userId={data ? data.userId : null}
                title="Login Tracker"
              />
            </TabPanel>
            <TabPanel value={value} index={13}>
              <Friends userId={data ? data.userId : null} title="Friends" />
            </TabPanel>
            <TabPanel value={value} index={14}>
              <UserActivities
                userId={data ? data.userId : null}
                title="Activities"
              />
            </TabPanel>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewUserComponent;
