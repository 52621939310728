import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Table } from "antd";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { pricingCol } from "../../../utils/dummyData";
import { getRequest } from "../../../services/request";
import config from "../../../services/config";
import SearchInput from "../../../components/CustomInput/SearchInput";
import CustomButton from "../../../components/CustomButton";
import { FiEdit3 } from "react-icons/fi";
import { createUseStyles, useTheme } from 'react-jss';
import { useNavigate } from "react-router-dom";
import slugs from "../../../resources/slugs";

const useStyles = createUseStyles((theme) => ({
  button: {
    padding: "15px",
    backgroundColor: theme.color.brightOrange,
    color: theme.color.white,
    fontSize: "14px",
    borderRadius: "5px",
    marginLeft:'63rem',
  },
}));



const Pricing = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("pricings");
  const [metaData, setMetaData] = useState(0);
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const [limits, setLimits] = useState(4);
  const [reload, setReload] = useState(false);

  let navigate = useNavigate();


  
  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `admin-get-all-percent-prices`,
      config.postUrl
    );
    
    setLoading(false);
    const { data } = response?.data?.data || [];
    setPosts(data || []);
  };

  useEffect(() => {
    getData();
  }, [currentTab, reload]);



  return (
    <Column>
      <Row horizontal="space-between">
        {/* <SearchInput /> */}
        <CustomButton loading={loading} content="" 
        className={classes.button} 
        onClick={()=> navigate(slugs.edit_pricing)}>
          <FiEdit3 />
          <span>Edit Pricings</span>
        </CustomButton >
      </Row>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
        <Table
          columns={pricingCol}
          dataSource={posts}
          rowKey="id"
          loading={loading}
          pagination={false}
        />
      </div>
    </Column>
  );
};

export default Pricing;
