import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';



const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const UserFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();

  
  const title = data ? data.name : '';
  const description = data ? data.description : '';
  const isPublic = data.isPublic ? 'Public' : 'Private';
  const isDeleted = data.isDeleted ?  'inactive' : 'acive';
  const headerImageUrl = data ? data.headerImageUrl : '';
  const imageUrl = data ? data.imageUrl : '';
  const groupId = data ? data.id : null;

  return (
    <>
      <Formik
        initialValues={{
          title,
          description,
          isPublic,
          isDeleted,
          headerImageUrl,
          imageUrl,
          // userId,
          groupId,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('First name is required'),
          description: Yup.string().max(255).required('Last name is required'),
          isPublic: Yup.boolean().required('Group type is required'),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <h5 style={{ marginLeft: "280px"}}>group information</h5>
            <div>
              <div>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <div className={classes.profileCard}>
                      Header Image
                      {values.headerImageUrl && (
                        <div className={classes.avatarWrapper}>
                          <img
                            src={values.headerImageUrl}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <div className={classes.profileCard}>
                      Group Image
                      {values.imageUrl && (
                        <div className={classes.avatarWrapper}>
                          <img
                            src={values.imageUrl}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Group Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      
                      value={values.title}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Group Type"
                      name="Page Type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      value={values.isPublic}
                      variant="outlined"
                      
                    >
                     
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                    <TextField
                      fullWidth
                      label="Status"
                      name="isDeleted"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      value={isDeleted}
                      variant="outlined"
                      
                    >
                      
                    </TextField>
                  </Grid>
                </Grid>
                
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
