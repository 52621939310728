import React from "react";
import Avatar from "@mui/material/Avatar";
import ActionDropdown from "../dropdown/ActionDropdown";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "24px",
    paddingBottom: "30px",
    justifyContent: "space-between",
    width: "100%",
  },
  Input: {
    borderRadius: "10px",
    width: "40%",
  },
  p: {
    fontSize: "10px",
    color: "green",
    lineHeight: "0",
  },
  h2: {
    top: "30px",
    fontSize: "14px",
  },
  details: {
    display: "flex",
    gap: '10px'
  },
  userDetails: {
    paddingTop: "10px",
  },
}));

const ChatHeader = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <card className={classes.container}>
      <div className={classes.details}>
        <Avatar alt="Remy Sharp" src="https://joeschmoe.io/api/v1/random" />
        <div className={classes.userDetails}>
          <h2 className={classes.h2}> Michelle </h2>
          <p className={classes.p}>online</p>
        </div>
        <ActionDropdown />
      </div>

      <Input
        size="large"
        className={classes.Input}
        placeholder="Search chat"
        suffix={<SearchOutlined />}
      />
    </card>
  );
};

export default ChatHeader;
