import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Row } from "simple-flexbox";
import Page from "../../../components/Page";
import TableComponent from "../../../components/table/TableComponent";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { getRequest, putRequest } from "../../../services/request";
import swalService from "../../../services/swal-mixin";
import { getUserData } from "../../../services/helpers";

const useMatStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    maxWidth: "100%",
  },
}));

createUseStyles((theme) => ({
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  cardRow: {
    marginTop: 30,
    "@media (max-width: 768px)": {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    flexGrow: 1,
    marginRight: 20,
    "@media (max-width: 768px)": {
      // marginTop: 20,
      maxWidth: "none",
    },
  },
}));

const UserGroupsComponent = ({ userId }) => {
  const matclasses = useMatStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [headers] = useState([
    "Group Name",
    "Group Description",
    // 'Followers',
    // 'Status',
    "Type",
    "Date Created",
  ]);

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `group/get-user-groups?userId=${userId}&pageNumber=1`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );
    setLoading(false);

    const { data, status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    setData(data);
  };

  const activate = async ({ id: groupId }) => {
    const userData = getUserData();
    const userId = userData.user.id;
    const data = {
      groupId,
      userId,
      isDeleted: false,
    };
    const response = await putRequest(
      "group/admin-enable-or-disable-group",
      data,
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );

    const { status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    swalService.success(message);
    getData();
  };

  const deActivate = async ({ id: groupId }) => {
    const userData = getUserData();
    const userId = userData.user.id;
    const data = {
      groupId,
      userId,
      isDeleted: true,
    };
    const response = await putRequest(
      "group/admin-enable-or-disable-group",
      data,
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );

    const { status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    swalService.success(message);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Page className={matclasses.root} title="Group Accounts">
      <LoadingComponent loading={loading} />
      <Container maxWidth={false}>
        <Row>
          <Box marginBottom={2} fontWeight={600}>
            <Row vertical="center" breakpoints={{ 384: "column" }}>
              User Groups
            </Row>
          </Box>
        </Row>
        <Box mt={3} className={matclasses.tableContainer}>
          <TableComponent
            type={"group"}
            data={data}
            headers={headers}
            actionCol={false}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default UserGroupsComponent;
