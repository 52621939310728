import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import { Modal } from 'react-bootstrap';



const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const UserFormComponent = ({ className, data, show, handleAvatarClose, submitForm, ...rest }) => {
  const classes = useStyles();
  
  const handleClose = () => {
    handleAvatarClose();
  }
  
  const image = data ? data.avatar : '';
  const title = data ? data.displayName : '';
  
  return (
    <>
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="disp"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
      <Formik
        initialValues={{
        image,
        title,
         
        }}
        
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
              <p style={{fontWeight: 800, marginLeft: '130px', fontSize: '20px'}}>{title} Post</p>
              <div>
                <img style={{width: "100%"}} src={data.avatar} alt='moment'/>
              </div>
            </div>
          </form>
        )}
      </Formik>
      </Modal.Body>
      </Modal>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
