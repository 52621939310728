import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrivateSection from "../views/PrivateSection";
import PublicRoutes from "./PublicRoutes";
import store from "../redux";
import { getToken} from '../services/helpers';
import IdleTimer from "../utils/idleTimer";
import { logoutUser } from "../redux/actions";
import SLUGS from "../resources/slugs";
// import Page404 from './404Page'

const Routes = () => {
  const push = useNavigate();
  
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 30000, //expire after 10 seconds
      onTimeout: () => {
        push(SLUGS.login);
        store.dispatch(logoutUser());
      },
      onExpired: () => {
        //do something if expired on load
        push(SLUGS.login);
        store.dispatch(logoutUser());
      }
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  const { pathname } = useLocation();

  const [isUserLoggedIn, setLoggedIn] = useState(
    store.getState().isUserLoggedIn === true || (getToken() && getToken() !== 'undefined')
  );

  store.subscribe(() => {
    setLoggedIn(store.getState().isUserLoggedIn === true);
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return isUserLoggedIn ? <PrivateSection /> : <PublicRoutes />;
};

export default Routes;
