import config from "./config";
import { getToken, getUserData } from "./helpers";
import axios from "axios";
import { customBaseUrl } from "./http";
import { addSsoIdToUrl } from "../utils/helpers";

const Bluebird = require("bluebird");

// const fetch = require('node-fetch');
// fetch.Promise = Bluebird;

const baseUrl = config.baseUrl;
const delUrl = config.del;
const baseLink = config.baseLink;
const bLink = config.bLink;
const bills = config.bills;
const cards = config.card;
const wallet = config.wallet;
const acct = config.acct;
const core = config.core;
const cat = config.cat;
const payStackAPIKey = config.payStackAPIKey;
const CLIENT_ID = 'WAYAGRAM';
const CLIENT_TYPE = 'ADMIN';

const setHeader = () => {
  const config = {
    headers: {
      // mode: "no-cors",
      // "Access-Control-Allow-Origin": "http://localhost:3001/",
      // 'Access-Control-Allow-Origin': '*',
      authorization: getToken(),
      "Content-Type": "application/json",
      "CLIENT-TYPE": CLIENT_TYPE,
      "CLIENT-ID": CLIENT_ID,
      // "Access-Control-Allow-Headers": "Authorization",
    },
  };

  return config;
};
const setHeaderFile = () => {
  const config = {
    headers: {
      Authorization: getToken(),
      "CLIENT-TYPE": CLIENT_TYPE,
      "CLIENT-ID": CLIENT_ID,
      mode: "no-cors",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Origin": "http://localhost:3001/",
      // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // 'responseType': 'blob'
      "Access-Control-Allow-Headers": "Authorization",
    },
  };

  return config;
};
const setHeaders = () => {
  const config = {
    headers: {
      // 'Access-Control-Allow-Origin': '*',
      "Access-Control-Allow-Origin": "http://localhost:3001/",
      Authorization: getToken(),
      "Content-Type": "application/json",
      "CLIENT-TYPE": CLIENT_TYPE,
      "CLIENT-ID": CLIENT_ID,
      mode: "no-cors",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "Authorization",
    },
  };

  return config;
};

const setHeaderAlt = () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "http://localhost:3001/",
      Authorization: getToken(),
      "CLIENT-TYPE": CLIENT_TYPE,
      "CLIENT-ID": CLIENT_ID,
      mode: "no-cors",
      "Content-Type": "application/json; charset=UTF-8",
      "Access-Control-Allow-Headers": "Authorization",
    },
  };
  return config;
};

const multipartHeader = () => {
  const config = {
    headers: {
      Authorization: getToken(),
      "Access-Control-Allow-Origin": "http://localhost:3001/",
      "CLIENT-TYPE": CLIENT_TYPE,
      "CLIENT-ID": CLIENT_ID,
      // 'Access-Control-Allow-Origin': '*',
      mode: "no-cors",
      "Content-Type": "multipart/form-data",
      "Access-Control-Allow-Headers": "Authorization",
    },
  };
  return config;
};

const setPayStackHeader = () => {
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "http://localhost:3001/",
      authorization: `Bearer ${payStackAPIKey}`,
      "CLIENT-TYPE": CLIENT_TYPE,
      "CLIENT-ID": CLIENT_ID,
      mode: "no-cors",
      "Access-Control-Allow-Headers": "Authorization",
    },
  };
  return config;
};

const send = async (url, body = {}, method = "post") => {
  try {
    body =
      method.toLowerCase() === "get" && method.toLowerCase() === "delete"
        ? null
        : JSON.stringify(body);
    console.log(body);
    // const headers = { 'Content-Type': 'application/json' };
    const headers = setHeader();

    url = `${baseUrl}/${url}`;
    const sso = addSsoIdToUrl(url);

    const response = await fetch(sso, { method, body, headers });
    console.log(response);
    const data = await response.json();
    return await data;
  } catch (error) {
    return error;
  }
};

const sendDelete = async (url, body = {}, method = "delete") => {
  try {
    body = method.toLowerCase() === "get" ? null : JSON.stringify(body);
    console.log(body);
    // const headers = { 'Content-Type': 'application/json' };
    const headers = setHeader();

    url = `${delUrl}/${url}`;
    const sso = addSsoIdToUrl(url);
    const response = await fetch(sso, { method, body, headers });
    console.log(response);
    const data = await response.json();
    return await data;
  } catch (error) {
    return error;
  }
};

const sendWithAuth = async (
  url,
  body = {},
  method = "post",
  base = baseUrl
) => {
  try {
    body = method.toLowerCase() === "get" ? null : body;
    const { headers } = setHeader();

    url = `${base}/${url}`;
    const sso = addSsoIdToUrl(url);
    console.log(sso, { method, body, headers });
    const response = await fetch(url, { method, body, headers });
    const data = response;
    console.log(data);
    return await data;
  } catch (error) {
    return error;
  }
};

const sendMultipart = async (
  url,
  body = {},
  method = "post",
  base = baseUrl
) => {
  try {
    body = method.toLowerCase() === "get" ? null : JSON.stringify(body);
    const { headers } = multipartHeader();

    url = `${base}/${url}`;
    const sso = addSsoIdToUrl(url);
    const response = await fetch(sso, { method, body, headers });
    const data = await response.json();
    return await data;
  } catch (error) {
    return error;
  }
};

const getRequest = async (url, base = baseUrl, notSSO) => {
  try {
    const sso = addSsoIdToUrl(`${base}/${url}`);
    const no_sso = `${base}/${url}`;


    const r = await axios.get(notSSO ? no_sso : sso, setHeader());

    return r;
  } catch (error) {
    return error.response;
  }
};


const getRequestNoSso = async (url, base = baseUrl) => {
  try {
    const sso = `${base}/${url}`;

    const r = await axios.get(sso, setHeader());

    return r;
  } catch (error) {
    return error.response;
  }
};

const getRequestFile = (url, base = baseUrl) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeaderFile())
    .then((r) => r)
    .catch((e) => console.log(e.message));
const getRequestCore = (url, base = core) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));
const getRequestAggr = (url, base = bills) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));
const getAcctRequest = (url, base = acct) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const getRequestWallet = (url, base = wallet) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const getRequestWithdrawal = (url, base = baseLink) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));
const getRequestBVN = (url, base = bLink) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));
const getRequestBills = (url, base = bills) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));
const getRequestCards = (url, base = cards) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const getRequestAlt = (url, base = baseUrl) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeaderAlt())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const getRequestNoToken = (url) =>
  axios
    .get(addSsoIdToUrl(`${baseUrl}/${url}`))
    .then((r) => r)
    .catch((e) => console.log(e.message));

const getRequestNoTokenAlt = (url) =>
  axios
    .get(addSsoIdToUrl(`${url}`))
    .then((r) => r)
    .catch((e) => console.log(e.message));

const postRequest = async (url, data, base = baseUrl, noSso) => {
  try {
    const r = await axios.post(
      noSso ? `${base}/${url}` : addSsoIdToUrl(`${base}/${url}`),
      data,
      setHeader()
    );

    // console.log(r, "res");
    return r;
  } catch (error) {
    // console.log(error.response.data.message);
    return error.response;
  }
};
const postRequestSms = async (url, data, base = baseUrl) => {
  try {
    const r = await axios.post(
      addSsoIdToUrl(`${base}/${url}`),
      data,
      setHeader()
    );

    console.log(r, "res");
    return r;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};
const postSimulation = async (url, data, base = baseUrl) => {
  try {
    const r = await axios.post(
      addSsoIdToUrl(`${base}/${url}`),
      data,
      setHeader()
    );

    console.log(r, "res");
    return r;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
const postRequestVerify = async (url, data, base) => {
  try {
    const r = await axios.post(
      addSsoIdToUrl(`${base}/${url}`),
      data,
      setHeader()
    );

    console.log(r, "res");
    return r;
  } catch (error) {
    console.log(error.response.data.message);
    return error.response;
  }
};
const postRequestDefault = async (url, data) => {
  try {
    const r = await axios.post(addSsoIdToUrl(`${url}`), data, setHeader());

    console.log(r, "res");
    return r;
  } catch (error) {
    console.log(error.response.data.message);
    return error.response;
  }
};

const postRequestRole = async (url, base = baseUrl) => {
  console.log(setHeaders());
  try {
    const r = await axios.post(
      addSsoIdToUrl(`${base}/${url}`),
      {},
      setHeaders()
    );

    return r;
  } catch (error) {
    return error.response;
  }
};
const postRequestWaya = (url, data, base = baseUrl) =>
  axios
    .post(addSsoIdToUrl(`${base}/${url}`), data)
    .then((r) => r)
    .catch((e) => console.log(e.message));
const getRequestReset = async (url, data, base = baseUrl) => {
  try {
    const r = await axios.get(addSsoIdToUrl(`${base}/${url}`));
    return r;
  } catch (error) {
    console.log(error.response);
  }
};

const getRequestResetAuth = (url, data, base = baseUrl) =>
  axios
    .get(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const postRequestMult = async (url, data, base = baseUrl) => {
  try {
    const r = await axios.post(
      addSsoIdToUrl(`${base}/${url}`),
      data,
      multipartHeader()
    );
    return r;
  } catch (error) {
    console.log(error.response.data.message);
    return error.response;
  }
};

const getRequestForget = async (url, base = baseUrl) => {
  try {
    const r = await axios.get(addSsoIdToUrl(`${customBaseUrl.authUrl}/${url}`));
    return r;
  } catch (error) {
    console.log(error.response);
  }
};
const postAcctRequest = (url, data, base = acct) =>
  axios
    .post(addSsoIdToUrl(`${base}/${url}`), JSON.stringify(data), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const postRequestWithdrawal = (url, data, base = baseLink) =>
  axios
    .post(addSsoIdToUrl(`${base}/${url}`), data, setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const postRequestBVN = (url, data, base = bLink) =>
  axios
    .post(addSsoIdToUrl(`${base}/${url}`), data, setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const putRequestBills = (url, data, base = bills) =>
  axios
    .put(addSsoIdToUrl(`${base}/${url}`), data, setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));
const postRequestCards = (url, data, base = cards) =>
  axios
    .post(addSsoIdToUrl(`${base}/${url}`), data, setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const putRequest = async (url, data, base = baseUrl) => {
  try {
    const r = await axios.put(
      addSsoIdToUrl(`${base}/${url}`),
      data,
      setHeader()
    );
    return r;
  } catch (error) {
    console.log(error.response);
  }
};
const putRequestPay = async (url, data, base) => {
  try {
    const r = await axios.put(
      addSsoIdToUrl(`${base}/${url}`),
      data,
      setHeader()
    );
    return r;
  } catch (error) {
    console.log(error.response);
  }
};

const putRequestMult = (url, data, base = baseUrl) =>
  axios
    .put(addSsoIdToUrl(`${base}/${url}`), data, multipartHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const putRequestAggr = (url, data, base = bills) =>
  axios
    .put(addSsoIdToUrl(`${base}/${url}`), data, setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const patchRequest = async (url, data, base = baseUrl) => {
  try {
    const r = await axios.patch(
      addSsoIdToUrl(`${base}/${url}`),
      data,
      setHeader()
    );

    return r;
  } catch (error) {
    return error.response;
  }
};

const multipartRequest = (url, formData) =>
  axios
    .post(addSsoIdToUrl(`${baseUrl}/${url}`), formData, multipartHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const postRequestNoToken = async (url, data, base = baseUrl) => {
  try {
    const r = await axios.post(`${customBaseUrl.authUrl}/${url}`, data);
    return r;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const updateRequest = (url, data) =>
  axios
    .put(addSsoIdToUrl(`${baseUrl}/${url}`), data, setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const deleteRequest = (url, base = baseUrl) =>
  axios
    .delete(addSsoIdToUrl(`${base}/${url}`), setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));

const deleteRequestInt = async (url, data, base = baseUrl) => {
  try {
    const r = await axios.delete(addSsoIdToUrl(`${base}/${url}`), {
      ...setHeaders(),
      data,
    });
    console.log(r);
    return r;
  } catch (err) {
    console.log(err.response);
  }
};

const deleteRequestData = (url, data, base = baseUrl) =>
  axios
    .delete(addSsoIdToUrl(`${base}/${url}`), { data, ...setHeader() })
    .then((r) => r)
    .catch((e) => console.log(e.message));

const deleteRequestCat = (url, data) => {
  return axios
    .delete(addSsoIdToUrl(`${cat}/${url}`), data, setHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));
};

const deleteRequestNoToken = (url) =>
  axios
    .delete(`${baseUrl}/${url}`)
    .then((r) => r)
    .catch((e) => console.log(e.message));

const getPayStackRefunds = async (page = 1) =>
  axios
    .get(`https://api.paystack.co/refund?page=${page}`, setPayStackHeader())
    .then((r) => r)
    .catch((e) => console.log(e.message));
  
const putRequestNoSSoId = async (url, data, base = baseUrl) => {
      try {
        const r = await axios.put(
          `${base}/${url}`,
          data,
          setHeader()
        );
        return r;
      } catch (error) {
        console.log(error.response);
      }
};

export {
  send,
  sendWithAuth,
  sendMultipart,
  getRequestFile,
  getRequest,
  getRequestCore,
  getRequestWallet,
  getRequestWithdrawal,
  getRequestBVN,
  getRequestBills,
  getAcctRequest,
  getRequestCards,
  getRequestAlt,
  getRequestNoToken,
  getRequestNoTokenAlt,
  getRequestAggr,
  putRequestAggr,
  postRequest,
  postRequestSms,
  postSimulation,
  postRequestVerify,
  postRequestDefault,
  postRequestRole,
  postRequestWaya,
  getRequestReset,
  getRequestResetAuth,
  postRequestMult,
  getRequestForget,
  postRequestWithdrawal,
  postRequestBVN,
  postAcctRequest,
  putRequestBills,
  putRequestMult,
  postRequestCards,
  multipartRequest,
  postRequestNoToken,
  updateRequest,
  deleteRequest,
  deleteRequestInt,
  deleteRequestData,
  deleteRequestCat,
  deleteRequestNoToken,
  putRequest,
  putRequestPay,
  patchRequest,
  getPayStackRefunds,
  sendDelete,
  getRequestNoSso,
  putRequestNoSSoId,
};

export default send;
