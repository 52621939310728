import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from '../../components/Page';
import TableComponent from '../../components/table/TableComponent';
import { getRequest } from '../../services/request';
import { createUseStyles, useTheme } from 'react-jss';
import { Row } from 'simple-flexbox';
import PromoCardsComponent from '../../components/cards/PromoCardsComponent';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';

const useMatStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '100%',
  },

  tool: {
    display: 'flex',
    paddingLeft: '0px',
    borderRadius: '250px',
  },
  text: {
    width: '230%',
    fontWeight: '800px',
    borderRadius: '1px red',

    '& .MuiFormLabel-root': {
      border: 'none',
      color: 'black',
      marginLeft: 5,
    },
  },

  textfield: {
    paddingTop: '5px',
    paddingRight: '100px',
    marginRight: '600px',
  },
}));

const useStyles = createUseStyles((theme) => ({
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  cardRow: {
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    flexGrow: 1,
    marginRight: 20,
    marginLeft: 0,
    paddingLeft: '5px',
    borderRadius: '15px',
    '@media (max-width: 768px)': {
      maxWidth: 'none',
    },
  },
}));



const datas = [
  {
    firstName: 'clever',
    phone: '08103612164',
    email: 'clever@gmail.com',
    status: 'success',
    createdAt: new Date(),
  },
  {
    firstName: 'clever',
    phone: '08103612164',
    email: 'clever@gmail.com',
    status: 'success',
    createdAt: new Date(),
  },
  {
    firstName: 'clever',
    phone: '08103612164',
    email: 'clever@gmail.com',
    status: 'success',
    createdAt: new Date(),
  },
  {
    firstName: 'clever',
    phone: '08103612164',
    email: 'clever@gmail.com',
    status: 'success',
    createdAt: new Date(),
  },
  {
    firstName: 'clever',
    phone: '08103612164',
    email: 'clever@gmail.com',
    status: 'success',
    createdAt: new Date(),
  },
  {
    firstName: 'clever',
    phone: '08103612164',
    email: 'clever@gmail.com',
    status: 'success',
    createdAt: new Date(),
  },
  {
    firstName: 'clever',
    phone: '08103612164',
    email: 'clever@gmail.com',
    status: 'success',
    createdAt: new Date(),
  },
];

const PromotionsAndAwardComponent = () => {
  const matclasses = useMatStyles();
  const [users, setUsers] = useState([]);
  const [metaData, setMetaData] = useState({
    invited: 0,
    registered: 0,
    rewarded: 0,
  });
  const [headers] = useState([
    'Customer',
    'Phone Number',
    'Email Address',
    'Status',
    'Date Created',
  ]);

  const [values, setValues] = useState('');

  const handleChange = (e) => {
    setValues(e.target.value);
  };
  const getTransaction = async () => {
    const response = await getRequest('marketing/non-waya-user');

    if (!response  || response.error !== undefined) {
    return
    }
    const { data } = response.data;
    setUsers(data.results);
    setMetaData(data.metaInfo);
  };

  useEffect(() => {
    getTransaction();
  }, []);

  const RenderStat = () => {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
      <Row
        className={classes.cardsContainer}
        wrap
        flexGrow={1}
        horizontal="space-between"
        breakpoints={{ 768: 'column' }}
      >
        <Row
          className={classes.cardRow}
          wrap
          flexGrow={1}
          horizontal="space-between"
          breakpoints={{ 384: 'column' }}
        >
          <PromoCardsComponent
            title="Zero Charges on Bill Payment"
            status="Active"
            validThrough="01/09/2020 - 30/09/2020"
            validOn="Everyday"
            className={classes.miniCardContainer}
          />
          <PromoCardsComponent
            title="Referral Bonus"
            status="Active"
            validThrough="01/09/2020 - 30/09/2020"
            validOn="Everyday"
            className={classes.miniCardContainer}
          />
          <PromoCardsComponent
            title="20% on Bill Payments (Monday)"
            status="Active"
            validThrough="01/09/2020 - 30/09/2020"
            validOn="Monday"
            className={classes.miniCardContainer}
          />
        </Row>
      </Row>
    );
  };

  return (
    <Page className={matclasses.root} title="Promotions">
      <Container maxWidth={false}>
      <div className={matclasses.tool}>
          <div className={matclasses.textfield}>
            <TextField
              className={matclasses.text}
              id="outlined-basic"
              label="Search Users by Name or Email"
              variant="outlined"
              value={values}
              InputLabelProps={{
                classes: {
                  root: matclasses.cssLabel,
                  focused: matclasses.cssFocused,
                },
              }}
              InputProps={{
                matclasses: {
                  root: matclasses.cssOutlinedInput,
                  focused: matclasses.cssFocused,
                  notchedOutline: matclasses.notchedOutline,
                },
              }}
              onChange={handleChange}
            />
          </div>
          <Button
            variant="contained"
            style={{
              backgroundColor: '#FF4B01',
              color: 'white',
              fontWeight: 800,
              width: "300px"
            }}
            // onClick={() => handleOpenCRM()}
          >
            Create Promotions
          </Button>
        </div>
        <Box mt={3}>
          <RenderStat />
        </Box>
        <Box mt={3} className={matclasses.tableContainer}>
          <TableComponent data={datas} headers={headers} />
        </Box>
      </Container>
    </Page>
  );
};

export default PromotionsAndAwardComponent;
