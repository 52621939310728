import React from "react";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  cardAvatar: {
    display: "flex",
    // width: '100%',
    // justifyContent: "space-around",
  },
  h2: {
    fontSize: "14px",
    wordBreak: "keep-all",
  },
  p: {
    fontSize: "10px",
  },
  count: {
    borderRadius: "50%",
    background: theme.color.brightOrange,
    color: "white",
    padding: "5px",
    textAlign: "center",
    fontSize: "10px",
    //  marginLeft: '22px',
    float: 'right'
  },
  stats: {
    justifySelf: "end",
    alignSelf: "end",
    // marginLeft: '50px',
    float: "right",
  },
  userInfo: {
    // marginLeft: '14px',
    width: "100%",
  },
}));

const Messagepreview = ({ type }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div>
      <div className={classes.cardAvatar}>
        <Avatar alt="Remy Sharp" src="https://joeschmoe.io/api/v1/random" />
        <div className={classes.userInfo}>
          <h2 className={classes.h2}>Zuleiha Dantijani</h2>
          <p className={classes.p}>message for wayagram</p>
        </div>
        <div className={classes.stats}>
          <span className={classes.count}>2</span>
          <p>{moment().format("hh:mm")}</p>
        </div>
      </div>
    </div>
  );
};

export default Messagepreview;
