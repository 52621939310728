import { PlusOutlined, SearchOutlined, UserAddOutlined } from "@ant-design/icons";
import { Card, Input, Space, Button, Dropdown, Menu, DatePicker } from "antd";
// import { Button } from 'bootstrap'
import React, {useState} from "react";
import moment from "moment";
import OptionSearch from "./CustomInput/OptionSearch";
import AntDropdown from "./dropdown/AntDropdown";


const SearchBar = ({
  page,
  pages=true,
  total,
  commissionData,
  handleModals,
  Conversion,
  handleChange,
  setFilterType,
  count,
  selectOptions = [],
  showButtons=true,
  createButton=false,
  hideSelect=false,
  placeholder="Search Users by displayName or description",
  showRange=false,
  showSearch=true,
  setRange
}) => {
  const items = [
    {
      key: 'single',
      label: <span className="fw-bold">Create single user</span>,
    },
    {
      key: 'many',
      label: <span className="fw-bold">Create many user</span>,
    },
    {
      key: 'simulated',
      label: <span className="fw-bold">Create simulated user</span>,
    }
  ];
  const item = [
    {
      key: 'single',
      label: <span className="fw-bold">Create Single Group</span>,
    },
    {
      key: 'bulk',
      label: <span className="fw-bold">Create Bulk Group</span>,
    },
  ];
  const item1 = [
    {
      key: 'single',
      label: <span className="fw-bold">Create Single Page</span>,
    },
    {
      key: 'bulk',
      label: <span className="fw-bold">Create Bulk Page</span>,
    },
  ];
  const usersOption = [
    //  'Create single user',
    'Create many user',
    'Create simulated user',
  ];

  const handleUsersDropdown = (item) => {
    if (item === 'Create single user') handleModals('single');
    if (item === 'Create many user') handleModals('many');
    if (item === 'Create simulated user') handleModals('simulated');
  };

  const { RangePicker } = DatePicker;
  const dateFormat = "YYYY-MM-DD";

  
  return (
    <>
      <div className="w-100 d-block d-md-flex">
        <div className="w-50">
          <OptionSearch
            handleChange={handleChange}
            setFilterType={setFilterType}
            showSearch={showSearch}
            hideSelect={hideSelect}
            selectOptions={selectOptions}
            placeholder={placeholder}
          />
          <div className="text-start" style={{display: showRange ? 'flex' : 'none' }}>
            <label className="pe-2">Duration</label>
            <RangePicker
              onChange={(dates, dateStrings) =>
                setRange({
                  startDate: dateStrings[0],
                  endDate: dateStrings[1],
                })
              }
              format={dateFormat} 
            />
          </div>
        </div>
        <div className="w-50">
          <div className="text-end">
            {showButtons && <Space>
              <Conversion />
              {page !== "request" && page !== "user" && page !== "groups" && page !== "page" && (
                <button
                  onClick={() => handleModals("newPost", {})}
                  type="primary"
                  hidden={createButton}
                  style={{
                    padding: '8px 30px',
                    backgroundColor: '#FF4400',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    borderRadius: '5px'
                  }}
                >
                  <PlusOutlined />
                  <span>Create New {page}</span>
                </button>
              )}
              {page === "user" && (
                // <Dropdown
                //   overlay={
                //     <Menu
                //       items={items}
                //       onClick={(e) => handleModals(e.key, {})}
                //     />
                //   }
                //   placement={'bottomRight'}
                //   arrow={false}
                //   trigger={['click']}
                // >
                //   <button
                //     onClick={() => {}}
                //     type="primary"
                //     hidden={createButton}
                //     style={{
                //       padding: '8px 30px',
                //       backgroundColor: '#FF4400',
                //       color: 'white',
                //       display: 'flex',
                //       alignItems: 'center',
                //       gap: '5px',
                //       borderRadius: '5px'
                //     }}
                //   >
                //     <PlusOutlined />
                //     <span>Create New User</span>
                //   </button>
                // </Dropdown>
                <AntDropdown
                  dropDownOptions={usersOption}
                  handleDropdownAction={handleUsersDropdown}
                >
                  <button
                    onClick={() => {}}
                    type="primary"
                    hidden={createButton}
                    style={{
                      padding: '8px 30px',
                      backgroundColor: '#FF4400',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      borderRadius: '5px'
                    }}
                  >
                    <PlusOutlined />
                    <span>Create New User</span>
                  </button>
                </AntDropdown>
              )}
              {page === "groups" && (
                <Dropdown
                  overlay={
                    <Menu
                      items={item}
                      onClick={(e) => handleModals(e.key, {})}
                    />
                  }
                  placement={'bottomRight'}
                  arrow={false}
                  trigger={['click']}
                >
                  <button
                    onClick={() => {}}
                    type="primary"
                    hidden={createButton}
                    style={{
                      padding: '8px 30px',
                      backgroundColor: '#FF4400',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      borderRadius: '5px'
                    }}
                  >
                    <PlusOutlined />
                    <span>Create New Group</span>
                  </button>
                </Dropdown>
              )}
               {page === "page" && (
                <Dropdown
                  overlay={
                    <Menu
                      items={item1}
                      onClick={(e) => handleModals(e.key, {})}
                    />
                  }
                  placement={'bottomRight'}
                  arrow={false}
                  trigger={['click']}
                >
                  <button
                    onClick={() => {}}
                    type="primary"
                    hidden={createButton}
                    style={{
                      padding: '8px 30px',
                      backgroundColor: '#FF4400',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px',
                      borderRadius: '5px'
                    }}
                  >
                    <PlusOutlined />
                    <span>Create New Page</span>
                  </button>
                </Dropdown>
              )}
            </Space>}
          </div>
        </div>
      </div>
      <div className="row my-4">
        {page !== "groups" &&
          page !== "polls" &&
          page !== "request" &&
          page !== "moment" &&
          page !== "user" &&
          page !== "promotion" &&
          page !== "payment" &&
          page !== "report" &&
          page !== "email" &&
          page !== "push" &&
          page !== "sms" &&
          page !== "call" &&
          page !== "live" &&
          page !== "crm" &&
          page !== "admins" &&
          page !== "feedback" &&
          page !== "request_log" &&
          page !== "events" && (
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total number of {page}</p>
                <span className="fs-3 fw-bold">{total}</span>
              </Card>
            </div>
        )}
        {page === "groups" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">All Groups</p>
                <span className="fs-3 fw-bold">{total?.total_group || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Private Groups</p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_public_group || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Public Groups</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_private_group || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "polls" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total number of vote</p>
                <span className="fs-3 fw-bold">{total?.total_poll || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Votes in Progress</p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_ongoing_poll || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Finished Votes</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_ended_poll || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "request" && (
          <>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6">Total Number of Payment Request</p>
                <span className="fs-3 fw-bold">{count || 0}</span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-success">
                  Total Number of Disbursed Request{" "}
                </p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_disbursed_payment_request || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-warning">
                  Total Number of Pending Request
                </p>
                <span className="fs-3 fw-bold text-warning">
                  {total?.total_pending_payment_request || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-danger">
                  Total Number of Rejected Request
                </p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_rejected_payment_request || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "events" && (
          <>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6">All Event Created</p>
                {/* <span className='fs-3 fw-bold'>{count || 0}</span> */}
                <span className="fs-3 fw-bold text-success">
                  {total?.total_event || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-success">All Active Events </p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_active_event || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-warning">All Completed Events</p>
                <span className="fs-3 fw-bold text-warning">
                  {total?.total_closed_event || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-danger">All Upcoming Events</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_upcoming_event || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "moment" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total Moments</p>
                <span className="fs-3 fw-bold">
                  {total?.total_moments || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Total Active Moments</p>
                <span className="fs-3 fw-bold text-success">
                  {total?.active_moments || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Expired Moments</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.expired_moments || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "user" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total Number of Users</p>
                <span className="fs-3 fw-bold">{total?.all || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Total Active Users</p>
                <span className="fs-3 fw-bold text-success">
                  {total?.active || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Total Inactive Users</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.inactive || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "promotion" && (
          <>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6">Total Post Promotion Created</p>
                <span className="fs-3 fw-bold">
                {total?.total_promotion_count || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-success">
                Total Post Promotion Running{" "}
                </p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_promotion_live || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-warning">
                Total Post Promotion Ended
                </p>
                <span className="fs-3 fw-bold text-warning">
                  {total?.total_promotion_ended || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-danger">
                Total Amount Generated
                </p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_amount_spent?.sum_total || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "payment" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total Pending Payment Request</p>
                <span className="fs-3 fw-bold">{total?.total_pending_payment_request || 0}</span>
              </Card>
            </div>
            </>
        )}
        {page === "report" && (
          <>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6">Total Numbers of Report</p>
                <span className="fs-3 fw-bold">
                {total?.total_report_count || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-success">
                Total Numbers of Reported User{" "}
                </p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_reported_users || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-warning">
                Total Numbers of Reported Groups
                </p>
                <span className="fs-3 fw-bold text-warning">
                  {total?.total_reported_groups || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-danger">
                Total Numbers of Reported Posts
                </p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_reported_posts || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-danger">
                Total Numbers of Reported Pages
                </p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_reported_pages || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-danger">
                Total Numbers of Reported Events
                </p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_reported_events || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "request_log" && (
          <>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6">Total Requests</p>
                <span className="fs-3 fw-bold">
                {total?.total_request_log || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-success">
                Total approved requests{" "}
                </p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_approval_request_log || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-warning">
                Total initiated requests
                </p>
                <span className="fs-3 fw-bold text-warning">
                  {total?.total_initiated_request_log || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-3">
              <Card className="shadow">
                <p className="fs-6 text-danger">
                Total rejected requests
                </p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_rejected_request_log || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "email" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total Email Sent</p>
                <span className="fs-3 fw-bold">{total?.total_email_logs || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Total Email Received</p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_delivered || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Total Failed Email</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_failed || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "push" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total Push Notification Sent</p>
                <span className="fs-3 fw-bold">{total?.total || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Total Push Notification Delivered</p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_logged || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Total Push Notification Failed</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_failed_push || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "sms" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total SMS Sent</p>
                <span className="fs-3 fw-bold">{total?.total_sms_logs
                   || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Total SMS Delivered</p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_delivered || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Total SMS failed</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_failed || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "product" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Total Waya Commission on Product</p>
                <span className="fs-3 fw-bold text-success">
                  {commissionData?.wayagram_prod_comm?.clr_bal_amt || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "call" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total Calls</p>
                <span className="fs-3 fw-bold">{total?.total || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Total Accepted Calls</p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_logged || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Total Missed Calls</p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_failed_push || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "live" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total Live Videos Created</p>
                <span className="fs-3 fw-bold">{total?.total_group || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Active Live Videos </p>
                <span className="fs-3 fw-bold text-success">
                  {total?.total_public_group || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Ended Live Videos </p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.total_private_group || 0}
                </span>
              </Card>
            </div>
          </>
        )}
        {page === "crm" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">Total Media</p>
                <span className="fs-3 fw-bold">{total?.total_count || 0}</span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-success">Total Videos </p>
                <span className="fs-3 fw-bold text-success">
                  {total?.video_count || 0}
                </span>
              </Card>
            </div>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5 text-danger">Total Images </p>
                <span className="fs-3 fw-bold text-danger">
                  {total?.images_count || 0}
                </span>
              </Card>
            </div>
          </>
        )}

        {page === "admins" && (
          <>
            <div className="col-md-4">
              <Card className="shadow">
                <p className="fs-5">All Admin Users</p>
                <span className="fs-3 fw-bold">{total || 0}</span>
              </Card>
            </div>
          </>
        )}  
        
        
      </div>
    </>
  );
};

export default SearchBar;
