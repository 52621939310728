import React from 'react';
import { makeStyles } from '@mui/styles';
import { Modal } from 'react-bootstrap';
import { sendWithAuth } from '../../services/request';
import { error, success } from '../../services/swal-mixin';
import QRCodeFormComponent from './QRCodeFormComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    paddingBottom: 25,
    paddingLeft: 10,
    paddingRight: 10,
  },
  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 10,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: 'auto',
  },
  avatar: {
    height: 280,
    width: 280,
    minWidth: 280,
  },
}));

const EditQRCodeComponent = (props) => {
  const classes = useStyles();
  const { data, show, handleEditClose } = props;

  const handleClose = () => {
    handleEditClose();
  };

  const updateUser = async (userData) => {
    const response = await sendWithAuth(
      `users/update-user/${userData.id}`,
      userData,
      'put'
    );
    const { code, message } = response;
    if (code !== 200 || response.error === true) {
    return error(message);
    }
    success(message);
  };

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <QRCodeFormComponent data={data} submitForm={updateUser} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditQRCodeComponent;
