import React from "react";
import AdminRoles from "../../../components/admins/AdminRoles";
import AdminSideBar from "../../../components/admins/AdminSideBar";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
  },
}));

const RolesAndAccess = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <AdminSideBar />
      <AdminRoles />
      
    </div>
  );
};

export default RolesAndAccess;
