import React, { useEffect, useState } from "react";
import CollapsibleCard from "../../../components/CustomCard/CollapsibleCard";
import BlockInput from "../../../components/CustomInput/BlockInput";
import { Column, Row } from "simple-flexbox";
import { createUseStyles, useTheme } from "react-jss";
import CustomButton from "../../../components/CustomButton";
import { notification } from "antd";
import { updatePricing } from "../../../services/common";



const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    padding: "56px 21px 15px 21px",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  container: {
    width: "100%",
    padding: "35px 21px",
    backgroundColor: theme.color.white,
    borderRadius: "10px",
  },
  mainText: {
    color: theme.color.brightOrange,
    fontSize: "25px",
    fontWeight: "700",
  },
  subText: {
    color: theme.color.gray,
    fontSize: "14px",
    fontWeight: "400",
  },
  button: {
    backgroundColor: theme.color.brightOrange,
    color: theme.color.white,
    marginTop: "45px",
    width: "269px",
    height: "55px",
    fontSize: "14px",
    borderRadius: "5px",
  },
}));

const Events = ({ data }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [event, setEvent] = useState([]);
  const [value, setValue] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (data) {
      const dnt = data.filter((item) => item.product_type === "Event");
      if (dnt.length) {
        setValue(dnt[0].percent);
        setEvent(dnt[0]);
        setDescription(dnt[0]?.description);
      }
    }
  }, [data]);

  return (
    <>
      <CollapsibleCard title="Events">
      <Column className={classes.container}>
        <Column horizontal="center" vertical="center">
          <h2 className={classes.mainText}>Events</h2>
        </Column>
        <Column className={classes.contentWrapper}>
          <BlockInput placeholder="10%" 
          rightText="%" 
          label="Waya Percent"
          value={value}
          onChange={(e) => setValue(e.target.value)} />
        </Column>
        <Column className={classes.contentWrapper}>
          <BlockInput placeholder="" 
          rightText="" 
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)} />
        </Column>
        <Column horizontal="center" vertical="center">
          <CustomButton content="Save Changes" loading={false}
          className={classes.button}
          onClick={() => updatePricing(event, value, description)}/>
        </Column>
      </Column>
      </CollapsibleCard>
    </>
  );
};

export default Events;
