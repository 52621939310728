import CsvDownload from 'react-json-to-csv';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import config from '../../../services/config';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { Tabs, Table, Select, Input, Modal, Form, Button, notification, Pagination } from 'antd';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../services/request';
import {ExclamationCircleOutlined } from '@ant-design/icons';
import { hashTagColumn } from '../../../utils/dummyData';
import SearchBar from '../../../components/SearchBar';
import { getUsers } from '../../../services/helpers';

const DashboardComponent = () => {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("hashtags");
  const [filterType, setFilterType] = useState("description");
  const [metaData, setMetaData] = useState(0);
  const [page, setPage] = useState(1);
  // const [limits, setLimits] = useState(20);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [mode, setMode] = useState("");
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState("a");
  const { confirm } = Modal;
  const [form] = Form.useForm()
  const push = useNavigate ();

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `admin-get-hashtags`,
      //`admin-get-${currentTab}?profile_id=cfd2f35d-701d-495e-ac63-b886a8b78b5e&page=${page}&limit=${limits}`,
      config.postUrl
    );
    setLoading(false);
    const { data } = response?.data || {};
    const { metadata } = response?.data?.data || {};
    
    setMetaData(metadata?.total || 0)
    setPosts(data?.data || []);
    setFilteredPosts(data?.data || []);
  };

  const deActivate = async (pid, id) => {
    setLoading(true)
    const response = await deleteRequest(
     // `admin-delete-hashtag?profile_id=${pid}&hashtag_id=${id}`,
     `admin-delete-hashtag?hashtag_id=${id}`,
      config.postUrl
    );
    setLoading(false)
    const { data } = response || {};
    if (data?.status === true) {
      notification.success({
        message: "deleted",
        description:data?.message
      })
      setReload(!reload);
    } else {
      notification.warning({
        message: "error",
        description:data?.message
      })
    }
  };

  const handleConfirm = (pid, id) =>{
    confirm({
      title: `Do you want to delete these items?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        deActivate(pid, id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  useEffect(() => {
    getData();
  }, [currentTab, page, reload]);

  useEffect(() => {
    getUsers(value, setUsers);
  }, [value]);

  const handleChange = async (event) => {
    const {value} = event.target
    if (value === ''){
      setPosts(filteredPosts)
    }else{
      const res = await getRequest(`admin-search-hashtags?query=${value}`, config.postUrl);
      const { data } = res?.data || {};
      setPosts(data)
    }
  };
  
  const handleModals=(type, value)=>{
    if(type==='newPost'){
      setcreatePostModal(true)
      setMode("create")
    }
    if(type==='view'){
      const val = value?.hashtag?.replace('#',"");
      push(`/management/hashtag/users/${val}`);
    }
    // if(type==='editPost'){
    //   setcreatePostModal(true)
    //   setMode("update")
    //   setImages(value.content);
    //   form.setFieldsValue({
    //     profile_id: value.ProfileId,
    //     moment_id: value.id,
    //   })
    // }
  }

  const column = hashTagColumn(handleConfirm, handleModals)

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="hashtags.csv"
        style={{
          //pass other props, like styles
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          // border: '1px solid #a511c0',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          // marginTop: '16px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
          // textShadow: '0px 1px 0px #9b14b3',
        }}
      >
        Export Hashtags
      </CsvDownload>
    );
  };

  const handleSubmitPost = async(values) =>{
    setLoading(true);
    const newForm = new FormData();
    mode === 'update' && newForm.append('id', values.id);
    newForm.append('hashtag', values.hash_tag);
    const url = `admin-create-hashtag?profile_id=${values.profile_id}`;
    let  res = {}
    if (mode === 'create') {
      res = await postRequest(url, newForm, config.postUrl);
    }else{
      res = await putRequest(url, newForm, config.postUrl);
    }
    const {status, message} = res?.data || {}
    if (status) {
      notification.success({description:message, message:'success!'});
      setcreatePostModal(false)
      setReload(!reload);
    } else {
      notification.error({description:message, message:'Oops!'});
    }
    setLoading(false);
  };


  return (
    <div className="w-100">
      <SearchBar
        page="hashtag"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        hideSelect={true}
        placeholder="Search by username"
       
      />
      <LoadingComponent loading={loading} />
      <div>
        <Tabs onChange={(e)=>setCurrentTab(e)}>
          <Tabs.TabPane key="hashtags" tab="Hashtags">
            <div className='w-100 my-3'>
              <Table columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="trending-hashtags" tab="Trending Hashtags">
            <div className='w-100 my-3'>
              <Table columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
            </div>
          </Tabs.TabPane>
        </Tabs>
        <Pagination
          total={metaData}
          responsive={true}
          defaultCurrent={1}
          onChange={(e)=>setPage(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </div>
      <Modal
        visible={createPostModal}
        onCancel={()=> setcreatePostModal(false)}
        footer={false}
        title={<p className='text-center'>Create New Hashtag</p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Form.Item label="Hashtag Creator" name="profile_id" hidden={mode === "update"}>
            <Select
              showSearch
              value={value}
              placeholder="Search for user"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(e) => setValue(e)}
              notFoundContent={null} 
             
            >
              {users.map(user =>
                <Select.Option key={user.id}>{user.displayName}</Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item label="HashTags" name="hash_tag">
            <Input />
          </Form.Item>
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          <Form.Item className='text-center mt-4'>
            <Button size='large' loading={loading} htmlType='submit' type='primary' className='rounded px-5'>
              {mode} hashtag
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DashboardComponent;
