import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Row } from "simple-flexbox";
import Page from "../../../components/Page";
import { getRequest } from "../../../services/request";
import swalService from "../../../services/swal-mixin";
import DisplayTableComponent from "../../../components/table/DisplayTableComponent";
import LoadingComponent from "../../../components/loading/LoadingComponent";

const useMatStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    width: "100%",
  },
  tableContainer: {
    width: "100%",
    maxWidth: "100%",
  },
}));

createUseStyles((theme) => ({
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  cardRow: {
    marginTop: 30,
    "@media (max-width: 768px)": {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    flexGrow: 1,
    marginRight: 20,
    "@media (max-width: 768px)": {
      // marginTop: 20,
      maxWidth: "none",
    },
  },
}));

const UserPagesComponent = ({ userId }) => {
  const matclasses = useMatStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [headers] = useState([
    "Page Title",
    "Page Description",
    "Status",
    "Type",
    "Date Created",
  ]);

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `page/get-all-pages-a-user-follows?userId=${userId}&pageNumber=1`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );
    setLoading(false);

    const { data, status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    setData(data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Page className={matclasses.root} title="Group Accounts">
      <LoadingComponent loading={loading} />
      <Container maxWidth={false}>
        <Row>
          <Box marginBottom={2} fontWeight={600}>
            <Row vertical="center" breakpoints={{ 384: "column" }}>
              Manage Pages
            </Row>
          </Box>
        </Row>
        {/* <Box>
          <ToolbarComponent
            filter={filterOptions}
            // buttonTitle="Create New Group"
            type="group"
          />
        </Box> */}
        <Box mt={3} className={matclasses.tableContainer}>
          <DisplayTableComponent
            type={"group"}
            data={data}
            headers={headers}
            actionCol={false}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default UserPagesComponent;
