import CsvDownload from 'react-json-to-csv';
import React, { useEffect, useState } from 'react';
import converter from 'number-to-words';
import config from '../../../services/config';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { Table, Modal, notification, Pagination, Form, Input, Button } from 'antd';
import { getRequest, postRequest, patchRequest } from '../../../services/request';
import {ExclamationCircleOutlined } from '@ant-design/icons';
import { disburseColumn } from '../../../utils/dummyData';
import SearchBar from '../../../components/SearchBar';
import LogoComponent from '../../../components/sidebar/LogoComponent';
import { getUserData } from '../../../services/helpers';

const Disbursement = ({createButton = false, showRange = false, showSearch = true }) => {
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("description");
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [rejectMoal, setRejectModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [roles, setRoles] = useState([]);
  const [value, setValue] = useState({});
  const { confirm } = Modal;
  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });


  const getData = async () => {
    setLoading(true);
    let rangeQuery='';
    if (range.startDate && range.endDate) {
      rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
    }
    const response = await getRequest(
      `admin-get-pending-requests?paymentStatus=APPROVED&page=${pages}&limit=${limits}${rangeQuery}`,
      config.postUrl
    );
    setLoading(false);
    const { data } = response?.data || {};
    const { metadata } = response?.data?.data || {};
    
    setTotal(metadata?.total || 0)
    setPosts(data?.data || []);
    setFilteredPosts(data?.data || []);
  };

  const getOverview = async () => {
    const response = await getRequest(
      "admin-get-payment-request-overview",
      config.postUrl
    );
    const {data} = response?.data || {};
    setMetaData(data)
  };

  const deActivate = async (val, d) => {
    const datas = {
      payment_request_id: d.id,
      rejection_reason: val.reason
    }
    setLoading(true)
    const response = await patchRequest(
      'admin-reject-payment-request',
      datas,
      config.postUrl
    );
    setLoading(false)
    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message:"Rejected successfully",
        description:message
      })
      setRejectModal(false)
      setReload(!reload);
    } else {
      notification.warning({
        message:"error",
        description:message
      })
    }
  };
  const approve = async (d) => {
    const datas = {
      payment_request_id: d.id,
      profile_id: d.ProfileId,
      requester_role_id: roles.id
    }
    setLoading(true)
    const response = await postRequest(
      'admin-disburse-money',
      datas,
      config.postUrl
    );
    setLoading(false)
    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message:"Approved successfully",
        description:message
      })
      setReload(!reload);
    } else {
      notification.warning({
        message:"error",
        description:message
      })
    }
  };

  const handleConfirm = (action, d) =>{
    confirm({
      title: `Do you want to ${action} this?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        approve(d)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  const getAccess = async () => {
    let userData = getUserData();
    setLoading(true);
    const response = await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);

    setLoading(false);
    if (response?.data?.status == false) {
      return;
    }
    const data = response?.data?.data || [];
    setRoles(data?.Role);
  };

  useEffect(() => {
    getAccess();
    getData();
    getOverview()
  }, [pages, reload, range]);

  const handleChange = (event) => {
    const {value} = event.target
  };

  const handleModals=(type, val)=>{
    if(type==='view'){
      setViewModal(true)
      setValue(val)
    }
    if(type==='Rejected'){
      setRejectModal(true)
      setValue(val)
    }
    if(type==='reason'){
      setReasonModal(true)
      setValue(val)
    }
  }

  const column = disburseColumn(handleModals, handleConfirm)

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="payment.csv"
        style={{
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none', 
        }}
      >
        Export Request
      </CsvDownload>
    );
  };

  return (
    <div className="w-100">
      <SearchBar
        page="payment"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        count={total}
        showButtons={true}
        showRange={showRange}
        showSearch={showSearch}
        setRange={setRange}
        createButton={createButton}
      />
      <LoadingComponent loading={loading} />
      <div>
        <Table scroll={{ x: 1500 }} columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
        <Pagination
          total={total}
          responsive={true}
          defaultCurrent={1}
          onChange={(e)=>setPages(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </div>

      <Modal
        visible={rejectMoal}
        onCancel={()=> setRejectModal(false)}
        footer={false}
        title="Reject Payment"
      >
        <Form layout='vertical' onFinish={(e) => deActivate(e, value)}>
          <Form.Item label="Reason" name="reason">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className='text-center mt-4'>
            <Button size='large' htmlType='submit' type='ghost' className='rounded px-5'>
              Reject
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={reasonModal}
        onCancel={()=> setReasonModal(false)}
        footer={false}
        title="Reason For Rejection"
      >
        {value?.rejectionReason}
      </Modal>
    </div>
  );
};

export default Disbursement;
