import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { postRequestMult, postRequest } from '../../../services/request';
import { error, success } from '../../../services/swal-mixin';
import LoadingComponent from '../../../components/loading/LoadingComponent'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',
    
    justifyContent: 'space-around',
    
  },

  link: {
    marginLeft: 40,
  },
  textme: {
    minWidth: '92%',
    marginBottom: "10px"
  },
  textme2: {
    minWidth: '250%',
  },
  btnp3: {
    marginLeft: 145,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));


const BulkWithdrawComponent = (props) => {
  const classes = useStyles();
  console.log(props);
  const { data, handleOpenBulkWith, handleCloseBulkWith } = props;
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(0);
  
  const handleViewClose = () => {
    handleCloseBulkWith();
  };
  const handleViewOpen = () => {
    handleOpenBulkWith();
  };

  const handleChange = (e, newValue) => {
    console.log(e.target.files);
    setValue(e.target.files[0]);
  };
  

  const fundBulkUsers = async () => {
    if (value === 0) {
      return error('no file to upload!, please attach one');
    }
    const formdata = new FormData();
    formdata.append('file', value);
    console.log(formdata);
    setLoading(true)
    const response = await postRequest(
      `admin/withdrawal/multipleFund/excel`,
      formdata,
      'http://68.183.60.114:9086/waya-withdrawal-service'
    );
    setLoading(false)
    console.log(response);

    const { status, message } = response?.data;

    if (status === false) {
      return error(message);
    }
    
    if (status === true) {
      success(message);
    } else {
      return error('Error! please select a correct value and file');
    }
    setValue('')
    
  };
  console.log(value);

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="disp"
        style={{ marginTop: 0, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6">
                Fund User With Excel File
              </Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            
            <div className={classes.link}>
              <TextField
                className={classes.textme}
                id="outlined-select-currency-native"
                type="file"
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
          </div>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => fundBulkUsers()}
          >
            Fund Users
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BulkWithdrawComponent;
