export const VeriticalDropdown = () => {
  return (
    <svg
      width="9"
      height="26"
      viewBox="0 0 9 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49967 19.334C5.53844 19.334 6.53466 19.6676 7.26917 20.2615C8.00369 20.8553 8.41634 21.6608 8.41634 22.5007C8.41634 23.3405 8.00369 24.146 7.26917 24.7398C6.53466 25.3337 5.53844 25.6673 4.49967 25.6673C3.46091 25.6673 2.46469 25.3337 1.73017 24.7398C0.995656 24.146 0.583008 23.3405 0.583008 22.5007C0.583008 21.6608 0.995656 20.8553 1.73017 20.2615C2.46469 19.6676 3.46091 19.334 4.49967 19.334ZM4.49967 9.83398C5.53844 9.83398 6.53466 10.1676 7.26917 10.7615C8.00369 11.3553 8.41634 12.1608 8.41634 13.0007C8.41634 13.8405 8.00369 14.646 7.26917 15.2398C6.53466 15.8337 5.53844 16.1673 4.49967 16.1673C3.46091 16.1673 2.46469 15.8337 1.73017 15.2398C0.995656 14.646 0.583008 13.8405 0.583008 13.0007C0.583008 12.1608 0.995656 11.3553 1.73017 10.7615C2.46469 10.1676 3.46091 9.83398 4.49967 9.83398ZM4.49967 0.333984C5.53844 0.333984 6.53466 0.667614 7.26917 1.26148C8.00369 1.85534 8.41634 2.6608 8.41634 3.50065C8.41634 4.3405 8.00369 5.14596 7.26917 5.73982C6.53466 6.33369 5.53844 6.66732 4.49967 6.66732C3.46091 6.66732 2.46469 6.33369 1.73017 5.73982C0.995656 5.14596 0.583008 4.3405 0.583008 3.50065C0.583008 2.6608 0.995656 1.85534 1.73017 1.26148C2.46469 0.667614 3.46091 0.333984 4.49967 0.333984Z"
        fill="#575757"
      />
    </svg>
  );
};

export const GetSvgIcon = ({ type }) => {
  if (type === 'settingIcon') {
    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.165 7.5459C11.186 7.3709 11.2 7.1889 11.2 6.9999C11.2 6.8109 11.186 6.6289 11.158 6.4539L12.341 5.5299C12.446 5.4459 12.474 5.2919 12.411 5.1729L11.291 3.2339C11.221 3.1079 11.074 3.0659 10.948 3.1079L9.55498 3.6679C9.26098 3.4439 8.95298 3.2619 8.60998 3.1219L8.39998 1.6379C8.37898 1.4979 8.25998 1.3999 8.11998 1.3999H5.87998C5.73998 1.3999 5.62798 1.4979 5.60698 1.6379L5.39698 3.1219C5.05398 3.2619 4.73898 3.4509 4.45198 3.6679L3.05898 3.1079C2.93298 3.0589 2.78598 3.1079 2.71598 3.2339L1.59598 5.1729C1.52598 5.2989 1.55398 5.4459 1.66598 5.5299L2.84898 6.4539C2.82098 6.6289 2.79998 6.8179 2.79998 6.9999C2.79998 7.1819 2.81398 7.3709 2.84198 7.5459L1.65898 8.4699C1.55398 8.5539 1.52598 8.7079 1.58898 8.8269L2.70898 10.7659C2.77898 10.8919 2.92598 10.9339 3.05198 10.8919L4.44498 10.3319C4.73898 10.5559 5.04698 10.7379 5.38998 10.8779L5.59998 12.3619C5.62798 12.5019 5.73998 12.5999 5.87998 12.5999H8.11998C8.25998 12.5999 8.37898 12.5019 8.39298 12.3619L8.60298 10.8779C8.94598 10.7379 9.26098 10.5489 9.54798 10.3319L10.941 10.8919C11.067 10.9409 11.214 10.8919 11.284 10.7659L12.404 8.8269C12.474 8.7009 12.446 8.5539 12.334 8.4699L11.165 7.5459ZM6.99998 9.0999C5.84498 9.0999 4.89998 8.1549 4.89998 6.9999C4.89998 5.8449 5.84498 4.8999 6.99998 4.8999C8.15498 4.8999 9.09998 5.8449 9.09998 6.9999C9.09998 8.1549 8.15498 9.0999 6.99998 9.0999Z"
          fill="#828282"
        />
      </svg>
    );
  }

  if (type === 'greenDot') {
    return (
      <svg
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="4" fill="#27AE60" />
        <circle cx="4" cy="4" r="2" fill="#0E8741" />
      </svg>
    );
  }

  if (type === 'backArrow') {
    return (
      <svg
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.33931 10.8717L0.901955 6.08599C0.432781 5.67305 0.453163 4.93571 0.944434 4.54932L6.38179 0.272751C7.03784 -0.243247 8 0.224099 8 1.05876V10.121C8 10.9816 6.98527 11.4402 6.33931 10.8717Z"
          fill="black"
        />
      </svg>
    );
  }

  if (type === 'add') {
    return (
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0)">
          <path
            d="M7 0.706543C3.13996 0.706543 0 3.9489 0 7.9348C0 11.9207 3.13996 15.1631 7 15.1631C10.86 15.1631 14 11.9201 14 7.9348C14 3.94946 10.86 0.706543 7 0.706543ZM7 14.0433C3.73857 14.0433 1.08443 11.3031 1.08443 7.9348C1.08443 4.56646 3.73857 1.82633 7 1.82633C10.2614 1.82633 12.9156 4.56646 12.9156 7.9348C12.9156 11.3031 10.262 14.0433 7 14.0433Z"
            fill="#828282"
          />
          <path
            d="M9.71096 7.32487H7.54211V5.0853C7.54211 4.77623 7.29974 4.52539 6.99989 4.52539C6.70003 4.52539 6.45766 4.77623 6.45766 5.0853V7.32487H4.28881C3.98896 7.32487 3.74658 7.57572 3.74658 7.88478C3.74658 8.19385 3.98896 8.44469 4.28881 8.44469H6.45766V10.6843C6.45766 10.9933 6.70003 11.2442 6.99989 11.2442C7.29974 11.2442 7.54211 10.9933 7.54211 10.6843V8.44469H9.71096C10.0108 8.44469 10.2532 8.19385 10.2532 7.88478C10.2532 7.57572 10.0108 7.32487 9.71096 7.32487Z"
            fill="#828282"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="14"
              height="14.4565"
              fill="white"
              transform="translate(0 0.706543)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }

  if (type === 'plusIcon') {
    return (
      <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.845 12.8283V0.999819"
          stroke="#D7D7D7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M15.69 6.91406H2"
          stroke="#D7D7D7"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  }
};
