import {
  LOGIN,
  LOGOUT,
  USER_UPDATED,
  SET_MODAL_TYPE,
  SHOW_SMS_MODAL,
  SHOW_WAYA_MODAL,
  POST_UPDATE,
  REFER_UPDATE,
  CORP_ID,
} from '../actionTypes';

const initialState = {
  articles: [],
  isUserLoggedIn: false,
  isUserUpdated: false,
  modalType: 'user',
  showSmsModal:false,
  showWayaModal:false,
  postType: '',
  referType: '',
  corpType: '',
};

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return { ...state, isUserLoggedIn: true };
    case LOGOUT:
      return { ...state, isUserLoggedIn: false };
    case USER_UPDATED:
      return { ...state, isUserUpdated: true };
    case SET_MODAL_TYPE:
      return { ...state, modalType: action.payload };
    case SHOW_SMS_MODAL:
      return { ...state, showSmsModal: true };
    case SHOW_WAYA_MODAL:
      return { ...state, showWayaModal: true };
    case POST_UPDATE:
      return { ...state, isUserLoggedIn: true, postType: action.payload };
      case CORP_ID:
        return { ...state, isUserLoggedIn: true, corpType: action.payload };
    case REFER_UPDATE:
      return { ...state, isUserLoggedIn: true, referType: action.payload };
    default:
      return state;
  }
}

export default rootReducer;
