import CsvDownload from 'react-json-to-csv';
import React, { useEffect, useState } from 'react';
import config from '../../../services/config';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { Table, Select, Input, Modal, Form, Button, notification, Pagination, Divider, Upload } from 'antd';
import { getRequest, postRequest, putRequest, deleteRequestData } from '../../../services/request';
import {ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { pageColumn } from '../../../utils/dummyData';
import SearchBar from '../../../components/SearchBar';
import { getPageCategories, getUsers } from '../../../services/helpers';
import { InboxOutlined } from '@ant-design/icons';

const Page = ({createButton = false, showRange = false, showSearch = true}) => {
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("description");
  const [metaData, setMetaData] = useState(0);
  const [pages, setPages] = useState(1);
  const [posts, setPosts] = useState([]);
  const [singlePost, setSinglePost] = useState(null);
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [image, setImage] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [mode, setMode] = useState("");
  const [value, setValue] = useState("a");
  const [pageFollowersModal, setpageFollowersModal] = useState(false);
  const [inviteModal, showInviteModal] = useState(false);
  const { confirm } = Modal;
  const [form] = Form.useForm()
  const [category, setCategory] = useState('')
  const [createManyPostModal, setcreateManyPostModal] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const { Dragger } = Upload;

  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });


  const getData = async () => {
    setLoading(true);
    let rangeQuery='';
    if (range.startDate && range.endDate) {
      rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
    }
    const response = await getRequest(
      `admin-page/admin-get-all-pages?page=${pages}${rangeQuery}`,
      config.mainUrl
    );
    setLoading(false);
    const { data, totalPages } = response?.data?.data || {};

    setMetaData(totalPages);
    setPosts(data || []);
    setFilteredPosts(data || []);
  };

  const deActivate = async (d) => {
    const datas = {
      pageId: d.id,
      userId: d.userId,
    };
    setLoading(true)
    const response = await deleteRequestData(
      `page/admin-delete-page`,
      datas,
      config.mainUrl,
    );
    setLoading(false)

    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message:"Page deleted succefully!!",
        description:"status updated"
      })
      getData();
    } else {
      notification.warning({
        message,
        description:"error"
      })
    }
  };

  const handleConfirm = (d) =>{
    confirm({
      title: `Do you want to ${d.isDeleted? 'delete' : 'delete'} this page?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        deActivate(d)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  useEffect(() => {
    getData();
  }, [pages, reload, range]);

  useEffect(() => {
    getPageCategories(setCategories);
  }, []);

  useEffect(() => {
    getUsers(value, setUsers);
  }, [value]);

  const handleChange = (event) => {
    const {value} = event.target
    if (value === ''){
      setPosts(filteredPosts)
    }else{
      let filtered = []
      if (filterType === 'name'){
        filtered = filteredPosts.filter(post => post?.Profile?.displayName?.toLowerCase().includes(value?.toLowerCase()))
      }else if (filterType === "email"){
        filtered = filteredPosts.filter(post => post?.Profile?.email?.toLowerCase().includes(value?.toLowerCase()))
      }else{
        filtered = filteredPosts.filter(post => post?.description?.toLowerCase().includes(value?.toLowerCase()))
      }
      setPosts(filtered)
    }
  };

  const handleModals=(type, value)=>{
    if(type==='single'){
      form.resetFields()
      setcreatePostModal(true)
      setMode("create")
    }
    if(type==='bulk'){
      form.resetFields()
      setcreateManyPostModal(true)
      setMode("create")
    }
    if(type==='editPost'){
      setcreatePostModal(true)
      setMode("update")
      form.setFieldsValue({
        description:value.description,
        title:value.title,
        url:value.websiteUrl,
        profile_id: value.userId,
        category_id: `${value.categoryId},${value.category}`,
        isPublic: value.isPublic,
        page_id: value.id
      })
    }
    if(type === 'view-follower'){
      setpageFollowersModal(true)
      getFollowers(value.id);
      setSinglePost(value)
    }
  }

  const getFollowers = async (id) => {
    setFollowers([]);
    setLoading(true);
    const response = await getRequest(
      `admin-page/get-all-page-followers?pageId=${id}`,
      config.mainUrl
    );
    const { data, status } = response?.data || {};
    if(status){
      setFollowers(data || []);
    }
    setLoading(false);
  }

  const inviteUser = async ({requestTo}) => {
    setLoading(true);
    const d = {
      pageId: singlePost.id,
      requestFrom: singlePost.userId,
      requestTo
    }
    const response = await postRequest(
      `page/invite-to-a-page`,
      d,
      config.mainUrl
    );
    const {status, message} = response?.data || {};
    if(status){
      getFollowers(singlePost.id)
      notification.success({description:message, message:'success!'});
      // showInviteModal(false);
    }else{
      notification.success({description:message, message:'Oops!'});
    }
    setLoading(false);
  }

  const removeFollower = async (val) => {
    setLoading(true);
    const response = await putRequest(
      `admin-page/follow-or-un-follow`,
      val,
      config.mainUrl
    );
    const {status, message} = response?.data || {};
    if(status){
      getFollowers(val.pageId)
      notification.success({description:message, message:'success!'});
    }else{
      notification.success({description:message, message:'Oops!'});
    }
    setLoading(false);
  }

  const handleSubmitPost = async(values) =>{
    setLoading(true);
    const [id, name] = values?.category_id?.split(',') || ['', ''];
    const newForm = new FormData();
    mode === 'update' && newForm.append('pageId', values.page_id);
    newForm.append('userId', values.userId);
    newForm.append('category', name);
    newForm.append('categoryId', id);
    name === 'others' && newForm.append('customCategory', values.customCategory);
    newForm.append('description', values.description);
    newForm.append('username', values.title);
    newForm.append('title', values.title);
    newForm.append('websiteUrl', values.url);
    newForm.append('isPublic', values.isPublic);
    headerImage && newForm.append('headerImage', headerImage[0]);
    image && newForm.append('image', image[0]);
    const url = `pages/admin/create`;
    let  res = {}
    console.log(createManyPostModal, 'ASDFG', mode);

    if(createManyPostModal) {
      const formD = new FormData();
      formD.append('excelPageRecords', excelFile);
      res = await postRequest(`pages/admin/excel-upload`, formD, config.mainUrl);
    } else if (mode === 'create') {
      res = await postRequest(url, newForm, config.mainUrl);
    } else {
      res = await putRequest(url, newForm, config.mainUrl);
    }
    const {status, message} = res?.data || {}
    if (status) {
      notification.success({description:message, message:'success!'});
      setReload(!reload);
      setcreatePostModal(false)
      setcreateManyPostModal(false);
    } else {
      notification.error({description:message, message:'Oops!'});
    }
    setLoading(false);
  };

  const column = pageColumn(handleModals, handleConfirm)

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="pages.csv"
        style={{
          //pass other props, like styles
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          // border: '1px solid #a511c0',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          // marginTop: '16px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
          // textShadow: '0px 1px 0px #9b14b3',
        }}
      >
        Export Page
      </CsvDownload>
    );
  };

  const handlePreview = (e, type) => {
    const { files } = e.target;
    if (type === 'image') setImage(files);
    else setHeaderImage(files);
  };
  const props = {
    name: 'file',
    multiple: false,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    customRequest({ onSuccess }){
      setTimeout(() => {
        onSuccess("ok");
      }, 0)
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setExcelFile(info.file.originFileObj)
        console.log(info.file)
      } else if (status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files[0]);
    },
  };

  return (
    <div className="w-100">
      <SearchBar
        page="page"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        createButton={createButton}
        showRange={showRange}
        showSearch={showSearch}
        setRange={setRange}
      />
      <LoadingComponent loading={loading} />
      <div>
        <Table scroll={{ x: 1500 }} columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
        <Pagination
          total={metaData}
          responsive={true}
          defaultCurrent={1}
          onChange={(e)=>setPages(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </div>

      {/* create and update page */}
      <Modal
        visible={createPostModal}
        onCancel={()=> setcreatePostModal(false)}
        footer={false}
        title={<p className='text-center'>{mode} page</p>}
        width={600}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form} className='w-100'>
          <div className='row'>
            <Form.Item label="Profile Picture" className='col-md-6 px-3'>
              <div className="input-group">
                <input
                  onChange={(e) => handlePreview(e, 'image')}
                  type="file" className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
              </div>
            </Form.Item>
            <Form.Item label="Header Image" className='col-md-6 px-3'>
              <div className="input-group">
                <input
                  onChange={(e) => handlePreview(e, 'imageHeader')}
                  type="file" className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
              </div>
            </Form.Item>
            <Form.Item hidden name="page_id">
              <Input />
            </Form.Item>
            <Form.Item name="category_id" label="Page Category" className='col-md-6 px-3'>
              <Select onChange={(e)=>setCategory(e?.split(',')[1])}>
                <Select.Option>Select Category</Select.Option>
                {categories.map((cat, id) =>
                  <Select.Option key={id} value={`${cat.id || id},${cat.title}`}>
                    {cat.title}
                  </Select.Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item name="customCategory" label="Enter Category" className='col-md-6 px-3'
             hidden={category !== 'others'}>
              <Input />
            </Form.Item>
            <Form.Item name="userId" label="Username" className='col-md-6 px-3'>
              <Select
                showSearch
                value={value}
                placeholder="Search for user"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(e) => setValue(e)}
                // onChange={(e) => setValue(e)}
                notFoundContent={null}    
              >
                {users.map(user =>
                  <Select.Option key={user.id}>{user.displayName}</Select.Option>
                )}
              </Select>
            </Form.Item>
            <Form.Item name="title" label="Page Title" className='col-md-6 px-3'>
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Page Description" className='col-md-6 px-3'>
              <Input.TextArea />
            </Form.Item>
            <Form.Item name="url" label="Website URL" className='col-md-6 px-3'>
              <Input />
            </Form.Item>
            <Form.Item name="isPublic" label="Page Type" className='col-md-6 px-3'>
              <Select>
                <Select.Option value={true}>Public</Select.Option>
                <Select.Option value={false}>Private</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <Form.Item className='text-end mt-4'>
            <Button htmlType='submit' type='primary' className='rounded px-5' loading={loading}>
              {mode} page
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={createManyPostModal}
        onCancel={()=> setcreateManyPostModal(false)}
        footer={false}
        title={<p className='text-center'>Upload Excel Page</p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
           <Form.Item className="text-end mt-4">
            <Button
              htmlType="submit"
              type="primary"
              className="rounded px-5"
              loading={loading}
            >
              Create Bulk Page
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* user follower modal */}
      <Modal
        visible={pageFollowersModal}
        onCancel={() => setpageFollowersModal(false)}
        className='h-600p py-5 px-md-5 mx-auto'
        footer={false}
        width="60vw"
      >
        <div className='w-100 d-flex justify-content-between mt-4'>
          <div className=''>
            <p className='m-0 fs-5 fw-bold'>{pages} Followers</p>
          </div>
          <div className='w-50'>
            <div className='text-end'>
              <button onClick={() => showInviteModal(true)} className='btn btn-primary btn-sm rounded'><PlusOutlined /> Invite User</button>
            </div>
          </div>
        </div>
        <Divider type='horizontal' />
        {followers?.map((e, id)=>(
          <div className='w-100 d-flex justify-content-around' key={id}>
            <div className='text-start'>
              <p>{e?.Profile?.displayName}</p>
            </div>
            <div className='text-center'>
              <p>@{e.Profile?.username}</p>
            </div>
            <div className='text-end'>
              <button
                className='btn btn-danger btn-sm'
                onClick={() => removeFollower({pageId: e.id, userId: e.userId})}>
                Remove this follower
              </button>
            </div>
          </div>
        ))}
      </Modal>

      {/* invite modal */}
      <Modal
        visible={inviteModal}
        onCancel={()=> showInviteModal(false)}
        className='h-600p py-5 px-md-5 mx-auto'
        footer={false}
      >
        <div className='w-100 text-center mt-4'>
          <p className='fs-5 fw-bold'>Invite User To Page</p>
        </div>
        <Form layout='vertical' className='px-md-4' onFinish={inviteUser}>
          <Form.Item name="requestTo" label="Enter Users' Name">
            <Select
              showSearch
              value={value}
              placeholder="Search for user"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(e) => setValue(e)}
              notFoundContent={null}    
            >
              {users.map(user =>
                <Select.Option key={user.id}>{user.displayName}</Select.Option>
              )}
            </Select>
          </Form.Item>
          <Form.Item className='text-center mt-4'>
            <Button htmlType='submit' type='primary' className='w-100 rounded px-5'>Invite User</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Page;
