import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const OptionSearch = ({
  showSearch = true,
  hideSelect = false,
  setFilterType,
  selectOptions = [],
  handleChange,
  placeholder = "Search Users by displayName or description",
}) => {
  return (
    <div
      className="text-start"
      style={{
        display: showSearch ? "flex" : "none",
      }}
    >
      <select
        placeholder="Search by ...."
        size="large"
        className="w-50 text-orange form-select me-3 rounded border-orange"
        onChange={(e) => setFilterType(e.target.value)}
        hidden={hideSelect}
      >
        {selectOptions?.length ? (
          selectOptions.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))
        ) : (
          <>
            <option value="description">Description</option>
            <option value="username">Username</option>
            <option value="displayName">DisplayName</option>
          </>
        )}
      </select>
      <div className="me-3 w-100">
        <Input
          className="border-orange rounded"
          prefix={
            <span className="text-orange">
              <SearchOutlined />
            </span>
          }
          size="large"
          placeholder={placeholder}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default OptionSearch;
