import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { postRequest } from "../../../services/request";
import { error, success } from "../../../services/swal-mixin";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import Papa from "papaparse";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: "25px 0",
    display: "flex",
    justifyContent: "space-around",
  },
  tabContent2: {
    padding: "25px 0",

    justifyContent: "space-around",
  },

  link: {
    marginLeft: 40,
  },
  textme: {
    minWidth: "92%",
    marginBottom: "10px",
  },
  textme2: {
    minWidth: "250%",
  },
  btnp3: {
    marginLeft: 585,
    marginBottom: 20,
    backgroundColor: "#FF4B01",
    color: "white",
    marginTop: 30,
    "@media (max-width: 768px)": {
      marginLeft: 14,
    },
  },
}));

const BulkUserComponent = (props) => {
  const classes = useStyles();

  const { handleOpenBulkWaya, handleCloseBulkWaya, mode, deleteUsers } = props;
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  const handleViewClose = () => {
    handleCloseBulkWaya();
  };
  const handleViewOpen = () => {
    handleOpenBulkWaya();
  };

  const handleChange = async (e, newValue) => {
    setValue(e.target.files[0]);
  };

  const createUsers = async () => {
    if (value === 0) {
      return error("no file to upload!, please attach one");
    }
    const formdata = new FormData();
    formdata.append("excelProfileRecords", value);

    setLoading(true);
    const response = await postRequest(
      `admin-profile/wayagram-excel-bulk-profile-creation`,
      formdata,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/role-access"
    );

    setLoading(false);
    const { data, status, message } = response?.data;

    if (status === false) {
      return error(message);
    }

    if (data) {
      success(message);
    } else {
      return error("Error! please select a correct value and file");
    }
    setValue("");
    handleViewClose();
  };

  const deleteUser = async () => {
    if (value === 0) {
      return error("no file, please attach one");
    }
    if (value) {
      console.log(value);
      Papa.parse(value, {
        complete: (results) => {
          let users = results.data?.slice(1).map((user) => user[11]);
          deleteUsers(users);
        },
      });
    }
  };

  const handleSubmit = async () => {
    if (mode === "delete") {
      return deleteUser();
    } else {
      await createUsers();
    }
  };

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 150, minWidth: "100%" }}
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            padding: "0 1rem",
            borderTop: "5px solid #FF6634",
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6">
                Upload Excel Sheet to {mode === "delete" ? "Delete" : "Create"}{" "}
                Simulated Users
              </Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            <div className={classes.link}>
              <TextField
                className={classes.textme}
                id="outlined-select-currency-native"
                type="file"
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
          </div>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => handleSubmit()}
          >
            Upload Excel
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BulkUserComponent;
