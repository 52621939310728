import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Table, Pagination, Modal, notification } from "antd";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { reportCol } from "../../../utils/dummyData";
import { getRequest, deleteRequestData, patchRequest,putRequest } from "../../../services/request";
import config from "../../../services/config";
import SearchBar from '../../../components/SearchBar';
import CsvDownload from 'react-json-to-csv';
import {ExclamationCircleOutlined } from '@ant-design/icons';

const  ReportLog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [currentTab, setCurrentTab] = useState("ManagePromotion");
  const [filterType, setFilterType] = useState("REPORTER");
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [query, setQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [value, setValue] = useState("a");
  const [values, setValues] = useState("");
  const [imageModal, setShowImageModal] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const { confirm } = Modal;



  const selectOptions = [
    {
      value: "REPORTER",
      label: "Reporter",
    },
    {
      value: "REPORTEE",
      label: "Reportee",
    },
    {
      value: "SUBJECT",
      label: "Subject",
    },
    {
      value: "TYPE",
      label: "Type",
    },
    {
      value: "CATEGORY",
      label: "Category",
    },
  ];

  const handleChange = (e) => {
    setValues(e.target.value);
    setQuery(e.target.value);
  };


  const getData = async (q) => {
    let response
    if(q) {
      response = await getRequest(
        `admin-search-disputes?query=${query}&filterBy=${filterType}&page=${pages}&limit=20`,
        config.disputeUrl
      );
    }else{
      setLoading(true);
      response  = await getRequest(
        `admin-get-all-disputes?page=${pages}&limit=${limits}`,
        config.disputeUrl
      );
    };
    setLoading(false);
    const { data, metadata } = response?.data?.data || [];
    setPosts(data || []);
    setTotal(metadata?.total || 0);
    
   
    setFilteredPosts(data?.data || []);
  };
  

  const getDisputeOverview = async () => {
    const response = await getRequest(
      `admin-dispute-analysis`,
      config.disputeUrl
    );
    const { data } = response?.data || {};
    setMetaData(data);
  };

  useEffect(() => {
    getData(query);
  }, [currentTab, reload, query]);

  useEffect(() => {
    getDisputeOverview();
  }, []);

  const onBan =async (datum, ban) => {
   const response =  await putRequest (
    `admin-profile/ban-or-unban?profile_id=${datum.ProfileId}&ban=${ban}`,
    {},
      config.roleUrl
    )
    setLoading(false)
      const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message:`${ban ? 'Banned': 'Unbanned'} successfully`,
        description:message
      })
      setReload(!reload);
    } else {
      notification.warning({
        message:"error",
        description:message
      })
    }
   await getData()
   await getDisputeOverview()
  }

  const onDisable = async(datum, disable)=> {
    const response = await putRequest (
      `admin-profile/disable-or-enable?profile_id=${datum.ProfileId}&disable=${disable}`,
      {},
      config.roleUrl
      )
      setLoading(false)
      const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message:`${disable ? 'Disabled': 'Enabled'} successfully`,
        description: message
      })
      setReload(!reload);
    } else {
      notification.warning({
        message:"error",
        description:message
      })
    }
      await getData()
      await getDisputeOverview()
  }
  
  const handleConfirm = (action, d) =>{
    if(action === 'ban' || action === 'unban') {
      confirm({
        title: `Do you want to ${action} this user?`,
        icon: <ExclamationCircleOutlined />,
        content: 'Click OK to Continue',
        onOk() {
          let ban = action === 'ban' ? true : false
          onBan(d, ban)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
    if(action === 'disable' || action === 'enable') {
      confirm({
        title: `Do you want to ${action} this user?`,
        icon: <ExclamationCircleOutlined />,
        content: 'Click OK to Continue',
        onOk() {
          let disable = action === 'disable' ? true : false
          onDisable(d, disable)
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    }
  }





  const column = reportCol(handleConfirm, setPreviewImages, setShowImageModal);


  return (
    <Column>
      <Row horizontal="space-between">
      </Row>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
        <SearchBar
          page="report"
          total={metaData}
          showButtons={false}
          setFilterType={setFilterType}
          selectOptions={selectOptions}
          handleChange={handleChange}
        />
        <Table
          columns={column}
          dataSource={posts}
          rowKey="id"
          loading={loading}
          pagination={false}
        />
        <Pagination
          total={total}
          responsive={true}
          defaultCurrent={1}
          onChange={(e) => setPages(e)}
          defaultPageSize={limits}
          showSizeChanger={false}
        /> 
      </div>
    </Column>
  );
};

export default ReportLog;
