import React from 'react'
import Account from '../Logs/WayaAccount';

const PaymentDisburment = () => {
  return (
    <Account  createButton={true} showRange={true} showSearch={false} showView={false} showButton={true} />
  )
}

export default PaymentDisburment;
