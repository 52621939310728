import React from 'react';
import { Column, Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { Box } from '@mui/material';

const useStyles = createUseStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    borderRadius: 4,
    height: '100%',
    maxWidth: 330,
    width: 330,
  },
  containerMobile: {
    padding: '12px 16px 6px 16px !important',
  },
  itemContainer: {
    paddingBottom: 10,
    paddingTop: 10,
    padding: '5px 10px 0px 10px',
  },
  itemContainerMobile: {
    marginLeft: -16,
    marginRight: -16,
    paddingLeft: 16,
    paddingRight: 16,
  },
  status: {
    width: 12,
    height: 12,
    borderRadius: 30,
    backgroundColor: '#27AE60',
    marginRight: 5,
  },
  subtitle: {
    ...theme.typography.smallSubtitle,
    color: theme.color.grayishBlue2,
  },
  subtitle2: {
    color: theme.color.veryDarkGrayishBlue,
    marginLeft: 2,
  },
  title: {
    ...theme.typography.itemTitle,
    color: theme.color.veryDarkGrayishBlue,
    borderBottom: `1px solid ${theme.color.lightGrayishBlue2}`,
    marginBottom: 10,
    padding: '8px 10px 10px 10px',
  },
  itemTitle: {
    ...theme.typography.subTitle,
    color: theme.color.veryDarkGrayishBlue,
  },
  itemValue: {
    color: theme.color.grayishBlue2,
  },
}));

function PromoCardsComponent(props) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const { title, status, validThrough, validOn, containerStyles } = props;

  return (
    <Column
      flexGrow={1}
      className={[classes.container, containerStyles].join(' ')}
      breakpoints={{ 426: classes.containerMobile }}
    >
      <Row className={classes.title} horizontal="space-between">
        <Column>
          <span>{title}</span>
        </Column>
        <Box display="flex" alignItems="center">
          <div className={classes.status}></div>
          {status}
        </Box>
      </Row>
      <Column
        className={classes.itemContainer}
        breakpoints={{ 426: classes.itemContainerMobile }}
      >
        <Row horizontal="space-between" vertical="center">
          <span className={classes.itemTitle}>Valid Through</span>
          <span className={[classes.itemTitle, classes.itemValue].join(' ')}>
            {validThrough}
          </span>
        </Row>
      </Column>
      <Column
        className={classes.itemContainer}
        breakpoints={{ 426: classes.itemContainerMobile }}
      >
        <Row horizontal="space-between" vertical="center">
          <span className={classes.itemTitle}>Valid On</span>
          <span className={[classes.itemTitle, classes.itemValue].join(' ')}>
            {validOn}
          </span>
        </Row>
      </Column>
    </Column>
  );
}

export default PromoCardsComponent;
