import React, { useState, useEffect } from "react";
import CollapsibleCard from "../../../components/CustomCard/CollapsibleCard";
import { Column, Row } from "simple-flexbox";
import { createUseStyles, useTheme } from "react-jss";
import CustomButton from "../../../components/CustomButton";
import { notification } from "antd";
import { Input } from "antd";
import { HiOutlinePlusCircle } from "react-icons/hi";
import SelectInput from "../../../components/CustomInput/SelectInput";
import { getRequest, postRequest, deleteRequestData, patchRequest } from "../../../services/request";
import config from "../../../services/config";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { useNavigate } from "react-router-dom";
import slugs from "../../../resources/slugs";




const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
    width: "100%",
    padding: "56px 51px 15px 96px",
    border: "1px solid #E0E0E0",
    borderRadius: "10px",
  },
  container: {
    width: "100%",
    padding: "35px 21px",
    backgroundColor: theme.color.white,
    borderRadius: "10px",
  },
  mainText: {
    color: theme.color.brightOrange,
    fontSize: "25px",
    fontWeight: "700",
  },
  subText: {
    color: theme.color.gray,
    fontSize: "14px",
    fontWeight: "400",
  },
  button: {
    backgroundColor: theme.color.brightOrange,
    color: theme.color.white,
    marginLeft: "10px",
    marginTop:"20px",
    width: "50px",
    height: "35px",
    fontSize: "14px",
    borderRadius: "5px",
  },
  block: {
    width: "30px",
    height: "30px",
    backgroundColor: theme.color.black,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.color.white,
  },
  input: {
    width: "205px",
  },
  nairaInput: {
    padding: "0px",
  },
  nairaBox: {
    padding: "0px",
    //width: "30%",
    margin: "0px 100px 0px 23px",
  },
  addButton: {
    width: "408px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    padding: "9px 0px 9px 19px",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    cursor: "pointer",
    color: theme.color.black,
    alignSelf: "start",
    justifySelf: "start",
  },
  inputGroup: {
    padding: "0px",
    margin: "0px",
    width: "100%",
    marginBottom: "24px",
  },
}));

export const NairaInput = ({ value, onChange }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Column className={classes.nairaBox}>
      <label className={classes.subText}>Unit Price</label>
      <Input
        className={classes.nairaInput}
        style={{ width: "100px" }}
        prefix={<div className={classes.block}>₦</div>}
        value={value}
        onChange={(e) => onChange(e)}
      />
    </Column>
  );
};

export const InputGroup = ({
  id,
  onSave,
  onDelete,
  titleLabel,
  titleValue,
  price,
  length,
  quantity,
  reach,
  custom,
  days,
  onChangeInterval,
  onChangeLength,
  onChangePrice,
  onChangeQuantity,
  onChangeReach,
  onChangeDays,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [qty, setQty] = useState(quantity || 1);
  const [reachValue, setReachValue] = useState(reach || 1);
  const [priceValue, setPriceValue] = useState(price || 0);
  const [lengthValue, setLengthValue] = useState(length || 0);

  const amount = priceValue && qty ? priceValue * qty : 0;

  const handlePriceChange = (e) => {
    setQty(1);
    setPriceValue(e.target.value);
    onChangePrice(e.target.value);
  };
  const handleChangeLength = (value) => {
    setLengthValue(value);
    onChangeLength(value);
  };

  const handleQuantityChange = (value) => {
    setQty(value);
    onChangeQuantity(value);
  };

  const handleReachChange = (value) => {
    setReachValue(value);
    onChangeReach(value);
  };

  const handleClick = () => {
    onSave({
      titleValue,
      priceValue,
      reachValue,
      lengthValue,
      qty,
      id

    })
  };

  return (
    <Row
      horizontal="center"
      vertical="center"
      className={classes.inputGroup}
    >
      <Row>
        <Column>
          <label className={classes.subText}>{titleLabel}</label>
          <Input
            className={custom ? "" : classes.input}
            style={{
              padding: "5px 0px 5px",
              width: custom ? "136px" : "205px",
            }}
            placeholder="by Weekly"
            value={titleValue}
            onChange={(e) => onChangeInterval(e.target.value)}
          />
        </Column>
        {/* <NairaInput value={amount} onChange={handlePriceChange} /> */}
        {/* {custom && (
          <Column>
            <label className={classes.subText}>No of days</label>
            <Input
              placeholder="14"
              style={{ width: "69px" }}
              onChange={(e) => onChangeDays(e.target.value)}
            />
          </Column>
        )} */}
        <Column>
          <label className={classes.subText}>Length of days</label>
          <Input
            placeholder="14"
            className={custom ? "" : classes.input}
            style={{
              padding: "5px 0px 5px",
              width: "100px",
              marginLeft: "5px",
            }}
            value={lengthValue}
            onChange={(e) => handleChangeLength(e.target.value)}
          />
        </Column>
      </Row>
        <NairaInput value={amount} onChange={handlePriceChange} />
      <Column style={{ marginRight: "45px" }}>
        <label className={classes.subText}>Reach</label>
        <Input style={{ width: "69px" }} 
        placeholder="100"
        value={reachValue}
        onChange={(e) => handleReachChange(e.target.value)}/>
      </Column>
      <Column>
        <label className={classes.subText}>QTY</label>
        <Input
          value={qty}
          style={{ width: "69px" }}
          placeholder="30"
          onChange={(e)=> handleQuantityChange(e.target.value)}/>
      </Column>
      {!custom && (
      <CustomButton
                content="Save"
                className={classes.button}
                onClick={handleClick}
              />
              
              )}
              <CustomButton
                content="Delete"
                className={classes.button}
                onClick={onDelete}
              />
              
    </Row>
  );
};

const Promotion = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [pricing, setPricing] = useState("");
  const [reach, setReach] = useState("");
  const [length_of_days, setLength] = useState("");
  const [qty, setQty] = useState("");
  const [days, setDays] = useState("");
  const [interval, setInterval] = useState("");
  let navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `admin-get-promotion-pricing-configs?limit=${limits}&page=${pages}`,
      config.postUrl
    );
    const { data, metadata } = response?.data?.data || {};
    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSave = async({ priceValue, reachValue, lengthValue, qty, id }) => {
    let formData = new FormData();
    formData.append("promotion_pricing_id", id);
    formData.append("total_reach", reachValue);
    formData.append("price", priceValue);
    formData.append("default_qty", qty);
    formData.append("length_of_days", lengthValue);
    let resp = await patchRequest(
      "admin-promotion-price-config-update",
      formData,
      config.postUrl
    );
  
    const { status, data, message } = resp?.data || {};
  
    if (status) {
      notification.success({ description: message, message: "success!" });
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
  }

  const handleDelete = async(id) => {
    console.log({id});
    const datas = {
      promotion_pricing_id: id,
    };
    let resp = await deleteRequestData(
      `admin-delete-promotion-pricing-config`,
      datas,
      config.postUrl
    );
  
    const { status, data, message } = resp?.data || {};
  
    if (status) {
      notification.success({ description: message, message: "success!" });
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
  }

  return (
    <>
      <CollapsibleCard title="Promotion">
        <Column className={classes.container}>
          <Column horizontal="center" vertical="center">
            <h2 className={classes.mainText}>Promotion</h2>
          </Column>
          <Column
            className={classes.contentWrapper}
            vertical="center"
            justifyContent="space-between"
            alignContent="space-between"
          >
            <LoadingComponent loading={loading} />
            {data?.map((item) => (
              <InputGroup
                titleLabel="Plan"
                titleValue={item.interval}
                quantity={item.default_qty}
                reach={item.total_reach}
                length={item.length_of_days}
                price={item.price}
                key={item.id}
                onChangeInterval={(val) => setInterval(val)}
                onChangePrice={(val) => setPricing(val)}
                onChangeQuantity={(val) => setQty(val)}
                onChangeReach={(val) => setReach(val)}
                onChangeDays={(val) => setDays(val)}
                onChangeLength={(val) => setLength(val)}
                onSave={handleSave}
                onDelete={() => handleDelete(item.id)}
                id={item.id}
              />
            ))}
            <Row
              className={classes.addButton}
              vertical="center"
              onClick={() => navigate(slugs.add_pricing)}
            >
              <HiOutlinePlusCircle color="#FF4400" size={30} />
              <span style={{ padding: "0px 10px" }}>Add Pricing</span>
            </Row>
          </Column>
        </Column>
      </CollapsibleCard>
    </>
  );
};

export default Promotion;
