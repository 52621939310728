import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Row } from "simple-flexbox";
import Page from "../../../components/Page";
import DisplayTableComponent from "../../../components/table/DisplayTableComponent";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { getRequest, deleteRequestData } from "../../../services/request";
import swalService from "../../../services/swal-mixin";
import MiniCardComponent from "../../../components/cards/MiniCardComponents";
import { IconUsers } from "../../../assets/icons";
import { TextField } from "@mui/material";
import CsvDownload from "react-json-to-csv";

const useMatStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    width: "100%",
  },

  tool: {
    display: "flex",
    paddingLeft: "0px",
    borderRadius: "250px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  text: {
    width: "150%",

    fontWeight: "800px",
    borderRadius: "1px red",

    "& .MuiFormLabel-root": {
      border: "none",
      color: "black",
      marginLeft: 5,
    },
  },

  textfield: {
    paddingTop: "5px",
  },
  tableContainer: {
    width: "100%",
    maxWidth: "100%",
  },
}));

const useStyles = createUseStyles((theme) => ({
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
    // borderRadius: '85px',
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  cardRow: {
    marginTop: 30,
    "@media (max-width: 768px)": {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    maxWidth: "900px",
    width: "300px",

    marginLeft: 0,
    paddingLeft: "5px",
    borderRadius: "15px",

    "@media (max-width: 768px)": {
      maxWidth: "none",
    },
  },
}));

const ManagePostsComponent = () => {
  const matclasses = useMatStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(0);
  const [headers] = useState([
    "Post Creator",
    "Post Caption",
    "Images",
    "Likes",
    "Comments",
    // 'Date Created',
  ]);

  const [values, setValues] = useState("");

  const handleChange = (e) => {
    setValues(e.target.value);
  };

  const getData = async (page, size) => {
    const pages = page ? page : 1;
    const limits = size ? size : 20;
    setLoading(true);
    const response = await getRequest(
      `admin-get-all-posts?page=${pages}&limit=${limits}`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/all-posts/"
    );
    setLoading(false);
    console.log(response.data.data);
    const { data } = response.data.data;
    const { metadata } = response.data.data;

    setMetaData(metadata.total);
    setData(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const deActivate = async (user) => {
    const datas = {
      post_id: user.id,
    };

    setLoading(true);
    const response = await deleteRequestData(
      "admin-delete-post",
      datas,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/all-posts"
    );
    setLoading(false);

    const { status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    swalService.success(message);
    getData();
  };

  const Conversion = () => {
    return (
      <CsvDownload
        data={data}
        filename="private_User.csv"
        style={{
          boxShadow: "inset 0px 1px 0px 0px #e184f3",

          backgroundColor: "#27AE60",
          borderRadius: "6px",

          display: "inline-block",
          cursor: "pointer",
          color: "#ffffff",
          fontSize: "15px",
          marginTop: "16px",
          height: "40px",
          fontWeight: "bold",
          padding: "6px 24px",
          textDecoration: "none",
        }}
      >
        Export Posts
      </CsvDownload>
    );
  };

  const RenderStat = () => {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
      <Row
        className={classes.cardsContainer}
        wrap
        flexGrow={1}
        horizontal="space-between"
        breakpoints={{ 768: "column" }}
      >
        <LoadingComponent loading={loading} />
        <Row
          className={classes.cardRow}
          wrap
          flexGrow={1}
          horizontal="space-between"
          breakpoints={{ 384: "column" }}
        >
          <MiniCardComponent
            className={classes.miniCardContainer}
            title="Total Number of Posts"
            value={metaData}
            icon={IconUsers}
            iconColor="#FF5349"
            // style={{ marginRight: "300px"}}
          />
        </Row>
      </Row>
    );
  };

  const filteredPost = data?.filter((person) => {
    return (
      person?.description?.toLowerCase().includes(values?.toLowerCase()) ||
      person?.Profile.displayName?.toLowerCase().includes(values?.toLowerCase())
    );
  });

  return (
    <Page className={matclasses.root} title="Manage Posts">
      <Container maxWidth={false}>
        <div className={matclasses.tool}>
          <div className={matclasses.textfield}>
            <TextField
              className={matclasses.text}
              id="outlined-basic"
              label="Search Users by Name or posts"
              variant="outlined"
              onChange={handleChange}
            />
          </div>
          <Conversion />
          {/* <Button
            variant="contained"
            style={{
              backgroundColor: '#FF4B01',
              color: 'white',
              fontWeight: 800,
            }}
            // onClick={() => handleOpenCRM()}
          >
            Create Post
          </Button> */}
        </div>
        <Box mt={3}>
          <RenderStat />
        </Box>
        <Box mt={3} className={matclasses.tableContainer}>
          <DisplayTableComponent
            type="post"
            data={filteredPost}
            getPage={getData}
            metaData={metaData}
            headers={headers}
            delete={deActivate}
            actionCol={true}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ManagePostsComponent;
