import React from 'react';
import styled from 'styled-components';
import { GetSvgIcon } from '../svg';

export default function CreateCard({ title, handleClick }) {
  return (
    <CreateWrapper onClick={handleClick}>
      <div className="mx-auto my-auto text-center">
        <GetSvgIcon type="plusIcon" />
        <p>{title}</p>
      </div>
    </CreateWrapper>
  );
}

const CreateWrapper = styled.div`
  background: #ffffff;
  border: 1px dashed #c2c2c2;
  box-sizing: border-box;
  border-radius: 4px;
  height: 145px;
  display: flex;
  color: #d7d7d7;
`;
