import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import { postRequest } from '../../../services/request';
import { error, success } from '../../../services/swal-mixin';
import { Modal } from 'react-bootstrap';
import LoadingComponent from '../../../components/loading/LoadingComponent';

const userTypes = [
  {
    value: '',
    label: 'none',
  },
  {
    value: 'true',
    label: 'Admin',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    // background: theme.color.wayaPink,
    background: '#FF6634',
    color: '#ffffff',
    // padding: '8px 16px',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  cardContent: {
    padding: 15,
  },
}));

const PasswordComponent = (props) => {
  const { data, handlePinOpen, handlePinClose } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const state = {
    oldPin: '',
    newPin: '',
    otp: '',
    phoneOrEmail: '',
  };

  const [values, setValues] = useState(state);
  const [val, setVal] = useState([]);

  const handleViewClose = () => {
    handlePinClose();
  };
  const handleViewOpen = () => {
    handlePinOpen();
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const changePin = async () => {
    setLoading(true);
    console.log(typeof val);
    const response = await postRequest('api/v1/pin/change-pin', values);
    console.log(response);
    setLoading(false);
    const message = response.data.message;
    if (!response) {
      return error(message);
    }
    setValues(state);
    handleViewClose();
    success(message);
  };
  console.log(values);

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 50, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <form autoComplete="off" noValidate>
            <Card>
              <CardHeader subheader="Change pin." title="Change users pin" />

              <Divider />
              <CardContent className={classes.cardContent}>
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="Enter phone or Email"
                      name="phoneOrEmail"
                      onChange={handleChange}
                      required
                      value={values.phoneOrEmail}
                      SelectProps={{ native: true }}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      label="Old Pin"
                      name="oldPin"
                      onChange={handleChange}
                      type="password"
                      value={values.oldPin}
                      key={1}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="New Pin"
                      name="newPin"
                      onChange={handleChange}
                      type="password"
                      required
                      value={values.newPin}
                      key={2}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="OTP"
                      name="otp"
                      onChange={handleChange}
                      required
                      value={values.otp}
                      key={3}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              {/* <Divider /> */}

              <Box display="flex" justifyContent="center" p={2}>
                <Button
                  style={{ padding: '12px 40px', fontWeight: 800 }}
                  color="primary"
                  className={classes.createButton}
                  variant="contained"
                  onClick={() => changePin()}
                >
                  proceed
                </Button>
              </Box>
            </Card>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

PasswordComponent.propTypes = {
  className: PropTypes.string,
};

export default PasswordComponent;
