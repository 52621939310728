import {
  arrayOf,
  element,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useNavigate  } from 'react-router-dom';
import { Column, Row } from 'simple-flexbox';
import { IconArrowUp } from '../../assets/icons';
import SLUGS from '../../resources/slugs';
import { getUserData } from '../../services/helpers'





const useStyles = createUseStyles((theme) => ({
  arrowContainer: {
    position: 'absolute',
    top: -19,
    right: 15,
  },
  dropdownButton: {
    alignItems: 'center',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    padding: 0,
    outline: 'none',
  },
  dropdownContainer: {
    position: 'relative',
  },
  dropdownItemsContainer: {
    background: 'white',
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    borderRadius: 5,
    minWidth: 170,
    padding: 0,
    position: 'absolute',
    width: '100%',
    zIndex: 2,
    top: ({ position }) => position.top,
    right: ({ position }) => position.right,
    bottom: ({ position }) => position.bottom,
    left: ({ position }) => position.left,
    '& button:first-of-type:hover div > svg > path': {
      fill: theme.color.paleBlue,
    },
  },
  dropdownItem: {
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    fontSize: 16,
    outline: 'none',
    padding: '10px 10px',
    '&:hover': {
      background: theme.color.lightGrayishBlue,
    },
    '&:after': {
      content: '" "',
      display: 'block',
      position: 'relative',
      bottom: -10,
      width: '100%',
      height: 1,
      background: theme.color.paleBlue,
    },
    '&:last-child:after': {
      content: '',
      display: 'none',
    },
  },
  profileWrapper: {
    padding: 10,
    height: 200,
    width: '100%',
  },
  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    border: '1px solid rgba(0,0,0,.125)',
    borderRadius: '.25rem',
    width: '100%',
  },
  editProfileWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editProfile: {
    border: `1px solid ${theme.color.lighterGray}`,
    color: theme.color.lightGray,
    borderRadius: '.25rem',
    cursor: 'pointer',
    fontSize: 12,
    padding: 5,
    margin: 5,
    '&:hover': {
      background: theme.color.lightGrayishBlue,
    },
  },
  avatarWrapper: {
    margin: '5px auto',
  },
  avatar: {
    height: 70,
    width: 70,
    minWidth: 70,
    borderRadius: 70,
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
  },
  name: {
    ...theme.typography.itemTitle,
    fontSize: 12,
    textAlign: 'center',
  },
  role: {
    color: theme.color.lightGray,
  },
}));

function DropdownComponent({
  label,
  options,
  position,
  isUserProfile = false,
}) {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const theme = useTheme();
  const classes = useStyles({ theme, position });
  const push = useNavigate ();

  function onDropdownClick() {
    setUserMenuOpen((prev) => !prev);
  }

  function onItemClick(onClick) {
    setUserMenuOpen(false);
    onClick && onClick();
  }

  const onEditProfileClick = () => {
    console.log('edit profile clicked');
    push(SLUGS.edit_profile);
  };

  let user = getUserData()
  // console.log(user.firstName)

  return (
    <Column className={classes.dropdownContainer}>
      <button className={classes.dropdownButton} onClick={onDropdownClick}>
        {label}
      </button>
      {userMenuOpen && (
        <Column className={classes.dropdownItemsContainer}>
          {isUserProfile && (
            <Row className={classes.profileWrapper}>
              <div className={classes.profileCard}>
                <div className={classes.editProfileWrapper}>
                  <div
                    className={classes.editProfile}
                    onClick={() => onItemClick(onEditProfileClick)}
                  >
                    Edit Profile

                  </div>
                </div>

                <div className={classes.avatarWrapper}>
                  <img
                    src="https://www.minervastrategies.com/wp-content/uploads/2016/03/default-avatar.jpg"
                    alt="avatar"
                    className={classes.avatar}
                  />
                </div>
                <div className={classes.name}>
                  <span>
                    {user?.firstName} {user?.lastName}
                  </span>
                </div>
                <div className={classes.name}>
                  <span className={classes.role}>WAYA Admin</span>
                </div>
              </div>
            </Row>
          )}
          {options.map((option, index) => (
            <button
              key={`option-${index}`}
              className={classes.dropdownItem}
              onClick={() => onItemClick(option.onClick)}
            >
              <span className={option.style}>{option.label}</span>
              {index === 0 && (
                <div className={classes.arrowContainer}>
                  <IconArrowUp />
                </div>
              )}
            </button>
          ))}
        </Column>
      )}
    </Column>
  );
}

DropdownComponent.propTypes = {
  label: oneOfType([string, element]),
  options: arrayOf(
    shape({
      label: oneOfType([string, arrayOf(element)]),
      onClick: func,
    })
  ),
  position: shape({
    top: number,
    right: number,
    bottom: number,
    left: number,
  }),
};

DropdownComponent.defaultProps = {
  position: {
    top: 52,
    right: -6,
  },
};

export default DropdownComponent;
