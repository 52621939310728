import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

import LoadingComponent from '../../../components/loading/LoadingComponent';
import WalletTransfer from '../../transactions/Wallet/WalletTransfer';
import Wallet2WalletTransfer from '../../transactions/Wallet/AccountIndex';
import Withdrawal from '../../transactions/Withdrawals/selectWithdraw';

const userTypes = [
  {
    value: 'super admin',
    label: 'Super Admin',
  },
  {
    value: true,
    label: 'Admin',
  },
  {
    value: false,
    label: 'User',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',

    '&:hover': {
      background: '#2D9CDB',
    },
  },
  cardContent: {
    padding: 15,
    // marginLeft: "300px"
  },
  text: {
    width: '215%',
  },
}));

const ChargeMode = [
  {
    value: '--',
    label: 'none',
  },
  {
    value: 'Wallet transfer',
    label: 'Wallet transfer',
  },

  {
    value: 'Wallet transfer to others',
    label: 'Wallet transfer to others',
  },
  {
    value: 'Bills payment',
    label: 'Bills payment',
  },
  {
    value: 'Withdraw',
    label: 'Withdraw',
  },
];

const ChargeComponent = ({
  handleClose,
  data,
  getTransaction,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const state = {
    type: '',
  };
  const [values, setValues] = useState(state);
  const [show, setShow] = useState(false);
  const [showWal, setShowWal] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);

  console.log(data);
  const handleWalClose = () => {
    setShow(false);
  };
  const handleWalOpen = () => {
    setShow(true);
  };
  const handleWal2Close = () => {
    setShowWal(false);
  };
  const handleWal2Open = () => {
    setShowWal(true);
  };
  const handleOpenSelectWith = () => {
    setShowWithdraw(true);
  };
  const handleCloseSelectWith = () => {
    setShowWithdraw(false);
  };
  console.log(showWal);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
    openTransfer(value);
  };
  console.log(values.type);

  const openTransfer = (value) => {
    console.log(value);
    if (value === 'Wallet transfer to others') {
      handleWalOpen();
      // console.log('here');
    }else if (value === 'Wallet transfer'){
      handleWal2Open();

    }else if(value === 'Withdraw'){
      handleOpenSelectWith()
    }
  };

  return (
    <>
      <form
        autoComplete="off"
        // onSubmit={handleSubmit}
        noValidate
        className={clsx(classes.root, className)}
        {...rest}
      >
        <LoadingComponent loading={loading} />
        <Card>
          <CardHeader title="Perform Transaction For User" />
          <Divider />
          <CardContent className={classes.cardContent}>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  className={classes.text}
                  fullWidth
                  label="Select Transaction Type"
                  name="type"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  value={values.type}
                  key={2}
                  variant="outlined"
                >
                  {ChargeMode.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
          {/* <Divider /> */}
          {values.type === 'Wallet transfer' || showWal ? (
            <Wallet2WalletTransfer
              data={data}
              showWal={showWal}
              handleWal2Close={handleWal2Close}
              handleWal2Open={handleWal2Open}
            />
          ) : null}
        </Card>
      </form>
      {values.type === 'Wallet transfer to others' || show ? (
        <WalletTransfer
          data={data}
          show={show}
          handleWalClose={handleWalClose}
          handleWalOpen={handleWalOpen}
        />
      ) : null}
      {values.type === 'Withdraw' || showWithdraw ? (
        <Withdrawal
          data={data}
          showSelectWith={showWithdraw}
          handleOpenSelectWith={handleOpenSelectWith}
          handleCloseSelectWith={handleCloseSelectWith}
        />
      ) : null}
    </>
  );
};

ChargeComponent.propTypes = {
  className: PropTypes.string,
};

export default ChargeComponent;
