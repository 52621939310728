import React from "react";
import Avatar from "@mui/material/Avatar";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  cardAvatar: {
    display: "flex",
    width: '100%',
  },
  h2: {
    fontSize: "14px",
  },
  p: {
    fontSize: "10px",

  },
  userInfo: {
    marginLeft: '14px',
  }
}));

const UserContact = ({type}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div>
      <div className={classes.cardAvatar}>
        <Avatar alt="Remy Sharp" src="https://joeschmoe.io/api/v1/random" />
        <div className={classes.userInfo}>
          <h2 className={classes.h2}>Dantijani</h2>
          <p className={classes.p}>09087654321</p>
        </div>
      </div>
    </div>
  );
};

export default UserContact;
