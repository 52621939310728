import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { postRequest, getRequest } from '../../../services/request';
import { success, error } from '../../../services/swal-mixin';
import { getUserData } from '../../../services/helpers';
import {currencyFormat} from '../../../services/helpers';
import LoadingComponent from '../../../components/loading/LoadingComponent'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '0px 0',
    // display: 'flex',
    justifyContent: 'center',
    // marginLeft: 280,
  },
  tabContent3: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    // marginRight: 100,
    // marginLeft: 100,
  },

  link: {
    marginLeft: 60,
    marginBottom: 40,
  },
  textme: {
    minWidth: '83%',
    height: "2%",
  },
  textme2: {
    minWidth: '83%',
    height: "2%",
  },
  btnp3: {
    marginLeft: 200,
    marginBottom: 0,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 0,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));


const Type = [
  {
    value: '0',
    label: 'none',
  },
  {
    value: 'LOCAL',
    label: 'Transfer',
  },
  {
    value: 'CASH',
    label: 'Cash',
  },
];

const UserComponent = (props) => {
  const classes = useStyles();
  console.log(props);
  const { data, handleOpenUserFund, handleCloseUserFund } = props;
  const user = getUserData();
  console.log(user.id);
  const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };
  const unique = uid();
  const [loading, setLoading] = useState(false);
  const state = {
    amount: '',
    debitAccountNumber: '',
    benefAccountNumber: '',
    paymentReference: unique,
    tranCrncy: 'NGN',
    tranNarration: '',
    tranType: '',
    userId: user.id,
  };

  const datas = data.userId;
  const [value, setValue] = React.useState(state);
  const [userWallet, setUserWallet] = React.useState([]);


  console.log(data);
  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setValue((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const FundWallet = async () => {
    setLoading(true)
    const response = await postRequest(
      `api/v1/wallet/admin/sendmoney`,
      value,
      'http://68.183.60.114:9086'
      );
      setLoading(false)
      console.log(response);
      const { message, code, status } = response.data;
    if (status === false) {
      return error(message);
    }
    console.log(message)
    setValue(state)
    success(message);
    UserWallet()
  };
  const UserWallet = async () => {
    setLoading(true)
    const response = await getRequest(
      `api/v1/wallet/accounts/${datas}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false)
    console.log(response);
    const { data, status, message } = response.data;
    setUserWallet(data);
  };

  useEffect(() => {
    UserWallet();
  }, []);

  let newWallets = []
  newWallets.push(userWallet)

  console.log(value, datas, typeof userWallet, newWallets);

  return (
    <div id="textf" className={classes.root}>
      <LoadingComponent  loading={loading}/>
      <div className={classes.tabContent}>
        <div className={classes.name2}>
          {/* <Typography variant="h6">Fund User</Typography> */}
        </div>
      </div>
      <div  className={classes.tabContent2}>
        <div id="text2" className={classes.link}>
          <p>select wallet to transfer from</p>
          <TextField
            id="outlined-select-currency-native"
            select
            name="debitAccountNumber"
            // label=""
            className={classes.textme2}
            value={value.debitAccountNumber}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            {userWallet.length > 1 ? (
              <>
              {userWallet.map((option) => (
                <option key={option.value} value={option.accountNo}>
                  {option.accountNo}-{currencyFormat(option.clr_bal_amt)}
                  
                </option>
              ))}
              </>
              
            ) : (
            // {userWallet.map((option) => (
              <option key={userWallet.userId} value={userWallet.accountNo}>
                {userWallet.accountNo}-{currencyFormat(userWallet.clr_bal_amt)}
                
              </option>
            // ))}
            )}
            
          </TextField>
        </div>
        <div id="text2" className={classes.link}>
          <TextField
            id="outlined-select-currency-native"
            select
            name="tranType"
            label="select transaction type"
            className={classes.textme2}
            value={value.tranType}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            {Type.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
                {/* <Divider /> */}
              </option>
            ))}
          </TextField>
        </div>
        <div id="text2" className={classes.link}>
          <TextField
            className={classes.textme}
            id="outlined-select-currency-native"
            name="benefAccountNumber"
            label="Beneficiary Account No. "
            select
            value={value.benefAccountNumber}
            placeholder="please select User Account No."
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          >
            {userWallet.map((option) => (
              <option key={option.value} value={option.accountNo}>
                {option.accountNo}-{currencyFormat(option.clr_bal_amt)}
                
              </option>
            ))}
          </TextField>
        </div>
        <div id="text2" className={classes.link}>
          <TextField
            className={classes.textme}
            id="outlined-select-currency-native"
            name="amount"
            label="Amount "
            value={value.amount}
            placeholder="please enter amount"
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          ></TextField>
        </div>
        <div id="text2" className={classes.link}>
          <TextField
            className={classes.textme}
            id="outlined-select-currency-native"
            name="tranNarration"
            label="Description "
            value={value.tranNarration}
            placeholder="please enter description"
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          ></TextField>
        </div>
      </div>

      <Button
        variant="contained"
        className={classes.btnp3}
        onClick={() => FundWallet()}
      >
        Fund
      </Button>
    </div>
  );
};

export default UserComponent;
