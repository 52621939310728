import React from 'react';
import { Column } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { Row } from 'react-bootstrap';

const useStyles = createUseStyles((theme) => ({
  container: {
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    borderRadius: 4,
    cursor: 'pointer',

    padding: '16px 32px 16px 32px',
    '&:hover': {
      '&:nth-child(n) > span': {
        color: theme.color.lightBlue,
      },
    },
    '@media (max-width: 768px)': {
      width: 320,
    },
  },
  cardContent: {
    justifyContent: 'center',
    marginRight: 0,
    marginLeft: 0,
  },
  title: {
    ...theme.typography.cardTitle,
    color: 'black',
    minWidth: 12,
    marginLeft: 0,
    fontSize: 20,
    fontWeight: 800,
  },
  value: {
    color: theme.color.veryDarkGrayishBlue,

    fontWeight: 800,
    letterSpacing: '1px',
    marginBottom: 12,
    marginLeft: 5,
    marginTop: 20,
    lineHeight: '50px',
    fontSize: 20,
  },
}));

function MiniCardComponent({
  className = '',
  title,
  value,
  icon: Icon,
  iconColor,
  style,
}) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const composedClassName = [classes.container, className].join(' ');
  console.log('>>', style);
  return (
    <Row
      horizontal="space-between"
      className={composedClassName}
      breakpoints={{ 768: 'column' }}
    >
      <Row
        horizontal="space-between"
        className={classes.cardContent}
        breakpoints={{ 384: 'column' }}
      >
        <Column>
          <span className={classes.title} style={style?.title || {}}>
            {title}
          </span>
          <span className={classes.value}>{value}</span>
        </Column>
      </Row>
    </Row>
  );
}

export default MiniCardComponent;
