import React from 'react'
import EmailManagement from '../EmailManagement';

const Email = () => {
  return (
    <EmailManagement createButton={true} showRange={true} showSearch={false} />
  )
}

export default Email;
