import React from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import { useTheme } from 'react-jss';
import { Column } from 'simple-flexbox';
import { Button } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    minWidth: 320,
    marginBottom: 10,
    maxHeight: 160,
  },
  iconWrapper: {
    height: '45px',
    width: '45px',
    backgroundColor: '#F2F2F2',
    borderRadius: '50%',
  },
  actionButton: {
    background: '#FFFFFF',
    border: '1px solid #C4C4C4',
    color: '#4F4F4F',
    '&:hover': {
      background: '#FF6634',
      border: '1px solid #FF6634',
    },
  },
}));

const ActionCardComponent = ({
  title,
  subheader,
  iconColor,
  icon: Icon,
  isTextValue,
  buttonTitle = 'Generate Users',
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>
      <CardHeader
        avatar={
          <Column horizontal="center" vertical="center">
            <Column
              horizontal="center"
              vertical="center"
              className={classes.iconWrapper}
            >
              <div className={classes.icon}>
                {Icon && (
                  <Icon
                    fill={iconColor ? iconColor : theme.color.black}
                    opacity={'0.4'}
                  />
                )}
              </div>
            </Column>
          </Column>
        }
        title={title}
        subheader={subheader}
      />
      <CardContent>
        <Box display="flex" justifyContent="flex-end">
          {isTextValue ? (
            <Box fontWeight={800}>{buttonTitle}</Box>
          ) : (
            <Button color="primary" className={classes.actionButton}>
              {buttonTitle}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ActionCardComponent;
