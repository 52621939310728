import React, { useEffect, useState } from "react";
import {
  getRequest,
  deleteRequestCat,
  postRequest,
  patchRequest,
} from "../../services/request";
import CsvDownload from "react-json-to-csv";
import LoadingComponent from "../../components/loading/LoadingComponent";
import config from "../../services/config";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { pollsColumn } from "../../utils/dummyData";
import SearchBar from "../../components/SearchBar";
import PollImagePreview from "./PollImagePreview";
import { getUsers } from "../../services/helpers";
import StatCard from "../../components/StatCard";
import DonationProgress from "../../components/DonationProgress";
import moment from "moment";
import color from "../../theme/color";

const ManageDonationsComponent = ({createButton = false, showRange = false, showSearch = true }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [filterType, setFilterType] = useState("username");
  const [limits, setLimits] = useState(20);
  const [options, setOptions] = useState([]);
  const [pollCat, setPollCat] = useState("");
  const [pollId, setPollId] = useState("");
  const [value, setValue] = useState("a");
  const [query, setQuery] = useState("");
  const [poll, setPoll] = useState({})
  const[userId, setSso_key] = useState('')
  const [tempData, setTempData] = useState({
    option: "",
    file: "",
    description: "",
    preview: "",
  });
  const [users, setUsers] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [mode, setMode] = useState("");
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [createOptionModal, setCreateOptionModal] = useState(false);
  const [date, setDate] = useState({});
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const [editPollOPtions, setEditPollOPtions] = useState(false);
  const amountRaised = viewData?.Donate?.reduce(
    (sum, amt) => (sum += amt?.amount),
    0
  );
  const pollAmount = (val) => viewData?.PollVotes?.reduce(
    (sum, amt) => {
      if(amt.choice == val){
        sum += 1
      }
      return sum;
    } ,
    0
  );
  const dateFormat = "YYYY/MM/DD h:mm";
  const selectOptions = [
    {
      value: "username",
      label: "Username",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "phone",
      label: "Phone",
    },
  ];

  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) {
      setQuery(value);
    }
  };

  const handleSubmitPost = async (value) => {
    if (options.length < 2) {
      notification.warning({
        message: "Oops!",
        description: `poll options must be 2 atleast`,
      });
      return;
    }
    setLoading(true);
    const newForm = new FormData();
    const startDate = new Date(date[0]);
    const endDate = new Date(date[1]);
    newForm.append("profile_id", value.profile_id);
    newForm.append("pollCategory", value.pollCategory);
    newForm.append("type", "user");
    newForm.append("description", value.description);
    newForm.append("voteLimit", value.voteLimit);
    newForm.append("amount", value?.amount || 0);
    newForm.append("isPaid", value.type);
    newForm.append("mentions", "");
    newForm.append("hashtags", "");
    newForm.append("startDate", startDate.toLocaleDateString());
    newForm.append("endDate", endDate.toLocaleDateString());
    newForm.append("startTime", startDate.getTime());
    newForm.append("endTime", endDate.getTime());
    

    const url = `admin-poll-create`;
    const res = await postRequest(url, newForm, config.postUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      await addPollOptions(data.id);
      notification.success({ description: message, message: "success!" });
      setcreatePostModal(false);
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };

  const addPollOptions = async (id) => {
    options?.forEach(async (opt, i) => {
      const newFormOpt = new FormData();
      newFormOpt.append("pollId", id);
      newFormOpt.append("pollOptionId", opt.id);
      newFormOpt.append("option", opt.option);
      newFormOpt.append("position", i + 1);
      newFormOpt.append("short_desc", opt.description || '');
      opt.file && newFormOpt.append("file", opt.file);
      if (mode === "create" || !opt.id){        
        newFormOpt.delete("pollOptionId")
        await postRequest(
          "admin-poll-option-create",
          newFormOpt,
          config.postUrl
        );
      } else {
        newFormOpt.delete("pollId")
        await patchRequest(
          "admin-poll-option-update",
          newFormOpt,
          config.postUrl
        );
      }
    });
    getData();
  };

  const getData = async (q) => {
    let response;
    // if (q) {
    //   response = await getRequest(
    //     `/admin-search-polls?page=${pages}&limit=${limits}&query=${q}&filterBy=${filterType}`,
    //     config.postUrl
    //   );
    // } else {
      let rangeQuery='';
      const filter = q ? `&${filterType}=${q}` : '';
      if (range.startDate && range.endDate) {
        rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
      }
      setLoading(true);
      response = await getRequest(
        `admin-get-polls?page=${pages}&limit=${limits}${rangeQuery}${filter}`,
        config.postUrl
      );
    // }
    const { data, metadata } = response?.data?.data || {};
    setTotal(metadata?.total || 0);
    setFilteredData(data);
    setData(data);
    setLoading(false);
  };

  const getOverview = async () => {
    const response = await getRequest(
      "admin-get-poll-overview",
      config.postUrl
    );
    const { data } = response?.data || {};
    setMetaData(data);
  };

  const handleManage = async (action, id, profile_id) => {
    const payload = {
      action,
      poll_id: id,
      profile_id,
    };
    setLoading(true);
    const res = await patchRequest(
      `admin-manage-poll-post`,
      payload,
      config.postUrl
    );
    const { status, message } = res?.data || {};
    if (status) {
      notification.success({ description: message, message: "success!" });
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    getData();
  };

  const handleConfirm = (status, id, ProfileId) => {
    confirm({
      title: `Do you want to ${status} this poll?`,
      icon: <ExclamationCircleOutlined />,
      content: "Click OK to Continue",
      onOk() {
        handleManage(status, id, ProfileId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    getUsers(value, setUsers);
  }, [value]);

  useEffect(() => {
    getData();
    getOverview();
  }, [pages, range]);

  useEffect(() => {
    getData(query);
  }, [query]);

  const Conversion = () => {
    return (
      <CsvDownload
        data={data}
        filename="donations.csv"
        style={{
          boxShadow: "inset 0px 1px 0px 0px #e184f3",
          backgroundColor: "#27AE60",
          borderRadius: "6px",
          display: "inline-block",
          cursor: "pointer",
          color: "#ffffff",
          fontSize: "15px",
          height: "40px",
          fontWeight: "bold",
          padding: "6px 24px",
          textDecoration: "none",
        }}
      >
        Export Polls
      </CsvDownload>
    );
  };

  const handleModals = (type, value) => {
    if (type === "newPost") {
      setcreatePostModal(true);
      setOptions([]);
      setMode("create");
    }
    if (type === "editPost") {
      setEditPollOPtions(true);
      setMode("update");
      setPollId(value.id);
      setPoll(value);
      setOptions(value.PollOptions);
      setPollCat(value?.pollCategory)
    }

    if (type === "view") {
      console.log(value);
      setViewModal(true);
      setViewData(value);
    }
  };

  const handlePreview = (e) => {
    const { files } = e.target;
    if (files.length) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setTempData({
          ...tempData,
          file: files[0],
          preview: reader.result,
        });
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const remove = (i) => {
    const sliced = options.filter((item, index) => index !== i);
    setOptions(sliced);
  };

  const handleAddOption = () => {
    setOptions((item) => [
      ...item,
      {
        // id: options.length + 1,
        option: tempData.option,
        file: tempData.file,
        description: tempData.description,
        preview: tempData.preview,
      },
    ]);
    setTempData({ option: '', file: '', description: '', preview: '' });
  };

  const handleSubmitUpdate = async (value) => {
    setLoading(true);
    const newForm = new FormData();
    const startDate = new Date(value.startDate);
    const endDate = new Date(value.endDate);
    newForm.append("poll_id", value.id);
    newForm.append("profile_id", value.ProfileId);
    newForm.append("pollCategory", value.pollCategory);
    newForm.append("description", value.description);
    newForm.append("voteLimit", value.voteLimit);
    newForm.append("amount", value?.amount || 0);
    newForm.append("showResult", value.showResult);
    newForm.append("isPaid", value.isPaid);
    newForm.append("mentions", "");
    newForm.append("hashtags", "");
    newForm.append("startDate", moment(startDate).format('MM/DD/YYYY'));
    newForm.append("endDate", moment(endDate).format('MM/DD/YYYY'));
    newForm.append("startTime", startDate.getTime());
    newForm.append("endTime", endDate.getTime());
    newForm.append("millisecUpdatedAt", "0");
    const url = `admin-update-poll-post`;
    const res = await patchRequest(url, newForm, config.postUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      await addPollOptions(data.id);
      notification.success({ description: message, message: "success!" });
      setcreatePostModal(false);
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };

  const handleSubmitPollUpdate = async () => {
    if (options.length < 2) {
      notification.warning({message:'Oops!', description:`poll options must be 2 atleast` });
      return;
    }
    await addPollOptions(pollId);
  }
  
  const column = pollsColumn(handleModals, handleConfirm);
  return (
    <div className="w-100">
      <SearchBar
        page="polls"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        hideSelect={false}
        placeholder="Search by username"
        createButton={createButton}
        showRange={showRange}
        showSearch={showSearch}
        setRange={setRange}
      />
      <LoadingComponent loading={loading} />
      <Table
        scroll={{ x: 1500 }}
        columns={column}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
      <Pagination
        total={total}
        responsive={true}
        defaultCurrent={1}
        onChange={(e) => setPages(e)}
        defaultPageSize={limits}
        showSizeChanger={false}
      />
      <Modal
        visible={createPostModal}
        onCancel={() => setcreatePostModal(false)}
        footer={false}
        title={<p className="text-center">{mode} New Poll</p>}
      >
        <Form layout="vertical" onFinish={handleSubmitPost}>
          <Form.Item
            label="Creator"
            name="profile_id"
            hidden={mode === "update"}
          >
            <Select
              showSearch
              value={value}
              placeholder="Search for user"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(e) => setValue(e)}
              notFoundContent={null}
            >
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id}>
                  {user.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Vote Type" name="type">
            <Select placeholder="Vote Type" className="w-100">
              <Select.Option value={true}>Paid</Select.Option>
              <Select.Option value={false}>Free</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Vote Category" name="pollCategory">
            <Select
              placeholder="Vote Category"
              className="w-100"
              onChange={(e) => setPollCat(e)}
            >
              <Select.Option value="text">Text</Select.Option>
              <Select.Option value="image">Image</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="Vote Limit" name="voteLimit">
            <Input type="number" min="1"/>
          </Form.Item>
          <Form.Item label="Amount" name="amount">
            <Input type="number" min="1"/>
          </Form.Item>
          <Form.Item label="Select Start and End Date" name="date">
            <RangePicker
              showTime
              onChange={(dates, dateStrings) => setDate(dateStrings)}
            />
          </Form.Item>
          <Form.Item className="text-center mt-4">
            <Space>
              <Button
                size="large"
                htmlType="submit"
                type="ghost"
                className="rounded px-5"
              >
                Create Poll
              </Button>
              <Button
                size="large"
                type="primary"
                className="rounded px-5"
                onClick={() => setCreateOptionModal(true)}
              >
                Edit Poll Options
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={createOptionModal}
        onCancel={() => setCreateOptionModal(false)}
        footer={false}
        title={<p className="text-center">Edit Poll Option</p>}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <input
                className="w-100"
                placeholder="Option"
                value={tempData.option}
                onChange={(e) =>
                  setTempData({
                    ...tempData,
                    option: e.target.value,
                  })
                }
              />
              <input
                className="w-100 mt-5"
                placeholder="description"
                value={tempData.description}
                onChange={(e) =>
                  setTempData({
                    ...tempData,
                    description: e.target.value,
                  })
                }
              />
              {mode === "update" && (
                <Form.Item className="mt-5">
                  <Select placeholder="Vote Category" className='w-100' value={pollCat} onChange={(e)=>setPollCat(e)}>
                    <Select.Option value="text">Text</Select.Option>
                    <Select.Option value="image">Image</Select.Option>
                    {/* <Select.Option value="video">Video</Select.Option> */}
                  </Select>
                </Form.Item>
              )}
              {pollCat=== 'image' && (
                <input
                  className="w-50 mt-5"
                  type="file"
                  placeholder="add image"
                  accept="image/*"
                  onChange={handlePreview}
                />
              )}
              <Button
                className="btn btn-primary mb-3 mt-2"
                onClick={handleAddOption}
              >
                Add Option
              </Button>
            </div>
          </div>
          <PollImagePreview data={options} isImage={pollCat === 'image'} remove={remove} />
          <Button
            className="btn btn-primary mb-3 mt-2"
            onClick={() => {
              mode === "create" ? setCreateOptionModal(false) : handleSubmitPollUpdate()}}
          >
            {mode === "create" ? "Done" : "Update"}
          </Button>
        </div>
      </Modal>

      <Modal
        visible={editPollOPtions}
        onCancel={() => setEditPollOPtions(false)}
        footer={false}
        title={<p className="text-center">{mode} New Poll</p>}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <label>Editor</label>
              <input
                className="w-100 mb-4"
                placeholder="Editor"
                value={poll?.Profile?.displayName}
                readOnly={true}
              />
              <label>Post Detail</label>
              <input
                className="w-100 mb-4"
                placeholder="Ask your question?"
                value={poll.description}
                onChange={(e) =>
                  setPoll({
                    ...poll,
                    description: e.target.value,
                  })
                }
              />

                {mode === "update" && (
                <Form.Item className="mb-4">
                  <label>Show Result</label>
                  <Select
                    placeholder="Hide Result"
                    className="w-100"
                    value={poll.showResult}
                    onChange={(e) =>
                      setPoll({
                        ...poll,
                        showResult: e,
                      })
                    }
                  >
                    <Select.Option value={true}>YES</Select.Option>
                    <Select.Option value={false}>NO</Select.Option>
                  </Select>
                </Form.Item>
              )}

              {mode === "update" && (
                <Form.Item className="mb-4">
                  <label>Vote Category</label>
                  <Select
                    placeholder="Vote Category"
                    className="w-100"
                    value={poll?.pollCategory}
                    onChange={(e) =>
                      setPoll({
                        ...poll,
                        pollCategory: e,
                      })
                    }
                  >
                    <Select.Option value="text">Text poll</Select.Option>
                    <Select.Option value="image">Image poll</Select.Option>
                  </Select>
                </Form.Item>
              )}

              {mode === "update" && (
                <Form.Item className="mb-4">
                  <label>Vote Type</label>
                  <Select
                    placeholder="vote Type"
                    className="w-100"
                    value={poll.isPaid}
                    onChange={(e) =>
                      setPoll({
                        ...poll,
                        isPaid: e,
                      })
                    }
                  >
                    <Select.Option value={true}>PAID</Select.Option>
                    <Select.Option value={false}>FREE</Select.Option>
                  </Select>
                </Form.Item>
              )}
              {poll.isPaid && (
                <input
                  className="w-100 mb-5"
                  min="1"
                  type="number"
                  placeholder="amount"
                  value={poll.amount}
                  onChange={(e) =>
                    setPoll({
                      ...poll,
                      amount: e.target.value,
                    })
                  }
                />
              )}

              <label>Vote Limit</label>
              <Input
                className="mb-4"
                min="1"
                type="number"
                value={poll?.voteLimit}
                onChange={(e) =>
                  setPoll({
                    ...poll,
                    voteLimit: e.target.value,
                  })
                }
              />
              <label>Select Start and End Date</label>
              <RangePicker
                showTime
                onChange={(dates, dateStrings) =>
                  setPoll({
                    ...poll,
                    startDate: dateStrings[0],
                    endDate: dateStrings[1],
                  })
                }
                value={[
                  moment(poll?.startDate, dateFormat),
                  moment(poll?.endDate, dateFormat),
                ]}
                format={dateFormat}
              />
              <Row>
              <Button
                onClick={() => handleSubmitUpdate(poll)}
                size="large"
                htmlType="submit"
                type="ghost"
                className="rounded px-5 mt-3 mx-auto text-center d-flex text-light"
                style={{ background: color.brightOrange }}
              >
                Submit
              </Button>
              <Button
                onClick={() => setCreateOptionModal(true)}
                size="large"
                htmlType="submit"
                type="ghost"
                className="rounded px-5 mt-3 mx-auto text-center d-flex text-light"
                style={{ background: color.brightBlue }}
              >
                Edit poll Option
              </Button>
              </Row>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        visible={viewModal}
        onCancel={() => setViewModal(false)}
        footer={false}
        width={655}
        height={447}
        title=""
      >
        <div className="row mt-2">
          <StatCard
            title="Total Number of votes"
            value={viewData?.PollVotes?.length}
            type="amount"
          />
          <StatCard
            title="Total Amount Received"
            value={viewData?.amount || 0}
            type="amount"
          />
          <StatCard
            title="Time Remaining"
            value={viewData?.endTime}
            type="date"
          />
        </div>
        <p>{viewData?.description}</p>
        {
          viewData?.PollOptions?.map((poll) =>  (
            <DonationProgress
              description={poll?.option}
              current={pollAmount(poll?.option) || 0}
              total={viewData?.PollVotes?.length}
              title=""
            />
            )
          )
        }
        <h5 className="progress-bar-text">
          {viewData?.amount || 0} Raised out of {viewData.totalAmount}
        </h5>
      </Modal>
    </div>
  );
};

export default ManageDonationsComponent;
