// import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import SLUG from '../resources/slugs'
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: 15,
  marginLeft: 25,
  paddingBottom:10
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <RootStyle className="page404" title="404 Page Not Found | Minimal-UI">
      <Container>
        
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <div>
              <Typography variant="h3" paragraph>
                Sorry, page not found!
              </Typography>
            </div>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? 
              Or cannot access on this device size?
              Be sure to check your spelling or open on a larger screen.
            </Typography>

            <div>
              <Box
                component="img"
                src="/static/illustrations/illustration_404.svg"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </div>

            <Button to={SLUG.dashboard} size="large" variant="contained" component={RouterLink}>
              Go to Home
            </Button>
          </Box>
       
      </Container>
    </RootStyle>
  );
}
