import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar: {
    width: '300px',
  },
  productInner: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px',
    
  },
  productImg: {
    marginRight: '0.8rem',
    border: '1px solid red',
    width: '100%',
    height: '250px',
    borderRadius: '14px',
  },
  // imgInner: {
  //   width: '100%',
  //   height: '50px',
  // },
}));

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
};

const ViewProductComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();
  console.log(data);
  const name = data ? data.name : '';
  const description = data ? data.description : '';
  const currentState = data.currentState;
  const amount = data ? data.amount : '';
  const images = data ? data.ProductImages : '';

  return (
    <>
      <Formik
        initialValues={{
          name,
          description,
          currentState,
          images,
          amount,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('First name is required'),
          description: Yup.string().max(255).required('Last name is required'),
          currentStatus: Yup.boolean().required('Group type is required'),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
             <div>
                <p style={{textAlign: 'center', fontSize: '20px', marginBottom: '30px', fontWeight: 800}}> Product Post Information</p>
              </div>
            <div>
              <div>
                {images?.length ? (
                  <Carousel
                    responsive={responsive}
                    ssr
                    autoPlay
                    infinite={false}
                    autoPlaySpeed={10000}
                    keyBoardControl
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    // beforeChange={() => setState(true)}
                    // afterChange={() => setState(false)}
                    // containerClass="first-carousel-container container"
                  >
                    {images &&
                      images.map((data) => (
                        <div
                          key={data.id}
                          className="moment-card"
                          style={{
                            backgroundImage:
                              data.ProductId === 'image'
                                ? `url(${data.imageUrl})`
                                : 'none',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '100% 100%',
                            textAlign: 'center',
                            // backgroundColor: data.type !== 'image' && '#000000a8',
                            // color: data.type !== 'image' && '#fff',
                          }}
                        >
                          <div>
                            <div className={classes.productInner}>
                              <img
                                className={classes.productImg}
                                src={data.imageUrl}
                                alt="Profile"
                              />
                              {/* <h6>{data.id}</h6> */}
                            </div>
                          </div>
                        </div>
                      ))}
                  </Carousel>
                ) : (
                  <div>{/* <Skeleton height={150} width={150} /> */}</div>
                )}
                <Grid container spacing={3}>
                  {/* <Grid item lg={6} md={6} xs={12}> */}
                  {/* <div className={classes.profileCard}>
                      Product Images
                      {values.images && (
                        values.images.map((image)=>{

                        <div className={classes.avatarWrapper}>
                          <img
                            src={image}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div>
                        })
                      )}
                    </div> */}
                  {/* </Grid> */}
                  {/* <Grid item lg={6} md={6} xs={12}>
                    <div className={classes.profileCard}>
                      Group Image
                      {values.imageUrl && (
                        <div className={classes.avatarWrapper}>
                          <img
                            src={values.imageUrl}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div>
                      )}
                    </div>
                  </Grid> */}
                  {/* <Grid item lg={6} md={6} xs={12}>
                    
                  </Grid> */}
                  {/* <Grid item lg={6} md={6} xs={12}>
                    
                  </Grid> */}
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Product Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Product Status"
                      name="currentState"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentState}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Amount"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.amount}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                </Grid>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

ViewProductComponent.propTypes = {
  className: PropTypes.string,
};

export default ViewProductComponent;
