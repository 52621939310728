import React from 'react';
import { createUseStyles } from 'react-jss';
import { Column } from 'simple-flexbox';
import LogoComponent from '../components/sidebar/LogoComponent';
import { Formik } from 'formik';
import { useNavigate  } from 'react-router-dom';
import SLUGS from '../resources/slugs';
import { TextField } from '@mui/material';
import { getRequest } from '../services/request';
import swalService from '../services/swal-mixin';
import * as Yup from 'yup';
import { formatPhoneNumber } from '../services/helpers';
import { customBaseUrl, httpGet } from '../services/http';

const useStyles = createUseStyles((theme) => ({
  centreBlock: {
    textAlign: 'center',
  },
  bottomContent: {
    margin: '0 auto',
    position: 'relative',
    height: '15vh',
    bottom: 0,
  },
  footer: {
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
  },
  textLink: {
    color: theme.color.lightGray,
    marginLeft: 5,
    cursor: 'pointer',
    '&:hover': {
      color: theme.color.black,
      textDecoration: 'underline',
    },
  },
  verticalSpacing: {
    paddingTop: 20,
    paddingBottom: 20,
    '@media (max-width: 768px)': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '38px',
    letterSpacing: '0.2px',
    color: theme.color.black,
  },
  instruction: {
    fontSize: 16,
    textAlign: 'center',
    lineHeight: '25px',
    color: theme.color.textGrey,
    paddingTop: 20,
    paddingBottom: 20,
    '@media (max-width: 768px)': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  imageContainer: {
    marginLeft: 32,
    marginRight: 32,
  },
  formList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 20,
  },
  formInput: {
    width: '100%',
    marginBottom: '10px',
    padding: '10px',
    border: 'none',
    background: theme.color.darkGray,
  },
  submitButton: {
    width: '100%',
    margin: '15px auto',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '4px',
    background: theme.color.wayaPink,
    color: '#f2f2f2',
    letterspacing: '.09em',
  },
}));

const RequestOtpComponent = () => {
  const push = useNavigate ();
  const classes = useStyles();

  async function register() {
    console.log('register clicked');
    push(SLUGS.register);
  }

  return (
    <Column className={classes.wrapper}>
      <div className={classes.forgotPasswordCard}>
        <div className={classes.verticalSpacing}>
          <LogoComponent isColored={true} />
        </div>
        <div className={classes.title}>Request OTP</div>
        <div className={classes.verticalSpacing}>
          <Formik
            initialValues={{ email: '', phone: '' }}
            validationSchema={Yup.object().shape({
              phone: Yup.string().min(8).required('Phone is required'),
            })}
            validate={(values) => {
              // const errors = {};
              // if (!values.email) {
              //   errors.email = 'Required';
              // } else if (
              //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              // ) {
              //   errors.email = 'Invalid email address';
              // }
              // return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              const phoneNumber = formatPhoneNumber(values.phone);
              const response = await httpGet(
                `/api/v1/auth/resend-otp/${phoneNumber}`,
                customBaseUrl.authUrl
              );
              const { message, status } = response;
              if (!status) {
                return swalService.error(message);
              }
              return swalService.success(message);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <div>
                  <div className={classes.formList}>
                    {/* <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      required
                      value={values.email}
                      variant="outlined"
                    /> */}
                  </div>
                  <div className={classes.formList}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                      required
                      value={values.phone}
                      variant="outlined"
                      type="phone"
                    />
                  </div>
                  <div className={classes.formList}>
                    <button
                      id="resetButton"
                      disabled={isSubmitting}
                      className={classes.submitButton}
                      type="submit"
                    >
                      Request OTP
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          <div
            className={classes.centreBlock}
            style={{ marginTop: 10, marginBottom: 70 }}
          ></div>
        </div>
      </div>
    </Column>
  );
};

export default RequestOtpComponent;
