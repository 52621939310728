import CsvDownload from 'react-json-to-csv';
import React, { useEffect, useState } from 'react';
import converter from 'number-to-words';
import config from '../../../services/config';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { Table, Modal, notification, Pagination, Form, Input, Button } from 'antd';
import { getRequest, postRequest,patchRequest } from '../../../services/request';
import {ExclamationCircleOutlined } from '@ant-design/icons';
import { accountColumn } from '../../../utils/dummyData';
import SearchBar from '../../../components/SearchBar';
import LogoComponent from '../../../components/sidebar/LogoComponent';
// import SearchInput from "../../../components/CustomInput/SearchInput";
import CustomButton from "../../../components/CustomButton";
import { FiEdit3 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import slugs from "../../../resources/slugs";
import { Row } from "simple-flexbox";
import { createUseStyles, useTheme } from 'react-jss';
import { getUserData } from '../../../services/helpers';
// import { IoMdArrowBack } from "react-icons/io";



const useStyles = createUseStyles((theme) => ({
  button: {
    padding: "10px",
    backgroundColor: theme.color.brightOrange,
    color: theme.color.white,
    fontSize: "14px",
    borderRadius: "5px",
    marginLeft:'55rem',
  },
}));


const WayaAccount = ({ createButton = false, showRange = false, showSearch = true, showView = true, showButton=false }) => {
  const [loading, setLoading] = useState(false);
  const [filterType, setFilterType] = useState("USERNAME");
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [posts, setPosts] = useState([]);
  const [roleId, setRoleId] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [rejectMoal, setRejectModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState({});
  // const [values, setValues] = useState("");
  const { confirm } = Modal;
  const [query, setQuery] = useState("");
  const userData = getUserData();
  // const selectOptions = [
  //   {
  //     value: "USERNAME",
  //     label: "Username",
  //   },
  //   {
  //     value: "HANDLE",
  //     label: "Handle",
  //   }
  // ];

  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });
  

  let navigate = useNavigate();

  const theme = useTheme();
  const classes = useStyles({ theme });


  const getData = async (q) => {
    let response
    if(q) {
      response = await getRequest(
        `admin-search-pay-requests?query=${q}&filterBy=${filterType}&page=${pages}&limit=20`,
        config.postUrl
      );
    }else{
      let rangeQuery='';
      if (range.startDate && range.endDate) {
        rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
      }
      setLoading(true);
    response = await getRequest(
      `admin-get-requests?page=${pages}&limit=${limits}${rangeQuery}`,
      config.postUrl
    );
    }
    setLoading(false);
    const { data } = response?.data || {};
    const { metadata } = response?.data?.data || {};
    
    setTotal(metadata?.total || 0)
    setPosts(data?.data || []);
    setFilteredPosts(data?.data || []);
  };

  const getOverview = async () => {
    const response = await getRequest(
      "admin-get-payment-request-overview",
      config.postUrl
    );
    const {data} = response?.data || {};
    setMetaData(data)
  };

  const deActivate = async (val, d) => {
    const datas = {
      payment_request_id: d.id,
      rejection_reason: val.reason,
      // requester_role_id: roleId
    }
    setLoading(true)
    const response = await patchRequest(
      'admin-reject-payment-request',
      datas,
      config.postUrl
    );
    setLoading(false)
    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message:"Rejected successfully",
        description:message
      })
      setRejectModal(false)
      setReload(!reload);
    } else {
      notification.warning({
        message:"error",
        description:message
      })
    }
  };

  const approve = async (d) => {
    const datas = {
      // profile_id: d.ProfileId,
      payment_request_id: d.id,
      requester_role_id: roleId
    }
    setLoading(true)
    const response = await postRequest(
      'admin-disburse-money',
      datas,
      config.postUrl
    );
    setLoading(false)
    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message:"Disbursed successfully",
        description:message
      })
      setReload(!reload);
    } else {
      notification.warning({
        message:"error",
        description:message
      })
    }
  };

  const handleConfirm = (action, d) =>{
    confirm({
      title: `Do you want to ${action} this funds?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        approve(d)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const getUserRole = async () => {
    const response = await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);
    if (response.data.status) {
      const id = response?.data?.data?.RoleId
      setRoleId(id);
    }
  }

  useEffect(() => {
    getData(query);
    getOverview()
  }, [pages, reload, query, range]);

  useEffect(() => {
    getUserRole();
  }, []);

  // const handleChange = (event) => {
  //   const {value} = event.target
  //   if (value === ''){
  //   }
  //   setValues(event.target.value);
  //   setQuery(event.target.value);
  // };
  

  const handleModals=(type, val)=>{
    if(type==='view'){
      setViewModal(true)
      setValue(val)
    }
    if(type==='Rejected'){
      setRejectModal(true)
      setValue(val)
    }
    if(type==='reason'){
      setReasonModal(true)
      setValue(val)
    }
  }

  const column = accountColumn(handleModals, handleConfirm)

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="account.csv"
        style={{
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none', 
        }}
      >
        Export
      </CsvDownload>
    );
  };

  return (
    <div>
   
    <Row hidden={!showView} horizontal="space-between">
      <CustomButton content="" 
        className={classes.button}
        onClick={()=> navigate(slugs.waya_account)}
      >
          <FiEdit3 />
          <span >View Transaction History</span>
        </CustomButton >
      </Row>  
    <div className="w-100 my-3">
      </div>
    <div className="w-100">
      <SearchBar
        handleModals={handleModals}
        Conversion={Conversion}
        //handleChange={handleChange}
        setFilterType={setFilterType}
       // selectOptions={selectOptions}
        count={total}
        createButton={true}
        showButtons={showButton}
        showRange={showRange}
        showSearch={showSearch}
        setRange={setRange}
      />
      <LoadingComponent loading={loading} />
      <div>
        <Table scroll={{ x: 1500 }} columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
        <Pagination
          total={total}
          responsive={true}
          defaultCurrent={1}
          onChange={(e)=>setPages(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </div>
      <Modal
        visible={viewModal}
        onCancel={()=> setViewModal(false)}
        footer={false}
        title={
          <div>
            <LogoComponent isColored={true} />
            <p className='text-center'>Transaction Receipts</p>
          </div>
        }
      >
        <div className="row mb-2">
          <div className="col-6">
            <p>Transaction Date:</p>
          </div>
          <div className="col-6">
            <p>{new Date(value.updatedAt)?.toDateString()}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <p>Reference number:</p>
          </div>
          <div className="col-6">
            <p>{value.paymentRef}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <p>Sender:</p>
          </div>
          <div className="col-6">
            <p>WAYAGRAM</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <p>Transaction amount:</p>
          </div>
          <div className="col-6">
            <p>{value.amount}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <p >Amount in words:</p>
          </div>
          <div className="col-6">
            <p>{converter.toWords(value?.amount || 0)} naira</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <p>Transaction type:</p>
          </div>
          <div className="col-6">
            <p>LOCAL</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <p>Recipient:</p>
          </div>
          <div className="col-6">
            <p>{value?.RecipientProfile?.displayName}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <p>Recipient Account:</p>
          </div>
          <div className="col-6">
            <p>{value.accountNo}</p>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-6">
            <p>Recipient Bank:</p>
          </div>
          <div className="col-6">
            <p>WAYABANK</p>
          </div>
        </div>
      </Modal>

      <Modal
        visible={rejectMoal}
        onCancel={()=> setRejectModal(false)}
        footer={false}
        title="Reject Payment"
      >
        <Form layout='vertical' onFinish={(e) => deActivate(e, value)}>
          <Form.Item label="Reason" name="reason">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className='text-center mt-4'>
            <Button size='large' htmlType='submit' type='ghost' className='rounded px-5'>
              Reject
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={reasonModal}
        onCancel={()=> setReasonModal(false)}
        footer={false}
        title="Reason For Rejection"
      >
        {value?.rejectionReason}
      </Modal>
    </div>
    </div>
  );
};

export default WayaAccount;
