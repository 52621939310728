import React, { useState, useEffect } from "react";
import config from "../../services/config";
import { Modal, Form, Button, notification, Upload, Input, Switch, Table } from "antd";
import { getRequest, patchRequest, postRequest, putRequest } from "../../services/request";
import { hideLoader, showLoader } from "../../utils/loader";
// import WayaTable from "../management/WayagramUsers/wayagramTable";

const ManageCategories = () => {
  const [loading, setLoading] = useState(false);
  const [createCategory, setCreateCategory] = useState(false);
  const [reload, setReload] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoryName, setCategoryName] = useState('');

  const handleSubmit = async () => {
    showLoader();
    setLoading(true);
    let postData = {
      name:  categoryName,
    }
    // return console.log({ postData });
    const url = 'add-category'
    const res = await postRequest(url, postData, config.postUrl, true);
    const { status, message } = res?.data || {};
    if (status) {
        hideLoader();
      notification.success({ description: message, message: "success!" });
      setCreateCategory(false);
      setCategoryName('');
      setReload(!reload);
    } else {
      hideLoader();
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };
 
  const columns = [
    {
      title: 'Category',
      dataIndex: 'name',
      key: 'category',
      render: (text) => <a>{text}</a>,
    },
  ];

  
  const getCategories = async () => {
    const response = await getRequest(
        `categories?page=1&limit=100`,
        config.postUrl,
        true
      );
    const { data, status } = response?.data || [];
    console.log({ response });
    if (status) {
      setCategories(data?.data);
    }
    setLoading(false);
    return status;
  };


  useEffect(() => {
    getCategories();
  }, [reload]);
  return (
    <div className="w-100">
      <div className="text-end">
      <Button
        size="large"
        loading={loading}
        htmlType="submit"
        type="primary"
        className="rounded px-5 my-3"
        onClick={() => setCreateCategory(true)}
    >
        Add New Category
    </Button>
    </div>
    <Modal
        visible={createCategory}
        onCancel={() => {
          setCreateCategory(false)
          setCategoryName('');
        }}
        footer={false}
        title={<p className="text-center">Create Category </p>}
      >
        <div style={{ position: 'relative' }} class="col-md-12">
          <div className="my-2">
          <input
            placeholder="New Category"
            type='text'
            class='form-control'
            id='validationCustom01'
            value={categoryName}
            required
            onChange={(e) => setCategoryName(e.target.value)}
          />
          </div>
         
          <div style={{ marginTop: 10, textAlign: 'center' }}>
          <Button
            size="large"
            loading={loading}
            htmlType="submit"
            type="primary"
            className="rounded px-5 my-3"              
            onClick={handleSubmit}
          >
                Add
          </Button>
        </div>

        </div>
        
    </Modal>
    <Table columns={columns} dataSource={categories} />
    </div>
  );
};

export default ManageCategories;
