import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "react-bootstrap";
import GroupFormComponent from "./PostImage";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: "25px 0",
  },

  profileCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundClip: "border-box",
    marginBottom: 20,
    borderRadius: ".25rem",
    width: "100%",
  },
  avatarWrapper: {
    margin: "5px auto",
  },
  avatar: {
    height: 150,
    width: 150,
    minWidth: 150,
    borderRadius: 100,
    // border: `1px solid ${theme.color.lightGrayishBlue2}`,
  },
}));

const CreatePageComponent = (props) => {
  const classes = useStyles();

  const { data, show, handleClosePostImage } = props;

  const handleClose = () => {
    handleClosePostImage();
  };
  // const handleOpen = () => {
  //   handleOpenMoment();
  // };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  // const getData = async () => {
  //   const response = await getRequest(
  //     '/moments?page=1&limit=5',
  //     process.env.REACT_APP_BASE_URL + '/wayagram-service/all-posts/'
  //   );

  //   console.log(response);
  //   const { data, metaData, status, message } = response.data;
  //   if (status !== true) {
  //   // return swalService.error(message);
  //   }
  //   setData(data.data);
  //   // setMetaData(metaData)
  // };

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="disp"
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            padding: "0 1rem",
            borderTop: "5px solid #FF6634",
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <GroupFormComponent data={data} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreatePageComponent;
