import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { HiSelector } from "react-icons/hi";
import { Column, Row } from "simple-flexbox";

const useStyles = createUseStyles((theme) => ({
  select: {
    width: "69px",
    border: "1px solid #ffffff",
    borderRadius: "5px",
    padding: "4px 2px",
    fontSize: "14px",
    color: "#333",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px 0px #00000040",
    "&:focus": {
      outline: "none",
      borderColor: "#00bcd4",
      boxShadow: "0 1px 2px rgba(0,0,0,.1), 0 0 0 2px rgba(0,188,212,.1)",
    },
  },
  icon: {
    marginLeft: "5px",
  },
  dropdown: {
    position: "absolute",
    top: "33px",
    width: "69px",
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px 0px #00000040",
    maxHeight: "120px",
    overflowY: "scroll",
    padding: "5px 0 ",
    zIndex: "10",
  },
  container: {
    position: "relative",
  },
  pointer: {
    cursor: "pointer",
  },
}));

const SelectInput = ({
  data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
  id,
  name,
  label,
  className,
  style,
  onChange,
}) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [value, setValue] = useState(data[0]);
  const [isShown, setIsShown] = useState(false);

  const handleChange = (item) => {
    setValue(item);
    onChange(item);
  };

  return (
    <Column
      className={[classes.container, classes.pointer].join(" ")}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <Row
        className={classes.select}
        id={id}
        name={name}
        horizontal="end"
        vertical="center"
      >
        <span>{value}</span>
        <HiSelector size={20} className={classes.icon} />
      </Row>
      {isShown && (
        <Column className={classes.dropdown} horizontal="center">
          {data.map((item) => (
            <p onClick={() => handleChange(item)} className={classes.pointer} key={item}>
              {item}
            </p>
          ))}
        </Column>
      )}
    </Column>
  );
};

export default SelectInput;
