import CsvDownload from 'react-json-to-csv';
import React, { useEffect, useState } from 'react';
import config from '../../../services/config';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { Tabs, Table, Select, Input, Modal, Form, Button, notification, Pagination } from 'antd';
import { deleteRequestData, getRequest, postRequest, putRequest } from '../../../services/request';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { postColumn } from '../../../utils/dummyData';
import SearchBar from '../../../components/SearchBar';
import { getUserData, getUsers } from '../../../services/helpers';
import ImagePreview from '../../../components/ImagePreview';

const DashboardComponent = () => {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("all");
  const [filterType, setFilterType] = useState("username");
  const [query, setQuery] = useState("");
  const [metaData, setMetaData] = useState(0);
  const [page, setPage] = useState(1);
  const [limits, setLimits] = useState(20);
  const [posts, setPosts] = useState([]);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [pages, setPages] = useState([]);
  const [images, setImages] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [imageModal, setShowImageModal] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [mode, setMode] = useState("");
  const [userLoginModal, setuserLoginModal] = useState(false);
  const [value, setValue] = useState("a");
  const [type, setType] = useState("user");
  const [userCanManage, setUserCanManage] = useState(false);
  const { confirm } = Modal;
  const userData = getUserData();
  const [form] = Form.useForm()
  const selectOptions = [
    {
      value: "username",
      label: "Username"
    },
    {
      value: "email",
      label: "Email"
    },
    {
      value: "phone",
      label: "Phone"
    },
  ]
  const getData = async (q) => {
    let response;
    const filter = q ? `${filterType}=${query}` : '';
    // if(q){
    //   response = await getRequest(
    //   `admin-search-post?query=${query}&filterBy=${filterType}`,
    //   config.postUrl
    //   )
    // } else {
    setLoading(true);
    response = await getRequest(
      `admin-get-${currentTab}-posts?page=${page}&limit=${limits}&${filter}`,
      config.postUrl
    );
    // }
    setLoading(false);
    const { data } = response?.data || {};
    const { metadata } = response?.data?.data || {};

    setMetaData(metadata?.total || data?.length || 0)
    setPosts(data?.data || data || []);
    setFilteredPosts(data?.data || data || []);
  };

  const getGroups = async () => {
    const response = await getRequest(
      `admin-group/admin-get-all-groups?page=${page}`,
      config.mainUrl
    );
    const { data } = response?.data || {};
    setGroups(data)
  };

  const getPages = async () => {
    const response = await getRequest(
      `admin-page/admin-get-all-pages?pageNumber=${page}`,
      config.mainUrl
    );
    const { data } = response?.data || {};
    setPages(data)
  };

  const deActivate = async (pid) => {
    const datas = {
      post_id: pid
    };
    setLoading(true)
    const response = await deleteRequestData(
      'admin-delete-post',
      datas,
      config.postUrl
    );
    setLoading(false)

    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message,
        description: "deleted"
      })
      getData();
    } else {
      notification.warning({
        message,
        description: "error"
      })
    }
  };

  const handleConfirm = (pid) => {
    confirm({
      title: `Do you want to delete these items?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        deActivate(pid)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  useEffect(() => {
    getData(query);
  }, [currentTab, page, query]);
  // console.log(users);
  useEffect(() => {
    console.log(userData)
    if (userData?.permits?.includes('CAN_VIEW_OR_MANAGE_CONTENTS')) {
      setUserCanManage(true);
      getUsers(value, setUsers);
    }
  }, [value]);

  useEffect(() => {
    getGroups()
    getPages()
  }, []);

  const handleChange = (event) => {
    const { value } = event.target
    if (value) {
      setQuery(value)
    }
  };
  const handleModals = (type, value) => {
    if (type === 'newPost') {
      setcreatePostModal(true)
      setMode("create")
    }
    if (type === 'images') {
      setShowImageModal(value)
    }
    if (type === 'editPost') {
      setcreatePostModal(true)
      setMode("update")
      form.setFieldsValue({
        description: value.description,
        profile_id: value.ProfileId,
        post_id: value.id,
        group_id: value.id,
        page_id: value.id,
      })
    }
    if (type === 'userLogin') {
      setuserLoginModal(value)
    }
  }

  const handleSubmitPost = async (values) => {
    setLoading(true);
    const newForm = new FormData();
    const file = Object.values(images);
    newForm.append('images', file[0]);
    mode === 'update' && newForm.append('post_id', values.post_id);
    type === 'user' && newForm.append('profile_id', values.profile_id);
    type === 'page' && newForm.append('page_id', values.page_id);
    type === 'group' && newForm.append('group_id', values.group_id);
    mode === 'create' && newForm.append('type', values.type);
    newForm.append('description', values.description);
    mode === 'create' && newForm.append('mentions', "");
    mode === 'create' && newForm.append('hashtags', "");
    const url = `admin-${mode}-post`;
    let res = {}
    if (mode === 'create') {
      res = await postRequest(url, newForm, config.postUrl);
    } else {
      res = await putRequest(url, newForm, config.postUrl);
    }
    const { status, data, message } = res?.data || {}
    if (status) {
      // if(file.length > 0){
      //   file?.forEach(async (img) => {
      //     const newFormOpt = new FormData();
      //     newFormOpt.append('images', img);
      //     newFormOpt.append('profile_id', values.profile_id);
      //     newFormOpt.append('post_id', data?.id);
      //     await addImagesToPosts(newFormOpt);
      //   });
      // }
      notification.success({ description: message, message: 'success!' });
      setcreatePostModal(false)
      getData()
    } else {
      notification.error({ description: message, message: 'Oops!' });
    }
    setLoading(false);
  };

  // const addImagesToPosts = async (newFormOpt) => {
  //   await putRequest('update', newFormOpt, config.postUrl);
  //   getData()
  // };

  const column = postColumn(
    handleModals,
    handleConfirm,
    setShowImageModal,
    setPreviewImages,
    userCanManage
  )

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="private_User.csv"
        style={{
          //pass other props, like styles
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          // border: '1px solid #a511c0',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          // marginTop: '16px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
          // textShadow: '0px 1px 0px #9b14b3',
        }}
      >
        Export Posts
      </CsvDownload>
    );
  };

  const handlePreview = (e) => {
    const { files } = e.target;
    if (files.length > 10) {
      notification.warning('Oops!', `Upload exceeded limit of 10`, 'error');
      return;
    }
    setImages(files)
  };

  return (
    <div className="w-100">
      <SearchBar
        page="post"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        showButtons={true}
      />
      <LoadingComponent loading={loading} />
      <div>
        <Tabs onChange={(e) => setCurrentTab(e)}>
          <Tabs.TabPane key="all" tab="Posts">
            <div className='w-100 my-3'>
              <Table columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="top" tab="Top Posts">
            <div className='w-100 my-3'>
              <Table
                columns={column}
                dataSource={posts}
                rowKey="id"
                loading={loading}
                pagination={false}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
        <Pagination
          total={metaData}
          responsive={true}
          defaultCurrent={1}
          onChange={(e) => setPage(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </div>
      <Modal
        visible={createPostModal}
        onCancel={() => setcreatePostModal(false)}
        footer={false}
        title={<p className='text-center'>Create New Post</p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Form.Item label="Images">
            <div className="input-group">
              <input type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                onChange={handlePreview}
                accept="image/*"
                multiple="multiple"
                aria-label="Upload" />
              <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Browse</button>
            </div>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item hidden name="post_id">
            <Input />
          </Form.Item>
          {mode === "create" && (
            <Form.Item label="Post Type" name="type">
              <Select placeholder="" className='w-100' onChange={(t) => setType(t)}>
                <Select.Option key="user">User</Select.Option>
                <Select.Option key="page">Page</Select.Option>
                <Select.Option key="group">Group</Select.Option>
              </Select>
            </Form.Item>
          )}
          {type === "user" && (
            <Form.Item label="Post Owner" name="profile_id" hidden={mode === "update"}>
              <Select
                showSearch
                value={value}
                placeholder="Search for user"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(e) => setValue(e)}
                notFoundContent={null}
              >
                {users?.map(user =>
                  <Select.Option key={user.id}>{user.displayName}</Select.Option>
                )}
              </Select>
            </Form.Item>
          )}
          {mode === "create" && type === "page" && (
            <Form.Item label="Select Page" name="page_id">
              <Select>
                {pages?.map(grp =>
                  <Select.Option key={grp?.id}>{grp?.username}</Select.Option>
                )}
              </Select>
            </Form.Item>
          )}
          {mode === "create" && type === "group" && (
            <Form.Item label="Select Group" name="group_id">
              <Select>
                {groups.map(pg =>
                  <Select.Option key={pg.id}>{pg.name}</Select.Option>
                )}
              </Select>
            </Form.Item>
          )}
          <Form.Item className='text-center mt-4'>
            <Button size='large' loading={loading} htmlType='submit' type='primary' className='rounded px-5'>
              {mode} post
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ImagePreview
        data={previewImages}
        visible={imageModal}
        setVisible={setShowImageModal}
      />
    </div>
  );
};

export default DashboardComponent;
