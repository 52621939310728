import { Formik } from 'formik';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate  } from 'react-router-dom';
import { Column, Row } from 'simple-flexbox';
import backgroundImage from '../assets/images/login-bg.png';
import loginDemoImage from '../assets/images/login-demo.png';
import withStaticModal from '../components/modals/withStaticModal';
import LogoComponent from '../components/sidebar/LogoComponent';
import store from '../redux';
import { loginUser, logoutUser } from '../redux/actions';
import SLUGS from '../resources/slugs';
import { setToken, setUserData } from '../services/helpers';
import { send } from '../services/request';
import { error, success } from '../services/swal-mixin';
import ForgotPasswordComponent from './ForgotPasswordComponet';
import VerifyComponent from './VerifyOtpComponent';
import LoadingComponent from '../components/loading/LoadingComponent';
import { formatPhoneNumber } from '../services/helpers';
import { customBaseUrl, httpPost } from '../services/http';
import { Modal } from 'react-bootstrap';
import { SlShield } from "react-icons/sl";
import {
  postRequest, getRequest
} from "../../src/services/request";
import config from "../../src/services/config";
import * as Yup from 'yup';
import { TextField } from '@mui/material';


const useStyles = createUseStyles((theme) => ({
  wrapper: {
    background: `url(${backgroundImage})`,
    height: '100vh',
    width: '100vw',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  demoContainer: {
    width: '30vw',
    height: '80vh',
    padding: '24px 32px 0px 32px',
    '@media (max-width: 768px)': {
      display: 'none !important',
    },
  },
  demoImage: {
    width: '40vw',
    height: '80vh',
  },
  loginContainer: {
    width: '30vw',
    height: '80vh',
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    borderTop: `5px solid ${theme.color.wayaPink}`,
    borderRadius: 4,
    marginRight: 30,
    marginLeft: 30,
    padding: '24px 32px 0px 32px',
    background: theme.color.white,
    '@media (max-width: 768px)': {
      width: '80vw',
      height: '85vh',
    },
  },
  centreBlock: {
    textAlign: 'center',
  },
  bottomContent: {
    margin: '0 auto',
    position: 'relative',
    height: '17vh',
    bottom: 0,
    '@media (max-width: 768px)': {
      height: '20vh',
    },
  },
  loginFooter: {
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
  },
  textLink: {
    color: theme.color.wayaPink,
    marginLeft: 5,
    cursor: 'pointer',
  },
  verticalSpacing: {
    paddingTop: 20,
    paddingBottom: 20,
    '@media (max-width: 768px)': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '38px',
    letterSpacing: '0.2px',
    color: theme.color.black,
  },
  imageContainer: {
    marginLeft: 32,
    marginRight: 32,
  },
  formList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  formInput: {
    width: '100%',
    marginBottom: '10px',
    padding: '10px',
    border: 'none',
    background: theme.color.darkGray,
  },
  submitButton: {
    width: '100%',
    margin: '15px auto',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '4px',
    background: theme.color.wayaPink,
    color: '#f2f2f2',
    letterspacing: '.09em',
  },
  verify: {
   textAlign: 'center',
   padding: '40px'
  }
}));

const ForgotPasswordModal = withStaticModal(
  ForgotPasswordComponent,
  'Forgot Password?'
);
const VerifyOtpModal = withStaticModal(VerifyComponent, 'Verify Account');

function LoginComponent() {
  const [emailOrPhoneNumber, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState();
  const [otp, setOTP] = useState('');
  const push = useNavigate ();
  const classes = useStyles();
  const [errorModal, setErrorModal] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);

  async function register() {
    push(SLUGS.signup);
  }
  const sendOTP = async () => {
    const res = await getRequest(`resend-otp/signup/${emailOrPhoneNumber}?domain=WAYAGRAM`, config.authUrl);
    console.log(res)
  };
  

  const check = () => {
    if (emailOrPhoneNumber?.includes('@') || emailOrPhoneNumber === '') {
      return emailOrPhoneNumber;
    } else {
      return formatPhoneNumber(emailOrPhoneNumber);
    }
  };

  const values = check();

  const generateOTP = async (e) => {
    try {
      e.preventDefault();
      return login(e);
      const res = await httpPost(`/api/v1/auth/generate-login-otp?email=${values}`, {}, customBaseUrl.authUrl);
      // if(!res?.status && res?.message.includes('2FA not active for this user')) {
      //   return login(e)
      // }
      if (res?.status === true) {
        success('Check your email', 'Check your email to verify OTP');
        setOtpModal(true);
      } else {
        if(res?.message == " 2FA not active for this user"){
          return login(e);
        }else{
        error(res.message || "An error occured!");
        }
      }
    } catch (err) {
      error(err.message || 'An error occured!');
    }
  }

  async function login(e) {
    // e.preventDefault();
    setLoading(true);
    const response = await httpPost(
      isAdmin ? '/api/v1/auth/admin/login' : '/api/v1/auth/owner/login',
      {
        //  admin: true,
        email: values,
        password,
        //  otp: otp,
        //  type: 'ADMIN',
      },
      customBaseUrl.authUrl
    );
    // const response = await httpPost(
    //   '/api/v1/auth/login',
    //   {
    //     admin: true,
    //     emailOrPhoneNumber: values,
    //     password,

    setLoading(false);
    setOtpModal(false);
    const { status, data, message } = response||{};
    if (!status) {
      if (message == "Account not Verified") {
        setErrorModal(true)
        sendOTP()
      }
      return error(
        message
          ? message
          : message
          ? !message
          : 'invalid credentials, please confirm and try again'
      );
    }

    if (!data?.user?.isAdmin) {
        setToken(data);
        setUserData(data);
        success(message);
        push(SLUGS.dashboard);
        store.dispatch(loginUser());
      //  return error('access denied');
      // store.dispatch(logoutUser());
    } else if (status === 401 ) {
      return error(message);

    } else {
      const isAdmin = data?.user?.isAdmin;
      if(isAdmin) {
        setToken(data);
        setUserData(data);
        success(message);
        push(SLUGS.dashboard);
        store.dispatch(loginUser());
      }else{
        return error('Dear Esteemed Customer, \nKindly go to our customer page https://app.wayagram.ng. \nThanks');
      }
    }
  }
  let year = new Date();
  const time = year.getFullYear();
  return (
    <Column className={classes.wrapper}>
      <LoadingComponent loading={loading} />
      <Row className={classes.container}>
        <Row
          horizontal="space-between"
          className={classes.lastRow}
          breakpoints={{ 1024: 'column' }}
        >
          <Column className={classes.demoContainer}>
            <Row
              className={classes.imageContainer}
              horizontal="center"
              vertical="center"
            >
              <img
                src={loginDemoImage}
                alt="login-demo"
                className={classes.demoImage}
              />
            </Row>
          </Column>
          <Column className={classes.loginContainer}>
            <div className={classes.loginCard}>
              <div className={classes.verticalSpacing}>
                <LogoComponent isColored={true} />
              </div>
              <div className={classes.title}> Admin Dashboard</div>
              <div className={classes.verticalSpacing}>
                <Formik
                  initialValues={{
                    emailOrPhoneNumber: '',
                    password: '',
                    admin: true,
                  }}
                  validate={(values) => {
                    const errors = {};
                    const passwordRegex = /^[a-zA-Z0-9!@#$&()`.+,/"-]*$/;
                    if (!values.password) {
                      errors.password = 'Required';
                    } else if (values.password.length < 8) {
                      errors.password = 'Password must be 8 characters long.';
                    } else if (!passwordRegex.test(values.password)) {
                      errors.password =
                        'Invalid password. Must be alphanumeric with 1 capital letter.';
                    }
                    if (!values.emailOrPhoneNumber) {
                      errors.emailOrPhoneNumber = 'Required';
                    } else if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                        values.emailOrPhoneNumber
                      )
                    ) {
                      errors.emailOrPhoneNumber =
                        'Invalid emailOrPhoneNumber address';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                      alert(JSON.stringify(values, null, 2));
                      setSubmitting(false);
                    }, 400);
                  }}
                >
                  {({ isSubmitting, errors, handleBlur, touched }) => (
                    <form onSubmit={generateOTP}>
                      <div>
                      <div className={classes.formList}>
                          <select 
                           onChange={(e) => {
                            console.log({ hh: e.target.value});
                            setIsAdmin(e.target.value === "true"? true : false)
                           }}
                           className={classes.formInput}
                           >
                            <option value={true}>Admin User</option>
                            <option value={false}>Owner User</option>
                          </select>
                        </div>
                        <div className={classes.formList}>
                          <input
                            autoFocus
                            className={classes.formInput}
                            type="text"
                            id="emailOrPhoneNumber"
                            placeholder="Email Or Phone Number"
                            onChange={(e) => setEmail(e.target.value)}
                            onBlur={handleBlur}
                            error={Boolean(
                              errors.emailOrPhoneNumber &&
                                touched.emailOrPhoneNumber &&
                                'error'
                            )}
                          />
                        </div>
                        <div className={classes.formList}>
                          <input
                            className={classes.formInput}
                            type="password"
                            id="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            onBlur={handleBlur}
                            error={Boolean(
                              errors.password && touched.password && 'error'
                            )}
                          />
                        </div>
                        <div className={classes.formList}>
                          <button
                            className={classes.submitButton}
                            type="submit"
                          >
                            Log In
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>

                {/* <div
                  className={classes.centreBlock}
                  style={{ marginTop: 10, marginBottom: 20 }}
                >
                  <span>
                    Don't have an admin account?
                    <span className={classes.textLink} onClick={register}>
                      Request Now
                    </span>
                  </span>
                </div>
               */}
              </div>
              <div className={classes.bottomContent}>
                <div
                  className={classes.centreBlock}
                  style={{ marginBottom: 50 }}
                >
                  <span className={classes.textLink}>
                    <ForgotPasswordModal />
                  </span>
                  <span className={classes.textLink}>
                    {/* <VerifyOtpModal /> */}
                  </span>
                </div>

                <div className={classes.loginFooter}>
                  <span>
                    &#169;{time} WayaGram -
                    <span className={classes.textLink}>wayagram.ng</span>
                  </span>
                </div>
              </div>
            </div>

            <Modal show={errorModal} onHide={() => setErrorModal(false)}>
              <Modal.Header closeButton></Modal.Header>
              <div className={classes.verify}>
                <SlShield color="red"  size="25" />
                <h2>Error</h2>
                <p>Account not Verified</p>
                <span className={classes.textLink}>
                  <VerifyOtpModal phoneOrEmail={emailOrPhoneNumber} />
                </span>
              </div>
            </Modal>

            <Modal show={otpModal} onHide={() => setOtpModal(false)}>
              <Modal.Header>Enter OTP</Modal.Header>
              <div className={classes.verify}>
              <div className={classes.verticalSpacing}>
              <Formik
                initialValues={{  otp: '' }}
                validationSchema={Yup.object().shape({
                  otp: Yup.number().required('OTP is required'),
                })}
                onSubmit={login}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form autoComplete="off" onSubmit={handleSubmit} noValidate>
                    <div>
                      <div className={classes.formList}>
                        <TextField
                          fullWidth
                          label="OTP"
                          name="otp"
                          onChange={(e) => {
                            handleChange(e)
                            setOTP(e.target.value)
                          }}
                          onBlur={handleBlur}
                          error={Boolean(touched.otp && errors.otp)}
                          helperText={touched.otp && errors.otp}
                          required
                          value={values.otp}
                          variant="outlined"
                          type="text"
                        />
                      </div>

                      <div className={classes.formList}>
                        <button
                          id="resetButton"
                          disabled={isSubmitting}
                          className={classes.submitButton}
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
              </div>
              </div>
            </Modal>
          </Column>
        </Row>
      </Row>
    </Column>
  );
}

export default LoginComponent;
