import './App.css';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
// import Theme from './resources/theme';
import Theme from './theme';
import Routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';  

const App = () => {
  return (
    <div className='screen'>
    <ThemeProvider theme={Theme}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
    </div>
  );
};

export default App;
