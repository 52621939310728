import React, { useState, useEffect } from "react";
import { Card } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import MenuItem from "../sidebar/MenuItemComponent";
import { default as SLUGS } from "../../resources/slugs";
import { convertSlugToUrl } from "../../resources/utilities";
import { useNavigate } from "react-router-dom";
import { HiOutlinePlusCircle, HiOutlinePencilAlt } from "react-icons/hi";
import CustomAdmin from "../../components/admins/CustomAdmin";
import {
  getRequest,
} from "../../services/request";
import config from "../../services/config";
import EditRole from "./EditRole";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: 260,
  },
  header1: {
    fontSize: "16px",
    paddingBottom: "30px",
    fontWeight: 700,
    paddingLeft: 50,
    marginTop: "30px",
  },
  header2: {
    fontSize: "16px",
    paddingBottom: "30px",
    fontWeight: 700,
    marginTop: "70px",
    paddingLeft: 50,
  },
  custom: {
    display: "flex",
  },
}));

const AdminSideBar = () => {
  const push = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);


  const theme = useTheme();
  const classes = useStyles({ theme });

  function onClick(slug, parameters = {}) {
    push(convertSlugToUrl(slug, parameters));
  }

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(`admin-get-roles?limit=1000`, config.roleUrl);

    setLoading(false);
    if (response.data.status == false) {
      return;
    }
    const data = response?.data?.data || {};
    setRoles(data.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Card className={classes.container} bodyStyle={{ padding: "12px" }}>
        <div className={classes.messageContainer}>
          <h2 className={classes.header1}>Roles</h2>
          {!loading ? (
            roles?.map((role, idx) => (
              <MenuItem
                id={`${SLUGS.roles_and_access}${role?.id}`}
                title={role.userRole}
                onClick={() => onClick(`${SLUGS.roles_and_access}${role?.id}`)}
                key={role?.id}
              />
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>

        <div className={classes.messageContainer}>
          <MenuItem
            id='#create-role'
            title="Create Custom Role"
            onClick={() => setShowModal(true)}
            icon={HiOutlinePlusCircle}
            iconSize={20}
          />
           <MenuItem
            id='#edit'
            title="Edit Role"
            onClick={() => setEditModal(true)}
            icon={HiOutlinePencilAlt}
            iconSize={20}
          />
        </div>
      </Card>
      <CustomAdmin show={showModal} onCancel={() => setShowModal(false)} />
      <EditRole show={editModal} onCancel={() => setEditModal(false)} />
    </>
  );
};

export default AdminSideBar;
