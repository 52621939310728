import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import Table from '@mui/material/Table';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    // padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: 20,
  },
  tabContent3: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: 250,
  },

  link: {
    marginRight: 80,
  },
  textme: {
    minWidth: '290%',
  },
  textme2: {
    minWidth: '230%',
  },
  
}));



const UserComponent = (props) => {
  const classes = useStyles();
  
  const { handleOpenVote, handleCloseVote } = props;
  

  const handleViewClose = () => {
    handleCloseVote();
  };
  const handleViewOpen = () => {
    handleOpenVote();
  };

  

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 150, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            
          </div>
          <div className={classes.tabContent2}>
            <Typography style={{fontSize: "20px", fontWeight: "400px"}}>Vote 1</Typography>
            <p style={{fontSize: "15px", fontWeight: "400px", color: "green"}}>212</p>
            <Button variant='outlined' style={{backgroundColor: "#FF0000"}}>Stop voting</Button>
            
          </div>
          <div className={classes.name2}>
              <Typography variant="h6" style={{fontWeight: "400"}}>Participants</Typography>
              <Divider />
            </div>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  
                    <TableCell
                      style={{
                        
                        fontWeight: 800,
                        fontSize: '16px',
                        borderBottom:"none",
                      }}
                      align="right"
                    >
                     Voter
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Votes
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Description
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Email
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Time of Vote
                    </TableCell>
                    
                    
                </TableHead>
                <TableHead>
                  
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 200,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                     Clever Hilton
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 200,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      10
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 200,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      vote for princess
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 200,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      clever@gmail.com
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom:"none",
                        fontWeight: 200,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      10:40pm
                    </TableCell>
                    
                    
                </TableHead>
                </Table>
          
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserComponent;
