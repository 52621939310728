import React, { useState, useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { Column, Row } from "simple-flexbox";
import config from "../../../services/config";
import { getRequest, postRequest } from "../../../services/request";
import { Card, Input, Space, Button } from "antd";
import CustomButton from "../../../components/CustomButton";
import { FiEdit3 } from "react-icons/fi";
import slugs from "../../../resources/slugs";
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { orange } from "@mui/material/colors";
import SearchInput from "../../../components/CustomInput/SearchInput";



const useStyles = createUseStyles({
  container: {
    padding: "0px 35px",
  },
  back: {
    cursor: "pointer",
  },
  cardContainer: {
    rowGap: "20px",
    marginTop: "20px",
  },
  rowContainer: {
    columnGap: "10px",

  },
  textTitle: {
    fontSize: "0.8rem",
  },
  textBlue: {
    color: '#064A72'
  },
  textOrange: {
    color: '#E7472C'
  }

});

const Account = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [post, setPost] = useState([]);
  // const [filterType, setFilterType] = useState("USERNAME");
  // const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  // const [values, setValues] = useState("");
  const [event, setEvent] = useState({});
  const [poll, setPoll] = useState({});
  const [donation, setDonation] = useState({});
  const [commission, setCommission] = useState({});


  const getData = async () => {
    setLoading(true);
    const total = await getRequest(
      `admin-get-payment-request-overview`,
      config.postUrl
    );
    const event = await getRequest(
      `admin-get-payment-request-event-overview`,
      config.postUrl
    );

    const donation = await getRequest( 
      `admin-get-payment-request-donation-overview`,
      config.postUrl
    );

    const poll = await getRequest(
      `admin-get-payment-request-poll-overview`,
      config.postUrl
    );


    const commission = await getRequest(
      `admin-get-commission`,
      config.postUrl
    );
    

    setLoading(false);
    const totalData = total?.data?.data || {};
    const  eventData = event?.data?.data || {};
    const donationData= donation?.data?.data || {};
    const pollData = poll?.data?.data || {};
    const commissionData = commission?.data?.data || {};

    setTotal(totalData || {})
    setDonation(donationData || {})
    setPoll(pollData || {})
    setDonation(eventData || {})
    setCommission(commissionData || {})


    console.log(data)
  };

  useEffect(() => {
     getData();
  }, [pages]);

  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   if (value === "") {
  //   }
  //   setValues(event.target.value);
  // };

  return (
    <Column className={classes.container}>
      <Column className={classes.back} onClick={() => navigate(-1)}>
      <Row alignItems="center">
          <IoMdArrowBack style={{ marginRight: "23px" }} />
          Back
        </Row>
        </Column>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
      </div>

      <Column className={classes.cardContainer}>
        <Row className={classes.rowContainer}>
          <div className="col">
            <Card className="shadow">
              <p className={classes.textTitle}>
                Total Approved Payment Request
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {total?.total_approval_payment_request || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow text-success">
              <p className={classes.textTitle}>
                Total Payment Request Disbursed
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {total?.total_disbursed_payment_request || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className={`shadow ${classes.textOrange}`}>
              <p className={classes.textTitle}>
                Total Payment Request Rejected
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {total?.total_rejected_payment_request || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow text-warning">
              <p className={classes.textTitle}>Total Pending Payment Disbursement</p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {total?.total_pending_payment_request || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className={`shadow ${classes.textBlue}`}>
              <p className={classes.textTitle}> Total Waya Payment Comission</p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                {commission?.wayagram_total_comm || 0}
                </p>
              </Row>
            </Card>
          </div>
        </Row>

        <Row className={classes.rowContainer}>
          <div className="col">
            <Card className="shadow">
              <p className={classes.textTitle}>
                Total Approved Payment Request on Donation
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {donation?.total_approval_payment_request_donation || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow text-success">
              <p className={classes.textTitle}>
                Total Payment Request Disbursed on Donation
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {donation?.total_disbursed_payment_request_donation || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className={`shadow ${classes.textOrange}`}>
              <p className={classes.textTitle}>
                Total Payment Request Rejected on Donation
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {donation?.total_rejected_payment_request_donation || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow text-warning">
              <p className={classes.textTitle}>
                Total Pending Disbursement on Donation
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {donation?.total_pending_payment_request_donation || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className={`shadow ${classes.textBlue}`}>
              <p className={classes.textTitle}>
                Total Waya Comission on Donation
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                {commission?.wayagram_donat_comm?.clr_bal_amt || 0}
                </p>
              </Row>
            </Card>
          </div>
        </Row>

        <Row className={classes.rowContainer}>
          <div className="col">
            <Card className="shadow">
              <p className={classes.textTitle}>
                Total Approved Payment Request on paid poll
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {poll?.total_approval_payment_request_poll || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow text-success">
              <p className={classes.textTitle}>
                Total Payment Request Disbursed on paid poll
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {poll?.total_disbursed_payment_request_poll || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className={`shadow ${classes.textOrange}`}>
              <p className={classes.textTitle}>
                Total Payment Request Rejected on paid poll
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {poll?.total_rejected_payment_request_poll || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow text-warning">
              <p className={classes.textTitle}>
                Total Pending Disbursment on Paid Poll
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {poll?.total_pending_payment_request_poll || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className={`shadow ${classes.textBlue}`}>
              <p className={classes.textTitle}>
                Total Waya Comission on paid poll
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                {commission?.wayagram_poll_comm?.clr_bal_amt || 0}
                </p>
              </Row>
            </Card>
          </div>
        </Row>
        <Row className={classes.rowContainer}>
          <div className="col">
            <Card className="shadow">
              <p className={classes.textTitle}>
                Total Approved Payment Request on Events
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {event?.total_approval_payment_request_event || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow text-success">
              <p className={classes.textTitle}>
                Total Payment Request Disbursed on Events
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {event?.total_disbursed_payment_request_event || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className={`shadow ${classes.textOrange}`}>
              <p className={classes.textTitle}>
                Total Payment Request Rejected on Events
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {event?.total_rejected_payment_request_event || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className="shadow text-warning">
              <p className={classes.textTitle}>
                Total Pending Disbursment on Events
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                  {event?.total_pending_payment_request_event || 0}
                </p>
              </Row>
            </Card>
          </div>
          <div className="col">
            <Card className={`shadow ${classes.textBlue}`}>
              <p className={classes.textTitle}>
                Total Waya Commission on Events
              </p>
              <Row justifyContent="space-between">
                <p className="fs-6 fw-bold">
                {commission?.wayagram_evnt_comm?.clr_bal_amt || 0}
                </p>
              </Row>
            </Card>
          </div>
        </Row>
      </Column>
    </Column>
  );
};

export default Account;
