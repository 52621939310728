import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import LoadingComponent from '../../components/loading/LoadingComponent';
import { putRequest } from '../../services/request';
import {currencyFormat} from '../../services/helpers'
import UpdateIcon from '@material-ui/icons/Update';
import { getRequest, postRequest } from '../../services/request';
import { success, error } from '../../services/swal-mixin'


class TransactionsTableComponent extends React.Component {
  state = {
    anchorEl: null,
    menus: [],
    headers: [],
    data: [],
    metaData: {
      count: 0,
    },
    limit: 15,
    page: 0,
    selecteduserIds: [],
    showUser: false,
    transaction: null,
    index: null,
    loading: false,
  };

  componentDidMount() {
    this.initializeView();
  }

  componentDidUpdate(prevProps) {
    console.log(prevProps)
    if (
      prevProps.data !== this.props.data ||
      prevProps.metaData !== this.props.metaData
    ) {
      this.initializeView();
    }
  }

  initializeView = () => {
    const type = 'type' in this.props ? this.props.type : null;
    const data = this.props.data;
    const metaData = this.props.metaData || this.state.metaData;
    
    this.setState({
      ...this.state,
      data,
      metaData,
      type,
     
      headers: this.props.headers,
      activeVendor: this.props.activeVendor,
    });
  };

  handleClick = (index) => (event) => {
    const { menus } = this.state;
    menus[index] = true;
    this.setState({ anchorEl: event.currentTarget, menus });
  };

  handleClose = (index) => () => {
    const { menus } = this.state;
    menus[index] = false;
    this.setState({ anchorEl: null, menus });
  };

  handleView = (item, index) => (event) => {
    this.handleClose(index);
    this.setState({ ...this.state, showUser: true, transaction: item, index });
  };

  handleViewClose = () => {
    const { index } = this.state;
    this.handleClose(index);
    this.setState({ ...this.state, showUser: false, transaction: null });
  };

  handleEdit = (item) => (event) => {
    alert(item.name);
  };

  handleDelete = (item) => (event) => {
    alert(item.name);
  };

  handleLimitChange = (event) => {
    this.setState({ ...this.state, limit: event.target.value });
  };

  handlePageChange = (event, newPage) => {
    const page = newPage + 1;
    this.props.getPage(page);
    this.setState({ ...this.state, page: newPage });
    
  };
  // handleReverse = (id) => {
//  ReverseTran = async (id) =>{
      
//       const datas = this.props.data.find((card)=> card.id === id)
//    console.log(id)
//     var date = new Date(datas.trxDate);
//   const year = date.getFullYear()
//   const m = date.getMonth() +1
//   const d = date.getDate()
//   const trxDate = `${year}-${m}-${d}`
  
//   const state = {
//     tranCrncy: datas.currencyCode,
//     tranDate: trxDate,
//     tranId: id,
//   };
//     // setLoading(true)
//     const response = await postRequest(
//       'api/v1/wallet/transaction/reverse',
//       state,
//       'http://68.183.60.114:9086'
//     );
//     // setLoading(false)
//     console.log(response);
//     const {message, status} = response?.data
//     if(status === false){
//       return error(message)
//     }
//     success(message)
//   }
    
  

  

  render() {
    const {
      headers,
      data,
      metaData,
      limit,
      page,
      selecteduserIds,
      type,
      
      // showUser,
      // transaction,
    } = this.state;

    console.log(data);

    return (
      <Card>
        <LoadingComponent loading={this.state.loading} />
        <PerfectScrollbar>
          <Box overflow="scroll">
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{ backgroundColor: '#f6b17f' }}
                    >
                      {' '}
                      {header}{' '}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
             
              <TableBody>
                {data.slice(page * limit, page * limit + limit).map((transaction, index) => (
                  <TableRow
                    hover
                    key={transaction.id}
                    selected={
                      selecteduserIds.indexOf(transaction.userId) !== -1
                    }
                  >
                    {(type === 'wallet' ||
                      headers.includes('Account Name')) && (
                      <TableCell>
                        {transaction.accountName
                          ? transaction.accountName
                          : 'N/A'}
                      </TableCell>
                    )}
                    {(type === 'bankcard' ||
                      headers.includes('Account Number')) && (
                      <TableCell>
                        {transaction.accountNumber
                          ? transaction.accountNumber
                          : 'N/A'}
                      </TableCell>
                    )}
                    {type === 'wallet' && (
                      <TableCell>
                        {transaction.accountNo ? transaction.accountNo : 'N/A'}
                      </TableCell>
                    )}
                    {type === 'bankAccount' && (
                      <TableCell>
                        {transaction.accountNumber
                          ? transaction.accountNumber
                          : 'N/A'}
                      </TableCell>
                    )}
                    {type === 'bankAccount' && (
                      <TableCell>
                        {transaction.bankName ? transaction.bankName : 'N/A'}
                      </TableCell>
                    )}
                    {type === 'bankAccount' && (
                      <TableCell>
                        {transaction.bankCode ? transaction.bankCode : 'N/A'}
                      </TableCell>
                    )}
                    {type === 'bankAccount' && (
                      <TableCell>
                        {transaction.rubiesBankCode
                          ? transaction.rubiesBankCode
                          : 'N/A'}
                      </TableCell>
                    )}

                    {type === 'wallet' && (
                      <TableCell>
                        {transaction.accountType
                          ? transaction.accountType
                          : 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Types') && (
                      <TableCell>
                        {transaction.txnType ? transaction.txnType.toLowerCase() : 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Receiver Name') && (
                      <TableCell>{transaction.receiverName || 'N/A'}</TableCell>
                    )}
                    
                    {headers.includes('Biller') && (
                      <TableCell>
                        {transaction.biller.toUpperCase() || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Provider') && (
                      <TableCell>
                        {transaction.provider ? transaction.provider.toLowerCase() : 'N/A'}
                      </TableCell>
                    )}
                    
                    {headers.includes('Customer Email') && type === 'refund' && (
                      <TableCell>
                        {transaction.createdEmail ? transaction.createdEmail : 'N/A'}
                      </TableCell>
                    )}
                    
                    {headers.includes('Refund Email') && (
                      <TableCell>
                        {transaction.refunded_by
                          ? transaction.refunded_by
                          : 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Phone Number') && (
                      <TableCell>
                        {transaction.receiverPhoneNumber
                          ? transaction.receiverPhoneNumber
                          : 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('User Type') && (
                      <TableCell>
                        {transaction.wayauser ? 'Waya User' : 'Non-Waya User'}
                      </TableCell>
                    )}
                    {headers.includes('Type') && (
                      <TableCell>
                        {transaction.tranType ? transaction.tranType.toLowerCase() : 'N/A'}
                      </TableCell>
                    )}
                    
                    {transaction.type && headers.includes('Type') && (
                      <TableCell>{transaction.type.toUpperCase()}</TableCell>
                    )}
                    
                    {headers.includes('Description') && (
                      <TableCell>
                        {transaction.tranNarrate
                          ? transaction.tranNarrate.toLowerCase()
                          : transaction.merchant_note ||
                            transaction.narrationOfDispute ||
                            'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Amount') && type !== "bankCard" &&(
                      <TableCell>
                        {currencyFormat(transaction.tranAmount) || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Account Number') &&  type !== "bankCard" && (
                      <TableCell>
                        {transaction.acctNum || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Transaction Date') && (
                      <TableCell>
                        {transaction.tranDate || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Vendor') && (
                      <TableCell>{transaction}</TableCell>
                    )}
                    {headers.includes('Third Party') && (
                      <TableCell>
                        {transaction.thirdPartyName || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Fee Type') && (
                      <TableCell>{transaction.feeType || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Charge') && (
                      <TableCell>₦ {transaction.value || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Transaction Amount') && (
                      <TableCell>
                        ₦
                        {transaction.transactionAmount
                          ? transaction.transactionAmount.toLocaleString()
                          : 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Active') && (
                      <TableCell
                        style={{
                          color:
                            this.state.activeVendor === transaction
                              ? 'green'
                              : 'red',
                        }}
                      >
                        {this.state.activeVendor === transaction
                          ? 'Active'
                          : 'Inactive'}
                      </TableCell>
                    )}

                    {headers.includes('Action') && (
                      <TableCell>
                        {this.state.activeVendor !== transaction ? (
                          <Button
                            onClick={async () => {
                              try {
                                this.setState({ loading: true });
                                await putRequest(
                                  `billspay/api/v1/config/thirdparty/${transaction}`,
                                  null,
                                  'http://157.230.223.54:8181'
                                );
                                this.setState({
                                  activeVendor: transaction,
                                  loading: false,
                                });
                              } catch (e) {}
                            }}
                            variant="contained"
                          >
                            Enable
                          </Button>
                        ) : null}
                      </TableCell>
                    )}
                    {type === 'wallet' ? (
                      <TableCell>
                        ₦ {transaction.balance.toLocaleString()}
                      </TableCell>
                    ) : (
                      transaction.amount && (
                        <TableCell>
                          ₦ {transaction.amount.toLocaleString()}
                        </TableCell>
                      )
                    )}
                    {headers.includes('Status') && (
                      <TableCell>
                        {transaction.status === 'SUCCESS' ? (
                          <Box color="success.main">{transaction.status}</Box>
                        ) : transaction.successful === true ? (
                          <Box color="success.main">SUCCESS</Box>
                        ) : transaction.status === 'PAID' ? (
                          <Box color="success.main">{transaction.status}</Box>
                        ) : transaction.active === true ? (
                          <Box color="success.main">Active</Box>
                        ) : transaction.active === false ? (
                          <Box color="error.main">In Active</Box>
                        ) : transaction.txnStatus === 'FAILED' ? (
                          <Box color="error.main">{transaction.txnStatus}</Box>
                        
                        ) : transaction.txnStatus === 'SUCCESS' ? (
                          <Box color="success.main">{transaction.txnStatus}</Box>
                        ) : (
                          <Box color="warning.main">{transaction.status}</Box>
                        )}
                      </TableCell>
                    )}
                    { type === "bankCard" && (
                      <TableCell>
                       <UpdateIcon style={{color: "green", cursor: "pointer"}} />
                      </TableCell>
                    )}
                   
                    {headers.includes('Reference') && type !== "bankCard" && (
                      <TableCell>
                        {transaction.reference ||
                          transaction.transaction ||
                          'N/A'}
                      </TableCell>
                    )}
                    {transaction.createdAt && type === "Date Created" && (
                      <TableCell>
                        {moment(transaction.createdAt).format('MMM, Do YYYY')}
                      </TableCell>
                    )}
                    {transaction.transactionDate && type !== "Date Created" &&(
                      <TableCell>
                        {moment(transaction.transactionDate).format(
                          'MMM, Do YYYY'
                        )}
                      </TableCell>
                    )}
                    
                  </TableRow>
                ))}
                {data.length === 0 && (
                  <TableRow colSpan={headers.length}>
                    <Box display="flex" justifyContent="center" m={5}>
                      No data found.
                    </Box>
                  </TableRow>
                )}
              </TableBody>
              
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          style={{ backgroundColor: '#f6b17f' }}
          component="div"
          count={data.length}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
        />
      </Card>
    );
  }
}

export default TransactionsTableComponent;
