import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { postRequest } from '../../../services/request';
import { error, success } from '../../../services/swal-mixin';
import { formatPhoneNumber } from '../../../services/helpers';
import LoadingComponent from '../../../components/loading/LoadingComponent'


const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',

    '&:hover': {
      background: '#2D9CDB',
    },
  },
  cardContent: {
    padding: 15,
  },
}));



const ChargeComponent = ({block, handleClose, getTransaction, className, ...rest }) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false)
console.log(block)
  return (
    <Formik
      initialValues={{
        customerAccountNo: block.accountNo,
        lienAmount: '',
        lienReason: '',
        
      }}
      validationSchema={Yup.object().shape({
        
          lienAmount: Yup.string()
          .min(1, 'Too Short!')
          .max(50, 'Too Long!')
          .required('Required'),
          lienReason: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .required('Required'),
          
          
        
      })}
      onSubmit={async (data) => {
        
        console.log(data);
        const amount = Number(data.lienAmount)
        console.log(amount)
        data.lienAmount = amount

        setLoading(true)
        
        const response = await postRequest(
          `api/v1/wallet/account/block/transaction`,
          data,
          'http://68.183.60.114:9086'
          );
          setLoading(false)
          console.log(response);

        const { message, status } = response.data;
        if (status === true) {
          handleClose();
          success(message);
          getTransaction()
        } else {
          error(message);
        }
        data = {};
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <LoadingComponent loading={loading}/>
          <Card>
            <CardHeader
              subheader="Create new Charge and Transaction Name."
              title="Create New Transaction Charge"
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Enter Lien Amount"
                    name="lienAmount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    
                    SelectProps={{ native: true }}
                    value={values.lienAmount}
                    variant="outlined"
                    error={Boolean(touched.lienAmount && errors.lienAmount)}
                    helperText={touched.lienAmount && errors.lienAmount}
                  >
                    
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Enter Reason"
                    name="lienReason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.lienReason && errors.lienReason)}
                    helperText={touched.lienReason && errors.lienReason}
                    value={values.lienReason}
                    key={1}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                
                
                
                
                
              </Grid>
            </CardContent>
            {/* <Divider /> */}
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                className={classes.createButton}
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Block Wallet
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ChargeComponent.propTypes = {
  className: PropTypes.string,
};

export default ChargeComponent;
