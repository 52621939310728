import React from "react";
import Sidebar from "../../../components/chats/Sidebar";
import DefaultScreen from "../../../components/chats/DefaultScreen";
import ChatScreen from "../../../components/chats/ChatScreen";
import { createUseStyles, useTheme } from "react-jss";
import Profile from "../../../components/chats/Profile";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
  },
  chat: {
    display: "flex",
  }
}));

const Chat = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <Sidebar />
      {false ? (
        <DefaultScreen />
      ) : (
        <div className={classes.chat}>
          <ChatScreen />
          <Profile />
        </div>
      )}
    </div>
  );
};

export default Chat;
