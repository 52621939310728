import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, List } from 'antd';
import React from 'react'

export default function PollImagePreview(props) {
  const { data, isImage, remove } = props;
  return (
    <List
      itemLayout="vertical"
      dataSource={data}
      renderItem={(item, i) => (
        <List.Item
          key={Math.random()}
          extra={
            isImage && (
              <img
                width={100}
                alt="logo"
                src={item.media_url || item.preview}
              />
            )
          }
          actions={[
            <DeleteOutlined
              key="list-vertical-star-o"
              onClick={() => remove(i)}
            />,
          ]}
        >
          <List.Item.Meta
            avatar={
              isImage && (
                <Avatar src={item.preview || item.media_url} />
              )
            }
            title={<p>{item.option}</p>}
            description={ item.description || item.short_desc }
          />
        </List.Item>
      )}
    />
  );
}