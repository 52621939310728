import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import moment from 'moment';
import { getRequest } from '../../services/request';

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,

    width: '100%',
    display: 'flex',
  },
  tool: {
    paddingLeft: '0px',
    borderRadius: '250px',
  },
  text: {
    width: '150%',

    fontWeight: '800px',
    borderRadius: '1px red',

    '& .MuiFormLabel-root': {
      border: 'none',
      color: 'black',
      marginLeft: 5,
    },
  },

  textfield: {
    paddingTop: '5px',
    paddingRight: '100px',
    marginRight: '580px',
  },
}));

export default function BasicTable(props) {
  const classes = useStyle();
 
  const [page, setPage] = React.useState(0);
  const { userId } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(9);


  
  const [value, ] = useState([]);
  
  const [logs, ] = useState([]);

 
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getUserLogs = async () => {
    
    const response = await getRequest(
      `api/v1/log/${userId}`,
      'http://68.183.60.114:8059'
    );

    
    if (!response || response.error !== undefined) {
      return;
    }
    
  };


  const filteredWallet = logs?.filter((person) => {
    return (
      person?.accountName?.toLowerCase().includes(value?.toLowerCase()) ||
      person?.accountType?.toLowerCase().includes(value?.toLowerCase())
    );
  });

  useEffect(()=>{
    getUserLogs()
  }, [])

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#f6b17f', color: '#E7472C' }}>
              <TableCell
                style={{ color: '#ed462f', fontWeight: 800, fontSize: '13px' }}
              >
                Service
              </TableCell>
              <TableCell
                style={{ color: '#ed462f', fontWeight: 800, fontSize: '13px' }}
                align="right"
              >
                Description
              </TableCell>
              <TableCell
                style={{ color: '#ed462f', fontWeight: 800, fontSize: '13px' }}
                align="right"
              >
                Activity
              </TableCell>
              
              <TableCell
                style={{ color: '#ed462f', fontWeight: 800, fontSize: '13px' }}
                align="right"
              >
                Date
              </TableCell>

              
              <TableCell
                style={{ color: '#ed462f', fontWeight: 800, fontSize: '16px' }}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.name}>
                  <TableCell
                    align="left"
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {row.module}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {row.message}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {row.action}
                  </TableCell>
                  
                  <TableCell
                    align="right"
                    style={{
                      fontSize: '10px',
                    }}
                  >
                    {moment(row.requestDate).format('MMM, Do YYYY')}
                  </TableCell>

                  
                  
                 
                </TableRow>
              ))}
            {logs.length === 0 && (
              <TableRow>
                <Box display="flex" justifyContent="center" m={5}>
                  No data found.
                </Box>
              </TableRow>
            )}
          </TableBody>
        </Table>

        
        <TablePagination
          style={{ backgroundColor: '#f6b17f', color: '#E7472C' }}
          rowsPerPageOptions={[5, 9, 10, 25]}
          component="div"
          count={logs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
