import { TextField } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate  } from 'react-router-dom';
import { Column } from 'simple-flexbox';
import * as Yup from 'yup';
import LogoComponent from '../components/sidebar/LogoComponent';
import SLUGS from '../resources/slugs';
import { postRequestNoToken, getRequestForget } from '../services/request';
import swalService from '../services/swal-mixin';
import LoadingComponent from '../components/loading/LoadingComponent';


const useStyles = createUseStyles((theme) => ({
  centreBlock: {
    textAlign: 'center',
  },
  bottomContent: {
    margin: '0 auto',
    position: 'relative',
    height: '15vh',
    bottom: 0,
  },
  footer: {
    textAlign: 'center',
    position: 'absolute',
    bottom: 0,
  },

  textLinkColored: {
    color: theme.color.wayaPink,
    marginLeft: 5,
    marginBottom: 10,
    cursor: 'pointer',
  },
  textLink: {
    color: theme.color.lightGray,
    marginLeft: 5,
    cursor: 'pointer',
    '&:hover': {
      color: theme.color.black,
      textDecoration: 'underline',
    },
  },
  verticalSpacing: {
    paddingTop: 20,
    paddingBottom: 20,
    '@media (max-width: 768px)': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '38px',
    letterSpacing: '0.2px',
    color: theme.color.black,
  },
  instruction: {
    fontSize: 16,
    textAlign: 'center',
    lineHeight: '25px',
    color: theme.color.textGrey,
    paddingTop: 20,
    paddingBottom: 20,
    '@media (max-width: 768px)': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  imageContainer: {
    marginLeft: 32,
    marginRight: 32,
  },
  formList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 15,
  },
  formInput: {
    width: '100%',
    marginBottom: '10px',
    padding: '10px',
    border: 'none',
    background: theme.color.darkGray,
  },
  submitButton: {
    width: '100%',
    margin: '15px auto',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '4px',
    background: theme.color.wayaPink,
    color: '#f2f2f2',
    letterspacing: '.09em',
  },
}));

const ForgotPasswordComponent = () => {
  const push = useNavigate ();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  async function register() {
    push(SLUGS.signup);
  }

  async function requestOTP(emailOrPhone) {
    console.log(emailOrPhone);
    if (!emailOrPhone) {
      return swalService.error(
        'Please enter your email address or Phone number.'
      );
    }
    setLoading(true);
    if (emailOrPhone.includes('@')) {
      const response = await getRequestForget(
        `api/v1/password/forgot-password/byEmail?email=${emailOrPhone}`,
        'http://68.183.60.114:9086'
      );
      setLoading(false);
      console.log(response);
      const { data, status, message } = response?.data;
      if (status === false) {
        return swalService.error(message);
      }
      return swalService.success(data);
    } else {
      const response = await getRequestForget(
        `api/v1/password/forgot-password/byPhone?phoneNumber=${emailOrPhone}`,
        'http://68.183.60.114:9086'
      );
      setLoading(false);
      console.log(response);
      const {data, status, message } = response?.data;
      if (status === false) {
        return swalService.error(message);
      }
      return swalService.success(data);
    }
  }

  return (
    <Column className={classes.wrapper}>
      <LoadingComponent loading={loading} />
      <div className={classes.forgotPasswordCard}>
        <div className={classes.verticalSpacing}>
          <LogoComponent isColored={true} />
        </div>
        <div className={classes.title}>Forgot Password?</div>
        <div className={classes.instruction}>
          Please enter your email or phone number(234) to reset your password
          associated with your wayapay account
        </div>
        <div className={classes.verticalSpacing}>
          <Formik
            initialValues={{
              emailOrPhone: '',
              newPassword: '',
              phoneOrEmail: '',
              otp: '',
              confirmPassword: '',
              // userId: 0,
            }}
            // onSubmit={async (values, { setSubmitting }) => {
            //   const response = await send('auth/forget-password', values);
            //   const { code, message } = response;
            //   if (code !== 200) {
            //     return error(message);
            validationSchema={Yup.object().shape({
              // emailOrPhone: Yup.string()
              //   .max(255)
              //   .required('Email Or Phone is required'),
              otp: Yup.string().min(6).required('OTP is required'),
              newPassword: Yup.string().min(8).required('Password is required'),
              confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
              phoneOrEmail: Yup.string().min(4).required('phone Or Email is required'),
            })}
            onSubmit={async (values) => {
              let data = {
                
                newPassword: values.newPassword,
                phoneOrEmail: values.phoneOrEmail,
                otp: Number(values.otp),
                
              };
              console.log(data);
              const response = await postRequestNoToken(
                'api/v1/password/forgot-password',
                data,
                'http://68.183.60.114:9086'
              );
              const { status, message } = response.data;
              if (status === false) {
                return swalService.error(message);
              }
              data = ''
              swalService.success(message);
              push(SLUGS.login);
              return;
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form autoComplete="off" onSubmit={handleSubmit} noValidate>
                <div>
                  <div className={classes.formList}>
                    <TextField
                      fullWidth
                      label="Email or Phone"
                      name="phoneOrEmail"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(
                        touched.phoneOrEmail && errors.phoneOrEmail
                      )}
                      helperText={touched.phoneOrEmail && errors.phoneOrEmail}
                      required
                      value={values.phoneOrEmail}
                      variant="outlined"
                    />
                  </div>
                  <div
                    className={classes.textLinkColored}
                    onClick={() => requestOTP(values.phoneOrEmail)}
                  >
                    Request an OTP
                  </div>
                  <div className={classes.formList}>
                    <TextField
                      fullWidth
                      label="OTP"
                      name="otp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.otp && errors.otp)}
                      helperText={touched.otp && errors.otp}
                      required
                      value={values.otp}
                      variant="outlined"
                      type="password"
                    />
                  </div>
                  <div className={classes.formList}>
                    <TextField
                      fullWidth
                      label="New Password"
                      name="newPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      helperText={touched.newPassword && errors.newPassword}
                      required
                      value={values.newPassword}
                      variant="outlined"
                      type="password"
                    />
                  </div>
                  <div className={classes.formList}>
                    <TextField
                      fullWidth
                      label="Confirm Password"
                      name="confirmPassword"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                      helperText={touched.confirmPassword && errors.confirmPassword}
                      required
                      value={values.confirmPassword}
                      variant="outlined"
                      type="password"
                    />
                  </div>
                  <div className={classes.formList}>
                    <button
                      id="resetButton"
                      disabled={isSubmitting}
                      className={classes.submitButton}
                      type="submit"
                    >
                      Reset Password
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className={classes.bottomContent}>
          <div
            className={classes.centreBlock}
            style={{ marginTop: 10, marginBottom: 50 }}
          >
            {/* <span>
              Don't have an account?
              <span className={classes.textLink} onClick={register}>
                Sign up instead
              </span>
            </span> */}
          </div>
          <div className={classes.centreBlock}>
            {/* <span>&#169; WayaPay</span> */}
            <span>
              &#169;{new Date().getFullYear()} WayaGram -
              <span className={classes.textLink}>wayagram.ng</span>
            </span>
          </div>
        </div>
      </div>
    </Column>
  );
};

export default ForgotPasswordComponent;
