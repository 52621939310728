import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { postRequest } from '../../../services/request';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { success, error } from '../../../services/swal-mixin';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',

    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',

    justifyContent: 'space-around',
    marginLeft: 150,
  },
  tabContent3: {
    padding: '25px 0',

    justifyContent: 'center',
    marginLeft: 94,
  },

  link: {
    marginRight: 80,
  },
  link2: {
    marginLeft: 110,
    marginBottom: '25px',
  },
  phones: {
    marginBottom: '25px',
  },

  textme2: {
    minWidth: '66%',
  },
  textme3: {
    minWidth: '65%',
  },
  btnp3: {
    marginLeft: 270,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

export default function UpdateAccountComponent(props) {
  const classes = useStyles();
  const { userId, virtualAccount, account, getAccount, handleCloseAccount } =
    props;

  const state = {
    accountName: account.accountName,
    userId: userId,
  };
  const [values, setValues] = useState(state);
  const [loading, setLoading] = useState(false);

  const handleViewClose = () => {
    handleCloseAccount();
    getAccount();
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const updateAccount = async (accountNumber, index, user) => {
    console.log(accountNumber, user, userId);
    setLoading(true);
    const response = await postRequest(
      `account-creation-service/api/account/editAccounts`,
      values,
      'http://46.101.41.187:7090'
    );
    setLoading(false);
    const { message, status } = response.data;
    if (status === false) {
      return error(message);
    }
    handleCloseAccount();
    success(message);
    getAccount();
  };

  return (
    <>
      <div className={classes.root}>
        <Modal
          show={virtualAccount}
          onHide={handleViewClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          style={{ marginTop: 150, minWidth: '105%' }}
        >
          <Modal.Header
            style={{
              borderBottom: 'none',
              padding: '0 1rem',
              borderTop: '5px solid #FF6634',
            }}
            closeButton
          ></Modal.Header>
          <LoadingComponent loading={loading} />
          <Modal.Body>
            <div className={classes.tabContent}>
              <div className={classes.name2}>
                <Typography variant="h6" style={{ marginLeft: '270px' }}>
                  Update Virtual Account
                </Typography>
              </div>
            </div>
            <div className={classes.tabContent2}></div>
            <div className={classes.tabContent3}>
              <div className={classes.link2}>
                <TextField
                  className={classes.textme3}
                  id="outlined-select-currency-native"
                  label="Account Name"
                  name="accountName"
                  placeholder="please enter name"
                  onChange={handleChange}
                  value={values.accountName}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                ></TextField>
              </div>
            </div>
            <Button
              variant="contained"
              className={classes.btnp3}
              onClick={() => updateAccount()}
            >
              Update Virtual Account
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
