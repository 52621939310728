import React from 'react'
import ManageDonationsComponent from '../ManagePollsComponent';

const Polls = () => {
  return (
    <ManageDonationsComponent  createButton={true} showRange={true} showSearch={false}/>
  )
}

export default Polls;
