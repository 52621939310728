import React from 'react';
import { Modal } from 'react-bootstrap';
import AddCharge from './PauseAccount'
import {postRequest} from '../../../services/request'
import LoadingComponent from '../../../components/loading/LoadingComponent'


export default function ChargeIndex(props){
const {handleClosePause, getTransaction, handleOpenPause, pause} = props
const [loading, setLoading] = React.useState(false)
const handleView =()=>{
  handleOpenPause()
}
const handleClose =()=>{
  handleClosePause()
}



  
  return(

  <div >
      <Modal
        show={handleView}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        size="lg"
        id="user"
        >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
            {/* <LoadingComponent loading={loading} /> */}
        <Modal.Body>
        <AddCharge pause={pause} getTransaction={getTransaction} handleClose={handleClose}/>
        </Modal.Body>
        </Modal>
      </div>
  )
}















