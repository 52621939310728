import React from "react";
import { Card } from "antd";
import { createUseStyles, useTheme } from "react-jss";
import UserContact from "./UserContact";
import UserGroup from "./UserGroup";
import UserProfile from "./UserProfile";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: 305,
    height: "100%",
    borderRadius: "10px",
    padding: "20px",
  },
  profileContainer: {
    marginTop: "25px",
  },
  userContact: {
    height: "40vh",
    overflowY: "scroll",
  },
  header1: {
    fontSize: "16px",
    color: theme.color.brightOrange,
    paddingBottom: "14px",
    fontWeight: 700,
    borderTop: "1px solid gray",
    paddingTop: "15px",
    color: "gray",
  },
  header2: {
    fontSize: "16px",
    color: theme.color.textGrey,
    paddingBottom: "14px",
    fontWeight: 700,
    borderTop: "1px solid gray",
    paddingTop: "15px",
    color: "gray",
  },
  groups: {
    height: "25vh",
    overflowY: "scroll",
    display: "flex",
    flexDirection: "column",
    rowGap: "5px",
  },
}));

const Profile = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <Card className={classes.container}>
      <UserProfile />

      <div className={classes.profileContainer}>
        <h2 className={classes.header1}>Blocked Contacts</h2>
        <div className={classes.userContact}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((a) => (
            <UserContact type="blocked contact" />
          ))}
        </div>
      </div>

      <div className={classes.profileContainer}>
        <h2 className={classes.header2}>Groups</h2>
        <div className={classes.groups}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((a) => (
            <UserGroup type="group" />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Profile;
