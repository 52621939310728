import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import SLUGS from '../../../resources/slugs';

const useStyles = makeStyles({
  heads: {
    display: 'flex',
    '@media (max-width: 868px)': {
      marginLeft: '65px',
    },
  },
  type: {
    marginRight: '350px',
    fontSize: '10px',
    '@media (max-width: 868px)': {
      marginRight: '210px',
      marginBottom: '25px',
    },
  },
  inputs: {
    justifyContent: 'space-around',
  },
  root: {
    maxWidth: 400,
    '@media (max-width: 868px)': {
      maxWidth: 660,
      marginLeft: '60px',
    },
  },
  roots: {
    minWidth: 336,
    display: 'flex',
    backgroundColor: 'white',
    '@media (max-width: 868px)': {
      maxWidth: 990,
      marginBottom: '30px',
      marginLeft: '60px',
    },
  },
  root1: {
    minWidth: 300,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FBFBFB',
    '@media (max-width: 868px)': {
      maxWidth: 990,
      marginBottom: '30px',
      marginLeft: '60px',
    },
  },
  sec2: {
    display: 'flex',
    minWidth: '100%',
  },

  cover: {
    display: 'flex',

    '@media (max-width: 868px)': {
      display: 'block',
    },
  },
  cover2: {
    display: 'flex',
    marginTop: '20px',
    '@media (max-width: 868px)': {
      display: 'block',
    },
  },
  cover3: {
    display: 'flex',
    marginTop: '30px',
    '@media (max-width: 868px)': {
      display: 'block',
    },
  },
  btnp3: {
    display: 'column',
  },
  CardHeader: {
    marginRight: '50px',
  },
  CardHeader2: {
    marginRight: '65px',
  },
  CardHeader3: {
    marginRight: '60px',
  },
  createButton: {
    color: 'white',
    backgroundColor: '#FF4B01',
  },
});

const TechOverviewComponent = (props) => {
  const classes = useStyles();

  let test = localStorage.getItem('generateUser')
    ? JSON.parse(localStorage.getItem('generateUser'))
    : [];

  // localStorage.setItem('generateUser', val ? JSON.stringify(val) : '[]');

  // localStorage.setItem('generateUser', val ? JSON.stringify(val) : '[]');

  // if(test.length === 0){

  // }else{

  //   localStorage.setItem('generateUser', val);
  // }

  return (
    <>
      <div className={classes.heads}>
        <div className={classes.type}>
          <Typography
            gutterBottom
            variant="h5"
            component="h2"
            style={{ fontSize: '18px' }}
          >
            Overview
          </Typography>
        </div>
      </div>
      <div className={classes.cover}>
        <div className={classes.CardHeader}>
          <Card className={classes.roots}>
            <div>
              <CardContent>
                <div className={classes.sec2}>
                  <div style={{ marginRight: '90px', width: '100%' }}>
                    <Typography
                      gutterBottom
                      variant="h1"
                      component="h5"
                      style={{ fontSize: '21px', fontWeight: 700 }}
                    >
                      Wayagram Accounts
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ marginBottom: '20px' }}
                    >
                      Generate users for simulations
                    </Typography>

                    <Link to={SLUGS.waya_simulated_users}>
                      <Button
                        key={1}
                        variant="outlined"
                        style={{
                          backgroundColor: '#FFF1EC',
                          color: '#FF4B01',
                          border: '1px solid #FF4B01',
                        }}
                        // onClick={() => handleOpenUser()}
                      >
                        Proceed
                      </Button>
                    </Link>
                    {/* {showUser ? (
                      <UserModal
                        val={val}
                        setVal={setVal}
                        handleCloseUser={handleCloseUser}
                      />
                    ) : (
                      ''
                    )} */}
                  </div>
                </div>
              </CardContent>
            </div>
          </Card>
        </div>
        <div className={classes.CardHeader}>
          <Card className={classes.roots}>
            <div>
              <CardContent>
                <div className={classes.sec2}>
                  <div style={{ marginRight: '90px', width: '100%' }}>
                    <Typography
                      gutterBottom
                      variant="h1"
                      component="h5"
                      style={{ fontSize: '21px', fontWeight: 700 }}
                    >
                      Groups
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ marginBottom: '20px' }}
                    >
                      Create Groups for simulation
                    </Typography>

                    <Link to={SLUGS.waya_simulated_group}>
                      <Button
                        key={2}
                        variant="outlined"
                        style={{
                          backgroundColor: '#FFF1EC',
                          color: '#FF4B01',
                          border: '1px solid #FF4B01',
                        }}
                        // onClick={() => handleOpenFundUser()}
                      >
                        Proceed
                      </Button>
                    </Link>
                    {/* {showFundUser ? (
                      <FundUserModal
                        handleCloseFundUser={handleCloseFundUser}
                      />
                    ) : (
                      ''
                    )} */}
                  </div>
                </div>
              </CardContent>
            </div>
          </Card>
        </div>
        <div className={classes.CardHeader}>
          <Card className={classes.roots}>
            <div>
              <CardContent>
                <div className={classes.sec2}>
                  <div style={{ marginRight: '90px', width: '100%' }}>
                    <Typography
                      gutterBottom
                      variant="h1"
                      component="h5"
                      style={{ fontSize: '21px', fontWeight: 700 }}
                    >
                      Pages
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                      style={{ marginBottom: '20px' }}
                    >
                      Create Pages for simulation
                    </Typography>

                    <Link to={SLUGS.waya_simulated_page}>
                      <Button
                        key={3}
                        variant="outlined"
                        style={{
                          backgroundColor: '#FFF1EC',
                          color: '#FF4B01',
                          border: '1px solid #FF4B01',
                        }}
                        // onClick={() => handleOpenProcess()}
                      >
                        Proceed
                      </Button>
                    </Link>
                    {/* {showProcess ? (
                      <ProcessModal handleCloseProcess={handleCloseProcess} />
                    ) : (
                      ''
                    )} */}
                  </div>
                </div>
              </CardContent>
            </div>
          </Card>
        </div>
      </div>
      <div className={classes.cover2}>
        {/* {showCreditUser ? (
            <CreditModal handleCloseCreditUser={handleCloseCreditUser} />
          ) : (
            ''
          )} */}
        {/* {showMassCreation ? (
            <FileFundModal handleCloseMassCreation={handleCloseMassCreation} />
          ) : (
            ''
          )} */}
      </div>
    </>
  );
};

export default TechOverviewComponent;
