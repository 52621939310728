import React from 'react'
import RequestLog from '../Logs/RequestLog';

const Request = () => {
  return (
    <RequestLog  createButton={true}
    showRange={true} showSearch={false}/>
  )
}

export default Request;