import React from 'react';
import { createUseStyles } from 'react-jss';
import { Column } from 'simple-flexbox';
import LogoComponent from '../components/sidebar/LogoComponent';
import { Formik } from 'formik';
import { useNavigate  } from 'react-router-dom';
import SLUGS from '../resources/slugs';
import { TextField } from '@mui/material';
import send from '../services/request';
import { error, success } from '../services/swal-mixin';
import { Row } from 'react-bootstrap';
import * as Yup from 'yup';
import backgroundImage from '../assets/images/login-bg.png';

const useStyles = createUseStyles((theme) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: `url(${backgroundImage})`,
  },
  formContainer: {
    width: '30vw',
    height: '80vh',
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    borderTop: `5px solid ${theme.color.wayaPink}`,
    borderRadius: 4,
    marginRight: 30,
    marginLeft: 30,
    padding: '24px 32px 0px 32px',
    background: theme.color.white,
    '@media (max-width: 768px)': {
      width: '80vw',
      height: '85vh',
    },
  },
  centreBlock: {
    textAlign: 'center',
  },
  bottomContent: {
    margin: '0 auto',
    position: 'relative',
    height: '15vh',
    bottom: 0,
  },
  textLink: {
    color: theme.color.lightGray,
    marginLeft: 5,
    cursor: 'pointer',
    '&:hover': {
      color: theme.color.black,
      textDecoration: 'underline',
    },
  },
  verticalSpacing: {
    paddingTop: 20,
    paddingBottom: 20,
    '@media (max-width: 768px)': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '38px',
    letterSpacing: '0.2px',
    color: theme.color.black,
    marginBottom: 10,
  },
  formList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 20,
  },
  submitButton: {
    width: '100%',
    margin: '15px auto',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '4px',
    background: theme.color.wayaPink,
    color: '#f2f2f2',
    letterspacing: '.09em',
  },
}));

const ChangePasswordFromEmail = (props) => {
  const classes = useStyles();
  const push = useNavigate ();
  const params = new URLSearchParams(props.location.search);
  const resetToken = params.get('resetToken'); 
  return (
    <Row className={classes.container}>
      <Column className={classes.formContainer}>
        <div className={classes.forgotPasswordCard}>
          <div className={classes.verticalSpacing}>
            <LogoComponent isColored={true} />
          </div>
          <div className={classes.title}>Change Password</div>
          <div className={classes.verticalSpacing}>
            <Formik
              initialValues={{ password: '', confirmPassword: '', resetToken }}
              validationSchema={Yup.object().shape({
                password: Yup.string().min(8).required('Password is required'),
                confirmPassword: Yup.string().oneOf(
                  [Yup.ref('password'), null],
                  'Passwords must match'
                ),
              })}
              onSubmit={async (values) => {
                console.log(values);
                const response = await send('auth/reset-password', values);
                const { code, message } = response;
                if (code !== 200) {
                  return error(message);
                }
                push(SLUGS.login);
                return success(message);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form autoComplete="off" onSubmit={handleSubmit} noValidate>
                  <div>
                    <div className={classes.formList}>
                      <TextField
                        fullWidth
                        label="Password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        required
                        value={values.password}
                        variant="outlined"
                        type="password"
                      />
                    </div>
                    <div className={classes.formList}>
                      <TextField
                        fullWidth
                        label="Confirm Password"
                        name="confirmPassword"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        required
                        value={values.confirmPassword}
                        variant="outlined"
                        type="password"
                      />
                    </div>
                    <div className={classes.formList}>
                      <button
                        id="resetButton"
                        disabled={isSubmitting}
                        className={classes.submitButton}
                        type="submit"
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
            <div
              className={classes.centreBlock}
              style={{ marginTop: 10, marginBottom: 50 }}
            >
              <span>
                
                <span className={classes.textLink} onClick={(() => push(SLUGS.login))}>Back to Sign In</span>
              </span>
            </div>
          </div>
          <div className={classes.bottomContent}>
            <div className={classes.centreBlock}>
              <span>&#169; WayaPay</span>
            </div>
          </div>
        </div>
      </Column>
    </Row>
  );
};

export default ChangePasswordFromEmail;
