import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { Row } from 'simple-flexbox';
import LoadingComponent from '../../components/loading/LoadingComponent';
import Page from '../../components/Page';
import { getRequest, postRequest } from '../../services/request';
import { success, error } from '../../services/swal-mixin'
import TransactionsTableComponent from './TransactionsTableComponent';
import {CsvConversion} from '../../components/CsvDownload'

const useMatStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '100%',
  },
}));

// const filterOptions = [
//   {
//     value: 'firstName',
//     label: 'First Name',
//   },
//   {
//     value: 'lastName',
//     label: 'Last Name',
//   },
//   {
//     value: 'phone',
//     label: 'Phone Number',
//   },
//   {
//     value: 'status',
//     label: 'Status',
//   },
//   {
//     value: 'availableBalance',
//     label: 'Available Balance',
//   },
//   {
//     value: 'clearedBalance',
//     label: 'Cleared Balance',
//   },
//   {
//     value: 'date',
//     label: 'Date',
//   },
// ];

const BankCardsComponent = ({ userId, title = 'Payment Requests' }) => {
  const matclasses = useMatStyles();
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  
console.log(userId)
  const [headers] = useState([
    'Account Number',
    'Types',
    'Provider',
    'Amount',
    'Status',
    'Reverse',
  ]);

  const getCards = async () => {
    console.log(userId)
    setLoading(true);
    const response = await getRequest(
      `card-service/api/bankAccount/getTransactionRecord/${userId}`,
      'http://157.230.223.54:3020'
    );
    setLoading(false);
    console.log(response);

    const { data, status, message } = response?.data;
    if (status === false) {
      return error(message)
    }
    setCards(data);
    
  };
console.log(cards)
  // const getPage = async (page) => {
  //   setLoading(true);
  //   const response = await getRequest(`wallet/all-wallets?page=${page}`);
  //   setLoading(false);

  //   if (!response || response.error !== undefined) {
  //     return;
  //   }
  //   const { data } = response.data;
  //   setCards(data);
  //   // setMetaData(data.metaInfo);
  // };

  // async function ReverseTran(id) {
  //   // console.log(id)
  //   const data = cards.find((card)=> card.id === id)
  //   var date = new Date(data.trxDate);
  // const year = date.getFullYear()
  // const m = date.getMonth() +1
  // const d = date.getDate()
  // const trxDate = `${year}-${m}-${d}`
  
  // const state = {
  //   tranCrncy: data.currencyCode,
  //   tranDate: trxDate,
  //   tranId: id,
  // };
  //   setLoading(true)
  //   const response = await postRequest(
  //     'api/v1/wallet/transaction/reverse',
  //     state,
  //     'http://157.230.223.54:9009'
  //   );
  //   setLoading(false)
  //   console.log(response);
  //   const {message, status} = response?.data
  //   if(status === false){
  //     return error(message)
  //   }
  //   success(message)
  // }

  useEffect(() => {
    getCards();
  }, []);

  return (
    <Page className={matclasses.root} title="Users">
      <LoadingComponent loading={loading} />
      <Container maxWidth={false}>
        <Row>
          <Box marginBottom={2} fontWeight={600}>
            <Row vertical="center" breakpoints={{ 384: 'column' }}>
              {title}
            </Row>
          </Box>
        </Row>
        <CsvConversion users={cards} title="Export Data"/>
        <Box mt={3} className={matclasses.tableContainer}>
          <TransactionsTableComponent
            // getPage={getPage}
            data={cards}
            // ReverseTran={ReverseTran}
            headers={headers}
            type="bankCard"
          />
        </Box>
      </Container>
    </Page>
  );
};

export default BankCardsComponent;
