import CsvDownload from 'react-json-to-csv';
import React, { useEffect, useState } from 'react';
import { createCanvas } from 'canvas';
import config from '../../../services/config';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import { Tabs, Table, Select, Input, Modal, Form, Button, notification, Pagination, Switch } from 'antd';
import { deleteRequest, getRequest, postRequest, putRequest } from '../../../services/request';
import {ExclamationCircleOutlined } from '@ant-design/icons';
import { momentColumn } from '../../../utils/dummyData';
import SearchBar from '../../../components/SearchBar';
import { getUsers } from '../../../services/helpers';
const CanvasTextWrapper = require('canvas-text-wrapper').CanvasTextWrapper;

const DashboardComponent = () => {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState("moments");
  const [filterType, setFilterType] = useState("username");
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [limits, setLimits] = useState(20);
  const [posts, setPosts] = useState([]);
  const [postData, setPostData] = useState({});
  const [users, setUsers] = useState([]);
  const [images, setImages] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [mode, setMode] = useState("");
  const [type, setType] = useState(true);
  const [reload, setReload] = useState(false);
  const [value, setValue] = useState("a");
  const { confirm } = Modal;
  const [form] = Form.useForm()
  const [query, setQuery] = useState("");

  const selectOptions = [
    {
      value: "username",
      label: "Username",
    },
    // {
    //   value: "date",
    //   label: "Date",
    // },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "phone",
      label: "Phone",
    },
  ];

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) {
      setQuery(value);
    }
  };

  const getData = async (q) => {
    let response;
    const filter = q ? `${filterType}=${query}` : '';
    // if (q) {
    //   response = await getRequest(
    //     `admin-search-moments?search_word=${q}&query_by=${filterType}`,
    //     config.postUrl
    //   );
    // } else {
    response = await getRequest(
      `admin-get-${currentTab}?page=${page}&limit=${limits}&${filter}`,
      config.postUrl
    );
    // }
    setLoading(false);
    const { data } = response?.data || {};
    const { metadata } = response?.data?.data || {};
    
    setTotal(metadata?.total || 0)
    setPosts(data?.data || []);
    setFilteredPosts(data?.data || []);
  };

  const getStats = async () => {
    const response = await getRequest(
      'admin-get-moment-stats',
      config.postUrl
    );
    const { data} = response.data || {};
    setMetaData(data);
  };

  const deActivate = async (pid, id) => {
    setLoading(true)
    const response = await deleteRequest(
      `admin-delete-moment?profile_id=${pid}&moment_id=${id}`,
      config.postUrl
    );
    setLoading(false)
    const { data } = response || {};
    if (data?.status === true) {
      notification.success({
        message: "deleted",
        description:data?.message
      })
      setReload(!reload);
    } else {
      notification.warning({
        message: "error",
        description:data?.message
      })
    }
  };

  const handleConfirm = (pid, id) =>{
    confirm({
      title: `Do you want to delete these items?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        deActivate(pid, id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  useEffect(() => {
    getData();
    getStats();
    
  }, [currentTab, page, reload]);

  useEffect(() => {
    getUsers(value, setUsers);
    getData(query);
  }, [value, query]);


  const handleModals=(type, value)=>{
    if(type==='newPost'){
      setcreatePostModal(true)
      setMode("create")
    }
    // if(type==='editPost'){
    //   setcreatePostModal(true)
    //   setMode("update")
    //   setImages(value.content);
    //   form.setFieldsValue({
    //     profile_id: value.ProfileId,
    //     moment_id: value.id,
    //   })
    // }
  }

  const handleSubmitPost = async(values) =>{
    setLoading(true);
    const newForm = new FormData();
    mode === 'update' && newForm.append('post_id', values.post_id);
    newForm.append('profile_id', values.profile_id);
    newForm.append('file', postData.file);
    const url = `admin-${mode}-moment`;
    let  res = {}
    if (mode === 'create') {
      res = await postRequest(url, newForm, config.postUrl);
    }else{
      res = await putRequest(url, newForm, config.postUrl);
    }
    const {status, data, message} = res?.data || {}
    if (status) {
      notification.success({description:message, message:'success!'});
      setcreatePostModal(false)
      setReload(!reload);
    } else {
      notification.error({description:message, message:'Oops!'});
    }
    setLoading(false);
  };

  const column = momentColumn(handleConfirm, handleModals)

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="moments.csv"
        style={{
          //pass other props, like styles
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          // border: '1px solid #a511c0',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          // marginTop: '16px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
          // textShadow: '0px 1px 0px #9b14b3',
        }}
      >
        Export Moment
      </CsvDownload>
    );
  };

  const handlePreview = async (e, type) => {
    e.preventDefault();
    let files;
    if (type === 'text') {
      const canvas = createCanvas(512, 512);
      const ctx = canvas.getContext('2d');
      ctx.globalCompositeOperation = 'destination-over';
      ctx.fillStyle = '#000000';
      CanvasTextWrapper(canvas, e.target.value, {
        strokeText: false,
        textAlign: 'center',
        font: '30px Impact',
        verticalAlign: 'middle',
        paddingX: 10,
        paddingY: 10,
      });
      ctx.fillStyle = '#ffffff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      const base64 = canvas.toDataURL('image/png');
      const cavaRes = await fetch(base64);
      const blob = await cavaRes.blob();
      const file = new File([blob], 'converted.png');
      setImages(base64);
      setPostData({ ...postData, file });
    } else if (e.target) {
      files = e.target.files;
      setPostData({ ...postData, file: files[0] });
      const reader = new FileReader();
      reader.onload = () => {
        setImages(reader.result);
      };
      reader.readAsDataURL(files[0]);
    }
  };


  return (
    <div className="w-100">
      <SearchBar
        page="moment"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        hideSelect={false}
        placeholder="Search by username"
        selectOptions={selectOptions}
      />
      <LoadingComponent loading={loading} />
      <div>
        <Tabs onChange={(e)=>setCurrentTab(e)}>
          <Tabs.TabPane key="moments" tab="Moments">
            <div className='w-100 my-3'>
              <Table columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane key="top-moments" tab="Top Moments">
            <div className='w-100 my-3'>
              <Table columns={column} dataSource={posts} rowKey="id" loading={loading} pagination={false} />
            </div>
          </Tabs.TabPane>
        </Tabs>
        <Pagination
          total={total}
          responsive={true}
          defaultCurrent={1}
          onChange={(e)=>setPage(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </div>
      <Modal
        visible={createPostModal}
        onCancel={()=> setcreatePostModal(false)}
        footer={false}
        title={<p className='text-center'>Create New Post</p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Switch
            unCheckedChildren="upload image"
            checkedChildren="create text"
            defaultChecked
            onChange={(e)=> setType(e)}
          />
          <Form.Item label="Post Owner" name="profile_id" hidden={mode === "update"}>
            <Select
              showSearch
              value={value}
              placeholder="Search for user"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onSearch={(e) => setValue(e)}
              notFoundContent={null} 
            >
              {users.map(user =>
                <Select.Option key={user.id}>{user.displayName}</Select.Option>
              )}
            </Select>
          </Form.Item>
          {type ? (
            <Form.Item label="Images">
              <div className="input-group">
                <input type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  onChange={(e) => handlePreview(e, "image")}
                  accept="image/*"
                  multiple="multiple"
                  aria-label="Upload" />
                <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Browse</button>
              </div>
            </Form.Item>
          ):(
            <Form.Item label="Description" name="description">
              <Input.TextArea onChange={(e) => handlePreview(e, "text")}/>
            </Form.Item>
          )}
          {images && <img src={images} alt="preview moment" height="100%" width="100%" />}
          <Form.Item hidden name="moment_id">
            <Input />
          </Form.Item>
          <Form.Item className='text-center mt-4'>
            <Button size='large' loading={loading} htmlType='submit' type='primary' className='rounded px-5'>
              {mode} moment
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DashboardComponent;
