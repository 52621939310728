import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingComponent from '../loading/LoadingComponent'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getRequest } from '../../services/request';
import { success, error } from '../../services/swal-mixin';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '20px 0',
    justifyContent: 'space-around',
    marginRight: 100,
  },

  link: {
    marginLeft: 100,
    marginBottom: '0.5rem',
  },
  textme: {
    minWidth: '100%',
  },
  textme2: {
    minWidth: '250%',
  },
  btnp3: {
    marginLeft: 320,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

const UserComponent = (props) => {
  const classes = useStyles();
  const state = {
    pin: '',
  };
  const [value, setValue] = React.useState(state);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    const { value, name } = event.target;
    setValue((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  async function validatePin(){
    if(value.pin === ''){
      return error("please enter valid pin")
    }
    setLoading(true)
    const response = await getRequest(`api/v1/pin/validate-pin/${value.pin}`)
    setLoading(false)
    
    const {message, status} = response.data
    if(status === false){
      return error(message)
    }

    success(message)
  }
  


  console.log(value);

  return (
    <div className={classes.root}>
      <LoadingComponent loading={loading}/>
      <div className={classes.tabContent}>
        <div className={classes.name2}>
          <Typography variant="h6">Validate Waya Official PIN</Typography>
        </div>
      </div>
      
      <div className={classes.tabContent2}>
        
        <div className={classes.link}>
          <TextField
            className={classes.textme}
            id="outlined-select-currency-native"
            label="PIN"
            placeholder="please enter new Pin"
            name="pin"
            type="password"
            value={value.pin}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          ></TextField>
        </div>
      </div>
      <Button variant="contained" className={classes.btnp3} onClick={()=>validatePin()}>
        Send
      </Button>
    </div>
  );
};

export default UserComponent;
