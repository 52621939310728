import { makeStyles } from '@mui/styles';
import React from 'react';
import { Modal } from 'react-bootstrap';
// import { sendWithAuth, putRequest } from '../../services/request';
import { error, success } from '../../services/swal-mixin';
import UserFormComponent from './UserFormComponent';
import LoadingComponent from '../../components/loading/LoadingComponent';
import { customBaseUrl, httpPut } from '../../services/http';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: '25px 0',
  },

  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 20,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: '5px auto',
  },
  avatar: {
    height: 150,
    width: 150,
    minWidth: 150,
    borderRadius: 100,
    // border: `1px solid ${theme.color.lightGrayishBlue2}`,
  },
}));

const EditUserComponent = (props) => {
  const classes = useStyles();
  const { data, show, handleEditClose } = props;
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    handleEditClose();
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const updateUser = async (userData, id) => {
    setLoading(true);
    console.log(id);
    const response = await httpPut(
      `/api/v1/profile/update-personal-profile/${id}`,
      userData,
      customBaseUrl.authUrl
    );
    console.log(response);
    setLoading(false);
    const { message } = response.data;
    if (response.data.status === false) {
      return error(message);
    }
    userData = '';
    handleClose();
    success(message);
    // store.dispatch({ type: 'userUpdated' });
  };

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <div className={classes.tabContent}>
            <UserFormComponent data={data} submitForm={updateUser} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditUserComponent;
