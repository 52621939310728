import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Table, Pagination, Button, Modal, Form, Input, notification, Upload, Select } from "antd";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { smsCol } from "../../../utils/dummyData";
import { getRequest, postRequest, patchRequest, deleteRequest } from "../../../services/request";
import config from "../../../services/config";
import SearchBar from '../../../components/SearchBar';
import CsvDownload from 'react-json-to-csv';
import color from '../../../theme/color'
import { InboxOutlined } from '@ant-design/icons';
import  MultiInput from '../../../components/CustomInput/MultiInput'

const  SmsManagement = ({createButton = false, showRange = false, showSearch = true }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [currentTab, setCurrentTab] = useState("SmsManagement");
  const [filterType, setFilterType] = useState("SENDER");
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [query, setQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [value, setValue] = useState("a");
  const [values, setValues] = useState("");
  const [createModal, setCreateModal] = useState(false)
  const [mode, setMode] = useState('')
  const [images, setImages] = useState([]);
  const [formdata, setFormdata] = useState({});
  const [users, setUsers] = useState([])
  const [options, setOptions] = useState([])
  const [createManyPostModal, setcreateManyPostModal] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const { Dragger } = Upload;
  const [form] = Form.useForm()



  const selectOptions = [
    {
      value: "SENDER",
      label: "Initiator",
    },
    {
      value: "RECIPIENT",
      label: "Reciever",
    },
  ];
  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });

  const handleChange = (e) => {
    setValues(e.target.value);
    setQuery(e.target.value);
  };




  const getData = async (q) => {
    let response
    if(q) {
      response = await getRequest(
        `admin-search-sms-logs?query=${query}&filterBy=${filterType}&page=${pages}&limit=20`,
        config.notificationUrl
      );
    }else{
      let rangeQuery='';
      if (range.startDate && range.endDate) {
        rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
      }
      setLoading(true);
      response  = await getRequest(
        `admin-get-sms-logs?page=${pages}&limit=${limits}${rangeQuery}`,
        config.notificationUrl
      );
    };
    setLoading(false);
    const { data, metadata } = response?.data?.data || [];
    setPosts(data || []);
    setTotal(metadata?.total || 0);
    setFilteredPosts(data?.data || []);
  };
  

  const getSMSOverview = async () => {
    const response = await getRequest(
      "admin-get-sms-log-analysis",
      config.notificationUrl
    );
    const { data } = response?.data || {};
    setMetaData(data);
  };

  useEffect(() => {
    getData(query);
  }, [currentTab, reload, query, range]);

  useEffect(() => {
    getSMSOverview();
    // getUsers();
  }, []);

  const handleSubmit = async (datum) => {
    const formD = new FormData();
    formD.append('bulkSMS', excelFile);
    const payload = createManyPostModal ? formD : formdata;
    const response =await postRequest(
      `send-message`,
      payload,
      config.notificationUrl
    );

    const { status, message } = response?.data || {};
    if (status === true) {
      notification.success({
        message,
        description:"SMS sent"
      })
      await getData();
      await getSMSOverview();
      setCreateModal(false)
      setcreateManyPostModal(false)
    } else {
      notification.warning({
        message,
        description:"Failed"
      })
    }
  }

  // const getUsers = async () => {
  //   const response = await getRequest(
  //     `admin-profile/all?page=${pages}&limit=1000000`,
  //     config.mainUrl
  //   );
  //   const data = response?.data?.data?.AllWayAGramProfiles || [];
  //   setUsers(data);
  //   setOptions(data.map((d) => { return { value: d.phone, label: d.phone }}))
  // }

const handleModals=(type)=>{
  if(type==='single'){
    setCreateModal(true)
    setMode(type)
  }
  if(type==='bulk'){
    form.resetFields()
    setcreateManyPostModal(true)
    setMode(type)
  }
  // setCreateModal(true)
  // setMode(type)
}

const resend =async (datum) => {
  const response =  await patchRequest (
   `resend-sms?sms_log_id=${datum.id}`,
   {
    // phone: datum.phone,
    content: datum.message,
    type: datum.type,
   },
     config.notificationUrl
  )
  setLoading(false)
  const { status, message } = response.data;
   if (status === true) {
     notification.success({
       message: `SMS Sent successfully`,
       description: message
     })
     setReload(!reload);
   } else {
     notification.warning({
       message:"failed",
       description:message
     })
   }
  await getData()
  await getSMSOverview()
}

const deleteSms = async (datum) => {
  const response = await deleteRequest (
   `admin-delete-sms-log?sms_log_id=${datum.id}`,
    config.notificationUrl
  )
  setLoading(false)
  const { status, message } = response?.data;
   if (status === true) {
     notification.success({
       message: `SMS Sent successfully`,
       description: message
     })
     setReload(!reload);
   } else {
     notification.warning({
       message : "failed",
       description : message
     })
   }
  await getData()
  await getSMSOverview()
}

const props = {
  name: 'file',
  multiple: false,
  accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  customRequest({ onSuccess }){
    setTimeout(() => {
      onSuccess("ok");
    }, 0)
  },
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      setExcelFile(info.file.originFileObj)
      console.log(info.file)
    } else if (status === 'error') {
      console.log(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files[0]);
  },
};

const Buttons = () => {
  return(
    <div hidden={createButton} >
      <Button
        onClick={() => handleModals("single")}
        type="primary"
        className="me-2"
      >
        Send Single SMS
      </Button>
      <Button
        style={{ backgroundColor: color.brightOrange, color: color.white }}
        onClick={() => handleModals("bulk")}
      >
        Send Bulk SMS
      </Button>
    </div>
  )
}

const column = smsCol(resend, deleteSms);


  return (
    <Column>
      <Row horizontal="space-between">
      </Row>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
      <SearchBar
        page="sms"
        total={metaData}
        showButtons={true}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        handleChange={handleChange}
        Conversion={Buttons}
        createButton={true}
        showRange={showRange}
        showSearch={showSearch}
        setRange={setRange}
      />
      <Table
        columns={column}
        dataSource={posts}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
        <Pagination
        total={total}
        responsive={true}
        defaultCurrent={1}
        onChange={(e) => setPages(e)}
        defaultPageSize={limits}
        showSizeChanger={false}
      />
      </div>
      <Modal
        visible={createModal }
        onCancel={()=> setCreateModal(false)}
        footer={false}
        title={<p className='text-center'>Send SMS</p>}
      >
        <Form layout='vertical' onFinish={handleSubmit}>

          {/* <Form.Item label="Mobile number" name="email" hidden={mode==='bulk'}>
            <Select
              showSearch
              value={value}
              placeholder="Search for number"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onChange={(e)=> setFormdata({...formdata, email: [JSON.parse(e)]})}
              notFoundContent={null}
            >
              {users.map((user) => (
                <Select.Option key={user.id} value={JSON.stringify({ recipientId: user.id, phone: user.phone })} disabled={user.phone === null} >
                  {user.phone || 'No number'}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}

          {/* <Form.Item name="phone" label="Mobile Number" hidden={mode==='single'}>
            <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              onSearch={(e) => setValue(e)}
              onChange={(values)=> setFormdata({...formdata, email: values.map((value)=> JSON.parse(value)) })}
              // options={options}
              >
              {users.map((user) => (
                <Select.Option key={user.id} value={JSON.stringify({ recipientId: user.id, phone: user.phone })} disabled={user.phone === null} >
                  {user.phone || 'No number'}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}

          <Form.Item label="Enter Mobile Number" name="phone">
            <Input onChange={(e)=>setFormdata({...formdata, phone: e.target.value})} placeholder="+2348137898098"/>
          </Form.Item>

          <Form.Item label="Message" name="message">
            <Input.TextArea onChange={(e)=>setFormdata({...formdata, content: e.target.value})}/>
          </Form.Item>

          {/* <Form.Item label="Type" name="type">
            <Select
              onChange={(e)=>setFormdata({...formdata, type: e})}
            >
              <Select.Option value="PROMOTION">Promotion</Select.Option>
              <Select.Option value="POLL">Poll</Select.Option>
              <Select.Option value="PRODUCT">Product</Select.Option>
              <Select.Option value="DONATION">Donation</Select.Option>
              <Select.Option value="EVENT">Event</Select.Option>
              <Select.Option value="OTHERS">Others</Select.Option>
            </Select>
          </Form.Item> */}

          {/* <Form.Item label="Attach files">
            <div className="input-group">
              <input type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                accept="image/*"
                multiple="multiple"
                aria-label="Upload" /> */}
              {/* <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Browse</button> */}
            {/* </div>
          </Form.Item> */}
          <Form.Item className='text-center mt-4'>
            <Button 
              size='large' 
              loading={loading} 
              htmlType='submit' 
              className='rounded px-5 '
              style={{ backgroundColor: color.brightOrange, color: color.white }}
            >
              Send SMS
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={createManyPostModal}
        onCancel={()=> setcreateManyPostModal(false)}
        footer={false}
        title={<p className='text-center'>Upload Excel SMS</p>}
      >
        <Form layout='vertical' onFinish={handleSubmit}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
          <Form.Item className="text-end mt-4">
            <Button
              htmlType="submit"
              type="primary"
              className="rounded px-5"
              loading={loading}
            >
              Send Bulk SMS
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Column>
  );
};

export default SmsManagement;
