import React, { useEffect, useState } from "react";
import config from "../../../services/config";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import {
  Table,
  notification,
  Switch,
} from "antd";
import {
  getRequest,
  patchRequest,
} from "../../../services/request";


const CV = () => {
  const [loading, setLoading] = useState(false);
  const [publicRoutes, setPublicRoute]  = useState([]);

  const getPublicRoute = async () => {
    setLoading(true);
    const response = await getRequest("admin/get-public-routes", config.gramUrl);
    const { publicRoutes } = response?.data || {};
    setPublicRoute(publicRoutes);
    setLoading(false);
  };

  const handleToggleRoute = async (data) => {
    const datas = {
      slug: data.slug,
      isPublic: !data.isPublic
    };
    setLoading(true);
    const response = await patchRequest(
      "admin/toggle-route-status",
      datas,
      config.gramUrl
    );
    setLoading(false);

    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message,
        description: "updated",
      });
      getPublicRoute();
    } else {
      notification.warning({
        message,
      });
      getPublicRoute();
    }
  };


  useEffect(() => {
    getPublicRoute();
  }, []);


  const columns = [
    {
      title: 'Route',
      dataIndex: 'name',
      key: 'title',
    },
    {
      title: 'Content Visibilty',
      dataIndex: 'isPublic',
      key: 'description',
      render: (_, record) =>
        <Switch
          checked={record.isPublic}
          onClick={() => handleToggleRoute(record)}    
        />
    }
  ];

  return (
    <div className="w-100">
      <LoadingComponent loading={loading} />
      <div>
        <Table
          scroll={{ x: 800 }}
          columns={columns}
          dataSource={publicRoutes}
          rowKey="id"
          loading={loading}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default CV;
