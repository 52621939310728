import React from 'react';
import { makeStyles } from '@mui/styles';
import { Modal } from 'react-bootstrap';
import { Grid, TextField } from '@mui/material';
import { getFullName } from '../../services/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    paddingBottom: 25,
    paddingLeft: 10,
    paddingRight: 10,
  },
  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 10,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: 'auto',
  },
  avatar: {
    height: 280,
    width: 280,
    minWidth: 280,
  },
}));

const ViewQRCodeComponent = (props) => {
  const classes = useStyles();
  const { data, show, handleViewClose } = props;
  console.log(data);

  const handleClose = () => {
    handleViewClose();
  };

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.profileCard}>
            <div className={classes.avatarWrapper}>
              <img
                src={data.qrString}
                alt="QR Code"
                className={classes.avatar}
              />
            </div>
          </div>
          <div>
          </div>
          <div className={classes.content}>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="QR Key"
                    name="qrKey"
                    value={data ? data.qrKey : ''}
                  />
                </Grid>
                {/* <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    label="File name"
                    name="fileName"
                    value={data ? data.fileName : ''}
                    fullWidth
                  />
                </Grid> */}
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Available"
                    name="isActive"
                    value={data ? data.isActive : ''}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="createdBy"
                    name="name"
                    value={data.createdBy ? getFullName(data.createdBy) : ''}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Assigned To"
                    name="name"
                    value={data.user ? getFullName(data.user) : ''}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Asignee Username"
                    name="username"
                    value={data.user ? data.user.username : ''}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                <TextField
                    fullWidth
                    label="Asignee Phone Number"
                    name="phone"
                    value={data.user ? data.user.phone : ''}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Agent"
                    name="agent"
                    value={data.agent ? getFullName(data.agent) : ''}
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Agent Username"
                    name="username"
                    value={data.agent ? data.agent.username : ''}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                <TextField
                    fullWidth
                    label="Agent Phone Number"
                    name="phone"
                    value={data.agent ? data.agent.phone : ''}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Agent Role"
                    name="agent_role"
                    value={data.agent ? data.agent.agentRole : ''}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Agent City"
                    name="agent_city"
                    value={data.agent ? data.agent.city : ''}
                  />
                </Grid>
              </Grid>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewQRCodeComponent;
