import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getFullName } from '../../services/helpers';
import ViewUserComponent from '../../views/users/ViewUserComponent';
import ViewGroupComponent from '../../views/management/GroupAccounts/ViewGroupComponent';

import EditUserComponent from '../../views/users/EditUserComponent';
import EditMerchantComponent from '../../views/users/EditMerchant';
import EditGroupComponent from '../../views/management/GroupAccounts/EditGroupComponent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { green, red } from '@mui/material/colors';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import ResetComponent from '../../views/users/UserListView/ResetComponent';
import AddBoxIcon from '@material-ui/icons/AddBox';
import CreateNewGroupComponent from '../../views/management/GroupAccounts/GroupIndex';
import CreateRoleComponent from '../../views/users/UserListView/ChangeRoleModal';
import Button from '@mui/material/Button';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import UserTransaction from '../../views/users/UserListView/TransferIndex'

class TableComponent extends React.Component {
  state = {
    anchorEl: null,
    menus: [],
    headers: [],
    data: [],
    metaData: 0,
    limit: 15,
    page: 0,
    selecteduserIds: [],
    showUser: false,
    showGroup: false,
    showEditUser: false,
    showEditGroup: false,
    showReset: false,
    showRole: false,
    showCreate: false,
    showTransaction: false,
    modalType: 'view user',
    user: {
      id: '',
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      phoneNumber: '',
      city: '',
      state: '',
      role: '',
      status: '',
      verified: '',
      cardLinked: '',
      // functions,
    },
    index: null,
  };

  componentDidMount() {
    this.initializeView();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.metaData !== this.props.metaData
    ) {
      this.initializeView();
    }
  }

  initializeView = async () => {
    const actionCol = 'actionCol' in this.props ? this.props.actionCol : true;
    const type = 'type' in this.props ? this.props.type : 'user';
    const data = this.props.data || [];
    const metaData = this.props.metaData || this.state.metaData;

    const menus = data.map((m) => false);
    this.setState({
      ...this.state,
      menus,
      data,
      metaData,
      actionCol,
      type,
      headers: this.props.headers || [],
    });
  };

  handleClick = (index) => (event) => {
    const { menus } = this.state;
    menus[index] = true;
    this.setState({ anchorEl: event.currentTarget, menus });
  };

  handleClose = (index) => () => {
    const { menus } = this.state;
    menus[index] = false;
    this.setState({ anchorEl: null, menus });
  };

  handleView = (item, index) => (event) => {
    console.log('users');
    this.handleClose(index);
    if (this.state.type === 'user' || this.state.type === 'merchant') {
      this.setState({ ...this.state, showUser: true, user: item, index });
    }
    if (this.state.type === 'group') {
      this.setState({ ...this.state, showGroup: true, user: item, index });
    }
    if (this.state.type === 'group') {
      this.setState({ ...this.state, showCreate: true, user: item, index });
    }
  };
  handleViewReset = (item, index) => (event) => {
    this.handleClose(index);
    this.setState({ ...this.state, showReset: true, user: item, index });
  };
  handleOpenEditRole = (item, index) => (event) => {
    this.handleClose(index);
    this.setState({ ...this.state, showRole: true, user: item, index });
  };

  handleViewCloseReset = () => {
    const { index } = this.state;
    this.handleClose(index);
    this.setState({ ...this.state, showReset: false });
  };
  handleCloseEditRole = () => {
    const { index } = this.state;
    this.handleClose(index);
    this.setState({ ...this.state, showRole: false });
  };

  handleViewTran = (item, index) => (event) => {
    this.handleClose(index);
    this.setState({ ...this.state, showTransaction: true, user: item, index });
  };

  handleViewTranClose = () => {
    const { index } = this.state;
    this.handleClose(index);
    this.setState({ ...this.state, showTransaction: false });
  };

  handleViewClose = () => {
    const { index } = this.state;
    this.handleClose(index);
    this.setState({
      ...this.state,
      showUser: false,
      showGroup: false,
      showCreate: false,
    });
  };

  handleEdit = (item, index) => (event) => {
    this.handleClose(index);
    if (this.state.type === 'group') {
      return this.setState({
        ...this.state,
        showEditGroup: true,
        user: item,
        index,
      });
    }
    return this.setState({
      ...this.state,
      showEditUser: true,
      user: item,
      index,
    });
  };

  handleEditClose = () => {
    const { index } = this.state;
    this.handleClose(index);

    if (this.state.type === 'group') {
      return this.setState({
        ...this.state,
        showEditGroup: false,
        index,
      });
    }
    return this.setState({
      ...this.state,
      showEditUser: false,
    });
  };

  handleActivation = (item, user) => (event) => {
    console.log(this.props);
    if ('activate' in this.props) {
      return this.props.activate(item);
    }
    alert('No function passed');
  };
  handleGroupActivation = (item) => {
    console.log(item);
    if ('activate' in this.props) {
      return this.props.activate(item);
    }
    alert('No function passed');
  };

  handleGroupDeactivation = (item) => {
    console.log(this.props);
    if ('delete' in this.props) {
      return this.props.delete(item);
    }
    alert('No function passed');
  };
  handleDeactivation = (item, user) => (event) => {
    console.log(this.props);
    if ('delete' in this.props) {
      return this.props.delete(item);
    }
    alert('No function passed');
  };

  handleLimitChange = (event) => {
    this.setState({ ...this.state, limit: event.target.value });
  };

  handlePageChange = (event, newPage) => {
    const page = this.state.page + 1;
    let limit = this.state.limit;
    console.log(this.props);
    this.props.getPage(page, limit);
    this.setState({ ...this.state, page: newPage });
  };

  render() {
    const {
      // anchorEl,
      // menus,
      headers,
      data,
      metaData,
      limit,
      page,
      selecteduserIds,
      showUser,
      showGroup,
      showEditUser,
      showEditGroup,
      showCreate,
      showReset,
      showRole,
      showTransaction,
      user,
      actionCol = false,
      type,
    } = this.state;

    return (
      <Card>
        <PerfectScrollbar>
          <Box overflow="auto">
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      key={index}
                      style={{ backgroundColor: '#f6b17f' }}
                    >
                      {' '}
                      {header}{' '}
                    </TableCell>
                  ))}
                  {actionCol ? (
                    <TableCell align='right' style={{ backgroundColor: '#f6b17f' }}>
                      Action
                    </TableCell>
                  ) : (
                    ''
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(0, limit).map((user, index) => (
                  <TableRow
                    hover
                    key={user.id}
                    selected={selecteduserIds.indexOf(user.userId) !== -1}
                  >
                    {type !== 'event' && type !== 'group' ? (
                      <TableCell>
                        <Box alignItems="center" display="flex">
                          <Typography color="textPrimary" variant="body1" style={{fontSize: "10px",}}>
                            {getFullName(user)}
                          </Typography>
                        </Box>
                      </TableCell>
                    ) : null}
                    {headers.includes('Report type') && (
                      <TableCell>{user.reportType || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Reason For Report') && (
                      <TableCell>{user.report || 'N/A'}</TableCell>
                    )}
                    
                    {headers.includes('Creator Name') && (
                      <TableCell>
                        {user.Profile.displayName ? user.Profile?.displayName : 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Event Name') && (
                      <TableCell>
                        {user.eventName ? user.eventName : 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('Details') && (
                      <TableCell>
                        {user.details ? user.details : 'N/A'}
                      </TableCell>
                    )}
                    {console.log(`users00000, ${user}`)}
                    {type === 'group' && headers.includes('Group Name') ? (
                      <TableCell>{user.name || 'N/A'}</TableCell>
                    ) : (
                      ''
                    )}
                    {type === 'group' &&
                    headers.includes('Group Description') ? (
                      <TableCell>{user.description || 'N/A'}</TableCell>
                    ) : (
                      ''
                    )}
                    {headers.includes('Description') && (
                      <TableCell>
                        {user.description ? user.description : 'N/A'}
                      </TableCell>
                    )}
                    
                    {type === 'group' && headers.includes('Type') ? (
                      <TableCell>
                        {user.isPublic ? 'Public' : 'Private'}
                      </TableCell>
                    ) : null}
                    {/* {type === 'group' && headers.includes('Status') ? (
                      <TableCell>
                        {user.isDeleted ? 'Inactive' : 'active'}
                      </TableCell>
                    ) : null} */}
                    {type === 'group' && headers.includes('Status') && (
                      <TableCell>
                        {!user.isDeleted && (
                          <Box color="success.main">Active</Box>
                        )}
                        {user.isDeleted && (
                          <Box color="warning.main">In Active</Box>
                        )}
                      </TableCell>
                    )}
                    {type === 'group' && headers.includes('Date Created') ? (
                      <TableCell>
                        {new Date(user.createdAt).toDateString()}
                      </TableCell>
                    ) : null}

                    {user.phone && (
                      <TableCell style={{fontSize: "10px",}}>
                        {user.phone
                          ? user.phone
                          : user.phone_number
                          ? user.phone_number
                          : user.phoneNumber
                          ? user.phoneNumber
                          : 'N/A'}
                      </TableCell>
                    )}
                    {user.phoneNumber && (
                      <TableCell style={{fontSize: "10px",}}>
                        {user.phone
                          ? user.phone
                          : user.phone_number
                          ? user.phone_number
                          : user.phoneNumber
                          ? user.phoneNumber
                          : 'N/A'}
                      </TableCell>
                    )}
                    {/* {user.otp && <TableCell>{user.otp}</TableCell>} */}
                    {/* {type === 'user' && (
                      <TableCell>
                        {user.phone
                          ? user.phone
                          : user.phone_number
                          ? user.phone_number
                          : user.phoneNumber
                          ? user.phoneNumber
                          : 'N/A'}
                          </TableCell>
                        )} */}
                        
                    {type === 'user' && (
                      <TableCell style={{fontSize: "10px",}}>{user.email ? user.email : 'N/A'}</TableCell>
                    )}
                    
                    {type === 'admin' && (
                      <TableCell>{user.email ? user.email : 'N/A'}</TableCell>
                    )}
                    {type === 'merchant' && (
                      <TableCell>{user.email ? user.email : 'N/A'}</TableCell>
                    )}
                    {headers.includes('Location') && (
                      <TableCell style={{fontSize: "10px",}}>{user.state || 'N/A'}</TableCell>
                    )}
                    {/* {type === 'merchant' && (
                      <TableCell>{user.state ? user.state : 'N/A'}</TableCell>
                    )} */}
                    
                    {user.status && <TableCell style={{fontSize: "10px",}}>{user.status}</TableCell>}
                    {user.city && <TableCell>{user.city}</TableCell>}
                    {user.area && <TableCell>{user.area}</TableCell>}
                    {user.cardLinked && (
                      <TableCell>{user.cardLinked}</TableCell>
                    )}
                    {/* {user.onboardedMerchants && (
                      <TableCell>
                        <Box
                          justifyContent="center"
                          alignItems="center"
                          display="flex"
                        >
                          <Typography color="textPrimary">
                            {user.onboardedMerchants}
                          </Typography>
                        </Box>
                      </TableCell>
                    )} */}
                    <TableCell>
                      {user.isPrivate && type === 'event' && (
                        <Box color="success.main">Private</Box>
                        // <Chip color="primary" label="Active" size="small" />
                      )}
                      {!user.isPrivate && type === 'event' && (
                        <Box color="warning.main">Public</Box>
                        // <Chip color="primary" label="In Active" size="small" />
                      )}
                    </TableCell>
                    <TableCell>
                      {user.active && type !== 'group' && type !== 'event' && (
                        <Box color="success.main">Active</Box>
                        // <Chip color="primary" label="Active" size="small" />
                      )}
                      {!user.active && type !== 'group' && type !== 'event' && (
                        <Box color="warning.main">In Active</Box>
                        // <Chip color="primary" label="In Active" size="small" />
                      )}
                    </TableCell>
                    {headers.includes('Access type') && (
                      <TableCell>{user.role || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Date Created')  && type === 'event' && (
                      <TableCell>
                        {moment(user.CreatedAt).format('MMM, Do YYYY')}
                      </TableCell>
                    )}
                    {user.funded_date && type !== 'event' && (
                      <TableCell>
                        {moment(user.funded_date).format('MMM, Do YYYY')}
                      </TableCell>
                    )}

                    {user.dateCreated && type !== 'event' && (
                      <TableCell>
                        {moment(user.dateCreated).format('MMM, Do YYYY')}
                      </TableCell>
                    )}
                    {headers.includes('Date') && type !== 'event' && (
                      <TableCell>
                        {moment(user.CreatedAt).format('MMM, Do YYYY')}
                      </TableCell>
                    )}
                    
                    {actionCol && (
                      <TableCell>
                        <Box color="#FF6634">
                          {type !== 'group' && type !== 'admin' && (
                            <IconButton edge="end" size="small" color="primary">
                              <VisibilityIcon
                                onClick={this.handleView(user, index)}
                              />
                            </IconButton>
                          )}
                          {type === 'admin' && (
                            <IconButton edge="end" size="small" color="primary">
                              <VisibilityIcon
                              
                                onClick={this.handleView(user, index)}
                              />
                            </IconButton>
                          )}
                          {type === 'group' && (
                            <IconButton edge="end" size="small" color="primary">
                              <VisibilityIcon
                                onClick={this.handleView(user, index)}
                              />
                            </IconButton>
                          )}
                          {type === "merchant" && (
                          <IconButton edge="end" size="small" color="inherit">
                            <SyncAltIcon onClick={this.handleViewTran(user)} />
                          </IconButton> )}

                          {/* <IconButton edge="end" size="small" color="inherit">
                            <EditIcon onClick={this.handleEdit(user)} />
                          </IconButton> */}
                          {type === 'group' && (
                            <IconButton edge="end" size="small" color="inherit">
                              <AddBoxIcon onClick={this.handleView(user)} />
                            </IconButton>
                          )}
                          {user.isDeleted &&
                            type !== 'group' &&
                            type !== 'user' &&
                            type !== 'event' &&
                            !user.isActive && (
                              <IconButton
                                edge="end"
                                size="small"
                                style={{ color: green[500] }}
                              >
                                <CheckIcon
                                  onClick={this.handleActivation(
                                    user.id,
                                    user.userId
                                  )}
                                />
                              </IconButton>
                            )}

                          {/* {(user.isDeleted || type === 'merchant') && (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: green[500] }}
                            >
                              <CheckIcon
                                onClick={this.handleActivation(user, index)}
                              />
                            </IconButton>
                          )} */}

                          {/* {(!user.isDeleted ||
                            user.isActive ||
                            type !== 'event') && (
                            <IconButton
                              edge="end"
                              size="small"
                              color="secondary"
                            >
                              <DeleteIcon
                                onClick={this.handleDeactivation(user.id)}
                              />
                            </IconButton>
                          )} */}
                          {type === 'user' ||
                          type === 'privateuser' ||
                          type === 'merchant' ? (
                            <IconButton edge="end" size="small" color="inherit">
                              <RotateLeftIcon
                                onClick={this.handleViewReset(user)}
                              />
                            </IconButton>
                          ) : null}

                          {type === 'user' ? (
                            <IconButton edge="end" size="small" color="inherit">
                              <Button
                                variant="outlined"
                                color="primary"
                                style={{width: '30px', fontSize: "8px",}}
                                onClick={this.handleOpenEditRole(user)}
                              >
                                Change Role
                              </Button>
                            </IconButton>
                          ) : null}

                          {((!user.isDeleted && type === 'merchant') ||
                            user.isActive) && (
                            <IconButton
                              edge="end"
                              size="small"
                              color="secondary"
                            >
                              <DeleteIcon
                                // onClick={this.handleDelete(user.id)}
                                onClick={this.handleDeactivation(user, index)}
                              />
                            </IconButton>
                          )}

                          {!user.isDeleted && type === 'group' ? (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: green[500] }}
                            >
                              <CheckIcon
                                onClick={() =>
                                  this.handleGroupDeactivation(user.id)
                                }
                              />
                            </IconButton>
                          ) : user.isDeleted && type === 'group' ? (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon
                                onClick={() =>
                                  this.handleGroupActivation(user.id)
                                }
                              />
                            </IconButton>
                          ) : (
                            ''
                          )}
                          {!user.isDeleted && type === 'user' && (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon
                                onClick={() =>
                                  this.handleGroupDeactivation(user)
                                }
                              />
                            </IconButton>
                          )}
                        </Box>
                        {/* <IconButton edge="end" size="small">
                          <MoreHoriz
                            aria-owns={
                              anchorEl ? `simple-menu-${user.userId}` : null
                            }
                            aria-haspopup="true"
                            onClick={this.handleClick(index)}
                          />
                        </IconButton>
                        <Menu
                          id={`simple-menu-${user.userId}`}
                          anchorEl={anchorEl}
                          open={menus[index]}
                          onClose={this.handleClose(index)}
                        >
                          <MenuItem onClick={this.handleView(user, index)}>
                            View
                          </MenuItem>
                          <MenuItem onClick={this.handleEdit(user)}>
                            Edit
                          </MenuItem>
                          <MenuItem onClick={this.handleDelete(user)}>
                            Delete
                          </MenuItem>
                        </Menu> */}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {data.length === 0 && (
                  <TableRow colSpan={headers.length}>
                    <Box display="flex" justifyContent="center" m={5}>
                      No data found.
                    </Box>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <ViewUserComponent
          data={user}
          show={showUser}
          handleViewClose={this.handleViewClose}
        />
        <ViewGroupComponent
          data={user}
          show={showGroup}
          handleViewClose={this.handleViewClose}
        />
        <EditUserComponent
          data={user}
          show={showEditUser}
          handleEditClose={this.handleEditClose}
        />
        <EditMerchantComponent
          data={user}
          show={showEditUser}
          handleEditClose={this.handleEditClose}
        />
        <EditGroupComponent
          data={user}
          show={showEditGroup}
          handleEditClose={this.handleEditClose}
        />
        <ResetComponent
          data={user}
          show={showReset}
          handleViewCloseReset={this.handleViewCloseReset}
        />
        <CreateNewGroupComponent
          data={user}
          show={showCreate}
          handleViewClose={this.handleViewClose}
        />
        <CreateRoleComponent
          data={user}
          show={showRole}
          handleCloseEditRole={this.handleCloseEditRole}
        />
        <UserTransaction
          data={user}
          show={showTransaction}
          handleViewTranClose={this.handleViewTranClose}
        />
        <TablePagination
          style={{ backgroundColor: '#f6b17f' }}
          component="div"
          count={metaData}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        />
      </Card>
    );
  }
}

export default TableComponent;
