import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingComponent from '../../../components/loading/LoadingComponent';

import FundEmailPhoneComponent from './AccountTransfer';
import FundIdComponent from './wayaUserTransfer';
import {getToken } from '../../../services/helpers'
import { Modal } from 'react-bootstrap';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    
  },
  tabContent: {
    padding: '0px 0',
    marginTop: 0,
  },
  tab:{
    marginLeft: "70px",
    marginBottom: "0px",
  },
  wal:{
    textAlign: "center",
    fontWeight: 800,
    fontSize: "20px",
  }
}));

const DashboardComponent = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [loading] = React.useState(false);
  const {data, showWal, handleWal2Close, handleWal2Open } = props;
console.log(data)

  const handleViewClose = () => {
    handleWal2Close();
  };
  const handleViewOpen = () => {
    handleWal2Open();
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let token = getToken()
  console.log(token)

  return (
    <div className={classes.root}>
      <Modal
        show={showWal}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="fund"
        style={{ marginTop: 0, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <p className={classes.wal}>Wallet Transfer For User</p>
      <div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          className={classes.tab}
        >
          <Tab label="Wallet 2 wallet" {...a11yProps(0)} />
          <Tab label="Transfer to Waya Users" {...a11yProps(1)} />
          
        </Tabs>
      </div>
      <div className={classes.tabContent}>
        <TabPanel value={value} index={0}>
          <FundEmailPhoneComponent data={data} containerStyles={classes.unresolvedTickets} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FundIdComponent data={data}/>
        </TabPanel>
        
      </div>
    {/* </div> */}
    </Modal.Body>
      </Modal>
    </div>
    
  );
};

export default DashboardComponent;
