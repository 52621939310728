import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SingleWithdraw from './WithdrawModal';
import BulkWithdraw from './bulkWithdrawal';
import MultiWithdraw from './multiIndex';
import LoadingComponent from '../../../components/loading/LoadingComponent';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '0px 0',
    // display: 'flex',
    justifyContent: 'center',
    // marginLeft: 280,
  },
  tabContent3: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    // marginRight: 100,
    // marginLeft: 100,
  },

  link: {
    marginLeft: 60,
    marginBottom: 40,
  },
  textme: {
    minWidth: '83%',
    height: '2%',
  },
  textme2: {
    minWidth: '83%',
    height: '2%',
  },
  btnp3: {
    marginLeft: -25,
    marginBottom: 0,
    fontWeight: 800,
    color: 'black',
    marginTop: 0,
    width: '100%',
    fontSize: '15px',
    boxShadow: '1px 1px 1px 1px #888888',
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

const UserComponent = (props) => {
  const classes = useStyles();
  console.log(props);
  const {data, showSelectWith, handleOpenSelectWith, handleCloseSelectWith } = props;

  const [loading] = useState(false);
  const [showSingle, setSingleWith] = useState(false);
  const [showBulk, setBulkWith] = useState(false);
  const [showMulti, setMultiWith] = useState(false);

  const handleViewCloseSelect = () => {
    handleCloseSelectWith();
  };
  const handleViewOpen = () => {
    handleOpenSelectWith();
  };
  const handleOpenWith = () => {
    setSingleWith(true);
  };
  const handleCloseWith = () => {
    setSingleWith(false);
  };
  const handleOpenBulkWith = () => {
    setBulkWith(true);
  };
  const handleCloseBulkWith = () => {
    setBulkWith(false);
  };
  const handleOpenMultiFund = () => {
    setMultiWith(true);
  };
  const handleCloseMultiFund = () => {
    setMultiWith(false);
  };

  

  return (
    <div id="textf" className={classes.root}>
      <Modal
        show={showSelectWith}
        onHide={handleViewCloseSelect}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="fund"
        style={{ marginTop: 0, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6">Select Withdrawal Method</Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            <div id="text2" className={classes.link}>
              <Button
                variant="outlined"
                className={classes.btnp3}
                onClick={() => handleOpenWith()}
              >
                Single Withdrawal
              </Button>
            </div>
            <div id="text2" className={classes.link}>
              <Button
                variant="outlined"
                className={classes.btnp3}
                onClick={() => handleOpenMultiFund()}
              >
                Web form Withdrawal
              </Button>
            </div>
            <div id="text2" className={classes.link}>
              <Button
                variant="outlined"
                className={classes.btnp3}
                onClick={() => handleOpenBulkWith()}
              >
                Excel upload
              </Button>
            </div>
           
          </div>
        </Modal.Body>
      </Modal>
      {showSingle ? (
        <SingleWithdraw data={data} handleOpenWith={handleOpenWith} handleCloseWith={handleCloseWith}/>
      ): null}
      {showBulk ? (
        <BulkWithdraw data={data} handleOpenBulkWith={handleOpenBulkWith} handleCloseBulkWith={handleCloseBulkWith}/>
      ): null}
      {showMulti ? (
        <MultiWithdraw data={data} handleCloseMultiFund={handleCloseMultiFund} handleOpenMultiFund={handleOpenMultiFund}/>
      ): null}
    </div>
  );
};

export default UserComponent;
