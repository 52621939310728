import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Box from '@mui/material/Box';


const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const pageType = [
  {
    value: true,
    label: 'public',
  },
  {
    value: false,
    label: 'Not Public',
  },
];
const pageMute = [
  {
    value: true,
    label: 'muted',
  },
  {
    value: false,
    label: 'Not Muted',
  },
];

const UserFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();
  

  return (
    <>
      <Formik
        initialValues={{
          name: '',
          description: '',
          isPublic: '',
          userId: data.userId,
          mute: ''
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('name is required'),
          description: Yup.string().max(255).required('Last name is required'),
          isPublic: Yup.boolean().required('Page type is required'),
        })}
        onSubmit={async (values) => {
          
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
                <p style={{marginLeft: '330px', marginBottom: '30px', fontWeight: 800}}> Create Group</p>
              <div>
                <Grid container spacing={3}>
                 
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Group Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                     
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      value={values.name}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Group Type"
                      name="isPublic"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.isPublic && errors.isPublic)}
                      helperText={touched.isPublic && errors.isPublic}
                      value={values.isPublic}
                      variant="outlined"
                      
                    >
                     {pageType.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Mute"
                      name="mute"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.mute && errors.mute)}
                      helperText={touched.mute && errors.mute}
                      value={values.mute}
                      variant="outlined"
                      
                    >
                     {pageMute.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  
                  
                  
                  
                  
                  
                  
                </Grid>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.createButton}
                    variant="contained"
                    type="submit"
                    disablexd={isSubmitting}
                  >
                    create Page
                  </Button>
                </Box>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
