import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Row } from "simple-flexbox";
import Page from "../../../components/Page";
import {
  getRequest,
  putRequest,
  patchRequest,
} from "../../../services/request";
import swalService from "../../../services/swal-mixin";
import { getUserData, getUsers } from "../../../services/helpers";
import CreateForm from "./CreateEventComponent";
import CsvDownload from "react-json-to-csv";
import config from "../../../services/config";
import { Form, Pagination, Table } from "antd";
import { eventsColumn } from "../../../utils/dummyData";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import SearchBar from "../../../components/SearchBar";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Input, Modal, notification, Select, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { range } from "lodash";

const useMatStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    width: "100%",
  },

  tool: {
    display: "flex",
    paddingLeft: "0px",
    borderRadius: "250px",
  },
  text: {
    width: "150%",
    // background: theme.color.wayaPink,
    fontWeight: "800px",
    borderRadius: "1px red",

    "& .MuiFormLabel-root": {
      border: "none",
      color: "black",
      marginLeft: 5,
    },
  },

  textfield: {
    paddingTop: "5px",
    paddingRight: "100px",
    // marginRight: '470px',
  },

  tableContainer: {
    width: "100%",
    maxWidth: "100%",
  },
}));

const ManageEventComponent = ({ createButton=false, showRange = false, showSearch = true }) => {
  const matclasses = useMatStyles();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("");
  const [filterType, setFilterType] = useState("username");
  const [query, setQuery] = useState("");
  const [pages, setPages] = useState(1);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [value, setValue] = useState("a");
  const [values, setValues] = useState("");
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const { confirm } = Modal;
  const [createPostModal, setcreatePostModal] = useState(false);
  const [followers, setFollowers] = useState([]);
  const [pageFollowersModal, setpageFollowersModal] = useState(false);
  const [username, setUserName] = useState("");
  const push = useNavigate();
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [posts, setPosts] = useState([]);

  const selectOptions = [
    {
      value: "username",
      label: "Username",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "phone",
      label: "Phone",
    },
  ];
  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });

  const handleChange = (e) => {
    setValues(e.target.value);
    setQuery(e.target.value);
  };

  const getData = async (q) => {
    let response;
    // if (q) {
    //   response = await getRequest(
    //     `admin-search-events?query=${query}&filterBy=${filterType}&page=${pages}&limit=20`,
    //     config.postUrl
    //   );
    // } else {
      setLoading(true);
      let rangeQuery='';
      const filter = q ? `&${filterType}=${q}` : '';
      if (range.startDate && range.endDate) {
      rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
      }
      response = await getRequest(
        `admin-get-all-events?page=${pages}${rangeQuery}&limit=20${filter}`,
        config.postUrl
      );
    // }

    
    const status = response?.data?.status;

    if (status) {
      const { data, metadata } = response?.data?.data;
      //setTotal(metadata?.total || 0)
      setPosts(data || []);
      setFilteredPosts(data || []);
      setData(data);
      setTotal(metadata?.total);
    }
    setLoading(false);
  };

  const getEventOverview = async () => {
    const response = await getRequest(
      "admin-get-event-analytics",
      config.postUrl
    );
    const { data } = response?.data || {};
    setMetaData(data);
  };

  const deActivate = async ({ id: groupId }) => {
    const userData = getUserData();
    const userId = userData.user.id;
    const data = {
      groupId,
      userId,
      isDeleted: true,
    };
    const response = await putRequest(
      "group//admin-enable-or-disable-group",
      data,
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );

    const { status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    swalService.success(message);
    getData();
  };

  useEffect(() => {
    getUsers(value, setUsers);
  }, [value]);

  useEffect(() => {
    getData(query);
    getEventOverview();
  }, [pages, query, range]);

  const Conversion = () => {
    return (
      <CsvDownload
        data={data}
        filename="events.csv"
        style={{
          boxShadow: "inset 0px 1px 0px 0px #e184f3",
          backgroundColor: "#27AE60",
          borderRadius: "6px",
          display: "inline-block",
          cursor: "pointer",
          color: "#ffffff",
          fontSize: "15px",
          marginTop: "16px",
          height: "40px",
          fontWeight: "bold",
          padding: "6px 24px",
          textDecoration: "none",
        }}
      >
        Export Events
      </CsvDownload>
    );
  };

  const handleManage = async (action, event_id) => {
    const payload = {
      action,
      event_id,
    };
    setLoading(true);
    const res = await patchRequest(
      `admin-manage-event`,
      payload,
      config.postUrl
    );
    const { status, message } = res?.data || {};
    if (status) {
      notification.success({ description: message, message: "success!" });
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    getData();
  };

  const handleConfirm = (status, EventId) => {
    confirm({
      title: `Do you want to ${status} this event?`,
      icon: <ExclamationCircleOutlined />,
      content: "Click OK to Continue",
      onOk() {
        handleManage(status, EventId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleModals = (type, value) => {
    if (type === "newPost") {
      setcreatePostModal(true);
      setMode("create");
    }
    if (type === "followers" || type === "following") {
      setpageFollowersModal(true);
      getFollowers(value.ProfileId);
      setUserName(`${value.username} ${type}`);
    }
    if (type === "view-post") {
      push(`/management/user/posts/${value}`);
    }

    // const getFollowers = async (id) => {
    //   setFollowers([]);
    //   // setLoading(true);
    //   const response = await getRequest(
    //     `admin-page/get-all-page-followers?pageId=${id}`,
    //     config.mainUrl
    //   );
    //   const { data, status } = response?.data || {};
    //   if (status) {
    //     setFollowers(data || []);
    //   }
    //   setLoading(false);
    // };

    //const handleModals=(type, value)=>{
    if (type === "newPost") {
      form.resetFields();
      setOpen(true);
      setMode("create");
    }
    if (type === "editPost") {
      setOpen(true);
      setMode("update");
      console.log(value);
      form.setFieldsValue({
        profile_id: value.ProfileId,
        details: value.details,
        location: value.location,
        eventName: value.eventName,
        isPaid: value.isPaid,
        isPrivate: value.isPrivate,
        url: value.websiteUrl,
        // profile_id: value.userId,
        event_id: value.id,
        isPublic: value.isPublic,
        virtual: value.virtual,
        eventUrl: value.eventUrl,
        amount: value.amount,
        username: value.eventOrganizer,
        date: [
          moment(Number(value.eventStart)),
          moment(Number(value.eventEnd)),
        ],
      });
    }
    if (type === "view") {
      setViewModal(true);
      setViewData(value);
      console.log(value);
    }
  };
  const getFollowers = async (id) => {
    setFollowers([]);
    // setLoading(true);
    const response = await getRequest(
      `admin-page/get-all-page-followers?pageId=${id}`,
      config.mainUrl
    );
    const { data, status } = response?.data || {};
    if (status) {
      setFollowers(data || []);
    }
    setLoading(false);
  };

  const column = eventsColumn(handleModals, handleConfirm, deActivate);
  return (
    <Page className={matclasses.root} title="Manage Events">
      <Container maxWidth={false}>
        <SearchBar
          page="events"
          total={metaData}
          count={total}
          handleModals={handleModals}
          Conversion={Conversion}
          handleChange={handleChange}
          setFilterType={setFilterType}
          selectOptions={selectOptions}
          createButton={createButton}
          showRange={showRange}
          showSearch={showSearch}
          setRange={setRange}
        />
        <LoadingComponent loading={loading} />

        {open ? (
          <CreateForm
            users={users}
            onClose={() => setOpen(false)}
            open={open}
            mode={mode}
            form={form}
            value={value}
            setValue={setValue}
          />
        ) : (
          ""
        )}

        <Table
          scroll={{}}
          columns={column}
          dataSource={data}
          rowKey="id"
          loading={loading}
          pagination={false}
        />
        <Pagination
          total={total}
          responsive={true}
          defaultCurrent={1}
          onChange={(e) => setPages(e)}
          defaultPageSize={20}
          showSizeChanger={false}
        />
      </Container>
      <Modal
        visible={pageFollowersModal}
        onCancel={() => setpageFollowersModal(false)}
        className="h-600p py-5 px-md-5 mx-auto"
        footer={false}
        width="60vw"
      >
        <div className="w-100 d-flex justify-content-between mt-4">
          <div className="">
            <p className="m-0 fs-5 fw-bold">{pages} Followers</p>
          </div>
          <div className="w-50">
            <div className="text-end">
              {/* <button onClick={() => showInviteModal(true)} className='btn btn-primary btn-sm rounded'><PlusOutlined /> Invite User</button> */}
            </div>
          </div>
        </div>
        <Divider type="horizontal" />
        {followers?.map((e, id) => (
          <div className="w-100 d-flex justify-content-around" key={id}>
            <div className="text-start">
              <p>{e?.Profile?.displayName}</p>
            </div>
            <div className="text-center">
              <p>@{e.Profile?.username}</p>
            </div>
            <div className="text-end">
              <button
                className="btn btn-danger btn-sm"
                // onClick={() => removeFollower({pageId: e.id, userId: e.userId})}
              >
                Remove this follower
              </button>
            </div>
          </div>
        ))}
      </Modal>
    </Page>
  );
};

export default ManageEventComponent;
