import color from "./color";

const typography = {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontWeight: 500,
    fontSize: 24,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontWeight: 500,
    fontSize: 20,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontWeight: 500,
    fontSize: 16,
    letterSpacing: '-0.05px'
  },
  h6: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: '-0.05px'
  },
  overline: {
    fontWeight: 500
  },
  cardTitle: {
    fontWeight: 'bold',
    fontSize: 19,
    lineHeight: '24px',
    letterSpacing: '0.4px',
  },
  smallSubtitle: {
    fontSize: 12,
    lineHeight: '16px',
    letterSpacing: '0.1px',
  },
  link: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: color.lightBlue,
    textAlign: 'right',
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: color.grayishBlue,
    },
  },
  itemTitle: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '20px',
    letterSpacing: 0.2,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: '30px',
    letterSpacing: 0.3,
  },
};

export default typography;
