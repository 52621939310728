import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import {
  getRequest,
  deleteRequest,
  postRequest,
} from '../../../services/request';
import { getUserData } from '../../../services/helpers';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { success, error } from '../../../services/swal-mixin';
import CloseIcon from '@material-ui/icons/Close';
import PauseAccount from './PauseIndex';
import BlockAccount from './BlockIndex';
import CreateCard from '../../../components/CustomCard';
import { Col, Row } from 'antd';

const useBankStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    // display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',
    // display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 150,
  },
  tabContent3: {
    padding: '25px 0',
    // display: 'flex',
    justifyContent: 'center',
    marginLeft: 94,
  },

  link: {
    marginRight: 80,
  },
  link2: {
    marginLeft: 55,
    marginBottom: '25px',
  },
  phones: {
    marginBottom: '25px',
  },
  textme: {
    // minWidth: '20%',
  },
  textme2: {
    minWidth: '66%',
  },
  textme3: {
    minWidth: '65%',
  },
  btnp3: {
    marginLeft: 320,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  roots: {
    // display: 'flex',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1rem 1rem',
    // justifyContent: 'space-between',
  },
  root: {
    // minWidth: 350,
    // display: 'flex',
    display: 'grid',
    position: 'relative',
    background: '#FFFFFF',
  },
  media: {
    position: 'absolute',
    color: 'red',
    // height: '30px',
    // FontSize: "800px",
    marginLeft: '0px',
    bottom: 250,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  types: {
    fontSize: '15px',
  },
  name: {
    fontSize: '10px',
    // marginBottom: '15px',
  },
  content: {
    flex: '1 0 auto',
  },
  head: {
    // marginLeft: '430px',
    marginTop: 0,
  },
  headbtn: {
    backgroundColor: 'white',
  },
  money: {
    position: 'absolute',
    bottom: '50px',
    marginLeft: '40px',
    marginBottom: '20px',
    fontSize: '30px',
    fontWeight: 800,
    color: 'green',
  },
  moneys: {
    position: 'absolute',
    bottom: '50px',
    marginLeft: '40px',
    marginBottom: '20px',
    fontSize: '30px',
    fontWeight: 800,
  },
}));

export default function RecipeReviewCard(props) {
  const classes = useStyles();
  const { userId, users } = props;
  const user = userId;
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(-1);
  const [openBank, setOpenBank] = React.useState(false);
  const [openPause, setPause] = React.useState(false);
  const [openBlock, setBlock] = React.useState(false);
  const [account, setAccount] = React.useState([]);
  const [Transactions, setTransactions] = React.useState([]);
  const [block, setBlocks] = React.useState([]);
  const [pause, setPauses] = React.useState([]);
  const anchorRef = React.useRef(null);

  const handleOpenPause = (Id) => {
    let val = Transactions.find((row) => (row.id = Id));
    setPauses(val);
    setPause(true);
  };
  const handleClosePause = () => {
    setPause(false);
  };
  const handleOpenBlock = (Id) => {
    let val = Transactions.find((row) => (row.id = Id));
    setBlocks(val);
    setBlock(true);
  };
  const handleCloseBlock = () => {
    setBlock(false);
  };

  const handleToggle = (index) => {
    setOpen(index);
  };

  const handleClose = (event) => {
    setOpen(-1);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
  console.log(user);
  const admin = getUserData();
  const adminId = admin.id;
  const getAccount = async () => {
    setLoading(true);
    const response = await getRequest(
      `card-service/api/admin/list/userBankAcct/${userId}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);

    console.log(response);
    if (!response || response.error !== undefined) {
      return;
    }

    const { data } = response.data;
    setAccount(data);
    // setMetaData(data.metaInfo);
  };

  const getTransaction = async () => {
    setLoading(true);
    const response = await getRequest(
      `api/v1/wallet/accounts/${userId}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);
    console.log(response);
    if (!response || response.error !== undefined) return;

    const { data } = response.data;
    console.log(data);
    setTransactions(data);
    // setMetaData({ count: data.totalElements, page: data.pageable.pageNumber });
  };
  console.log(Transactions);
  // const accountNumber = account.accountNumber

  const defaultAccount = async (acctNo) => {
    const defaultWallet = {
      newDefaultAcctNo: acctNo,
      userId: userId,
    };
    setLoading(true);
    const response = await postRequest(
      `api/v1/wallet/user/account/toggle`,
      defaultWallet,
      'http://68.183.60.114:9086'
    );
    setLoading(false);

    console.log(response);
    const { data, message, status } = response.data;
    if (status === false) {
      return error(message);
    }
    if (status === true) {
      success(`Accouunt Number ${data.accountNo} is set to default`);
    }
    getTransaction();
    // setMetaData(data.metaInfo);
  };
  const deleteAccount = async (acctNo) => {
    const defaultWallet = {
      newDefaultAcctNo: acctNo,
      userId: userId,
    };
    setLoading(true);
    const response = await postRequest(
      `api/v1/wallet/account/closure`,
      {
        customerAccountNo: acctNo,
      },
      'http://68.183.60.114:9086'
    );
    setLoading(false);

    console.log(response);
    const { data, message, status } = response.data;
    if (status === false) {
      return error(message);
    }
    if (status === true) {
      success(message);
    }
    getTransaction();
    // setMetaData(data.metaInfo);
  };

  console.log('*************', account, Transactions);

  useEffect(() => {
    getAccount();
    getTransaction();
  }, []);

  function currencyFormat(num) {
    return '₦' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  return (
    <>
      {/* <LoadingComponent loading={loading} /> */}
      <div className={classes.roots}>
        {Transactions ? (
          <>
            {Transactions?.map((acct, index) => (
              <Card className={classes.root}>
                <div>
                  {open !== index ? (
                    <div
                      ref={anchorRef}
                      key={1}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={() => handleToggle(index)}
                      className={classes.headbtn}
                    >
                      <CardHeader
                        className={classes.head}
                        style={{ marginBottom: '-60px', marginRight: 'auto' }}
                        action={
                          <IconButton>
                            <MoreVertIcon />
                          </IconButton>
                        }
                      />
                    </div>
                  ) : (
                    <div
                      ref={anchorRef}
                      key={1}
                      aria-controls={open ? 'menu-list-grow' : undefined}
                      aria-haspopup="true"
                      onClick={() => handleClose(index)}
                      className={classes.headbtn}
                    >
                      <CardHeader
                        className={classes.head}
                        style={{ marginBottom: '-60px', marginRight: 'auto' }}
                        action={
                          <IconButton>
                            <CloseIcon />
                          </IconButton>
                        }
                      />
                    </div>
                  )}
                  {open === index ? (
                    <Paper
                      id="paperWallet"
                      style={{
                        width: '40%',
                        marginTop: '30px',
                        left: '210px',
                        position: 'absolute',
                        zIndex: '9999',
                      }}
                    >
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <MenuItem
                            style={{ fontSize: '12px' }}
                            onClick={() => defaultAccount(acct.accountNo)}
                          >
                            set as Primary wallet
                          </MenuItem>
                          <MenuItem
                            style={{ fontSize: '12px' }}
                            onClick={() => handleOpenBlock(acct.id)}
                          >
                            Block Wallet
                          </MenuItem>

                          <MenuItem
                            style={{ color: 'red', fontSize: '12px' }}
                            onClick={() => handleOpenPause(acct.id)}
                          >
                            Pause
                          </MenuItem>
                          <MenuItem
                            style={{ color: 'red', fontSize: '12px' }}
                            onClick={() => deleteAccount(acct.accountNo)}
                          >
                            Delete Card
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  ) : null}
                </div>
                {acct.walletDefault ? (
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.money}
                  >
                    {currencyFormat(acct.clr_bal_amt)}
                  </Typography>
                ) : acct.acct_cls_flg ? (
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.moneys}
                    style={{ color: 'red' }}
                  >
                    {currencyFormat(acct.clr_bal_amt)}
                  </Typography>
                ) : (
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.moneys}
                  >
                    {currencyFormat(acct.clr_bal_amt)}
                  </Typography>
                )}
                <CardContent>
                  <div style={{ display: 'flex', marginTop: '80px' }}>
                    <div style={{ display: 'grid' }}>
                      <Typography
                        component="h5"
                        variant="h6"
                        className={classes.types}
                      >
                        Account Name
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.name}
                      >
                        {acct.acct_name}
                      </Typography>
                    </div>
                    <div style={{ display: 'grid', marginLeft: 'auto' }}>
                      <Typography
                        component="h5"
                        variant="h6"
                        className={classes.types}
                      >
                        Account Number
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        color="textSecondary"
                        className={classes.name}
                      >
                        {acct.accountNo}
                      </Typography>
                    </div>
                  </div>
                </CardContent>
                <div>
                  {openPause ? (
                    <PauseAccount
                      pause={pause}
                      getTransaction={getTransaction}
                      handleOpenPause={handleOpenPause}
                      handleClosePause={handleClosePause}
                    />
                  ) : null}
                  <div></div>
                  {openBlock ? (
                    <BlockAccount
                      block={block}
                      getTransaction={getTransaction}
                      handleOpenBlock={handleOpenBlock}
                      handleCloseBlock={handleCloseBlock}
                    />
                  ) : null}
                </div>
              </Card>
            ))}
          </>
        ) : (
          'No account yet'
        )}
      </div>
      {/* <Button
        variant="outlined"
        color="textSecondary"
        style={{
          marginTop: '20px',
          width: '360px',
          height: '85px',
          background: '#FFFFFF',
          position: 'relative',
        }}
        onClick={() => handleOpenBank()}
      >
        ADD WALLET{' '}
        <span style={{ position: 'absolute', top: '17.44%' }}>+</span>
      </Button> */}
      <Row gutter={16}>
        <Col span={7} className="mt-1 mr-1 p-0">
          <CreateCard
            handleClick={() => setOpenBank(true)}
            title="ADD WALLET"
          />
        </Col>
        <Col span={7} className="mt-1 mr-1 p-0">
          <CreateCard
            handleClick={() => setOpenBank(true)}
            title="ADD WALLET"
          />
        </Col>
        <Col span={7} className="mt-1 mr-1 p-0">
          <CreateCard
            handleClick={() => setOpenBank(true)}
            title="ADD WALLET"
          />
        </Col>
        <Col span={7} className="mt-1 mr-1 p-0">
          <CreateCard
            handleClick={() => setOpenBank(true)}
            title="ADD WALLET"
          />
        </Col>
      </Row>

      {openBank ? (
        <AccountComponent
          getTransaction={getTransaction}
          users={users}
          userId={user}
          handleCloseBank={() => setOpenBank(false)}
        />
      ) : (
        ''
      )}
    </>
  );
}

const AccountComponent = (props) => {
  const admin = getUserData();

  const { userId, users, getTransaction, handleOpenBank, handleCloseBank } =
    props;
  const state = {
    userId: userId,
  };
  const classes = useBankStyles();

  const [loading, setLoading] = React.useState(false);
  const [bank, setBank] = React.useState([]);

  const handleViewClose = () => {
    handleCloseBank();
  };
  const handleViewOpen = () => {
    handleOpenBank();
  };

  console.log(bank, users);

  const addWallet = async () => {
    setLoading(true);
    const response = await postRequest(
      'api/v1/wallet/create-wallet',
      state,
      'http://157.230.223.54:9009'
    );
    setLoading(false);

    if (!response || response.error !== undefined) {
      return;
    }
    console.log(response);
    const { data, message } = response.data;
    setBank(data);
    success(message);
    getTransaction();
  };

  return (
    <>
      <div className={classes.root}>
        <Modal
          show={handleViewOpen}
          onHide={handleViewClose}
          backdrop="static"
          keyboard={false}
          size="lg"
          style={{ marginTop: 180, minWidth: '105%' }}
        >
          <Modal.Header
            style={{
              borderBottom: 'none',
              padding: '0 1rem',
            }}
            closeButton
          ></Modal.Header>
          <LoadingComponent loading={loading} />
          <Modal.Body>
            <div className={classes.tabContent}>
              <div className={classes.name2}>
                <Typography variant="h6" style={{ marginLeft: '310px' }}>
                  Create Wallet
                </Typography>
              </div>
            </div>
            <div className={classes.tabContent2}>
              {/* <Typography
                variant="h6"
                style={{ marginLeft: '60px', color: 'green' }}
              >
                {`Do you want to create wallet for ${users.firstName} ${users.lastName}`}
              </Typography> */}

              <div className="text-center new-form" style={{ width: '80%' }}>
                <div className="inputbox-with-one-input2">
                  <input placeholder="Email Address" type="text" />
                </div>

                <input
                  className="form-control mt-3"
                  placeholder="Account Number"
                  type="text"
                />
                <input
                  className="form-control mt-3"
                  placeholder="Wallet Type"
                  type="text"
                />
              </div>
            </div>
            <Button
              variant="contained"
              className={classes.btnp3}
              onClick={() => addWallet()}
            >
              Create Wallet
            </Button>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
