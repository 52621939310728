import React from "react";
import { Card, Input } from "antd";
import { GrAttachment } from "react-icons/gr";
import { BiSend } from "react-icons/bi";
import { BsEmojiSmile } from "react-icons/bs";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    // width: '100%',
  },
  input: {
    border: "none",
    borderRadius: "8px",
    boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
    paddingTop: "10px",
    paddingBottom: "10px",
  },
  icon: {
    marginRight: '20px',
    padding: '13px',
    background: theme.color.newLightOrange,
    borderRadius: '25px',
  },
}));

const MessageInput = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <span className={classes.icon}>
        <GrAttachment size={20} />
      </span>
      <Input
        className={classes.input}
        placeholder="Type a message..."
        size="large"
        suffix={
          <div>
            <BsEmojiSmile size={25} />
            <BiSend size={30} />
          </div>
        }
      />
    </div>
  );
};

export default MessageInput;
