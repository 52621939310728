import React from 'react';

const StatCard = ({ title, value, type }) => {
  const date = type === 'date' && new Date(Number(value));
  return (
    <div className="col-md-4 col-sm-4 col-xs-12">
      <div className="stat-card">
        <span className="title">{title}</span>
        {date ? (
          <div className="d-flex justify-content-between w-100">
            <div>
              <div className="date-box">{date.getDay() || 0}</div>
              <span>Days</span>
            </div>
            <div>
              <div className="date-box">{date.getHours() || 0}</div>
              <span>Hours</span>
            </div>
            <div>
              <div className="date-box">{date.getMinutes() || 0}</div>
              <span>Minutes</span>
            </div>
          </div>
        ) : (
          <p className="count">{value}</p>
        )}
      </div>
    </div>
  );
};

export default StatCard;
