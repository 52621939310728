import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../services/config";
import { Modal, Form, Button, notification, Upload, Input, Table, Space, Image, Skeleton } from "antd";
import { getRequest } from "../../services/request";
import useMatStyles from "../users/UserListView/WebPos/style";
import MediaCard from "./media";

const ContestantDetails = () => {
  const navigate = useNavigate();
  const styles = useMatStyles();
  const { id, contestid, profileid } = useParams();
  const [contestData, setContestData] = useState([]);
  const [profileData, setProfileData] = useState([]);

  const getContest= async () => {
    let response;
      response = await getRequest(
      `contests/uploaded/contents?contestantId=${id}`,
        config.contestUrl,
        true
      );
      const { data, status } = response?.data || [];
      if (status) {
        setContestData(data);        
      }    
  };
  const getProfile= async () => {
    let response;
      response = await getRequest(
      `contests/contestants?profileId=${profileid}&contestId=${contestid}`,
        config.contestUrl,
        true
      );
      const { data, status } = response?.data || [];
      if (status) {
        setProfileData(data[0]);        
      }    
  };

  useEffect(() => {
    getProfile();
    getContest();
  }, []);
  return (
    <div className="w-100">
      <div
        className="banner-div"
        style={{
          backgroundImage: `url(${profileData?.miscellaneous?.coverPhoto})`,
          backgroundSize: 'cover',
          height: '140px',
          backgroundPosition: 'center',
          border: 1,
          borderStyle: 'dotted',
          borderTopLeftRadius: 14,
          borderTopRightRadius: 14,
        }}
      />
      <div className="avatar-section d-flex mb-4 justify-content-between">
        {profileData?.profileId ? (
          <img
            // onClick={() => {
            //   setSelectedImg(avatar || avatarImg);
            //   setPreviewImg(true);
            // }}
            src={profileData?.miscellaneous?.contestPhoto}
            alt="avatar"
            style={{ borderRadius: '50%', width: 150 }}
            className="img"
          />
        ) : (
          <Skeleton circle width={65} height={65} />
        )}
        {profileData?.profileId ? (
          <div className="mt-3 text-end">
            {profileData?.id && (
              <Space>
                {profileData?.id && (
                  <div className="flex flex-col">
                    <div>
                      <label className="mx-1">
                        Status:
                        <label
                          style={{
                            color:
                            profileData?.status === 'ACTIVE'
                                ? 'green'
                                : 'red',
                          }}
                        >
                          {profileData?.status ?? 'N/A'}
                        </label>
                      </label>
                      <label className="text-muted mx-1">
                        Position: {profileData?.rank?.position ?? 'N/A'}
                      </label>
                      <label className="text-muted mx-1">
                        Votes: {profileData?.voteCounts ?? 0}
                      </label>
                    </div>
                  </div>
                )}
              </Space>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      <div>
        Bio: {profileData?.bio}
        <p>
          Talent: {profileData?.talent}
        </p>
      </div>
        <hr />
      <div>
      {contestData?.length > 0 ? contestData?.map((e) =>
        <div key={e.id}>
          <MediaCard contentFile={e?.miscellaneous?.contentFile} likeCount={e.likeCount} repostCount={e?.repostCount} />
        </div>
      ): (
        <div className="text-center">
        No post
        </div>
      )}
      </div>    
    </div>
  );
};

export default ContestantDetails;
