import React from 'react';
import CsvDownload from 'react-json-to-csv';
import Report from 'react-data-report';

function CsvConversion(props) {
  const { users, title, name } = props;
  return (
    <CsvDownload
      data={users}
      filename={name}
      style={{
        

        backgroundColor: '#27AE60',
        borderRadius: '6px',
        
        display: 'inline-block',
        cursor: 'pointer',
        color: '#ffffff',
        fontSize: '15px',
        marginTop: '23px',
        height: '40px',
        fontWeight: 'bold',
        padding: '6px 24px',
        textDecoration: 'none',
        textShadow: '0px 1px 0px #9b14b3',
      }}
    >
      {title}
    </CsvDownload>
  );
}

function PdfConversion(props) {
  const { users } = props;
  return (
    <div >
      <Report data={users} opening={<h1>This is an opening content.</h1>} />
    </div>
  );
}

export { CsvConversion, PdfConversion };
