import React from 'react';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import logoAlt from '../../assets/images/Waya-logo.png';
import logo from '../../assets/images/logo.svg';

const useStyles = createUseStyles((theme) => ({
  container: {
    marginLeft: 32,
    marginRight: 32,
  },
  title: {
    ...theme.typography.cardTitle,
    color: theme.color.grayishBlue,
    opacity: 0.7,
    marginLeft: 12,
  },
  avatar:{
    height: 50,
    width: 121
  }
}));

function LogoComponent({ isColored }) {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <Row className={classes.container} horizontal="center" vertical="center">
      <img
        src={ logo }
        alt="avatar"
        className={classes.avatar}
      />
    </Row>
  );
}

export default LogoComponent;
