import React from "react";
import { makeStyles } from "@mui/styles";
import { Modal } from "react-bootstrap";
import GroupFormComponent from "./ViewPageForm";
import { postRequest } from "../../../services/request";
import { error, success } from "../../../services/swal-mixin";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: "25px 0",
  },

  profileCard: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    backgroundClip: "border-box",
    marginBottom: 20,
    borderRadius: ".25rem",
    width: "100%",
  },
  avatarWrapper: {
    margin: "5px auto",
  },
  avatar: {
    height: 150,
    width: 150,
    minWidth: 150,
    borderRadius: 100,
    // border: `1px solid ${theme.color.lightGrayishBlue2}`,
  },
}));

const CreatePageComponent = (props) => {
  const classes = useStyles();

  const { data, show, handleViewClose } = props;
  // const [value, setValue] = React.useState(0);

  const handleClose = () => {
    handleViewClose();
  };

  const createGroup = async (groupData) => {
    const response = await postRequest(
      `group/create-group`,
      groupData,
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );
    const { status, message } = response.data;
    if (status !== true) {
      return error(message);
    }
    success(message);
  };

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            padding: "0 1rem",
            borderTop: "5px solid #FF6634",
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <GroupFormComponent data={data} submitForm={createGroup} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreatePageComponent;
