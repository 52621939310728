import React, { useState, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";
// import { Row, Column } from "simple-flexbox";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Table,
  Checkbox,
  Row,
  Col,
} from "antd";
import {
  deleteRequestData,
  getRequest,
  patchRequest,
  postRequest,
} from "../../services/request";
import config from "../../services/config";
import { formatString } from "../../utils/helpers";
import { error, success } from "../../services/swal-mixin";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "70%",
    height: "100%",
    borderRadius: "1px",
    border: "1px solid gray",
    padding: "10px",
  },
  // memberContainer: {
  //   border: "1px solid gray",
  //   padding: "2px 4px",
  //   fontSize: "12px",
  //   margin: "2px 5px",
  //   marginBottom: "30px"
  // },
  h2: {
    fontSize: "15px",
    fontWeight: "700",
  },
  canDo: {
    background: "rgba(139, 184, 159, 0.2)",
    padding: "10px",
  },
  cantDo: {
    background: "rgba(227, 211, 211, 0.2)",
    padding: "10px",
  },
  p: {
    fontSize: "6px",
    // padding: "10px",
    margin: "5px 0",
    color: "red",
  },
  box: {
    display: "flex",
  },
  rows: {
    width: "100%",
    gap: "10px",
    marginTop: "20px",
  },
  h4: {
    fontSize: "14px",
  },
  cols: {
    width: "48%",
  },
}));

const CustomAdmin = ({ show=false, onCancel }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const [showing, setShowing] = useState(show);

  useEffect(()=> {
    setShowing(show)
  }, [show]);

  const [loading, setLoading] = useState(false);
  const [custom, setCustom] = useState({});
  const [permissions, setPermissions] = useState([]);

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `admin-get-all-permissions?limit=1000`,
      config.roleUrl
    );

    setLoading(false);
    if (response.data.status == false) {
      return;
    }
    const data = response?.data?.data || [];
    setPermissions([]);
  };

  useEffect(() => {
    getData();
  }, []);

  const createRole = async (e) => {
    const payload = {
      userRole: e.name,
      description: e.description,
      permissions: ['null'],
    }
    const response = await postRequest("admin-create-role", payload, config.roleUrl);
    const { status, message } = response.data;
    if (status !== true) {
      return error(message);
    }
    success(message);
    setShowing(false);
    window.location.reload();
  };

  return (
    <Modal
      visible={showing}
      onCancel={onCancel}
      footer={false}
      title={<h4 className="text-center">Create New Roles</h4>}
    >
      <Form layout="vertical" onFinish={createRole}>
        <div className={classes.memberContainer}>
          <Form.Item label="Role Name " name="name">
            <Input placeholder="Give this role a name" />
          </Form.Item>
          <Form.Item label="Role Description" name="description">
            <Input placeholder="Give this role a discription" />
          </Form.Item>
        </div>
        <Form.Item name="permissions" valuePropName="checked">
          <Checkbox.Group className={classes.box} style={{ width: "100%" }}>
            <Row>
              {permissions.map((item, idx) => (
                <Col span={12} key={idx} style={{ margin: "2px 0" }}>
                  <Checkbox value={item.id} style={{ fontSize: "10px" }}>
                    {formatString(item?.access)}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item className="text-center mt-4">
          <Space>
            <Button
              size="large"
              htmlType="submit"
              type="ghost"
              className="rounded px-5"
            >
              Create Role
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CustomAdmin;
