import React, { useState, useEffect } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Row, Column } from "simple-flexbox";
import {
  deleteRequestData,
  getRequest,
  patchRequest,
  postRequest,
} from "../../services/request";
import config from "../../services/config";
import { useParams } from "react-router-dom";
import { formatString } from "../../utils/helpers";
import LoadingComponent from "../loading";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "70%",
    height: "100%",
    borderRadius: "1px",
    border: "1px solid gray",
    padding: "10px",
  },
  memberContainer: {
    border: "1px solid gray",
    padding: "2px 4px",
    fontSize: "12px",
    margin: "2px 5px",
    marginBottom: "30px",
  },
  h2: {
    fontSize: "15px",
    fontWeight: "700",
  },
  canDo: {
    background: "rgba(139, 184, 159, 0.2)",
    padding: "10px",
  },
  cantDo: {
    background: "rgba(227, 211, 211, 0.2)",
    padding: "10px",
  },
  p: {
    fontSize: "12px",
    padding: "10px",
    margin: "5px",
  },
  box: {
    border: "1px solid gray",
    [`&:nth-child(odd)`]: {
      background: "rgba(1, 1, 1, 0.1)",
    },
  },
  rows: {
    width: "100%",
    gap: "10px",
    marginTop: "20px",
  },
  h4: {
    fontSize: "14px",
  },
  cols: {
    width: "48%",
  },
}));

const AdminRoles = ({ text }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [loading, setLoading] = useState(false);
  const [access, setAccess] = useState([]);
  const [role, setRole] = useState({});

  let { id } = useParams();

  const getData = async (roleId) => {
    setLoading(true);
    let response;
    let roleResponse;
    if (roleId) {
      response = await getRequest(
        `admin-get-access-and-non-access?role_id=${roleId}`,
        config.roleUrl
      );
      roleResponse = await getRequest(
        `admin-get-role?role_id=${roleId}`,
        config.roleUrl
      );
    } else {
      response = await getRequest(
        `admin-get-all-permissions?limit=1000`,
        config.roleUrl
      );
    }

    setLoading(false);
    if (response.data.status == false) {
      return;
    }
    const data = response?.data?.data || [];

    setRole(
      roleResponse?.data?.data || {
        userRole: "All Permissions",
        description: "List of all permissions available",
      }
    );
    setAccess(data?.data || data);
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  return loading ? (
    <LoadingComponent loading={loading} />
  ) : (
    <div className={classes.container}>
      <h2 className={classes.h2}>{loading ? "Loading..." : role.userRole}</h2>
      <p className={classes.p}>{loading ? "Loading..." : role.description}</p>
      {/* <div className={classes.memberContainer}>
        10 Members with this role:Adeyanju Akorede, Philip Templar, Adeyanju
        Gabriel, Clever Eziogor, Samuel Aniefiok, John Mary, Adeyanju Akorede,
        Philip Templar, Adeyanju Gabriel, Clever Eziogor, John Mary
      </div> */}
      <Row horizontal="space-between" classes={classes.rows}>
        <Column className={classes.cols}>
          <div className={classes.canDo}>
            <h4 className={classes.h4}>What this role can do</h4>
          </div>

          <>
            {(access.role_ability ? access?.role_ability : access)?.map(
              (item, idx) => (
                <div key={idx} className={classes.box}>
                  <p className={classes.p}>{formatString(item?.access)}</p>
                </div>
              )
            )}
          </>
        </Column>

        <Column className={classes.cols}>
          <div className={classes.cantDo}>
            <h4 className={classes.h4}>What this role can not do</h4>
          </div>
          <>
            {access?.role_non_able?.map((item, idx) => (
              <div key={idx} className={classes.box}>
                <p className={classes.p}>{formatString(item?.access)}</p>
              </div>
            ))}
          </>
        </Column>
      </Row>
    </div>
  );
};

export default AdminRoles;
