import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getRequest, postRequest } from '../../../services/request';
import { success, error } from '../../../services/swal-mixin';
import LoadingComponent from '../../../components/loading/LoadingComponent';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    // width: "50%",
  },
  tabContent2: {
    padding: '25px 0',
    // display: 'flex',
    justifyContent: 'space-around',
    marginLeft: 170,
  },
  tabContent3: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: 100,
  },

  link: {
    marginLeft: -160,
    marginBottom: 10,
  },
  phone: {
    marginBottom: 20,
    marginLeft: -160,
  },
  textme: {
    minWidth: '97%',
  },
  textme2: {
    minWidth: '97%',
  },
  btnp3: {
    marginLeft: 90,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));



const saveBenf = [
  {
    value: '--',
    label: '--',
  },
  {
    value: 'true',
    label: 'Yes',
  },
  {
    value: 'false',
    label: 'No',
  },
];

const UserComponent = (props) => {
  const classes = useStyles();
  console.log(props);
  const { data, handleOpenWith, handleCloseWith } = props;
  const userId = data.userId;
  const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };
  const unique = uid();
  const state = {
    amount: '',
    bankCode: '',
    bankName: '',
    crAccount: '',
    crAccountName: '',
    narration: '',
    saveBen: '',
    transRef: unique,
    transactionPin: '',
    userId: userId,
    walletAccountNo: '',
  };
  
  const [value, setValue] = React.useState(0);
  const [withdraw, setWithdraw] = React.useState(state);
  const [userWallet, setUserWallet] = React.useState([]);
  const [bank, setBank] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  console.log(data.userId);

  const handleViewClose = () => {
    handleCloseWith();
  };
  const handleViewOpen = () => {
    handleOpenWith();
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setWithdraw((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const getBank = async () => {
    setLoading(true);
    const response = await getRequest(
      `card-service/api/admin/getBanks`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);
    console.log('***************', response);

    if (!response || response.error !== undefined) {
      return;
    }
    const { data } = response.data;
    setBank(data);
    // setMetaData(data.metaInfo);
  };
  console.log(bank);

  const getUserWallet = async () => {
    setLoading(true);
    const response = await getRequest(
      `api/v1/wallet/accounts/${userId}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);

    if (!response || response.error !== undefined) {
      return;
    }
    console.log('************', response);
    const { data } = response.data;
    setUserWallet(data);
    // setMetaData(data.metaInfo);
  };

  async function withdrawFund() {
    setLoading(true)
    const response = await postRequest(
      'admin/withdrawal/fund',
      withdraw,
      'http://68.183.60.114:9086/waya-withdrawal-service'
    );
    setLoading(false)
    console.log(response);

    const {message, status } = response.data;
    if (status === false) {
      return error(message);
    }
    handleViewClose()
    success(message);
  }

  useEffect(() => {
    getBank();
    getUserWallet();
  }, []);

  console.log('************', userWallet);

  const object = {
    name: "--",
    code: "--"
  }
  const object1 = {
    acct_name: "--",
    accountNo: "--"
  }

  // bank.unshift(object)
  // userWallet.unshift(object1)

  console.log(withdraw)

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="disp"
        style={{ marginTop: 30, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body style={{ Width: '150%' }}>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6">Withdraw from user's wallet</Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            <div className={classes.link}>
              <TextField
                className={classes.textme}
                id="outlined-select-currency-native"
                label="Enter Amount"
                name="amount"
                placeholder="please enter amount"
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.link}>
              <TextField
                className={classes.textme}
                id="outlined-select-currency-native"
                label="Credit Account"
                name="crAccount"
                placeholder="please enter account to fund"
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.phone}>
              <p>Wallet to be withdrawn from</p>
              <TextField
                id="outlined-select-currency-native"
                select
                label=""
                name="walletAccountNo"
                className={classes.textme2}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {userWallet.map((option) => (
                  <option key={option.value} value={option.accountNo}>
                    {option.acct_name}-{option.clr_bal_amt}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.phone}>
              <p>Select bank to be sent to</p>
              <TextField
                id="outlined-select-currency-native"
                select
                // label="Bank to be sent to"
                name="bankName"
                className={classes.textme2}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {bank.map((option) => (
                  <option key={option.value} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.phone}>
              <p>Select bank Code to be sent to</p>
              <TextField
                id="outlined-select-currency-native"
                select
                label=""
                name="bankCode"
                className={classes.textme2}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {bank.map((option) => (
                  <option key={option.value} value={option.code}>
                    {option.name}-{option.code}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.link}>
              <TextField
                className={classes.textme}
                id="outlined-select-currency-native"
                label="Account name"
                name="crAccountName"
                placeholder="please enter account name"
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.link}>
              <TextField
                className={classes.textme}
                id="outlined-select-currency-native"
                placeholder="please enter narration"
                label="Transaction Narration"
                name="narration"
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.link}>
              <TextField
                className={classes.textme}
                id="outlined-select-currency-native"
                placeholder="please enter PIN"
                label="Transaction Pin"
                name="transactionPin"
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.phone}>
              <TextField
                id="outlined-select-currency-native"
                select
                label="Save Beneficiary"
                name="saveBen"
                className={classes.textme2}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                {saveBenf.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
          </div>

          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => withdrawFund()}
          >
            Proceed With Transaction
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserComponent;
