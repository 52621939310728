import config from "./config";
import { postRequest } from "./request";
import { notification } from "antd";

export const updatePricing = async (payload, value, description) => {
  let formData = new FormData();
  formData.append("percent_price_id", payload.id);
  formData.append("percent", value);
  formData.append("description", description);
  formData.append("product_type", payload.product_type);
  let resp = await postRequest(
    "admin-percent-price-log",
    formData,
    config.postUrl
  );

  const { status, data, message } = resp?.data || {};

  if (status) {
    notification.success({ description: message, message: "success!" });
  } else {
    notification.error({ description: message, message: "Oops!" });
  }
};
