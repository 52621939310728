import React, { useEffect, useState } from 'react';
import { AnalyticsCard, TableCard, PieChart,LayCard }  from '../../../components/AnalyticsCard';
import config from '../../../services/config';
import { getRequest } from '../../../services/request';
import { accountCol, commentsCol, hashtagCol, likesCol, momentCol, postsCol } from '../../../utils/dummyData';
import CsvDownload from 'react-json-to-csv';
import { over } from 'lodash';
import AntDropdown from '../../../components/dropdown/AntDropdown'



function Analytics() {
  const [overview, setOverview] = useState({});
  const [likes, setLikes] = useState([]);
  const [posts, setPosts] = useState([]);
  const [comments, setComments] = useState([]);
  const [moments, setMoments] = useState([]);
  const [hashtags, setHashTags] = useState([]);
  const [groups, setGroups] = useState({});
  const [auto, setAuto] = useState({});
  const [stat, setStat] = useState({});

  const getOverview = async () => {
    const response = await getRequest(
      `admin-get-analytics-overview`,
      config.postUrl
    );
    const {data} = response?.data || {};
    setOverview(data || {});
    console.log({ overview : data});
  };
  const getUsersStat = async () => {
    const response = await getRequest(
      `dashboard/users-analysis`,
      config.gramAuthUrl
    );
    const {data} = response?.data || {};
    setStat(data || {});
    console.log({ stat : data});
  };
  const getProductStat = async () => {
    
    const response = await getRequest(
      `main/order/monthly-order-status`,
      config.gramUrl
    );
    const response2 = await getRequest(
      `main/order/count/month`,
      config.gramUrl
    );
    const {data} = response?.data || {};
    // setStat(data || {});
    console.log({ stat : data});
  };
  // console.log(likes, posts, comments, moments, hashtags);

  const getLikes = async () => {
    const response = await getRequest(
      `admin-get-top-likes?page=1&limit=5`,
      config.postUrl
    );
    const {data} = response?.data?.data || {};
    setLikes(data);
    console.log({ likes : data});

  };

  const getPosts = async () => {
    const response = await getRequest(
      `admin-get-top-posts?page=1&limit=5`,
      config.postUrl
    );
    const {data} = response?.data?.data || {};
    setPosts(data);
    console.log({ posts : data});

  };

  const getComments = async () => {
    const response = await getRequest(
      `admin-get-top-comments?page=1&limit=5`,
      config.postUrl
    );
    const {data} = response?.data?.data || {};
    setComments(data);
    console.log({ comments : data});

  };

  const getMoments = async () => {
    const response = await getRequest(
      `admin-get-top-moments?page=1&limit=5`,
      config.postUrl
    );
    const {data} = response?.data?.data || {};
    // console.log(response);
    setMoments(data);
    console.log({ moments : data});

  };

  const getHashTags = async () => {
    const response = await getRequest(
      `admin-get-top-hashtags?page=1&limit=5`,
      config.postUrl
    );
    const {data} = response?.data?.data || {};
    setHashTags(data);
    console.log({ Hash : data});

  };
  const getGroups = async () => {
    const response = await getRequest(
      `admin-group/admin-get-group-analytics`,
      config.mainUrl
    );
    const {data} = response?.data || {};
    setGroups(data);
    console.log({ Groups : data});

  };
  const getAutoFollower = async () => {
    const response = await getRequest(
      //`admin-friend/waya-auto-follow`,
     `admin-friend/wayagram-auto-follow-analytics`,
      config.graphUrl
    );
    const {data} = response?.data || {};
    setAuto(data);
    console.log({ Auto : data});

  };
  
  useEffect(()=>{
    getOverview();
    getLikes();
    getComments();
    getPosts();
    getMoments();
    getHashTags();
    getGroups();
    getAutoFollower();
    getUsersStat();
    getProductStat();
  }, [])

  const tlData = [
    {
      "id": 1,
      "user": "@philpt",
      "like": "200,000",
      "date": "Feb, 9th 2021",
      "time": "08:57:01 PM"
    },
    {
      "id": 2,
      "user": "@hdhgtr",
      "like": "150,000",
      "date": "Feb, 9th 2021",
      "time": "08:57:01 PM"
    },
    {
      "id": 3,
      "user": "@rfedfrg",
      "like": "147,000",
      "date": "Feb, 9th 2021",
      "time": "08:57:01 PM"
    },
    {
      "id": 4,
      "user": "@verigreen",
      "like": "141,000",
      "date": "Feb, 9th 2021",
      "time": "08:57:01 PM"
    },
    {
      "id": 5,
      "user": "@realone",
      "like": "120,000",
      "date": "Feb, 9th 2021",
      "time": "08:57:01 PM"
    }
  ];
  const analyticsData = [
    {name: 'Overview', data: [{...overview, ...auto}]},
    {name: 'Top Likes', data: likes},
    {name: 'Top Comments', data: comments},
    {name: 'Top Moments', data: moments},
    {name: 'Top Hashtags', data: hashtags},
    {name: 'Top Post', data: posts}
  ];
  return (
    <div className='w-100'>
      <div className='w-100 d-flex justify-content-end'>
      <AntDropdown
        handleDropdownAction={(e) => console.log()}
        dropDownOptions={[
          analyticsData.map((e) =>
          <CsvDownload
          data={e.data}
          filename={`${e.name}.csv`}
          style={{
          color: '#000',
          fontSize: '15px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
         
        }}
      >
        {e?.name}
      </CsvDownload>)
      
        ]}
      >
        <button
          style={{
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#fff',
          fontSize: '15px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
          }}
        >
          Export Analytics
        </button>
      </AntDropdown>
        

      {/* <CsvDownload
        data={[{...overview, ...auto, total_group: groups?.total_group}]}
        filename="overview.csv"
        style={{
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
         
        }}
      >
        Export Analytics
      </CsvDownload> */}
      </div>
      <div className=''>
        <AnalyticsCard
          post={overview.total_posts}
          like={overview.total_likes}
          share={overview.total_shares}
          group={groups?.total_group}
          comment={overview.total_comment}
          followers={auto?.totalCountUserFollowWayagram}
          followings={auto?.totalCountWayagramFollowUser}
          personalUsers={stat.personalUsers}
          businessUsers={stat.corporateUsers}
        />
      </div>

      <div className='row my-4'>
        <div className='col-md-6'>
          <div className=''>
            <TableCard
              title="Top Likes"
              columns={likesCol}
              datas={likes}
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className=''>
            <TableCard 
              title="Top Comments"
              columns={commentsCol}
              datas={comments}
            />
          </div>
        </div>
      </div>

      <div className='row my-4'>
        {/* <div className='col-md-6'> */}
          {/* <div className=''> */}
            {/* <TableCard 
              title="Top Accounts"
              columns={accountCol}
              datas={tlData}
            /> */}
          {/* </div> */}
        {/* </div> */}
        <div className='col-md-6'>
          <div className=''>
            <TableCard 
              title="Top Moments"
              columns={momentCol}
              datas={moments}
            />
          </div>
        </div>
        <div className='col-md-6'>
          <div className=''>
            <TableCard 
              title="Top Hashtags"
              columns={hashtagCol}
              datas={hashtags}
            />
          </div>
        </div>
      </div>

      <div className='row my-4'>
        <div className='col-md-6'>
          <div className=''>
            <TableCard 
              title="Top Post"
              columns={postsCol}
              datas={posts}
            />
          </div>
        </div>

      </div>

      {/* <div className='row my-4'>
        <div className='col-md-6'>
          <LayCard title="Age Groups">
            <PieChart />
          </LayCard>
        </div>
        <div className='col-md-6'>
          <LayCard title="Gender">
            <PieChart />
          </LayCard>
        </div>
      </div> */}
    </div>
  )
}

export default Analytics;
