import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { postRequest, getRequest } from '../../../services/request';
import { success, error } from '../../../services/swal-mixin';

const AddDev = ({ data, handleViewCloseWeb, onClick }) => {
  const [loading, setLoading] = useState(false);
  const [userWallet, setUserWallet] = React.useState([]);
  const [bank, setBank] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);

  // const [stateDevStack, seteDevState] = useState("")
  const uid = () => {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  };
  const unique = uid();
  const [stacks, setStacks] = useState([
    {
      amount: '',
      bankCode: '',
      bankName: '',
      crAccount: '',
      crAccountName: '',
      narration: '',
      saveBen: '',
      transRef: unique,
      transactionPin: '',
      userId: data.userId,
      walletAccountNo: '',
    },
  ]);
  const saveBenf = [
    {
      value: '--',
      label: '--',
    },
    {
      value: 'true',
      label: 'Yes',
    },
    {
      value: 'false',
      label: 'No',
    },
  ];

  const [count, setCount] = useState(0);

  function validate(values) {
    const errors = {
      firstname: '',
      email: '',
      company: '',
      developer_number: '',
      employee_number: '',
      where_is_your_location_: '',
    };

    for (let key in errors) {
      delete errors[key];
      if (!values[key]) {
        errors[key] = 'Enter required field';
      }
    }
    let stacksOfEngineers = {};
    let stackHasError = false;
    stacks.forEach((stack, i) => {
      let error = {};
      let errExist = false;
      if (stack.developer_number <= 0) {
        error['developer_number'] = 'A valid number is required';
        errExist = true;
      }
      if (!stack.stack) {
        error['stack'] = 'please select a stack';
        errExist = true;
      }
      if (errExist) {
        stacksOfEngineers[i] = error;
        stackHasError = true;
      }
    });
    if (stackHasError) {
      errors.stacksOfEngineers = stacksOfEngineers;
    }
    return errors;
  }

  const addMore = () => {
    let countMore = count;
    let newCount = countMore + 1;
    setCount(newCount);
    setStacks([
      ...stacks,
      {
        amount: '',
        bankCode: '',
        bankName: '',
        crAccount: '',
        crAccountName: '',
        narration: '',
        saveBen: '',
        transRef: unique,
        transactionPin: '',
        userId: data.userId,
        walletAccountNo: '',
      },
    ]);
  };
  const removeOne = (index) => {
    console.log('ss', index);
    let countMore = count;
    let newCount = countMore - 1;
    setCount(newCount);
    const newStacks = stacks.filter((stack, i) => {
      return i !== index;
    });
    setStacks(() => newStacks);
  };
  const handleSelect = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log(value);

    const stk = stacks;
    if (name === 'amount') {
      // setStateDev(value);

      stk[stk.length - 1].amount = value;
    }
    if (name === 'bankCode') {
      // setStateDev(value);

      stk[stk.length - 1].bankCode = value;
    }
    if (name === 'bankName') {
      // setStateDevState(value);
      stk[stk.length - 1].bankName = value;
      // setDevNum(value);
    }
    if (name === 'crAccount') {
      // setStateDevState(value);
      stk[stk.length - 1].crAccount = value;
      // setDevNum(value);
    }
    if (name === 'crAccountName') {
      // setStateDevState(value);
      stk[stk.length - 1].crAccountName = value;
      // setDevNum(value);
    }
    if (name === 'narration') {
      // setStateDevState(value);
      stk[stk.length - 1].narration = value;
      // setDevNum(value);
    }
    if (name === 'saveBen') {
      // setStateDevState(value);
      stk[stk.length - 1].saveBen = value;
      // setDevNum(value);
    }
    if (name === 'transactionPin') {
      // setStateDevState(value);
      stk[stk.length - 1].transactionPin = value;
      // setDevNum(value);
    }
    if (name === 'walletAccountNo') {
      // setStateDevState(value);
      stk[stk.length - 1].walletAccountNo = value;
      // setDevNum(value);
    }
  };

  const getBank = async () => {
    setLoading(true);
    const response = await getRequest(
      `card-service/api/admin/getBanks`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);
    console.log('***************', response);

    if (!response || response.error !== undefined) {
      return;
    }
    const { data } = response.data;
    setBank(data);
    // setMetaData(data.metaInfo);
  };
  console.log(bank);
  const userId = data.userId;
  const getUserWallet = async () => {
    setLoading(true);
    const response = await getRequest(
      `api/v1/wallet/accounts/${userId}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);

    if (!response || response.error !== undefined) {
      return;
    }
    console.log('************', response);
    const { data } = response.data;
    setUserWallet(data);
    // setMetaData(data.metaInfo);
  };

  async function withdrawFund() {
    setLoading(true);
    const response = await postRequest(
      'admin/withdrawal/multipleFund',
      stacks,
      'http://68.183.60.114:9086/waya-withdrawal-service'
    );
    setLoading(false);
    console.log(response);

    const { message, status } = response.data;
    if (status === false) {
      return error(message);
    }
    handleViewCloseWeb();
    success(message);
  }

  useEffect(() => {
    getBank();
    getUserWallet();
  }, []);

  console.log(stacks, userWallet);
  return (
    <StyledRoleForm>
      {/* <TextField title="Make a Request" onClick={closeForm} /> */}
      <div className="form-section">
        <>
          {/* <> */}
          <LoadingComponent loading={loading} />
          {/* <form className="client-form" noValidate> */}

          <div className="select-eng-sect">
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    {/* <div className={classes.tabContent2}> */}

                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      label="Enter Amount"
                      name="amount"
                      placeholder="please enter amount"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      label="Credit Account"
                      name="crAccount"
                      placeholder="please enter account to fund"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <p>Wallet to be withdrawn from</p>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      select
                      label=""
                      name="walletAccountNo"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      {userWallet.map((option) => (
                        <option key={option.value} value={option.accountNo}>
                          {option.acct_name}-{option.clr_bal_amt}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <p>Select bank to be sent to</p>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      select
                      // label="Bank to be sent to"
                      name="bankName"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      {bank.map((option) => (
                        <option key={option.value} value={option.name}>
                          {option.name}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <p>Select bank Code to be sent to</p>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      select
                      label=""
                      name="bankCode"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      {bank.map((option) => (
                        <option key={option.value} value={option.code}>
                          {option.name}-{option.code}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <p>Enter account name</p>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      name="crAccountName"
                      placeholder="please enter account name"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      placeholder="please enter narration"
                      label="Transaction Narration"
                      name="narration"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      placeholder="please enter PIN"
                      label="Transaction Pin"
                      name="transactionPin"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      style={{ width: '100%' }}
                      id="outlined-select-currency-native"
                      select
                      label="Save Beneficiary"
                      name="saveBen"
                      onChange={handleSelect}
                      SelectProps={{
                        native: true,
                      }}
                      variant="outlined"
                    >
                      {saveBenf.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>

          {stacks.map(
            (item, i) =>
              i > 0 && (
                <div>
                  <div className="span-btn" onClick={() => removeOne(i)}>
                    {/* <BsTrash className="delete-icon" /> */}
                    <Button
                      style={{
                        backgroundColor: 'red',
                        color: 'white',
                        fontWeight: 500,
                        marginLeft: '44rem',
                      }}
                      full
                      variant="outlined"
                      color="secondary"
                      // white
                      className="delete-btn btn-component"
                    >
                      Delete
                    </Button>
                  </div>
                  <Card>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                          {/* <div className={classes.tabContent2}> */}

                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            label="Enter Amount"
                            name="amount"
                            placeholder="please enter amount"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            label="Credit Account"
                            name="crAccount"
                            placeholder="please enter account to fund"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <p>Wallet to be withdrawn from</p>
                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            select
                            label=""
                            name="walletAccountNo"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          >
                            {userWallet.map((option) => (
                              <option
                                key={option.value}
                                value={option.accountNo}
                              >
                                {option.acct_name}-{option.clr_bal_amt}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <p>Select bank to be sent to</p>
                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            select
                            // label="Bank to be sent to"
                            name="bankName"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          >
                            {bank.map((option) => (
                              <option key={option.value} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <p>Select bank Code to be sent to</p>
                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            select
                            label=""
                            name="bankCode"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          >
                            {bank.map((option) => (
                              <option key={option.value} value={option.code}>
                                {option.name}-{option.code}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <p>Enter account name</p>
                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            name="crAccountName"
                            placeholder="please enter account name"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            placeholder="please enter narration"
                            label="Transaction Narration"
                            name="narration"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            placeholder="please enter PIN"
                            label="Transaction Pin"
                            name="transactionPin"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          ></TextField>
                        </Grid>
                        <Grid item md={6} xs={12}>
                          <TextField
                            style={{ width: '100%' }}
                            id="outlined-select-currency-native"
                            select
                            label="Save Beneficiary"
                            name="saveBen"
                            onChange={handleSelect}
                            SelectProps={{
                              native: true,
                            }}
                            variant="outlined"
                          >
                            {saveBenf.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </div>
              )
          )}
          <Button
            style={{ marginTop: '20px', width: '100%' }}
            variant="outlined"
            onClick={addMore}
          >
            <span>
              <AddCircleIcon />
            </span>
            Add New Transaction
          </Button>
          <div>
            <Button
              style={{
                marginTop: '20px',
                marginBottom: '20px',
                alignItems: 'center',
                marginLeft: '320px',
                backgroundColor: 'red',
                color: 'white',
                fontWeight: 800,
              }}
              variant="outlined"
              // type="submit"
              full
              onClick={() => withdrawFund()}
            >
              Fund Users
            </Button>
          </div>
        </>
      </div>
    </StyledRoleForm>
  );
};
const StyledRoleForm = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 10;
  overflow-y: scroll;
  background: white;
  border: 1px solid rgba(33, 51, 79, 0.15);
  .underline {
    display: block;
  }
  .form-section {
    padding: 4rem 4rem 0 4rem;
    overflow-y: scroll;
  }
  .form-title {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 3.2rem;
  }
  .add-client-btn {
    margin-bottom: 23.8rem;
  }
  .stack-section {
    display: flex;
    flex-wrap: wrap;
    width: 32rem;
  }
  .client-form {
    display: block;
  }
  .form-success {
    display: flex;
    width: 100%;
    padding: 1rem;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
  }
  .permissions-div {
    display: flex;
  }
  .add-perms-btn {
    padding: 0.1rem 2rem;
    margin-top: 3.2rem;
    border: none;
    background: linear-gradient(96.67deg, #34a853 0%, #b8d344 100%);
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .sucess-icon {
    width: 4.2rem;
    margin-bottom: 1.7rem;
  }
  .green-text {
    font-size: 2.4rem;
    margin-bottom: 2.2rem;
  }
  .success-msg {
    width: 25.9rem;
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    margin-bottom: 3.8rem;
  }
  .success-btn {
    width: 32rem;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 3.2rem;
  }
  .success-btn:hover,
  .success-btn:focus {
    background: #34a853;
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
  .search-wrapper {
    padding: 1rem;
    font-size: 1.7rem;
    border: 1px solid rgba(33, 51, 79, 0.15) !important;
  }
  .searchBox::placeholder {
    color: rgb(104, 116, 135);
  }
  .perm-label {
    color: #21334f;
    font-size: 1.6rem;
    font-weight: 600;
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr !important;
  }
  .eng-stack {
    display: flex;
    gap: 0.5rem;
  }
  .eng-stack > div {
    max-width: 17rem;
    div {
      width: 100%;
      select {
        width: 100% !important;
      }
      input {
        width: 100% !important;
      }
    }
  }
  .span-btn {
    color: red;
    margin-top: 1.7rem;
    cursor: pointer;
    marginleft: 12rem;
  }
  .label-stack {
    font-size: 1.6rem;
    font-weight: 600;
    color: #21334f;
  }
  .select-eng-sect {
    button {
      text-align: center;
      font-size: 1rem;
      color: #21334f;
      width: 100%;
      padding: 0.7rem 1rem;
      background: #fff;
      border: 1px solid rgba(33, 51, 79, 0.15);
      span {
        font-weight: 800;
        font-size: 1rem;
        margin-right: 0.7rem;
      }
    }
  }
`;

export default AddDev;
