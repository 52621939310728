import React from "react";

import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import * as Yup from "yup";
import { Formik } from "formik";

import { putRequest } from "../../../services/request";
import { success } from "../../../services/swal-mixin";
import { Modal } from "react-bootstrap";

const status = [
  {
    value: false,
    label: "Public",
  },
  {
    value: true,
    label: "Not public",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    // background: theme.color.wayaPink,
    background: "#FF6634",
    color: "#ffffff",
    // padding: '8px 16px',
    "&:hover": {
      background: "#2D9CDB",
    },
  },
  cardContent: {
    padding: 15,
  },
}));

const UserProfile = ({ data, className, reload, hideModal, showModal }) => {
  const classes = useStyles();
  return (
    <Modal
    show={showModal}
    onHide={hideModal}
    backdrop="none"
    keyboard={false}
    size="lg"
    style={{ marginLeft: '30%', width: '400px' }}
    centered
  >
    <Modal.Header
      style={{
        borderBottom: 'none',
        padding: '0 1rem',
        borderTop: '5px solid #FF6634',
        textAlign: 'center'
      }}
      closeButton
    >
       User Profile
    </Modal.Header>
    <Modal.Body>
      <div>
        {/* <div style={{ height: 100, width: 100}}>
        <img src={data?.coverImage} alt='userImg' className="img-fluid" />

        </div> */}
        <div
          className="rounded-circle d-flex align-items-center justify-content-center"
          style={{marginTop: 40, width: 100, height: 100, border: "2px solid #FF6634", borderRadius: 50 }}
        >
            {data?.avatar ? (
              <img src={data?.avatar} alt='userImg' className="img-fluid" />
            ) : (
              <Avatar size="large" />
            )}
        </div>
        <div className="mt-2">
          <label className="mx-2">Display Name: </label>
          {data?.displayName}
        </div>
        <div className="mt-2">
          <label className="mx-2">Username: </label>
          {data?.username}
        </div>
        <div className="mt-2">
          <label className="mx-2">Phone Number: </label>
          {data?.phone}
        </div>
        <div className="mt-2">
          <label className="mx-2">Email: </label>
          {data?.email}
        </div>
        <div className="mt-2">
          <label className="mx-2">Bio: </label>
          {data?.bio}
        </div>
      </div>
    </Modal.Body>
  </Modal>
  );
};

UserProfile.propTypes = {
  className: PropTypes.string,
};

export default UserProfile;
