import React from 'react';

const editIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.3417 1.79583C18.5948 2.04884 18.7956 2.34923 18.9325 2.67985C19.0695 3.01046 19.14 3.36482 19.14 3.72269C19.14 4.08055 19.0695 4.43491 18.9325 4.76552C18.7956 5.09614 18.5948 5.39653 18.3417 5.64954L6.13828 17.853L1 19.1375L2.28457 14.0712L14.4931 1.80097C14.7453 1.54755 15.0449 1.34636 15.375 1.20891C15.705 1.07147 16.0589 1.00048 16.4163 1C16.7738 0.999525 17.1279 1.06957 17.4583 1.20613C17.7887 1.34269 18.0889 1.54308 18.3417 1.79583V1.79583Z"
      stroke={props.fill || '#828282'}
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.70728 19.1377H18.9838"
      stroke={props.fill || '#828282'}
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.4148 5.00684L17.6994 6.29141"
      stroke={props.fill || '#828282'}
      stroke-width="1.6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default editIcon;
