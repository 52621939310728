import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';

const statusOption = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'In Active',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 10,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: 'auto',
  },
  avatar: {
    height: 280,
    width: 280,
    minWidth: 280,
  },
}));

const QRCodeFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();

  const filename = data ? data.filename : '';
  const qrKey = data ? data.qrKey : '';
  const path = data ? data.path : '';
  const qrString = data ? data.qrString : '';
  const isActive = data ? data.isActive : '';

  return (
    <Formik
      initialValues={{
        filename,
        qrKey,
        isActive,
        path,
        qrString,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .max(255)
          .required('Email is required'),
        firstName: Yup.string().max(255).required('First name is required'),
        lastName: Yup.string().max(255).required('Last name is required'),
        phone: Yup.number().required('Phone number is required'),
      })}
      onSubmit={async (values) => {
        await submitForm(values);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <div>
          <div className={classes.profileCard}>
            <div className={classes.avatarWrapper}>
              <img
                src={data.qrString}
                alt="QR Code"
                className={classes.avatar}
              />
            </div>
          </div>
            <div>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    label="File Name"
                    name="filename"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.filename && errors.filename)}
                    helperText={touched.filename && errors.filename}
                    value={values.filename}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="QR Key"
                    name="qrKey"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.qrKey && errors.qrKey)}
                    helperText={touched.qrKey && errors.qrKey}
                    value={values.qrKey}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="isActive"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.isActive && errors.isActive)}
                    helperText={touched.isActive && errors.isActive}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.isActive}
                    variant="outlined"
                  >
                    {statusOption.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Path"
                    name="path"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.path && errors.path)}
                    helperText={touched.path && errors.path}
                    value={values.path}
                    variant="outlined"
                  ></TextField>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                  <TextField
                  required
                    fullWidth
                    label="QR String"
                    name="qrString"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.wayaqrString && errors.qrString)}
                    helperText={touched.wayaqrString && errors.qrString}
                    value={values.qrString}
                    variant="outlined"
                  ></TextField>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" p={2}>
                <Button
                  color="primary"
                  className={classes.createButton}
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Update QR Code
                </Button>
              </Box>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

QRCodeFormComponent.propTypes = {
  className: PropTypes.string,
};

export default QRCodeFormComponent;
