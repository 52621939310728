import { Favorite, Share } from '@material-ui/icons';
import React from 'react';

export default function MediaCard({ contentFile, likeCount, repostCount }) {
//   const { fileUrl, id } = data;
console.log({ contentFile });
  return (
    <div className="p-1 col-4 col-md-4 col-sm-12">
      <div style={{ marginBottom: '2rem' }}>
        {contentFile?.length && contentFile.length < 2 ? (
          <div className="img-div">
            <a href={contentFile[0]} rel="noopener noreferrer">
              <i className="mdi mdi-download dowload-icon" alt="uploaded-pix" />
            </a>
            {contentFile[0]?.includes('mp4') ||
            contentFile[0]?.includes('webm') ||
            contentFile[0]?.includes('MOV') ||
            contentFile[0]?.includes('wmv') ? (
              <div
                style={{
                  height: '100%',
                  maxWidth: '32rem',
                  width: '100%',
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <video
                  controls
                  autoPlay
                  muted
                  loop
                  width="80%"
                  height="auto"
                  className="video-post"
                  playsInline
                  style={{
                    objectFit: 'fill',
                  }}
                >
                  <source src={contentFile[0]} type="video/webm" />
                  <source src={contentFile[0]} type="video/mp4" />
                  <source src={contentFile[0]} type="video/wmv" />
                  <source src={contentFile[0]} type="video/MOV" />
                  Your browser does not support the video tag.
                  <track
                    // src="captions_en.vtt"
                    kind="captions"
                    srcLang="en"
                    label="english_captions"
                  />
                </video>
              </div>
            ) : (
              <div
                style={{
                  height: '100%',
                  maxWidth: '52rem',
                  width: '100%',
                  textAlign: 'center',
                  position: 'relative',
                  overflow: 'hidden',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
              <img style={{ maxHeight: '100%', maxWidth: '100%'}} src={contentFile[0]} alt="pic" />
              </div>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='d-flex justify-content-around mx-4'>
        <div>
          <Favorite />{likeCount ?? 0}
        </div>
        <div>
          <Share /> {repostCount ?? 0}
        </div>
      </div>
    </div>
  );
}
