import React from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';


const groupTypes = [
  {
    value: '',
    label: 'none',
  },
  {
    value: true,
    label: 'true',
  },
  {
    value: false,
    label: 'false',
  },
];

const isMuted = [
  {
    value: '',
    label: 'none',
  },
  {
    value: false,
    label: 'false',
  },
  {
    value: true,
    label: 'true',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));






const CreateUserComponent = ({ className, data, submitForm, name='update Donation', ...rest }) => {
  const classes = useStyles();



  return (
    <Formik
      initialValues={{
          title: '',
          description: '',
          profile_id: data?.ProfileId,
          donation_id: data?.id,
          isClosed: '',
          estimatedAmount: '',
          
          donationImage: '',
          displayTotalDonation: '',
      }}

      validationSchema={Yup.object().shape({
        
        title: Yup.string().max(255).required('title is required'),
        description: Yup.string().max(255).required('description is required'),
        
      })}
      

      onSubmit= {(data) => {
        
        // const response = await postRequest('auth/create', data);
        // console.log(response);
        return submitForm(data)
        // success("user created successfully")
        // window.location.reload();
        // navigate('/app/dashboard', { replace: true });

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          noValidate
          
          className={clsx(classes.root, className)}
          {...rest}
        >
          <Card>
            <CardHeader
              subheader="Create new Donations."
              title="Create New Donation"
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={3}>
                
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Donation Title"
                    name="title"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    value={values.title}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Description"
                    name="description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    required
                    value={values.description}
                    variant="outlined"
                  />
                </Grid>
                
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Status"
                    name="isClosed"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.isClosed && errors.isClosed)}
                    helperText={touched.isClosed && errors.isClosed}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.isClosed}
                    variant="outlined"
                  >
                    {isMuted.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    
                    name="donationImage"
                    
                    onChange={handleChange}
                    // onChange={handleChange}
                    type='file'
                    
                    variant="outlined"
                    
                    fullWidth
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Estimated Amount"
                    name="estimatedAmount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.estimatedAmount && errors.estimatedAmount)}
                    helperText={touched.estimatedAmount && errors.estimatedAmount}
                    value={values.estimatedAmount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Display Total Donation"
                    name="displayTotalDonation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={Boolean(touched.displayTotalDonation && errors.displayTotalDonation)}
                    helperText={touched.displayTotalDonation && errors.displayTotalDonation}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.displayTotalDonation}
                    variant="outlined"
                  >
                    {groupTypes.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
            {/* <Divider /> */}
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                className={classes.createButton}
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Create
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

CreateUserComponent.propTypes = {
  className: PropTypes.string,
};

export default CreateUserComponent;

