import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { postRequest } from '../../../services/request';
import { error, success } from '../../../services/swal-mixin';
import { formatPhoneNumber } from '../../../services/helpers';
import LoadingComponent from '../../../components/loading/LoadingComponent';


const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',

    '&:hover': {
      background: '#2D9CDB',
    },
  },
  cardContent: {
    padding: 15,
  },
}));

const ChargeMode = [
  {
    value: '--',
    label: 'none',
  },

  {
    value: 'T',
    label: 'Total Freeze',
  },
  {
    value: 'D',
    label: 'Debit Freeze',
  },
  {
    value: 'C',
    label: 'Credit Freeze',
  },
];

const ChargeComponent = ({
  pause,
  handleClose,
  getTransaction,
  className,
  ...rest
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  console.log(pause);
  return (
    <Formik
      initialValues={{
        customerAccountNo: pause.accountNo,
        freezCode: '',
        freezReason: '',
      }}
      validationSchema={Yup.object().shape({
        
        freezReason: Yup.string()
          .min(2, 'Too Short!')
          .max(500, 'Too Long!')
          .required('Required'),
      })}
      onSubmit={async (data) => {
        console.log(data);

        setLoading(true);

        const response = await postRequest(
          `api/v1/wallet/account/pause`,
          data,
          'http://68.183.60.114:9086'
        );
        setLoading(false);
        console.log(response);

        const { message, status } = response.data;
        const datas = response.data.data;
        if (status === true) {
          handleClose();
          success(message);
          getTransaction();
        } else {
          error(datas[0]);
        }
        data = {};
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          autoComplete="off"
          onSubmit={handleSubmit}
          noValidate
          className={clsx(classes.root, className)}
          {...rest}
        >
          <LoadingComponent loading={loading} />
          <Card>
            <CardHeader
              subheader="Create new Charge and Transaction Name."
              title="Create New Transaction Charge"
            />
            <Divider />
            <CardContent className={classes.cardContent}>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Enter Freez Code"
                    name="freezCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={values.freezCode}
                    variant="outlined"
                    error={Boolean(touched.freezCode && errors.freezCode)}
                    helperText={touched.freezCode && errors.freezCode}
                  >
                    {ChargeMode.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    label="Enter Reason"
                    name="freezReason"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.freezReason && errors.freezReason)}
                    helperText={touched.freezReason && errors.freezReason}
                    value={values.freezReason}
                    key={1}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
            {/* <Divider /> */}
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                className={classes.createButton}
                variant="contained"
                type="submit"
                disabled={isSubmitting}
              >
                Pause Wallet
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

ChargeComponent.propTypes = {
  className: PropTypes.string,
};

export default ChargeComponent;
