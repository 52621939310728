import { makeStyles } from '@mui/styles';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { sendWithAuth } from '../../../services/request';
import { error, success } from '../../../services/swal-mixin';
import BulkFormComponent from './multiForm';
// import store from '../../redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: '25px 0',
  },

  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 20,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: '5px auto',
  },
  avatar: {
    height: 150,
    width: 150,
    minWidth: 150,
    borderRadius: 100,
    // border: `1px solid ${theme.color.lightGrayishBlue2}`,
  },
}));

const BulkUserComponent = (props) => {
  const classes = useStyles();
  const { data, getTransaction, handleViewClose, handleOpenMultiFund, handleCloseMultiFund } = props;
  const [value, setValue] = React.useState(0);

  const handleViewCloseWeb = () => {
    handleCloseMultiFund();
    // handleViewClose()
  };
  const handleViewOpen = () => {
    console.log('yes')
    handleOpenMultiFund();
    // handleViewClose()
  };

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  console.log(data)

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewCloseWeb}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="web"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <div style={{fontSize: "2rem", textAlign: "center", fontWeight: 800}}>External Bank Transfer For Multiple Users</div>
            <BulkFormComponent data={data} handleViewCloseWeb={handleViewCloseWeb} getTransaction={getTransaction}  />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BulkUserComponent;
