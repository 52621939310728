import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Table, Pagination, Button, Modal, Form, Input, notification, Select, Upload } from "antd";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { emailCol } from "../../../utils/dummyData";
import { getRequest, postRequest, patchRequest, deleteRequest } from "../../../services/request";
import config from "../../../services/config";
import SearchBar from '../../../components/SearchBar';
import CsvDownload from 'react-json-to-csv';
import color from '../../../theme/color'
import  MultiInput from '../../../components/CustomInput/MultiInput'
import { InboxOutlined } from '@ant-design/icons';


const  EmailManagement = ({createButton = false, showRange=true, showSearch=false }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [currentTab, setCurrentTab] = useState("EmailManagement");
  const [filterType, setFilterType] = useState("SENDER");
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [query, setQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [value, setValue] = useState("a");
  const [values, setValues] = useState("");
  const [createModal, setCreateModal] = useState(false)
  const [mode, setMode] = useState('')
  const [images, setImages] = useState([]);
  const [formdata, setFormdata] = useState({});
  const [users, setUsers] = useState([])
  const [options, setOptions] = useState([])
  const [createManyPostModal, setcreateManyPostModal] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const { Dragger } = Upload;
  const [form] = Form.useForm()


  const selectOptions = [
    {
      value: "SENDER",
      label: "Initiator",
    },
    {
      value: "SUBJECT",
      label: "Message",
    },
    {
      value: "ADDRESS",
      label: "Email",
    },
  ];

  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });

  const handleChange = (e) => {
    setValues(e.target.value);
    setQuery(e.target.value);
  };




  const getData = async (q) => {
    let response
    if(q) {
      response = await getRequest(
        `admin-search-email-logs?query=${query}&filterBy=${filterType}&page=${pages}&limit=20`,
        config.notificationUrl
      );
    }else{
      let rangeQuery='';
      if (range.startDate && range.endDate) {
        rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
      }
      setLoading(true);
      response  = await getRequest(
        `admin-get-email-logs?page=${pages}&limit=${limits}${rangeQuery}`,
        config.notificationUrl
      );
    };
    setLoading(false);
    const { data, metadata } = response?.data?.data || [];
    setPosts(data || []);
    setTotal(metadata?.total || 0);
    setFilteredPosts(data?.data || []);
  };
  

  const getEmailOverview = async () => {
    const response = await getRequest(
      "admin-get-email-log-analysis",
      config.notificationUrl
    );
    const { data } = response?.data || {};
    setMetaData(data);
  };

  useEffect(() => {
    getData(query);
  }, [currentTab, reload, query, range]);

  useEffect(() => {
    getEmailOverview();
  }, []);

  // const handleSubmit = async () => {
  //   console.log(formdata)
  //   const response =await postRequest(
  //     'send-mail',
  //     formdata,
  //     config.notificationUrl
  //   );
  const handleSubmit = async (datum) => {
    const formD = new FormData();
    formD.append('bulkEmail', excelFile);
    const payload = createManyPostModal ? formD : formdata;
    const response =await postRequest(
      `send-mail`,
      payload,
      config.notificationUrl
    );

    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message,
        description:"Email sent"
      })
      await getData();
      await getEmailOverview();
      setCreateModal(false)
      setcreateManyPostModal(false)
    } else {
      notification.warning({
        message,
        description:"Failed"
      })
    }
  }

  // const getUsers = async () => {
  //   const response = await getRequest(
  //     `admin-profile/all?page=${pages}&limit=1000000`,
  //     config.mainUrl
  //   );
  //   const data = response?.data?.data.AllWayAGramProfiles || [];
  //   setUsers(data);
  //   setOptions(data.map((d) => { return { value: d.email, label: d.email }}))
  // }

  useEffect(() => {
   // getUsers();
  }, []);

  
const handleModals=(type)=>{
  if(type==='single'){
    setCreateModal(true)
    setMode(type)
  }
  if(type==='bulk'){
    form.resetFields()
    setcreateManyPostModal(true)
    setMode(type)
  }
  // setCreateModal(true)
  // setMode(type)
}

const resend =async (datum) => {
  const response =  await patchRequest (
   `resend-email?email_log_id=${datum.id}`,
   {
    subject: datum.subject,
    content: datum.message,
    type: datum.type,
   },
     config.notificationUrl
  )
  setLoading(false)
  const { status, message } = response.data;
   if (status === true) {
     notification.success({
       message: `Email sent successfully`,
       description: message
     })
     setReload(!reload);
   } else {
     notification.warning({
       message:"failed",
       description:message
     })
   }
  await getData()
  await getEmailOverview()
}

const deleteEmail = async (datum) => {
  const response = await deleteRequest (
   `admin-delete-email-log?email_log_id=${datum.id}`,
    config.notificationUrl
  )
  setLoading(false)
  const { status, message } = response?.data;
   if (status === true) {
     notification.success({
       message: `Email sent successfully`,
       description: message
     })
     setReload(!reload);
   } else {
     notification.warning({
       message : "failed",
       description : message
     })
   }
  await getData()
  await getEmailOverview()
}
const props = {
  name: 'file',
  multiple: false,
  accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  customRequest({ onSuccess }){
    setTimeout(() => {
      onSuccess("ok");
    }, 0)
  },
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      setExcelFile(info.file.originFileObj)
      console.log(info.file)
    } else if (status === 'error') {
      console.log(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files[0]);
  },
};

const Buttons = () => {
  return(
    <div hidden={createButton}>
      <Button
        onClick={() => handleModals("single")}
        type="primary"
        className="me-2"
      >
        Send Single Email
      </Button>
      <Button
        style={{ backgroundColor: color.brightOrange, color: color.white }}
        onClick={() => handleModals("bulk")}
      >
        Send Bulk Email
      </Button>
    </div>
  )
}

const column = emailCol(resend, deleteEmail);


  return (
    <Column>
      <Row horizontal="space-between">
      </Row>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
      <SearchBar
        page="email"
        total={metaData}
        showButtons={true}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        handleChange={handleChange}
        Conversion={Buttons}
        createButton={true}
        showRange={showRange}
        showSearch={showSearch}
        setRange={setRange}
      />
      <Table
        columns={column}
        dataSource={posts}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
        <Pagination
        total={total}
        responsive={true}
        defaultCurrent={1}
        onChange={(e) => setPages(e)}
        defaultPageSize={limits}
        showSizeChanger={false}
      />
      </div>
      <Modal
        visible={createModal}
        onCancel={()=> setCreateModal(false)}
        footer={false}
        title={<p className='text-center'>Send Email</p>}
      >
        <Form layout='vertical' onFinish={handleSubmit}>

          {/* <Form.Item label="Email" name="email" hidden={mode==='bulk'}> */}
            {/* <Select
              showSearch
              value={value}
              placeholder="Search for user"
              defaultActiveFirstOption={false}
              showArrow={false}
              onChange={(e)=> setFormdata({...formdata, email: [JSON.parse(e)]})}
              onSearch={(e) => setValue(e)}
              notFoundContent={null}
            >
              {users.map((user) => user.email && (
                <Select.Option key={user.id} value={JSON.stringify({ recipientId: user.id, email: user.email })} disabled={user.email === null} >
                  {user.email || 'no email'}
                </Select.Option>
              ))}
            </Select> */}
             {/* <Input onChange={(e)=>setFormdata({...formdata, subject: e.target.value})} />
          </Form.Item> */}

          {/* <Form.Item label="Email" name="email"> */}
            {/* <Select
              mode="multiple"
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              onSearch={(e) => setValue(e)}
              onChange={(values)=> setFormdata({...formdata, email: values.map((value)=> JSON.parse(value)) })}
              >
              {users.map((user) => user.email && (
                <Select.Option key={user.id} value={JSON.stringify({ recipientId: user.id, email: user.email })} disabled={user.email === null} >
                  {user.email || 'no email'}
                </Select.Option>
              ))}
            </Select> */}
            <Form.Item label="Email" name="email">
             <Input onChange={(e)=>setFormdata({...formdata, email: e.target.value})} />
          </Form.Item>

          <Form.Item label="Subject" name="subject">
            <Input onChange={(e)=>setFormdata({...formdata, subject: e.target.value})} />
          </Form.Item>

          <Form.Item label="Body" name="body">
            <Input.TextArea onChange={(e)=>setFormdata({...formdata, content: e.target.value})}/>
          </Form.Item>

          <Form.Item label="Type" name="type">
            <Select
              onChange={(e)=>setFormdata({...formdata, type: e})}
            >
              <Select.Option value="PROMOTION">Promotion</Select.Option>
              <Select.Option value="POLL">Poll</Select.Option>
              <Select.Option value="PRODUCT">Product</Select.Option>
              <Select.Option value="DONATION">Donation</Select.Option>
              <Select.Option value="EVENT">Event</Select.Option>
              <Select.Option value="OTHERS">Others</Select.Option>
            </Select>
          </Form.Item>

          {/* <Form.Item label="Attach files">
            <div className="input-group">
              <input type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                accept="image/*"
                multiple="multiple"
                aria-label="Upload" /> */}
              {/* <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Browse</button> */}
            {/* </div>
          </Form.Item> */}

          <Form.Item className='text-center mt-4'>
            <Button 
              size='large' 
              loading={loading} 
              htmlType='submit' 
              className='rounded px-5 '
              style={{ backgroundColor: color.brightOrange, color: color.white }}
            >
              Send
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={createManyPostModal}
        onCancel={()=> setcreateManyPostModal(false)}
        footer={false}
        title={<p className='text-center'>Upload Excel Email</p>}
      >
        <Form layout='vertical' onFinish={handleSubmit}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
          <Form.Item className="text-end mt-4">
            <Button
              htmlType="submit"
              type="primary"
              className="rounded px-5"
              loading={loading}
            >
              Send Bulk Email
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Column>
  );
};

export default EmailManagement;
