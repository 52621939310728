import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
//import { Modal } from "react-bootstrap";
import {
  postRequest,
  getRequest,
  patchRequest,
} from "../../../services/request";
import { error, success } from "../../../services/swal-mixin";
import { Select, Input, Form, Button, Modal, DatePicker } from "antd";
import config from "../../../services/config";
import { getUsers } from "../../../services/helpers";
import moment from "moment";
import { update } from "lodash";

const CreateEventComponent = (props) => {
  const { onClose, open, mode, loading, form, value, setValue } = props;
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState([]);
  const { RangePicker } = DatePicker;

  const handleSubmitPost = async (groupData) => {
    const eventStart = Date.now(groupData.date[0]);
    const eventEnd = Date.now(groupData.date[1]);
    const { date, ...data } = groupData;
    const payload = {
      eventStart: eventStart.toString(),
      eventEnd: eventEnd.toString(),
      ...data,
    };
    let response;
    if (mode == "create") {
      response = await postRequest(
        `admin-create-event`,
        payload,
        config.postUrl
      );
    } else if (mode == "update") {
      let { username, ...others } = payload;
      response = await patchRequest(
        `admin-update-event`,
        others,
        config.postUrl
      );
    }

    const { status, message } = response.data;
    if (status !== true) {
      return error(message);
    }
    success(message);
  };

  const handlePreview = (e, type) => {
    const { files } = e.target;
    setImage(files);
  };

  // useEffect(() => {
  //   console.log(users);
  //   console.log(value);

  //   getUsers(value, setUsers);
  // }, [value]);

  const dateFormat = "YYYY/MM/DD h:mm";

  return (
    <div>
      <Modal
        visible={open}
        onCancel={onClose}
        footer={false}
        title={<p className="text-center">{mode} Event</p>}
      >
        <Form
          layout="vertical"
          onFinish={handleSubmitPost}
          form={form}
          className="w-100"
        >
          <div className="row">
            <Form.Item label="Profile Picture" className="col-md-6 px-3">
              <div className="input-group">
                <input
                  onChange={(e) => handlePreview(e, "image")}
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
              </div>
            </Form.Item>
            <Form.Item hidden={mode == "create"} name="event_id">
              <Input />
            </Form.Item>
            <Form.Item
              hidden={mode === "create"}
              name="username"
              label="Username"
              className="col-md-6 px-3"
              readOnly
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item
              name="profile_id"
              label="Username"
              className="col-md-6 px-3"
              hidden={mode !== "create"}
            >
              <Select
                showSearch
                value={value}
                placeholder="Search for user"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(e) => getUsers(e, setUsers)}
                notFoundContent={null}
              >
                {users.map((user) => (
                  <Select.Option key={user.id} value={user.id}>
                    {user.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="eventName"
              label="Event Name"
              className="col-md-6 px-3"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="location"
              label="Location"
              className="col-md-6 px-3"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="details"
              label="Event Description"
              className="col-md-6 px-3"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
              name="isPrivate"
              label="Event Type"
              className="col-md-6 px-3"
            >
              <Select>
                <Select.Option value={true}>Public</Select.Option>
                <Select.Option value={false}>Private</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="isPaid" label="Type" className="col-md-6 px-3">
              <Select>
                <Select.Option value={true}>Paid</Select.Option>
                <Select.Option value={false}>Free</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.isPaid !== currentValues.isPaid
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("isPaid") === true ? (
                  <Form.Item
                    name="amount"
                    label="Amount"
                    className="col-md-6 px-3"
                  >
                    <Input />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
            <Form.Item label="Select Start and End Date" name="date">
              <RangePicker showTime />
            </Form.Item>
            <Form.Item name="virtual" label="Virtual" className="col-md-6 px-3">
              <Select>
                <Select.Option value={false}>Offline</Select.Option>
                <Select.Option value={true}>Online</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.virtual !== currentValues.virtual
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("virtual") === true ? (
                  <Form.Item
                    name="eventUrl"
                    label="Link"
                    className="col-md-6 px-3"
                  >
                    <Input />
                  </Form.Item>
                ) : null
              }
            </Form.Item>
          </div>
          <Form.Item className="text-end mt-4">
            <Button
              htmlType="submit"
              type="primary"
              className="rounded px-5"
              loading={loading}
            >
              {mode} Event
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateEventComponent;
