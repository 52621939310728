import React, { useEffect, useState } from "react";
import { Column } from "simple-flexbox";
import { Table, Pagination, notification, Input, Form, Modal, Button, Space, Tag } from "antd";
import { ExclamationCircleFilled } from '@ant-design/icons';
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { requestCol } from "../../../utils/dummyData";
import {
  getRequest,
  // deleteRequestData,
  patchRequest,
  // patchRequest,
  // putRequest,
} from "../../../services/request";
import config from "../../../services/config";
import SearchBar from "../../../components/SearchBar";
import { range } from "lodash";
// import { getUserData } from "../../../services/helpers";

const RequestLog = ({createButton = false, showRange = false, showSearch = true }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [reqData, setReqData] = useState(null);
  const [filterType, setFilterType] = useState("REPORTER");
  const [values, setValues] = useState("");
  const [query, setQuery] = useState("");
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState('All');
  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });
  
  

  // const userData = getUserData();
  const { confirm } = Modal;
  const { CheckableTag } = Tag;
  const tagsData = ['All','ADMIN', 'PAGE', 'GROUP', 'DISBURSE', 'PRICE']; 

  const getData = async () => {
    let rangeQuery='';
    if (range.startDate && range.endDate) {
      rangeQuery = `startDate=${range.startDate}&endDate=${range.endDate}`;
    }
    let logUrl = `logs?${rangeQuery}`
    if (selectedTags !== 'All') {
      logUrl = `logs?logType=${selectedTags}&${rangeQuery}`
    }

    setLoading(true);
    const response = await getRequest(
      logUrl,
      config.postUrl
    );
    setLoading(false);
    const { data, metadata } = response?.data || [];
    setPosts(data || []);
    setTotal(metadata?.total || 0);
    setFilteredPosts(data?.data || []);
  };

  const getOverview = async () => {
    const response = await getRequest(
      "admin-get-request-log-overview",
      config.postUrl
    );
    const {data} = response?.data || {};  
    setMetaData(data)
  };

  useEffect(() => {
    getOverview();
  }, []);

  useEffect(() => {
    getData();
  }, [pages, selectedTags, range]);

  const handleSubmitAction = async(values) => {
    setLoading(true);
    const d = {
      ...(values.rejection_reason && {reason: values.rejection_reason}),
      logId: values.rejection_reason ? reqData.id : values.id,
      action: values.action === 'reject' ? 'DECLINE' : 'APPROVE',
    }
    const url = `logs/admin/decide-log`;
    const response = await patchRequest(
      url, d, config.postUrl
    );
    setLoading(false);
    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message,
        description: "updated",
      });
      getData();
    } else {
      notification.warning({
        message,
        description: "error",
      });
    }
  }

  const handleAction = async (id, action) => {
    setReqData({id});
    if (action === 'reject') setShowRejectModal(true);
    else confirm({
      title: 'Are you sure you want to approve?',
      icon: <ExclamationCircleFilled />,
      content: 'Click ok to proceed or cancel to go back',
      onOk() {
        handleSubmitAction({id, action});
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const selectOptions = [
    {
      value: "REPORTER",
      label: "Reporter",
    },
    {
      value: "REPORTEE",
      label: "Reportee",
    },
    {
      value: "SUBJECT",
      label: "Subject",
    },
    {
      value: "TYPE",
      label: "Type",
    },
    {
      value: "CATEGORY",
      label: "Category",
    },
  ];
 

  const handleChange = (e) => {
    setValues(e.target.value);
    setQuery(e.target.value);
  };

  const column = requestCol(handleAction);

  return (
    <Column>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
        <SearchBar
          page="request_log"
          total={metaData}
          showButtons={false}
          setFilterType={setFilterType}
          selectOptions={selectOptions}
          handleChange={handleChange}
          createButton={createButton}
          showRange={showRange}
          showSearch={showSearch}
          setRange={setRange}
        />
        <div className="my-3">
      {/* <span className="me-2">Categories:</span> */}
      <Space size={[0, 8]} wrap>
        {tagsData.map((tag) => (
          <CheckableTag
            key={tag}
            checked={selectedTags ===tag}
            onChange={() => setSelectedTags(tag)}
          >
            {tag}
          </CheckableTag>
        ))}
      </Space>
    </div>
        <Table
          columns={column}
          dataSource={posts}
          rowKey="id"
          loading={loading}
          pagination={false}
          rowSelection={{
            type: "checkbox",
            // ...rowSelection,
          }}
        />
        <Pagination
          total={total}
          responsive={true}
          defaultCurrent={1}
          onChange={(e) => setPages(e)}
          defaultPageSize={limits}
          showSizeChanger={false}
        />
      </div>
      <Modal
       visible={showRejectModal}
        onCancel={() => setShowRejectModal(false)}
        footer={false}
        title={<h5 className="text-center text-capitalize">Reject Request</h5>}
      >
        <Form onFinish={(values) => handleSubmitAction({ ...values, action: 'reject' })}>
          <Form.Item name="rejection_reason">
            <Input.TextArea placeholder="Rejection Reason" />
          </Form.Item>
          <Form.Item className="text-center mt-4">
            <Button
              size="large"
              htmlType="submit"
              type="ghost"
              className="rounded px-5"
            >
              Reject
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Column>
  );
};

export default RequestLog;
