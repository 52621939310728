import { makeStyles } from '@mui/styles';
import React from 'react';
import { Modal } from 'react-bootstrap';
import BulkFormComponent from './PinIndex';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    
  },
  tabContent: {
    padding: '25px 0',
  },

  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 20,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: '5px auto',
  },
  avatar: {
    height: 150,
    width: 150,
    minWidth: 150,
    borderRadius: 100,
   
  },
}));

const BulkUserComponent = (props) => {
  const classes = useStyles();
  const { data, handleWayaOpen, handleWayaClose } = props;

  const handleClose = () => {
    handleWayaClose();
  };
  const handleView = () => {
    handleWayaOpen();
  };


  

  return (
    <div className={classes.root}>
      <Modal
        show={handleView}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <BulkFormComponent data={data} handleClose={handleClose} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BulkUserComponent;
