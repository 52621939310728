import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { getRequest, postRequest, putRequest } from "../../../services/request";
import { success, error } from "../../../services/swal-mixin";
import Divider from "@mui/material/Divider";
import { Modal } from "react-bootstrap";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { notification } from "antd";

export default function FollowTable(props) {
  const [users, setUsers] = useState([]);
  const { user, handleCloseFriends, handleOpenFriends } = props;

  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);

  const handleClose = () => {
    handleCloseFriends();
  };
  const handleOpen = () => {
    handleOpenFriends();
  };

  const userid = user.UserId;
  const getFollowers = async () => {
    console.log(userid);
    setLoading(true);
    const response = await getRequest(
      `admin-friend/followers?user_id=${userid}`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/graph"
    );
    setLoading(false);

    const { status, message } = response.data;
    const follower = response?.data?.data?.followers;
    setUsers(follower);
    if (status === false) {
      return error(message);
    }
  };

  useEffect(() => {
    getFollowers();
  }, []);

  const blockFollowers = async (userid, username) => {
    setLoading(true);
    const response = await postRequest(
      `admin-friend/block`,
      {
        user_id: userid,
        username: username,
      },
      process.env.REACT_APP_BASE_URL + "/wayagram-service/graph"
    );
    setLoading(false);

    const { status, message } = response.data;

    if (status === false) {
      return error(message);
    }
    success(message);
    getFollowers();
  };

  const unblockFollowers = async (userid, username) => {
    setLoading(true);
    const response = await postRequest(
      `admin-friend/unblock`,
      {
        user_id: userid,
        username: username,
      },
      process.env.REACT_APP_BASE_URL + "/wayagram-service/graph"
    );
    setLoading(false);

    const { status, message } = response.data;

    if (status === false) {
      return error(message);
    }
    success(message);
    getFollowers();
  };

  const muteFollowers = async (userid, username) => {
    setLoading(true);
    const response = await putRequest(
      `admin-friend/mute-or-unmute-friend`,
      {
        user_id: userid,
        username: username,
      },
      process.env.REACT_APP_BASE_URL + "/wayagram-service/graph"
    );
    setLoading(false);

    const { status, message } = response.data;

    if (status === false) {
      notification.success({
        message: `${muteFollowers ? "Mute" : "Unmute"} successfully`,
        description: message,
      });
      setReload(!reload);
      return error(message);
    }
    success(message);
    getFollowers();
  };

  return (
    <>
      <Modal
        show={handleOpen}
        onHide={handleClose}
        backdrop="none"
        keyboard={false}
        size="lg"
        style={{ marginTop: 50, minWidth: "100%" }}
      >
        <Modal.Header
          style={{
            borderBottom: "none",
            padding: "0 1rem",
            borderTop: "5px solid #FF6634",
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <h2 style={{ textTransform: "capitalize" }}>
            {user.displayName} followers
          </h2>
          <Divider />
          {users?.map((row) => (
            <div
              style={{
                justifyContent: "space-between",
                display: "flex",
                marginTop: "10px",
              }}
            >
              <p style={{ fontWeigth: 600, fontSize: "20px" }}>
                {row.username}
              </p>
              <p>{row.email}</p>
              <p>
                {!row.connection.blocked ? (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontWeigth: 800,
                      padding: "2px",
                      marginRight: "4px",
                    }}
                    onClick={() => blockFollowers(userid, row.username)}
                  >
                    Block
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      fontWeigth: 800,
                      padding: "2px",
                      marginRight: "4px",
                    }}
                    onClick={() => unblockFollowers(userid, row.username)}
                  >
                    UnBlock
                  </Button>
                )}
                {row.connection.muted ? (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "green",
                      color: "white",
                      fontWeigth: 800,
                      padding: "2px",
                    }}
                    onClick={() => muteFollowers(userid, row.username)}
                  >
                    Unmute
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontWeigth: 800,
                      padding: "2px",
                    }}
                    onClick={() => muteFollowers(userid, row.username)}
                  >
                    Mute
                  </Button>
                )}
              </p>
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  );
}
