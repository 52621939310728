import React from 'react';
import { DropDownWrapper } from './style';
import { Avatar } from '@mui/material';

export default function SearchDropdown(props) {
  const { options, handleClick } = props;

  return (
    <DropDownWrapper style={!options?.length ? { display: 'none' } : {}}>
      {options?.length
        ? options?.map((item) => (
            <div
              className='d-flex text-start'
              key={Math.random()}
              style={{
                borderBottom: '1px solid #EDEAEA',
                padding: '10px 20px',
              }}
              onClick={() => handleClick(item)}
            >
              <div className='profile-div'>
                <div className='image-div'>
                  {item?.avatar ? (
                  <img
                    src={item?.avatar}
                    alt='placeholder'
                    style={{ borderRadius: '50%' }}
                  /> 
                  ) : (
                    <Avatar />
                  )}
                </div>
                <div className='text-div flex-col'>
                {/* <span>{`${item.surname} ${item.firstName}`}</span> */}
                <span>{item.displayName}</span>
                <br />
                <span style={{ fontSize: 12 }}>{item.email}</span>
                </div>
              </div>
            </div>
          ))
        : ''}
    </DropDownWrapper>
  );
}
