import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InvitesComponent from './InvitesComponent';
import PromotionsAndAwardComponent from './PromotionsAndAwardComponent';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
  },
}));

const PromotionsComponent = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <div>
        <Tabs value={value} onChange={handleChange} aria-label="Promotions">
          <Tab label="Invites" {...a11yProps(0)} />
          <Tab label="Promotions" {...a11yProps(1)} />
        </Tabs>
      </div>
      <div className={classes.tabContent}>
        <TabPanel value={value} index={0}>
          <InvitesComponent />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <PromotionsAndAwardComponent />
        </TabPanel>
      </div>
    </div>
  );
};

export default PromotionsComponent;
