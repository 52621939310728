import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getUserData } from '../../../services/helpers';
import Box from '@mui/material/Box';


const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const pageType = [
  {
    value: '',
    label: '--',
  },
  {
    value: "user",
    label: 'user',
  },
  {
    value: "group",
    label: 'group',
  },
  {
    value: "page",
    label: 'page',
  },
  
];
const Reposted = [
  {
    value: '',
    label: '--',
  },
  {
    value: true,
    label: 'terms',
  },
  
];
const Polling = [
  {
    value: '',
    label: '--',
  },
  
  {
    value: false,
    label: 'Not poll',
  },
];
const status = [
  {
    value: '',
    label: '--',
  },
  {
    value: true,
    label: 'Yes',
  },
  
];

const UserFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();

  

  return (
    <>
      <Formik
        initialValues={{
          type: '',
          terms: '',
          description: '',
          options: '',
          forceTerms: '',
          profile_id: data.ProfileId,
          isPoll: ''
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().max(255).required('name is required'),
          forceTerms: Yup.boolean().required('options  is required'),
          description: Yup.string().max(255).required('description is required'),
          isPoll: Yup.boolean().required('Poll is required'),
        })}
        onSubmit={async (values) => {
          
          if(values.options !== ''){
            values.options = values?.options?.split(',')
          }
         
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
                <p style={{marginLeft: '330px', marginBottom: '30px', fontWeight: 800}}> Create Post</p>
              <div>
                <Grid container spacing={3}>
                 
                  
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Type"
                      name="type"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.type && errors.type)}
                      helperText={touched.type && errors.type}
                      value={values.type}
                      variant="outlined"
                      
                    >
                     {pageType.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Options"
                      name="options"
                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.options && errors.options)}
                      helperText={touched.options && errors.options}
                      value={values.options}
                      variant="outlined"
                      
                    >
                     
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Terms"
                      name="terms"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.terms && errors.terms)}
                      helperText={touched.terms && errors.terms}
                      value={values.terms}
                      variant="outlined"
                      
                    >
                      {status.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                     
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Force Terms"
                      name="forceTerms"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.forceTerms && errors.forceTerms)}
                      helperText={touched.forceTerms && errors.forceTerms}
                      value={values.forceTerms}
                      variant="outlined"
                      
                    >
                     {Reposted.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Poll"
                      name="isPoll"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.isPoll && errors.isPoll)}
                      helperText={touched.isPoll && errors.isPoll}
                      value={values.isPoll}
                      variant="outlined"
                      
                    >
                     {Polling.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  
                  
                  
                </Grid>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.createButton}
                    variant="contained"
                    type="submit"
                    disablexd={isSubmitting}
                  >
                    create Post
                  </Button>
                </Box>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
