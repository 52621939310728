import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';

const groupTypes = [
  {
    value: true,
    label: 'true',
  },
  {
    value: false,
    label: 'false',
  },
];

const isMuted = [
  {
    value: false,
    label: 'false',
  },
  {
    value: true,
    label: 'true',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const UserFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();
  
  
  const title = data ? data.userName : '';
  const content = data ? data.content: '';
  const isActive = data ? data.isActive : false;
  const isExpire = data ? data.isExpire  : false;
  

  return (
    <>
      <Formik
        initialValues={{
          title,
          content,
          isActive,
          isExpire,
         
        }}
        validationSchema={Yup.object().shape({
          title: Yup.string().max(255).required('First name is required'),
          content: Yup.string().max(255).required('Last name is required'),
          isActive: Yup.boolean().required('Group type is required'),
        })}
        onSubmit={async (values) => {
          
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
              <p style={{fontWeight: 800, marginLeft: '100px', fontSize: '20px'}}>{title} Moment</p>
              <div>
                <img style={{width: "100%"}} src={data.image} alt='moment'/>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
