import React, { useEffect, useState } from "react";
import {
  getRequest,
  deleteRequest,
  postRequest,
  patchRequest,
  putRequestNoSSoId,
} from "../../../services/request";
import CsvDownload from "react-json-to-csv";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import config from "../../../services/config";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Row,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { adminColumn } from "../../../utils/dummyData";
import SearchBar from "../../../components/SearchBar";
import { getUsers } from "../../../services/helpers";
import StatCard from "../../../components/StatCard";
import moment from "moment";
import color from "../../../theme/color";
import { MdSettings } from "react-icons/md";
import CustomButton from "../../../components/CustomButton";
import { FiEdit3 } from "react-icons/fi";
import { createUseStyles, useTheme } from 'react-jss';
import { useNavigate } from "react-router-dom";
import slugs from "../../../resources/slugs";
import { getUserData } from "../../../services/helpers";


const useStyles = createUseStyles((theme) => ({
  // button: {
  //   padding: "15px",
  //   backgroundColor: theme.color.brightOrange,
  //   color: theme.color.white,
  //   fontSize: "14px",
  //   borderRadius: "5px",
  //   marginLeft:'63rem',
  // },
}));




const ManageAdmin = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [filterType, setFilterType] = useState("USERNAME");
  const [limits, setLimits] = useState(20);
  const [options, setOptions] = useState([]);
  const [pollCat, setPollCat] = useState("");
  const [pollId, setPollId] = useState("");
  const [value, setValue] = useState("a");
  const [query, setQuery] = useState("");
  const [poll, setPoll] = useState({})
  const [users, setUsers] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [mode, setMode] = useState("");
  const { confirm } = Modal;
  // const [form] = Form.useForm();
  // const { RangePicker } = DatePicker;
  // const [createOptionModal, setCreateOptionModal] = useState(false);
  // const [date, setDate] = useState({});
  // const [viewModal, setViewModal] = useState(false);
  // const [viewData, setViewData] = useState({});
  // const [editPollOPtions, setEditPollOPtions] = useState(false);
  const [roles, setRoles] = useState([]);
  const [reload, setReload] = useState(false);
  const [roleId, setRoleId] = useState('');
  const [changePostModal, setchangePostModal] = useState(false);
  const [change2fa, setChange2fa] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [twoFaData, set2FaData] = useState();

  const userData = getUserData();

  const selectOptions = [
    {
      value: "USERNAME",
      label: "Super Admin",
    },
    {
      value: "INITIATOR",
      label: "Admin",
    },
    {
      value: "REVIEWER",
      label: "Reviewer",
    },
  ];

  let navigate = useNavigate();

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) {
      setQuery(value);
    }
  };

  const handleSubmitPost = async (value) => {
    setLoading(true);
    const dob = moment(value.dateOfBirth).format('YYYY-MM-DD')
    const newForm = {
      ...value, 
      dateOfBirth: dob,
      role_id: roleId,
      isApproved:true
    }
    const url = `users/admin/create`;
    const res = await postRequest(url, newForm, config.roleUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      //await addPollOptions(data.id);
      notification.success({ description: message, message: "success!" });
      setcreatePostModal(false);
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };

  const getData = async (q) => {
    let response;
    if (q) {
      response = await getRequest(
        `admin-get-all-admins?page=${pages}&limit=${limits}`,
        config.roleUrl
      );
    } else {
      setLoading(true);
      response = await getRequest(
        `admin-get-all-admins?page=${pages}&limit=${limits}`,
        config.roleUrl
      );
    }
    const { data, metadata } = response?.data?.data || {};
    setTotal(metadata?.total || 0);
    setFilteredData(data);
    setData(data);
    setLoading(false);
  };

  const getRoles = async () => {
    setLoading(true);
    const response = await getRequest(`/admin-get-roles?limit=1000`, config.profileUrl,true);

    setLoading(false);
    if (response.data.status == false) {
      return;
    }
    console.log(response,response?.data)
    const data = response?.data?.data || [];
    setRoles(data.data);
  };
  const get2faStatus = async (id) => {
    const response = await getRequest(`user/status-check-2faserial eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImY4Njg0OGU5LWIxYzEtNDY5NC05ZWUwLTc2ZTNmNDZmZjM2YiIsImZpcnN0TmFtZSI6IkNoaW5vbnNvIiwibGFzdE5hbWUiOiJFa3dvIiwidHlwZSI6IlBFUlNPTkFMIiwiU1NPX0lEIjoiNWY3OThhNWYtZDI3MC00NDhmLWJkMWEtN2NjNDg1MDZiZGVjIiwiZW1haWwiOiJub25pZWt3b0BnbWFpbC5jb20iLCJpYXQiOjE3MjQ2NjI0NjMsImV4cCI6MTcyNTI2NzI2M30.bqwyk2cUdcfItNyMAdj-AjU5sVs-JZjS0EIl3reAbZQ/${id}`, config.roleUrl, true);
    if (response.data.statusCode == 200) {
      set2FaData(response.data.data.tfa);
    } else {
      set2FaData(false);
    }
  }
  const handleToggle2fa = async () => {
    setLoading(true);

    const url = `user/change-2fa-status`;
    const res = await putRequestNoSSoId(url, {
      user_id:String(selectedUser.SSO_ID),
      status: !twoFaData

    }, config.roleUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      //await addPollOptions(data.id);
      setChange2fa(false);
      set2FaData(prev=>!twoFaData)
      notification.success({ description: message, message: "success!" });
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };
  const getUserRole = async () => {
    const response = await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);
    if (response.data.status) {
      const id = response?.data?.data?.RoleId
      setRoleId(id);
    }
  }

  // const getOverview = async () => {
  //   const response = await getRequest(
  //     "admin-get-poll-overview",
  //     config.postUrl
  //   );
  //   const { data } = response?.data || {};
  //   setMetaData(data);
  // };
  
  const Delete = async (id) => {
    setLoading(true)
    const response = await deleteRequest(
     `admin-delete-user-data?user_id=${id.SSO_ID}`,
      config.roleUrl
    );
    setLoading(false)
    const { data } = response || {};
    if (data?.status === true) {
      notification.success({
        message: "deleted",
        description:data?.message
      })
      setReload(!reload);
    } else {
      notification.warning({
        message: "error",
        description:data?.message
      })
    }
  };

  const handleConfirm = (id) =>{
    confirm({
      title: `Do you want to delete these role?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        Delete(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const handleManage = async (e, id) => {
    console.log(e)
    setLoading(true);
    const res = await patchRequest(
      `admin-change-admin-role`,
      { role_id: e.role_id, user_id: poll.id },
      config.roleUrl
    );
    const { status, message } = res?.data || {};
    if (status) {
      notification.success({ description: message, message: "success!" });
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    getData();
  };

  // const handleConfirm = (status, id, ProfileId) => {
  //   confirm({
  //     title: `Do you want to ${status} this poll?`,
  //     icon: <ExclamationCircleOutlined />,
  //     content: "Click OK to Continue",
  //     onOk() {
  //       handleManage(status, id, ProfileId);
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  useEffect(() => {
    getUsers(value, setUsers);
  }, [value]);

  useEffect(() => {
    getData();
   // getOverview();
  }, [pages]);

  useEffect(() => {
    getData(query);
  }, [query]);

  useEffect(() => {
    getRoles();
    getUserRole();
  }, []);


  const Conversion = () => {
    return (
      // <CsvDownload
      //   data={data}
      //   filename="donations.csv"
      //   style={{
      //     boxShadow: "inset 0px 1px 0px 0px #e184f3",
      //     backgroundColor: "#27AE60",
      //     borderRadius: "6px",
      //     display: "inline-block",
      //     cursor: "pointer",
      //     color: "#ffffff",
      //     fontSize: "15px",
      //     height: "40px",
      //     fontWeight: "bold",
      //     padding: "6px 24px",
      //     textDecoration: "none",
      //   }}
      // >
      //  <MdSettings size={25}/> Manage Admin Roles
      // </CsvDownload>
      <Row horizontal="space-between">
        <CustomButton content="" 
          className={classes.button} 
          onClick={()=> navigate(slugs.roles_and_access)}
        >
          <MdSettings size={25}/>
          <span>Manage Admin Roles</span>
        </CustomButton >
      </Row>
    );
  };
  
  const handleModals = (type, value) => {
    if (type === "newPost") {
      setcreatePostModal(true);
      setOptions([]);
      setMode("create");
    }

    if (type === "changePost") {
      console.log(value);
      setchangePostModal(true);
      setMode("change");
      setPollId(value.id);
      setPoll(value);
      setOptions(value.PollOptions);
      setPollCat(value?.pollCategory)
    }
    if (type === 'change2FA') {
      get2faStatus(value.SSO_ID);
      setChange2fa(true);
      setSelectedUser(value);
    }
    // if (type === "view") {
    //   console.log(value);
    //   setViewModal(true);
    //   setViewData(value);
    // }
  };

  // const handleSubmitUpdate = async (value) => {
  //   setLoading(true);
  //   const newForm = new FormData();
  //   const startDate = new Date(value.startDate);
  //   const endDate = new Date(value.endDate);
  //   newForm.append("poll_id", value.id);
  //   newForm.append("profile_id", value.ProfileId);
  //   newForm.append("pollCategory", value.pollCategory);
  //   newForm.append("description", value.description);
  //   newForm.append("voteLimit", value.voteLimit);
  //   newForm.append("amount", value?.amount || 0);
  //   newForm.append("showResult", value.showResult);
  //   newForm.append("isPaid", value.isPaid);
  //   newForm.append("mentions", "");
  //   newForm.append("hashtags", "");
  //   newForm.append("startDate", moment(startDate).format('MM/DD/YYYY'));
  //   newForm.append("endDate", moment(endDate).format('MM/DD/YYYY'));
  //   newForm.append("startTime", startDate.getTime());
  //   newForm.append("endTime", endDate.getTime());
  //   newForm.append("millisecUpdatedAt", "0");
  //   const url = `admin-update-poll-post`;
  //   const res = await patchRequest(url, newForm, config.postUrl);
  //   const { status, data, message } = res?.data || {};
  //   if (status) {
  //     await addPollOptions(data.id);
  //     notification.success({ description: message, message: "success!" });
  //     setcreatePostModal(false);
  //   } else {
  //     notification.error({ description: message, message: "Oops!" });
  //   }
  //   setLoading(false);
  // };

  const column = adminColumn(handleModals, handleConfirm);
  return (
    <div className="w-100">
      <SearchBar
        page="admins"
        total={total}
        handleModals={handleModals}
        Conversion={Conversion }
        handleChange={handleChange}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        hideSelect={false}
        placeholder="Search by username"
      />
      <LoadingComponent loading={loading} zIndex={10000} />
      <Table
        scroll={{ x: 1500 }}
        columns={column}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
      <Pagination
        total={total}
        responsive={true}
        defaultCurrent={1}
        onChange={(e) => setPages(e)}
        defaultPageSize={limits}
        showSizeChanger={false}
      />
      <Modal
        visible={createPostModal}
        onCancel={() => setcreatePostModal(false)}
        footer={false}
        title={<p className="text-center text-capitalize">{mode} New Admin User</p>}
      >
        <Form layout="vertical" onFinish={handleSubmitPost}>
          <Form.Item label="Firstname" name="firstName">
            <Input />
          </Form.Item>
          <Form.Item label="Lastname" name="lastName">
            <Input />
          </Form.Item>
          <Form.Item label="MiddleName" name="middleName">
            <Input />
          </Form.Item>
         
          <Form.Item label="Email Address" name="email">
            <Input />
          </Form.Item>
          <Form.Item label="User Location" name="userLocation">
            <Input />
          </Form.Item>
          <Form.Item label="Reference" name="referenceCode">
            <Input />
          </Form.Item>
          
          <Form.Item label="Phone Number" name="phoneNumber">
            <Input />
          </Form.Item>
          <Form.Item label=" Create Password" name="password">
            <Input />
          </Form.Item>
          <Form.Item label="Gender" name="gender">
            <Select
              placeholder="Gender"
              className="w-100"
            >
              <Select.Option value="MALE">Male</Select.Option>
              <Select.Option value="FEMALE">Female</Select.Option>
              <Select.Option value="OTHERS">Others</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Roles" name="role_id">
            <Select
              placeholder="Admin User"
              className="w-100"
            >
              {roles?.map((role,index) => <Select.Option key={index} value={role.id}>{role.userRole}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item label="Date of Birth" name="dateOfBirth">
            <DatePicker />
          </Form.Item>
          
          <Form.Item className="text-center mt-4">
            <Space>
              <Button
                size="large"
                htmlType="submit"
                type="ghost"
                className="rounded px-5"
              >
                Create New Admin
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
       visible={changePostModal}
        onCancel={() => setchangePostModal(false)}
        footer={false}
        title={<h2 className="text-center text-capitalize"> Change Role</h2>}
      >
        <Form onFinish={handleManage}>
          
          <p>Change {poll?.firstName} Role</p>
          <Form.Item label="change role" name="role_id">
            <Select
              placeholder="User"
              className="w-100"
            >
              {roles?.map((role,index) => <Select.Option key={index} value={role.id}>{role.userRole}</Select.Option>)}
            </Select>
          </Form.Item>
          <Form.Item className="text-center mt-4">
            <Space>
              <Button
                size="large"
                htmlType="submit"
                type="ghost"
                className="rounded px-5"
              >
                Save Changes
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
       visible={change2fa}
        onCancel={() => setChange2fa(false)}
        footer={false}
        title={<h2 className="text-center text-capitalize"> Change 2FA</h2>}
      >
        <div>
          
          <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="mx-3">Change {selectedUser.email} 2FA status</p>
          <Switch
            checked={twoFaData}
            onClick={handleToggle2fa}
          />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ManageAdmin;
