import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { getRequest, postRequestRole } from '../../services/request';
import { success, error } from '../../services/swal-mixin';
import SLUG from '../../resources/slugs'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '20px 0',
    // display: 'flex',
    justifyContent: 'space-around',
    marginRight: 100,
  },

  link: {
    marginLeft: 100,
    marginBottom: '0.5rem',
  },
  textme: {
    minWidth: '100%',
  },
  textme2: {
    minWidth: '250%',
  },
  btnp3: {
    marginLeft: 320,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

const UserComponent = (props) => {
  const classes = useStyles();
  
  const push = useNavigate ()
  const { showWaya,  handleOpenShow } = props;
  const state = {
    otp: '',
  };
  const [value, setValue] = React.useState(state);

  const handleView = () => {
    handleOpenShow();
  };
  const handleClose = () => {
    // handleCloseShow();
    push(SLUG.dashboard)
  };
  const handleCloseWaya = () => {
    
    handleOpenShow()
  };

  const handleChange = (event, newValue) => {
    const { value, name } = event.target;
    setValue((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  async function getOtpPhone() {
    const response = await getRequest(
      `api/v1/admin/authenticate-waya/otp-send`
    );
    
    const { message, data, status } = response.data;
    if (status === false) {
      return error(message);
    }

    success(data);
  }

  async function validateOtp() {
    if(value.otp === ""){
      return error("please enter OTP sent")
    }
    const response = await postRequestRole(
      `api/v1/admin/authenticate-waya/otp-verify/${value.otp}`,
      'http://68.183.60.114:8059'
    );
   
    push(SLUG.waya_official)
    const { message, status, data } = response.data;
    if (status === false || data === '') {
      return error(`${message}, Please request for OTP`);
    }
    
    handleCloseWaya()
   
    success(message);
   

  }

 

  return (
    <div>
      <Modal
        show={handleView}
        onHide={handleClose}
        backdrop="static"
        style={{
          backdropFilter: 'blur(3px)',
          backgroundColor: 'rgba(0,0,30,0.4)',
        }}
        keyboard={false}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.root}>
            <div className={classes.tabContent}>
              <div className={classes.name2}>
                <Typography variant="h6">
                  Verify with OTP you are authorised
                </Typography>
              </div>
            </div>
            <div
              style={{ marginLeft: '100px', justifyContent: 'space-between' }}
            >
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => getOtpPhone()}
                >
                  Send OTP 
                </Button>
              </>
            </div>
            <div className={classes.tabContent2}>
              <div className={classes.link}>
                <TextField
                  className={classes.textme}
                  id="outlined-select-currency-native"
                  label="OTP"
                  placeholder="please enter otp"
                  name="otp"
                  type="password"
                  value={value.otp}
                  onChange={handleChange}
                  SelectProps={{
                    native: true,
                  }}
                  variant="outlined"
                ></TextField>
              </div>
            </div>
            <Button
              variant="contained"
              className={classes.btnp3}
              onClick={() => validateOtp()}
            >
              Validate OTP
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserComponent;
