import React, { useState, useEffect } from "react";
import config from "../../services/config";
import { Modal, Form, Button, notification, Upload, Input, Table, Space } from "antd";
import { getRequest, patchRequest, postRequest, putRequest } from "../../services/request";
import { hideLoader, showLoader } from "../../utils/loader";

const ContestSettings = ({hideModal, showModal, record}) => {
  const [loading, setLoading] = useState(false);
  const [createHostModal, setSShowUpdateSettings] = useState(false);
  const [reload, setReload] = useState(false);
  const [settingsData, setSettingsData] = useState([]);
  const [selectedData, setSelectedData] = useState({});
  const [isNew, setIsNew] = useState(true);

  const getSettings = async () => {
    let response;
      response = await getRequest(
        `users/settings/?profileId=${record?.profileId}`,
        config.contestUrl,
        true
      );
      const { data, status } = response?.data || [];
      if (status) {
        setSelectedData(data[0]);
        setIsNew(!data?.length > 0);
      }    
    hideLoader();
  };

  const handleUpdateSettings = async () => {
    setLoading(true);
    showLoader();
    let postData = {
        profileId: selectedData?.profileId,
        id: selectedData?.id,
        uploadLimit: selectedData?.uploadLimit,
        voteLimit: selectedData?.voteLimit
      }
    const url = 'users/update/settings'
    const res = await patchRequest(url, postData, config.contestUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      hideLoader();
      hideModal(false);
      notification.success({ description: message, message: "success!" });
      setSShowUpdateSettings(false);
      setReload(!reload);
    } else {
      hideLoader();
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };
  const handleAddSettings = async () => {
    showLoader();
    setLoading(true);
    let postData = {
        profileId: record?.profileId,
        uploadLimit: selectedData?.uploadLimit,
        voteLimit: selectedData?.voteLimit
      }
    const url = 'users/create/settings'
    const res = await postRequest(url, postData, config.contestUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      hideLoader();
      notification.success({ description: message, message: "success!" });
      setSShowUpdateSettings(false);
      hideModal(false);
      setReload(!reload);
    } else {
      hideLoader();
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'Vote Limit',
      dataIndex: 'voteLimit',
      key: 'voteLimit',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Upload Limit',
      dataIndex: 'uploadLimit',
      key: 'uploadLimit',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button
            size="large"
            loading={loading}
            htmlType="submit"
            type="primary"
            className="rounded px-5"
            onClick={() => {
                setSelectedData(record);
                setSShowUpdateSettings(true)
            }}
        >
            Update
        </Button>
      ),
    },
  ];

  useEffect(() => {
    showLoader();
    getSettings();
  }, []);
  return (
    <div className="w-100">
      {/* <Button
        size="large"
        loading={loading}
        htmlType="submit"
        type="primary"
        className="rounded px-5"
        onClick={() => setSShowUpdateSettings(true)}
    >
        Create Settings
    </Button> */}
    <Modal
        visible={showModal}
        onCancel={() => {
          hideModal(false);
        }}
        footer={false}
        title={<p className="text-center"> Settings </p>}
      >
        <div className="my-2">
        <label>Vote Limit</label>

          <input
            placeholder="Vote Limit"
            type='text'
            class='form-control'
            id='validationCustom01'
            value={selectedData?.voteLimit}
            required
            onChange={(e) => setSelectedData({...selectedData, voteLimit:e?.target.value})}
          />
        </div>
        <div className="my-2">
            <label>Upload Limit</label>
          <input
            placeholder="Upload Limit"
            type='text'
            class='form-control'
            id='validationCustom01'
            value={selectedData?.uploadLimit}
            required
            onChange={(e) => setSelectedData({...selectedData, uploadLimit:e?.target.value})}
          />
        </div>
        <div className="text-center">
        <Button
            size="large"
            loading={loading}
            htmlType="submit"
            type="primary"
            className="rounded px-5"
            onClick={isNew ? handleAddSettings :  handleUpdateSettings}
        >
            Save
        </Button>
        </div>
    </Modal>

    </div>
  );
};

export default ContestSettings;
