import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box } from "@mui/material";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import TablePagination from "@mui/material/TablePagination";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { getRequest, deleteRequestInt } from "../../../services/request";
import { success, error } from "../../../services/swal-mixin";
import moment from "moment";
import SLUGS from "../../../resources/slugs";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import { Link } from "react-router-dom";
import CreateWaya from "./createBulk";
import { Row } from "simple-flexbox";
import Typography from "@mui/material/Typography";
import AddIcon from "@material-ui/icons/Add";
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@mui/material/Fade";
import SingleUser from "./singleUserSimilation";
import MiniCardComponent from "../../../components/cards/MiniCardComponents";
import { CsvConversion } from "../../../components/CsvDownload";
import Avatar from "./avatar";
import Image from "./image";
import config from "../../../services/config";
import { Card } from "antd";
import CsvDownload from "react-json-to-csv";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  miniCardContainer: {
    backgroundColor: "#fbfbfb",
  },
});

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme?.palette?.background?.dark,

    width: "100%",
  },
  tool: {
    paddingLeft: "0px",
    display: "flex",
    borderRadius: "250px",
    justifyContent: "space-between",
  },
  text: {
    width: "150%",

    fontWeight: "800px",
    borderRadius: "1px red",

    "& .MuiFormLabel-root": {
      border: "none",
      color: "black",
      marginLeft: 5,
    },
  },

  textfield: {
    paddingTop: "5px",
  },
  typography: {
    padding: 10,
    cursor: "pointer",
  },
}));

export default function WayaTable() {
  const classes = useStyle();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [metaData, setMetaData] = useState(0);
  const [values, setValues] = useState("");

  const [value, setValue] = useState([]);
  const [, setFriend] = useState(false);
  const [singleUser, setSingleUser] = useState(false);
  const [val, setVal] = useState([]);
  const [, setVals] = useState([]);
  const [simulated, setSimulated] = useState(false);
  const [avatar, setAvatar] = useState(false);
  const [image, setImage] = useState(false);
  const [mode, setMode] = useState("create");
  const handleChange = (e) => {
    setValues(e.target.value);
  };
  const handlCloseSingleUser = (key) => {
    setSingleUser(false);
    setMode("");
  };
  const handleAvatarClose = (key) => {
    setAvatar(false);
  };
  const handleAvatarOpen = (key) => {
    const val = users.find((user) => user.UserId === key);
    setVal(val);
    setAvatar(true);
  };
  const handleImageClose = (key) => {
    setImage(false);
  };
  const handleImageOpen = (key) => {
    const val = users.find((user) => user.UserId === key);
    setVal(val);
    setImage(true);
  };
  const handleOpenSingleUser = (key) => {
    setSingleUser(true);
    setMode(key);
  };
  const handleOpenBulkWaya = (key) => {
    setSimulated(true);
    setMode(key);
  };
  const handleCloseBulkWaya = (key) => {
    setSimulated(false);
    setMode("");
  };

  const handleOpenFollow = (key) => {
    const val = users.find((user) => user.UserId === key);
    setVals(val);
    setFriend(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getWayaUsers = async () => {
    // setLoading(true);
    const response = await getRequest(
      `admin-profile/all-simulated-users?page=${page + 1}`,
      config.roleUrl
    );
    setLoading(false);
    if (!response || response.error !== undefined) {
      return;
    }

    const { data } = response.data;
    setUsers(data?.profiles || []);
    setMetaData(data?.totalProfiles);
  };

  const deleteWayaUsers = async (id) => {
    console.log(id);
    const data = {
      profileIds: [...id],
    };
    console.log(data);
    setLoading(true);
    const response = await deleteRequestInt(
      "admin-profile/delete-simulated-wayagram-accounts-in-bulk",
      data,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/role-access"
    );
    setLoading(false);

    if (response.status === 401) {
      return error(response.data.message);
    }

    success(response.data.message);
    getWayaUsers();
  };

  useEffect(() => {
    getWayaUsers();
  }, [page]);

  const filteredPersons = users?.filter((person) => {
    return person?.displayName?.toLowerCase().includes(values?.toLowerCase());
  });

  const RenderStat = () => {
    return (
      <div className="row my-3">
        <div className="col-md-4">
          <Card className="shadow">
            <p className="fs-5">Total Number of Simulated Users</p>
            <span className="fs-3 fw-bold">{metaData || 0}</span>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <>
      <Link to={SLUGS.waya_simu}></Link>
      <div className={classes.root}>
        <LoadingComponent loading={loading} />
        <div className={classes.tool}>
          <div className={classes.textfield}>
            <TextField
              className={classes.text}
              id="outlined-basic"
              label="Search Users by Name"
              variant="outlined"
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
              }}
              onChange={handleChange}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "20px",
            }}
          >
            <CsvDownload
              data={users}
              filename="simulated_User.csv"
              style={{
                boxShadow: "inset 0px 1px 0px 0px #e184f3",
                backgroundColor: "#27AE60",
                borderRadius: "5px",
                display: "inline-block",
                cursor: "pointer",
                color: "#ffffff",
                fontSize: "18px",
                fontWeight: "bold",
                padding: "6px 24px",
                textDecoration: "none",
                marginRight: "10px",
                marginTop: "17px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Export Users
            </CsvDownload>
            <div stle={{ display: "flex" }}>
              <PopupState variant="popper" popupId="demo-popup-popper">
                {(popupState) => (
                  <div>
                    <Button
                      style={{
                        marginTop: "15px",
                        backgroundColor: "black",
                        color: "white",
                        fontWeight: 600,
                        marginRight: "10px",
                      }}
                      variant="contained"
                      {...bindToggle(popupState)}
                    >
                      Delete simulated users
                    </Button>
                    <Popper {...bindPopper(popupState)} transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            {/* <Typography
                              className={classes.typography}
                              onClick={() => handleOpenSingleUser('delete')}
                            >
                              Delete by date uploaded
                            </Typography> */}
                            <Typography
                              className={classes.typography}
                              onClick={() => handleOpenBulkWaya("delete")}
                            >
                              Upload Excel Sheet to delete
                            </Typography>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </div>
            <div stle={{ display: "flex" }}>
              <PopupState variant="popper" popupId="demo-popup-popper">
                {(popupState) => (
                  <div>
                    <Button
                      style={{
                        marginTop: "15px",
                        backgroundColor: "#FF4B01",
                        color: "white",
                        fontWeight: 600,
                      }}
                      variant="contained"
                      {...bindToggle(popupState)}
                    >
                      <AddIcon />
                      create wayagram users
                    </Button>
                    <Popper {...bindPopper(popupState)} transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            {/* <Typography
                              className={classes.typography}
                              onClick={() => handleOpenBulkWaya()}
                            >
                              Upload Excel to create User
                            </Typography> */}
                            <Typography
                              className={classes.typography}
                              onClick={() => handleOpenSingleUser()}
                            >
                              Specify No of users
                            </Typography>
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </div>
          </div>
        </div>
      </div>
      {simulated ? (
        <CreateWaya
          userId={value}
          wayaShow={simulated}
          handleCloseBulkWaya={handleCloseBulkWaya}
          handleOpenBulkWaya={handleOpenBulkWaya}
          mode={mode}
          deleteUsers={deleteWayaUsers}
        />
      ) : null}
      {avatar ? (
        <Avatar
          data={val}
          show={avatar}
          handleAvatarClose={handleAvatarClose}
        />
      ) : null}
      {image ? (
        <Image show={image} data={val} handleImageClose={handleImageClose} />
      ) : null}
      {singleUser ? (
        <SingleUser
          getWayaUsers={getWayaUsers}
          handlCloseSingleUser={handlCloseSingleUser}
          handlOpenSingleUser={handleOpenSingleUser}
          mode={mode}
          show={singleUser}
        />
      ) : null}
      {/* <Box mt={3} mb={3} ml={2}> */}
      <RenderStat />
      {/* </Box> */}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: "#fbe2dd", color: "#E7472C" }}>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
              >
                Display Name
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
                align="right"
              >
                username
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
                align="right"
              >
                Bio
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
                align="right"
              >
                Avatar
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
                align="right"
              >
                Cover Image
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
                align="right"
              >
                Date
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
                align="right"
              >
                Action
              </TableCell>
              <TableCell
                style={{ color: "#ed462f", fontWeight: 800, fontSize: "16px" }}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPersons
              // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.displayName}
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    {row.username}
                  </TableCell>
                  <TableCell align="right">{row.bio}</TableCell>
                  <TableCell align="right">
                    <Button
                      style={{
                        marginLeft: "1rem",
                        fontSize: "7px",
                        backgroundColor: "#fdf1ec",
                        color: "#ed462f",
                        border: "1px solid #ed462f",
                      }}
                      color="primary"
                      onClick={() => handleAvatarOpen(row.UserId)}
                    >
                      View
                    </Button>
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      style={{
                        marginLeft: "1rem",
                        fontSize: "7px",
                        backgroundColor: "#fdf1ec",
                        color: "#ed462f",
                        border: "1px solid #ed462f",
                      }}
                      color="primary"
                      onClick={() => handleImageOpen(row.UserId)}
                    >
                      View
                    </Button>
                  </TableCell>

                  <TableCell align="right">
                    {moment(row.createdAt).format("MMM, Do YYYY")}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ color: "red", cursor: "pointer" }}
                  >
                    <DeleteIcon onClick={() => deleteWayaUsers([row.id])} />
                  </TableCell>
                </TableRow>
              ))}
            {users.length === 0 && (
              <TableRow>
                <Box display="flex" justifyContent="center" m={5}>
                  No data found.
                </Box>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          style={{ backgroundColor: "#fbe2dd", color: "#E7472C" }}
          rowsPerPageOptions={[5, 10, 15, 20, 25]}
          component="div"
          count={metaData}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}
