import React, { useState } from "react";
import { any, arrayOf, func, string } from "prop-types";
import { Column, Row } from "simple-flexbox";
import { createUseStyles, useTheme } from "react-jss";
import CollapsibleContent from "../collapsible/CollapsibleContent";
import { useSidebar } from "../../hooks/useSidebar";
import { lightenColor } from "../../services/helpers";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const useStyles = createUseStyles({
  activeContainer: {
    backgroundColor: ({ theme }) => theme.color.wayaLightPink,
  },
  container: {
    display: "flex",
    height: 56,
    cursor: "pointer",
    backgroundColor: ({ theme, isActive }) => isActive ? theme.color.brightOrange :theme.color.darkestGrey,
    "&:hover": {
      backgroundColor: ({ theme, isActive }) => isActive ? theme.color.brightOrange : theme.color.lightGray,
    },
    paddingLeft:  36,
    paddingRight: 36,
    transition: "all 0.2s ease-in-out",
  },
  bar: {
    color: ({ theme, level }) => (level > 1 ? theme.color.wayaPink : "none"),
    backgroundColor: ({ theme }) => theme.color.brightOrange,
  },
  title: {
    fontSize: 18,
    lineHeight: "21.6px",
    letterSpacing: "0.2px",
    fontWeight: "bold",
    color: ({ theme, isActive }) => theme.color.white,
  },
});

const CollapsibleCard = ({ children, id, level = 1, title }) => {
  const [isActive, setIsActive] = useState(false);
  const theme = useTheme();

  const classes = useStyles({ theme, level, isActive });
  const classNameContainer = [
    classes.container,
    isActive && classes.activeContainer,
  ].join(" ");
  const iconColor = isActive ? "#ff4400" : "#FFCFBB";

  function onItemClicked(e) {
    e.stopPropagation();
    setIsActive(!isActive);
  }

  return (
    <Column
      key={id}
      style={{
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      <Row
        vertical="center"
        justifyContent="space-between"
        onClick={onItemClicked}
        className={classNameContainer}
      >
        <span className={classes.title}>{title}</span>
        {isActive ? (
          <AiOutlineMinus color="white" size={24} />
        ) : (
          <AiOutlinePlus color="white" size={24} />
        )}
      </Row>
      {children && (
        <CollapsibleContent expanded={isActive} style={{maxHeight: isActive ? '100%' : 0 }}>
          {children}
        </CollapsibleContent>
      )}
    </Column>
  );
};

CollapsibleCard.defaultProps = {};

CollapsibleCard.propTypes = {
  children: any,
  id: string,
  onClick: func,
  title: string,
};

export default CollapsibleCard;
