import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';

const groupTypes = [
  {
    value: true,
    label: 'true',
  },
  {
    value: false,
    label: 'false',
  },
];

const isMuted = [
  {
    value: false,
    label: 'false',
  },
  {
    value: true,
    label: 'true',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const UserFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();
  
  
  const image = data ? data.PostImages.imageURL : '';
  const title = data ? data.Profile.displayName : '';
  
  return (
    <>
      <Formik
        initialValues={{
        image,
        title,
         
        }}
        
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
              <p style={{fontWeight: 800, marginLeft: '130px', fontSize: '20px'}}>{title} Post</p>
              <div>
                <img style={{width: "100%"}} src={data.PostImages[0].imageURL} alt='moment'/>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
