import React from "react";
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import ActionDropdown from "../dropdown/ActionDropdown";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  incomeChat: {
    display: "flex",
    marginBottom: '10px'
  },
  message: {
    display: "flex",
  },
  chatContent: {
    // paddingTop: "5px",
    paddingBottom: "1px",
    paddingLeft: "6px",
    paddingRight: "10px",
    borderRadius: "10px 10px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    maxWidth: "300px",
  },
  p: {
    fontSize: "12px",
  },
}));

const IncomingChat = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.incomeChat}>
      <Avatar alt="Remy Sharp" src="https://joeschmoe.io/api/v1/random" />
      <div className={classes.chatContent}>
      <div className={classes.message}>
          <p>Good Morning xcxcxcxcx xc x cx xcx cx cxcxcx x cxcxcxcx xcxc x</p> 
          <ActionDropdown />
        </div>
        <p className={classes.p}>{moment().format("hh:mm A")}</p>
      </div>
    </div>
  );
};

export default IncomingChat;
