import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { getRequestReset, getRequestResetAuth } from '../../../services/request';
import { success, error } from '../../../services/swal-mixin';
import PasswordComponent from './PaswordModal'
import PinComponent from './PinReset'

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'center',
    // marginRight: 180,
  },
  tabContent3: {
    // padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: 100,
    // marginLeft: 100,
  },

  link: {
    marginLeft: 240,
    marginBottom: 20,
  },
  textme: {
    minWidth: '70%',
  },
  textme2: {
    minWidth: '50%',
  },
  btnp3: {
    marginLeft: 10,
    marginBottom: 0,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 20,
    fontSize: "12px",
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));



const ResetComponent = (props) => {
  const classes = useStyles();
  console.log(props);
  const { show, data,  handleViewCloseReset } = props;
  const [password, setPassword] = React.useState(false)
  const [pin, setPin] = React.useState(false)
  const state = {
    email: data.email,
    phoneNumber: data.phoneNumber
  };
  
  const handleViewClose = ()=>{
    handleViewCloseReset()
  }
  const handlePasswordOpen = ()=>{
    setPassword(true)
  }
  const handlePasswordClose = ()=>{
    setPassword(false)
  }
  const handlePinOpen = ()=>{
    setPin(true)
  }
  const handlePinClose = ()=>{
    setPin(false)
  }

  const ResendOtpEmail = async (e) => {
    console.log(data.email)
    const response = await getRequestReset(
      `api/v1/password/change-password/byEmail?email=${data.email}`,
      'http://68.183.60.114:9086'
    );
    
    const { status, message } = response.data;
    if (status !== true) {
      return error(message);
    }
    success(message);
    
  };
  const ResendOtpPhone = async (e) => {
   
    const phone = data.phoneNumber.slice(1,) 
    if(phone.length > 13){
      return error("Incorrect phone number")
    }
    const response = await getRequestReset(
      `api/v1/password/change-password/byPhone?phoneNumber=${phone}`,
      'http://68.183.60.114:9086'
    );
    
    const { status, message } = response?.data;
    if (status !== true) {
      return error(message);
    }
    success(message);
    
  };
  const ResendPINOtpPhone = async (e) => {
    const response = await getRequestResetAuth(
      `api/v1/pin/change-pin/byPhone?phoneNumber=${data.phoneNumber}`,
      'http://68.183.60.114:9086'
    );
    console.log(response)
    if (response === undefined) {
      return error('user have not setup pin');
    }else{
      const { status, message } = response.data;

      success(message);
    }
    
  };
  const ResendPINOtpemail = async (e) => {
    const response = await getRequestResetAuth(
      `api/v1/pin/change-pin/byEmail?email=${data.email}`,
      'http://68.183.60.114:9086'
    );
    
    const { status, message } = response.data;
    if (status !== true) {
      return error(message);
    }
    success(message);
    
  };

  console.log(state);

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 70, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6">Reset Credentials</Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => ResendOtpEmail()}
          >
            Resend OTP for password via email
          </Button>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => ResendOtpPhone()}
          >
            Resend OTP for password via phone
          </Button>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => ResendPINOtpPhone()}
          >
            Resend OTP for PIN via phone
          </Button>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => ResendPINOtpemail()}
          >
            Resend OTP for PIN via email
          </Button>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => handlePasswordOpen()}
          >
            Reset password via email/phone
          </Button>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => handlePinOpen()}
          >
            Reset pin via email/phone
          </Button>
          
          </div>

        </Modal.Body>
      </Modal>
      {password? <PasswordComponent handlePasswordClose={handlePasswordClose}/> : null}
      {pin? <PinComponent handlePinClose={handlePinClose}/> : null}
    </div>
  );
};

export default ResetComponent;
