import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';


const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const DonationFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();

  
  const title = data ? data.title : '';
  const description = data ? data.description : '';
  const isPublic = data.isPublic ? 'Public' : 'Private';
  const status = data.isDeleted ? 'acive'  : 'inactive';
  const estimatedAmount = data ? data.estimatedAmount : '';
  const currentAmount = data ? data.currentAmount : '';
  const imageUrl = data ? data.imageUrl : '';

  const groupId = data ? data.id : null;

  return (
    <>
      <Formik
        initialValues={{
          title,
          description,
          isPublic,
          status,
          estimatedAmount,
          currentAmount,
          imageUrl,
          // userId,
          groupId,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('First name is required'),
          description: Yup.string().max(255).required('Last name is required'),
          isPublic: Yup.boolean().required('Group type is required'),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
              <div>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                  <div className={classes.profileCard}>
                      Donation Image
                      {values.imageUrl && (
                        <div className={classes.avatarWrapper}>
                          <img
                            src={values.imageUrl}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Donation Title"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      
                      value={values.title}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Donation Type"
                      name="isPrivate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      value={values.isPublic}
                      variant="outlined"
                      
                    >
                     
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                    <TextField
                      fullWidth
                      // label="Status"
                      name="status"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      value={values.status}
                      variant="outlined"
                      
                    >
                      
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                    <TextField
                      
                      label="Estimated Amount"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      value={estimatedAmount}
                      variant="outlined"
                      required
                      fullWidth
                      
                    >
                      
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    
                    <TextField
                      
                      required
                      fullWidth
                      label="Current Amount"
                      name="amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      value={currentAmount}
                      variant="outlined"
                      
                    >
                      
                    </TextField>
                  </Grid>
                </Grid>
                {/* <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.createButton}
                    variant="contained"
                    type="submit"
                    disablexd={isSubmitting}
                  >
                    {title}
                  </Button>
                </Box> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

DonationFormComponent.propTypes = {
  className: PropTypes.string,
};

export default DonationFormComponent;
