import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, Box, Button } from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getUserData } from '../../../services/helpers';

class AdvertFormComponent extends React.Component {
  state = {
    data: {
      title: '',
      description: '',
      mainImage: '',
    },
    submitForm: null,
    title: '',
    file: null,
  };

  componentDidMount() {
    this.initializeView();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.initializeView();
    }
  }

  initializeView = () => {
    let data = this.state.data;
    const submitForm = this.props.submitForm || null;
    const title = this.props.title || 'Create Advert';
    if (this.props.data) {
      data = {
        ...data,
        ...this.props.data,
      };
    }

    this.setState({
      ...this.state,
      data,
      submitForm,
      title,
    });
  };

  handleFileChange = (e) => {
    console.log(this.state.file);
    this.setState({ file: e.target.files[0] });
    console.log(this.state.file);
  };

  render() {
    const { data, file, submitForm, title } = this.state;
    const {
      user: { id },
    } = getUserData();
    return (
      <>
        <Formik
          initialValues={{
            ...data,
            id,
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().max(255).required('First name is required'),
            description: Yup.string()
              .max(255)
              .required('Last name is required'),
          })}
          onSubmit={async (values) => {
            console.log(values);
            let requestData = new FormData();
            requestData.append('file', file);
            requestData.set('data', values);
            await submitForm(requestData);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit} noValidate>
              <div>
                <div>
                  <Grid container spacing={3}>
                    <Grid item lg={12} md={12} xs={12}>
                      <div
                        style={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          flexDirection: 'column',
                          backgroundClip: 'border-box',
                          marginBottom: 20,
                          borderRadius: '.25rem',
                          width: '100%',
                        }}
                      >
                        <h3>Advert Image</h3>
                        {values.mainImage && (
                          <div
                            style={{
                              margin: '5px auto',
                            }}
                          >
                            <img
                              src={values.mainImage}
                              alt="Header"
                              style={{
                                height: 250,
                                width: 400,
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                      <Box display="flex" justifyContent="center" mb={3}>
                        <Button variant="contained" component="label">
                          Upload Main Image
                          <input
                            type="file"
                            hidden
                            onChange={this.handleFileChange}
                          />
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        label="Advert Title"
                        name="title"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        value={values.title}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Description"
                        name="description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.description && errors.description
                        )}
                        helperText={touched.description && errors.description}
                        required
                        value={values.description}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Box display="flex" justifyContent="flex-end" p={2}>
                    <Button
                      color="primary"
                      style={{
                        background: '#FF6634',
                        color: '#ffffff',
                        '&:hover': {
                          background: '#2D9CDB',
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disablexd={isSubmitting}
                    >
                      {title}
                    </Button>
                  </Box>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </>
    );
  }
}

AdvertFormComponent.propTypes = {
  className: PropTypes.string,
};

export default AdvertFormComponent;
