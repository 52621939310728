import React from 'react';
import { createUseStyles } from 'react-jss';
import { Column } from 'simple-flexbox';
import LogoComponent from '../components/sidebar/LogoComponent';
import { Formik } from 'formik';
import { useNavigate  } from 'react-router-dom';
import SLUGS from '../resources/slugs';
import { TextField } from '@mui/material';
import { postRequest } from '../services/request';
import { error, success } from '../services/swal-mixin';
import { Row } from 'react-bootstrap';
import * as Yup from 'yup';
import backgroundImage from '../assets/images/login-bg.png';
import { formatPhoneNumber } from '../services/helpers';
import RequestOtpComponent from './RequestOtpComponent';
import withStaticModal from '../components/modals/withStaticModal';
import { customBaseUrl, httpPost, httpGet } from '../services/http';

const useStyles = createUseStyles((theme) => ({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: `url(${backgroundImage})`,
  },
  formContainer: {
    width: '30vw',
    height: '80vh',
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    borderTop: `5px solid ${theme.color.wayaPink}`,
    borderRadius: 4,
    marginRight: 30,
    marginLeft: 30,
    padding: '24px 32px 0px 32px',
    background: theme.color.white,
    '@media (max-width: 768px)': {
      width: '80vw',
      height: '85vh',
    },
  },
  centreBlock: {
    textAlign: 'center',
  },
  bottomContent: {
    margin: '0 auto',
    position: 'relative',
    height: '15vh',
    bottom: 0,
  },
  textLink: {
    color: theme.color.lightGray,
    marginLeft: 5,
    cursor: 'pointer',
    '&:hover': {
      color: theme.color.black,
      textDecoration: 'underline',
    },
  },
  verticalSpacing: {
    paddingTop: 20,
    paddingBottom: 20,
    '@media (max-width: 768px)': {
      paddingTop: 5,
      paddingBottom: 5,
    },
  },
  title: {
    fontSize: 24,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '38px',
    letterSpacing: '0.2px',
    color: theme.color.black,
    marginBottom: 10,
  },
  formList: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 20,
  },
  submitButton: {
    width: '100%',
    margin: '15px auto',
    padding: '12px 24px',
    border: 'none',
    borderRadius: '4px',
    background: theme.color.wayaPink,
    color: '#f2f2f2',
    letterspacing: '.09em',
  },
  textLinkAlt: {
    color: theme.color.wayaPink,
    marginLeft: 5,
    cursor: 'pointer',
  },
}));

const RequestOTPModal = withStaticModal(RequestOtpComponent, 'Request OTP');

const VerifyOtpComponent = ({ phoneOrEmail }) => {
  const classes = useStyles();
  const push = useNavigate ();

  const resendOTP = async () => {
    const response = await httpGet(
      `/api/v1/auth/resend-otp/signup/${phoneOrEmail}`,
      customBaseUrl.authUrl
    );
    const { message, status } = response;
    if (!status) {
      return error(message);
    }
    return success(message);
  }

  return (
    <Row className={classes.container}>
      <Column className={classes.formContainer}>
        <div className={classes.forgotPasswordCard}>
          <div className={classes.verticalSpacing}>
            <LogoComponent isColored={true} />
          </div>
          <div className={classes.title}>Verify OTP</div>
          <div className={classes.verticalSpacing}>
            <Formik
              initialValues={{ phone: '', otp: '' }}
              validationSchema={Yup.object().shape({
                // phoneOrEmail: Yup.string().min(8).required('email is required'),
                otp: Yup.number().required('OTP is required'),
              })}
              onSubmit={async (values) => {
                const data = {
                  phoneOrEmail: (phoneOrEmail),
                  otp: values.otp,
                };
                const response = await httpPost(
                  '/api/v1/auth/verify-otp',
                  data,
                  customBaseUrl.authUrl
                );
                const { status, message } = response;
                if (!status) {
                  return error(message);
                }
                push(SLUGS.login);
                return success(message);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <form autoComplete="off" onSubmit={handleSubmit} noValidate>
                  <div>
                    {/* <div className={classes.formList}>
                      <TextField
                        fullWidth
                        label="Email"
                        name="phoneOrEmail"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(
                          touched.phoneOrEmail && errors.phoneOrEmail
                        )}
                        helperText={touched.phoneOrEmail && errors.phoneOrEmail}
                        required
                        value={values.phoneOrEmail}
                        variant="outlined"
                        type="phone"
                      />
                    </div> */}
                    <div className={classes.formList}>
                      <TextField
                        fullWidth
                        label="OTP"
                        name="otp"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(touched.otp && errors.otp)}
                        helperText={touched.otp && errors.otp}
                        required
                        value={values.otp}
                        variant="outlined"
                        type="text"
                      />
                    </div>
                    <div className={classes.formList}>
                      <button
                        id="resetButton"
                        disabled={isSubmitting}
                        className={classes.submitButton}
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                    <div style={{ marginTop: 5, marginBottom: 10 }}>
                      <span>
                        <span className={classes.textLinkAlt} onClick={resendOTP} >
                          Resend OTP
                        </span>
                      </span>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
            <div
              className={classes.centreBlock}
              style={{ marginTop: 10, marginBottom: 30 }}
            >
              <span>
                <span
                  className={classes.textLink}
                  onClick={() => push(SLUGS.login)}
                >
                  Back to Sign In
                </span>
              </span>
            </div>
          </div>
          <div className={classes.bottomContent}>
            <div className={classes.centreBlock}>
              <span>&#169; Wayagram</span>
            </div>
          </div>
        </div>
      </Column>
    </Row>
  );
};

export default VerifyOtpComponent;
