import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import { createUseStyles, useTheme } from 'react-jss';
import { Box, Container } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
// import { Box, Container } from '@mui/material';
import Page from '../../../components/Page';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import TablePagination from '@mui/material/TablePagination';
import VoteModal from './VotesModal';
import MiniCardComponent from '../../../components/cards/MiniCardComponents';
import { Row } from 'simple-flexbox';
import CsvDownload from 'react-json-to-csv';



const rows = [
  {
    vote: 'vote 1',
    name: 'clever',
    type: 'free',
    count: '200',
    price: 'N100',
    status: 'Live',
  },
  {
    vote: 'vote 2',
    name: 'philip',
    type: 'free',
    count: '200',
    price: 'N100',
    status: 'Live',
  },
  {
    vote: 'vote 3',
    name: 'hilton',
    type: 'free',
    count: '200',
    price: 'N100',
    status: 'Live',
  },
];

const useStyle = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,

    width: '100%',
    display: 'flex',
  },
  tool: {
    paddingLeft: '0px',
    borderRadius: '250px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  text: {
    width: '140%',

    fontWeight: '800px',
    borderRadius: '1px red',
    position: 'relative',
    marginLeft: 5,
  },

  textfield: {
    paddingTop: '5px',
    // paddingRight: '100px',
    // marginRight: '500px',
  },
  textbtn: {
    backgroundColor: '#FFF1EC',
    color: '#FF0000',
    marginLeft: '5px',
  },
  textbtn2: {
    backgroundColor: '#FF0000',
    color: 'white',
    marginLeft: '5px',
  },
}));

const useMatStyles = createUseStyles((theme) => ({
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  cardRow: {
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    flexGrow: 1,
    marginRight: 20,
    marginLeft: 0,
    paddingLeft: '5px',
    borderRadius: '15px',
    '@media (max-width: 768px)': {
      maxWidth: 'none',
    },
  },
  miniCardContainer1: {
    flexGrow: 1,
    marginRight: 20,
    marginLeft: 0,
    paddingLeft: '5px',
    borderRadius: '15px',
    color: 'green',
    '@media (max-width: 768px)': {
      maxWidth: 'none',
    },
  },
  miniCardContainer2: {
    flexGrow: 1,
    marginRight: 20,
    marginLeft: 0,
    paddingLeft: '5px',
    borderRadius: '15px',
    color: 'red',
    '@media (max-width: 768px)': {
      maxWidth: 'none',
    },
  },
}));

export default function ThirdParty() {
  const classes = useStyle();
  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(9);

  const [show, setShow] = useState(false);
  

  const [values, setValues] = useState('');

  const handleChange = (e) => {
    setValues(e.target.value);
  };
  const handleCloseVote = (e) => {
    setShow(false);
  };
  const handleOpenVote = (e) => {
    setShow(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const getBills = async () => {
  //   const response = await getRequest('marketing/crm?page=1');

  //   if (!response || response.error !== undefined) {
  //     return;
  //   }
  //   const { data } = response.data;
  //   setUsers(data.results);
  //   setMetaData(data.metaInfo);
  // };

  // useEffect(() => {
  //   getCRM();
  // }, []);

  const filteredPersons = rows?.filter((person) => {
    return (
      person?.Livestream?.toLowerCase().includes(values?.toLowerCase()) ||
      person?.status?.toLowerCase().includes(values?.toLowerCase())
    );
  });



  const Conversion = () => {
    return (
      <CsvDownload
        // data={filteredPrivate}
        filename="private_User.csv"
        style={{
          //pass other props, like styles
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',

          backgroundColor: '#27AE60',
          borderRadius: '6px',
          // border: '1px solid #a511c0',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          marginTop: '16px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
          // textShadow: '0px 1px 0px #9b14b3',
        }}
      >
        Export Posts
      </CsvDownload>
    );
  };


  const RenderStat = () => {
    const theme = useTheme();
    const classes = useMatStyles({ theme });

    return (
      <Row
        className={classes.cardsContainer}
        wrap
        flexGrow={1}
        horizontal="space-between"
        breakpoints={{ 768: 'column' }}
      >
        <Row
          className={classes.cardRow}
          wrap
          flexGrow={1}
          horizontal="space-between"
          breakpoints={{ 384: 'column' }}
        >
          <MiniCardComponent
            className={classes.miniCardContainer}
            title="All Votes"
            // value={count}

            iconColor="#FF5349"
          />
          <MiniCardComponent
            className={classes.miniCardContainer1}
            title="Votes in Progress"
            // value={countInitiator}

            iconColor="#3EC1F3"
          />
          <MiniCardComponent
            className={classes.miniCardContainer2}
            // style={{padding: "400%"}}
            title="Finished Votes"
            // value={countAdmin}

            iconColor="#F2C94C"
          />
        </Row>
      </Row>
    );
  };

  return (
    <>
      <Page className={classes.root} title="Users">
        <Container maxWidth={false}>
          <div className={classes.tool}>
            <div className={classes.textfield}>
              <TextField
                className={classes.text}
                id="outlined-basic"
                label="Search Users by Name or Email"
                variant="outlined"
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                }}
                onChange={handleChange}
              />
            </div>
            <Conversion />

            {show ? <VoteModal handleCloseVote={handleCloseVote} /> : ''}
            {/* {showInvite ? (
          <InviteModal handleCloseInvite={handleCloseInvite} />
        ) : (
          ''
        )} */}
          </div>
          <Box mt={3}>
            <RenderStat />
          </Box>
          <Box mt={3} className={classes.tableContainer}>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow
                    style={{ backgroundColor: '#f6b17f', color: '#E7472C' }}
                  >
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Creator's Name
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Post Details
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Type
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      No of Votes
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Status
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Date
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                       Shares
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                       Likes
                    </TableCell>
                    <TableCell
                      style={{
                        color: '#ed462f',
                        fontWeight: 800,
                        fontSize: '16px',
                      }}
                      align="right"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  
                  {filteredPersons
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow key={row.name}>
                        <TableCell align="left">{row.vote}</TableCell>
                        <TableCell align="left">
                          {row.name}
                          
                        </TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.count}</TableCell>
                        <TableCell align="left">{row.price}</TableCell>
                        <TableCell align="left">
                          {row.status}
                          
                        </TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="right" style={{ color: 'red' }}>
                          <DeleteIcon />
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <TablePagination
                style={{ backgroundColor: '#f6b17f', color: '#E7472C' }}
                rowsPerPageOptions={[5, 9, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
          </Box>
        </Container>
      </Page>
    </>
  );
}



// THINGS TO WORK ON TODAY

// VOTING
// PRODUCT AND 
// ADVERTS