import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from '../../components/Page';
import { getRequest } from '../../services/request';
import TransactionsTableComponent from './TransactionsTableComponent';
import LoadingComponent from '../../components/loading/LoadingComponent';
import { Row } from 'simple-flexbox';

const useMatStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '100%',
  },
}));

const filterOptions = [
  {
    value: 'firstName',
    label: 'First Name',
  },
  {
    value: 'lastName',
    label: 'Last Name',
  },
  {
    value: 'phone',
    label: 'Phone Number',
  },
  {
    value: 'status',
    label: 'Status',
  },
  {
    value: 'availableBalance',
    label: 'Available Balance',
  },
  {
    value: 'clearedBalance',
    label: 'Cleared Balance',
  },
  {
    value: 'date',
    label: 'Date',
  },
];

const BankAccountsComponent = ({ userId, title = 'Payment Requests' }) => {
  const matclasses = useMatStyles();
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState({
    count: 0,
  });
console.log(userId)
  const [headers] = useState([
    'Account Name',
    'Account Number',
    'Bank Name',
    'Bank Code',
    'Rubies Bank Code',
  ]);
  const getAccount = async () => {
    setLoading(true);
    const response = await getRequest(
      `card-service/api/admin/list/userBankAcct/${userId}`,
      'http://157.230.223.54:3020'
    );
    setLoading(false);

    console.log(response);
    if (!response || response.error !== undefined) {
      return;
    }
    const { data } = response.data;
    setAccounts(data);
    // setMetaData(data.metaInfo);
  };
console.log(accounts)
  const getPage = async (page) => {
    setLoading(true);
    const response = await getRequest(`wallet/all-wallets}?page=${page}`);
    setLoading(false);

    if (!response || response.error !== undefined) {
      return;
    }
    const { data } = response.data;
    setAccounts(data);
    // setMetaData(data.metaInfo);
  };

  const filterData = async (key, value) => {
    const response = await getRequest(`wallets?${key}=${value}`);

    if (!response || response.error !== undefined) {
      return;
    }
    const { data } = response.data;
    setAccounts(data);
    // setMetaData(data.metaInfo);
  };

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <Page className={matclasses.root} title="Users">
      <LoadingComponent loading={loading} />
      <Container maxWidth={false}>
        <Row>
          <Box marginBottom={2} fontWeight={600}>
            <Row vertical="center" breakpoints={{ 384: 'column' }}>
              {title}
            </Row>
          </Box>
        </Row>
        <Box mt={3} className={matclasses.tableContainer}>
          <TransactionsTableComponent
            getPage={getPage}
            data={accounts}
            metaData={metaData}
            headers={headers}
            type="bankAccount"
          />
        </Box>
      </Container>
    </Page>
  );
};

export default BankAccountsComponent;
