import React, {useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {getRequest, postRequest} from '../../../../services/request'
import {success, error} from '../../../../services/swal-mixin';
import LoadingComponent from '../../../../components/loading/LoadingComponent'

const useStyles = makeStyles((theme) => ({
  main:{
    '@media (max-width: 768px)': {
      marginLeft: -12,
    },

  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  type: {
    marginLeft: 14,
  },
  avatar: {
    height: 100,
    width: 100,
    minWidth: 100,
    borderRadius: 50,
    marginRight: 8,
    marginBottom: 30,
    marginTop: 30,
    marginLeft: 14,
    // border: `1px solid ${theme.color.lightGrayishBlue2}`,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },

  first: {
    marginBottom: 30,
  },
  text: {
    minWidth: '40%',
  },
  btnp: {
    marginLeft: 760,
    marginTop: 20,
    backgroundColor: '#ef6330',
    color: 'white',
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
    '@media (min-width: 900px)': {
      marginLeft: 760,
    },
  },
  type2: {
    marginLeft: 14,
    marginTop: 20,
  },
  para: {
    marginLeft: 65,
    marginTop: 15,
    color: '#ef6330',
    fontWeight: 800,
    fontSize: "20px",
  },
  btnp2: {
    marginLeft: 25,
    marginBottom: 20,
    backgroundColor: '#FDECE5',
    color: '#ef6330',
  },
  third: {
    marginLeft: 10,
    marginTop: 50,
  },
  btnp3: {
    marginTop: 15,
    marginLeft: 100,
    // marginBottom: 10,
    backgroundColor: '#ef6330',
    color: 'white',
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
    '@media (min-width: 960px)': {
      marginLeft: 100,
    },
  },
}));

const userTypes = [
  {
    value: '--',
    label: 'none',
  },
  {
    value: 'tier1',
    label: 'tier1',
  },
  {
    value: 'tier2',
    label: 'tier2',
  },
  {
    value: 'tier3',
    label: 'tier3',
  },
  {
    value: 'tier4',
    label: 'tier4',
  },
  
];

export default function MultilineTextFields(props) {
  const classes = useStyles();
  const {userId}  = props
  const state = {
    tierType: "",
    userId: userId
  }
  const [value, setValue] = React.useState(state);
  const [loading, setLoading] = React.useState(false);
  const [show, setShow] = React.useState(false)
  const [kyc, setKyc] = React.useState([])

  const handleShow= (e)=>{
    setShow(true)
  }
  const handleClose= (e)=>{
    setShow(false)
  }

  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setValue((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const KYCLevel= async ()=>{
    setLoading(true)
    const response = await getRequest(`kyc/getUserKycStatus/${userId}`, 'http://68.183.60.114:9086/kyc-service')
    
    setLoading(false)
    setLoading(false)
    console.log(response)
    const data = response?.data?.data?.tiers
    setKyc(data)
    
    
  }
  const UpdateKYCLevel= async ()=>{
    setLoading(true)
    const response = await postRequest(`kyc/updateUserKycStatus`, value, 'http://68.183.60.114:9086/kyc-service')
    
    setLoading(false)
    console.log(response)
    const {data, status, message} = response?.data
    if(status === false){
      error(message)
    }

    success(message)
    handleClose()
    KYCLevel()
    

  
  }
console.log(kyc, value)
  useEffect(()=>{
    KYCLevel()
  }, [])

  return (
    <>
    <LoadingComponent loading={loading} />
    <div className={classes.main}>
      <Typography
        variant="h4"
        component="h2"
        className={classes.type}
        gutterBottom
      >
       KYC Level
      </Typography>
     
      <div className={classes.section2}>
        <p className={classes.para}>{kyc?.tiers}</p>
        { !show ?<div className={classes.sec}>
        <Button variant="contained" onClick={handleShow} className={classes.btnp2}>
          update KYC
        </Button>
        </div> : "" }
        { !show ? " " : <form className={classes.root} noValidate autoComplete="off">
        <div className={classes.third}>
        <TextField
                    fullWidth
                    label="Choose new KYC Level"
                    name="tierType"
                    onChange={handleChange}
                    
                    required
                    select
                    SelectProps={{ native: true }}
                   
                    variant="outlined"
                    
                  >
                    {userTypes.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
          
        <Button variant="contained" onClick={()=>UpdateKYCLevel()} className={classes.btnp3}>
          Save Changes
        </Button>
        </div>
        </form>}
      </div>
    </div>
    </>
  );
}
