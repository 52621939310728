import { Box, Container, TextField } from "@material-ui/core";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import Button from "@mui/material/Button";
import { Row } from "simple-flexbox";
import Page from "../../../components/Page";
import DisplayTableComponent from "../../../components/table/DisplayTableComponent";
import { getRequest, deleteRequest } from "../../../services/request";
import swalService from "../../../services/swal-mixin";
import MiniCardComponent from "../../../components/cards/MiniCardComponents";
import { IconUsers } from "../../../assets/icons";
// import { TextField } from '@mui/material';
import CreateMoment from "./createMoment";

const useMatStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    width: "100%",
  },

  tool: {
    display: "flex",
    paddingLeft: "0px",
    borderRadius: "250px",
    justifyContent: "space-between",
  },
  text: {
    width: "150%",
    fontWeight: "800px",
    borderRadius: "1px red",

    "& .MuiFormLabel-root": {
      border: "none",
      color: "black",
      marginLeft: 5,
    },
  },

  textfield: {
    paddingTop: "5px",
  },
  tableContainer: {
    width: "100%",
    maxWidth: "100%",
  },
}));

const useStyles = createUseStyles((theme) => ({
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  cardRow: {
    marginTop: 30,
    "@media (max-width: 768px)": {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    // flexGrow: 1,
    flexGrow: 1,
    marginRight: 20,
    marginLeft: 0,
    paddingLeft: "5px",
    borderRadius: "15px",
    "@media (max-width: 768px)": {
      // marginTop: 20,
      maxWidth: "none",
    },
  },
}));

const ManageMomentsComponent = () => {
  const matclasses = useMatStyles();
  const [data, setData] = useState([]);
  const [moment, setMoment] = useState([]);
  const [open, setOpen] = useState(false);
  const [metaData, setMetaData] = useState(0);

  const handleCloseMoment = () => {
    setOpen(false);
  };
  const handleOpenMoment = () => {
    setOpen(true);
  };

  const [values, setValues] = useState("");

  const handleChange = (e) => {
    setValues(e.target.value);
  };

  const headers = [
    "Creator",
    "Fullname",
    "Moment",
    "Views",
    "Status",
    "Date Created",
  ];

  const getData = async () => {
    const response = await getRequest(
      "admin-get-moments",
      process.env.REACT_APP_BASE_URL + "/wayagram-service/all-posts"
    );
    console.log(response);
    const { data, status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    setData(data.data);
    setMetaData(data.metadata.total);
  };
  const getStats = async () => {
    const response = await getRequest(
      "admin-get-moment-stats",
      process.env.REACT_APP_BASE_URL + "/wayagram-service/all-posts"
    );

    const { data, status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    setMoment(data);
  };

  const deActivate = async (user) => {
    const response = await deleteRequest(
      `admin-delete-moment?profile_id=${user.ProfileId}&moment_id=${user.id}`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service/all-posts"
    );

    const { status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    swalService.success(message);
    getData();
  };

  useEffect(() => {
    getData();
    getStats();
  }, []);

  const filteredMoment = data?.filter((person) => {
    return (
      person?.userName?.toLowerCase().includes(values?.toLowerCase()) ||
      person?.Profile.displayName?.toLowerCase().includes(values?.toLowerCase())
    );
  });

  const RenderStat = () => {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
      <Row
        className={classes.cardsContainer}
        wrap
        flexGrow={1}
        horizontal="space-between"
        breakpoints={{ 768: "column" }}
      >
        <Row
          className={classes.cardRow}
          wrap
          flexGrow={1}
          horizontal="space-between"
          breakpoints={{ 384: "column" }}
        >
          <MiniCardComponent
            className={classes.miniCardContainer}
            title="Total Moments"
            value={data.length}
            icon={IconUsers}
            iconColor="#FF5349"
            // style={{ marginRight: "300px"}}
          />
          <MiniCardComponent
            className={classes.miniCardContainer}
            title="Total Active Moments"
            value={moment.active_moments}
            icon={IconUsers}
            iconColor="#FF5349"
            // style={{ marginRight: "300px"}}
          />
          <MiniCardComponent
            className={classes.miniCardContainer}
            title="Expired Moments"
            value={moment.expired_moments}
            icon={IconUsers}
            iconColor="#FF5349"
            // style={{ marginRight: "300px"}}
          />
        </Row>
      </Row>
    );
  };

  return (
    <Page className={matclasses.root} title="Manage Moments">
      <Container maxWidth={false}>
        <div className={matclasses.tool}>
          <div className={matclasses.textfield}>
            <TextField
              className={matclasses.text}
              id="outlined-basic"
              label="Search Users by Name or username"
              variant="outlined"
              onChange={handleChange}
            />
          </div>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#FF4B01",
              color: "white",
              fontWeight: 800,
            }}
            onClick={() => setOpen(true)}
          >
            Create Moment
          </Button>
        </div>
        <Box mt={3}>
          <RenderStat />
        </Box>
        {open ? <CreateMoment handleCloseMoment={setOpen} show={open} /> : ""}

        <Box mt={3} className={matclasses.tableContainer}>
          <DisplayTableComponent
            // getPage={getPage}
            type="moment"
            data={filteredMoment}
            // getData={getData}
            metaData={metaData}
            headers={headers}
            actionCol={true}
            delete={deActivate}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ManageMomentsComponent;
