import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LoadingComponent from '../loading/LoadingComponent'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { postRequest, getRequest } from '../../services/request';
import { success, error } from '../../services/swal-mixin';


const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '20px 0',
   
    justifyContent: 'space-around',
    marginRight: 100,
  },

  link: {
    marginLeft: 100,
    marginBottom: '0.5rem',
  },
  textme: {
    minWidth: '100%',
  },
  textme2: {
    minWidth: '250%',
  },
  btnp3: {
    marginLeft: 320,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

const UserComponent = (props) => {
  const classes = useStyles();
  console.log(props);
  const { datas } = props;
  const state = {
    newPassword: '',
    otp: '',
    phoneOrEmail: '',
  };
  const [value, setValue] = React.useState(state);
  const [loading, setLoading] = React.useState(false);

  const handleChange = (event, newValue) => {
    const { value, name } = event.target;
    setValue((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  async function getOtpPhone(){
    setLoading(true)
    const phone = datas.phoneNumber.slice(1,)
    const response = await getRequest(`api/v1/password/forgot-password/byPhone?phoneNumber=${phone}`, "http://68.183.60.114:8059")
    setLoading(false)
    console.log(response)
    const {message, status} = response.data
    if(status === false || status === 404){
      return error(message)
    }
    
    success(message)
  }
  async function getOtpEmail(){
    setLoading(true)
    const response = await getRequest(`api/v1/password/forgot-password/byEmail?email=${datas.email}`, "http://68.183.60.114:8059")
    setLoading(false)
    console.log(response)
    const {message, status} = response.data
    if(status === false || status === 404){
      return error(message)
    }
    
    success(message)
  }
  async function CreatePassword(){
    setLoading(true)
    const response = await postRequest('api/v1/password/forgot-password', value, "http://68.183.60.114:8059")
    setLoading(false)
    console.log(response)
    const {message, status} = response.data
    if((status === false || status === 404)){
      return error("invalid token")
    }else if(status === false || status === 404){
      return error(message)

    }
    setValue(state)
    success(`${message} successfully`)
  }


  console.log(value);

  return (
    <div className={classes.root}>

      <LoadingComponent loading={loading}/>
      <div className={classes.tabContent}>
        <div className={classes.name2}>
          <Typography variant="h6">Reset Waya Official Password</Typography>
        </div>
      </div>
      <div style={{marginLeft: "100px", justifyContent: 'space-between'}}>
        <>
        <Button variant="outlined" color="primary" onClick={()=>getOtpPhone()}>
          OTP via phone
        </Button>
        </>
        <>
        <Button variant="outlined" color="primary" onClick={()=>getOtpEmail()}>
          OTP via email
        </Button>
        </>
      </div>
      <div className={classes.tabContent2}>
        <div className={classes.link}>
          <TextField
            className={classes.textme}
            id="outlined-select-currency-native"
            label="OTP"
            name="otp"
            type="password"
            placeholder="please enter otp received"
            value={value.otp}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          ></TextField>
        </div>
        <div className={classes.link}>
          <TextField
            className={classes.textme}
            id="outlined-select-currency-native"
            label="New Password"
            placeholder="please enter new password"
            name="newPassword"
            type="password"
            value={value.newPassword}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          ></TextField>
        </div>
        <div className={classes.link}>
          <TextField
            className={classes.textme}
            id="outlined-select-currency-native"
            label="phone or email"
            placeholder="please enter phone or email"
            name="phoneOrEmail"
            
            value={value.phoneOrEmail}
            onChange={handleChange}
            SelectProps={{
              native: true,
            }}
            variant="outlined"
          ></TextField>
        </div>
      </div>
      <Button variant="contained" className={classes.btnp3} onClick={()=>CreatePassword()}>
        Reset
      </Button>
    </div>
  );
};

export default UserComponent;
