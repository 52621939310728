import React from "react";
import { string } from "prop-types";
import { Row } from "simple-flexbox";
import { createUseStyles, useTheme } from "react-jss";
import DropdownComponent from "../dropdown";
import store from "../../redux";
import { getUserData } from '../../services/helpers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { logoutUser } from '../../redux/actions';



const useStyles = createUseStyles((theme) => ({
  avatar: {
    height: 35,
    width: 35,
    minWidth: 35,
    borderRadius: 50,
    marginRight: 8,
    border: `1px solid ${theme.color.lightGrayishBlue2}`,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
  container: {
    height: 70,
    backgroundColor: 'white',
    padding: 15,
  },
  name: {
    ...theme.typography.itemTitle,

    textAlign: "right",
    color: theme.color.wayaPink,
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  separator: {
    borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
    marginLeft: 32,
    marginRight: 32,
    height: 32,
    width: 2,
    '@media (max-width: 768px)': {
      marginLeft: 14,
      marginRight: 0,
    },
  },
  title: {
    ...theme.typography.title,
    paddingLeft: 10,
    '@media (max-width: 1080px)': {
      marginLeft: 50,
    },
    '@media (max-width: 468px)': {
      fontSize: 20,
    },
  },
  iconStyles: {
    cursor: 'pointer',
    marginLeft: 25,
    '@media (max-width: 768px)': {
      marginLeft: 12,
    },
  },
  textLink: {
    color: theme.color.wayaPink,
    marginLeft: 5,
    cursor: 'pointer',
  },
  heads: {
    display: "flex",
    textAlign: "left"
  },
  names: {
    ...theme.typography.itemTitle,
    textAlign: "center",
    justifyContent: "flex-end",
    color: theme.color.wayaPink,
    "@media (max-width: 768px)": {
      display: "none",
    },
  }
}));

const HeaderComponent = () => {
  // const push = useNavigate ();
  const theme = useTheme();
  // const { user } = JSON.parse(localStorage.getItem('user') || '{}');
  const classes = useStyles({ theme });

  let user = getUserData()

  // function onSettingsClick() {
  //   push(SLUGS.settings);
  // }


  const logout = () => {
    // clearStorage();
    store.dispatch(logoutUser());
  };


  // let user = getUserData()

  return (
    <Row
      className={classes.container}
      vertical="center"
      horizontal="space-between"
    >
      <span className={classes.title}></span>
      <Row vertical="center">

        {/*         
        <div className={classes.iconStyles}>
          <DropdownComponent
            label={<IconBell />}
            options={[
              {
                label: 'Notification #1',
                onClick: () => console.log('Notification #1'),
              },
              {
                label: 'Notification #2',
                onClick: () => console.log('Notification #2'),
              },
              {
                label: 'Notification #3',
                onClick: () => console.log('Notification #3'),
              },
              {
                label: 'Notification #4',
                onClick: () => console.log('Notification #4'),
              },
            ]}
            position={{
              top: 42,
              right: -14,
            }}
          />
        </div>
         */}
        <div className={classes.separator}></div>
        <DropdownComponent

          isUserProfile={true}
          label={
            <>
              <img
                src="https://www.minervastrategies.com/wp-content/uploads/2016/03/default-avatar.jpg"
                // src={userImg}
                alt="avatar"
                className={classes.avatar}
              />

              {/* <div className={classes.heads}> */}

              {/* <span className={classes.name}>Online</span> */}
              {/* {user.message === "Login Successful" ? <span className={classes.names}>Online</span> : ""} */}

              {/* </div> */}

              <span className={classes.name}>
                {user?.firstName} {user?.lastName}
              </span>
              <ArrowDropDownIcon style={{ color: "#ef6330" }} />

            </>
          }
          options={[
            // {
            //   label: 'Settings',
            //   onClick: onSettingsClick,
            // },
            {
              label: 'Logout',
              style: classes.textLink,
              onClick: logout,
            },
          ]}
          position={{
            top: 42,
            right: -6,
          }}
        />
      </Row>
    </Row>
  );
};

HeaderComponent.propTypes = {
  title: string,
};

export default HeaderComponent;
