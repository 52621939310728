import React from "react";
import Messagepreview from "./Messagepreview";
import { Card, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { createUseStyles, useTheme } from "react-jss";
import { HiChatAlt } from "react-icons/hi";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: "50%",
    height: "70vh",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    margin: "auto",
  },
  icon2: {
    color: theme.color.customWayaRed,
    marginBottom: '15px',
  },
  title: {
    fontSize: "17px",
    fontWeight: 700,
    color: "black",
  },
  content: {
    fontSize: "13px",
    fontWeight: 400,
  },
}));

const DefaultScreen = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Card className={classes.container}>
      <HiChatAlt className={classes.icon2} size="3em" />
      <h2 className={classes.title}>You've not started chat yet.</h2>
      <p className={classes.content}>Kindly select users to chat with</p>
    </Card>
  );
};

export default DefaultScreen;
