import { Box, Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { Row } from "simple-flexbox";
import Page from "../../../components/Page";
import { deleteRequest, getRequest } from "../../../services/request";
import swalService from "../../../services/swal-mixin";
import DisplayTableComponent from "../../../components/table/DisplayTableComponent";
import MiniCardComponent from "../../../components/cards/MiniCardComponents";
import { IconUsers } from "../../../assets/icons";
import { TextField } from "@mui/material";

const useMatStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    width: "100%",
  },
  tool: {
    display: "flex",
    paddingLeft: "0px",
    borderRadius: "250px",
  },
  text: {
    width: "150%",

    fontWeight: "800px",
    borderRadius: "1px red",

    "& .MuiFormLabel-root": {
      border: "none",
      color: "black",
      marginLeft: 5,
    },
  },

  textfield: {
    paddingTop: "5px",
    paddingRight: "100px",
    marginRight: "640px",
  },

  tableContainer: {
    width: "100%",
    maxWidth: "100%",
  },
}));

const useStyles = createUseStyles((theme) => ({
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
    "@media (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  cardRow: {
    marginTop: 30,
    "@media (max-width: 768px)": {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    maxWidth: "900px",
    width: "300px",

    marginLeft: 0,
    paddingLeft: "5px",
    borderRadius: "15px",
    "@media (max-width: 768px)": {
      // marginTop: 20,
      maxWidth: "none",
    },
  },
}));

const datas = [
  {
    firstName: "clever",
    description: "08103612164",
    createdAt: "4th, jul 2021",
    time: "10:40",
  },
  {
    firstName: "clever",
    description: "08103612164",
    createdAt: "4th, jul 2021",
    time: "10:40",
  },
  {
    firstName: "clever",
    description: "08103612164",
    createdAt: "4th, jul 2021",
    time: "10:40",
  },
  {
    firstName: "clever",
    description: "08103612164",
    createdAt: "4th, jul 2021",
    time: "10:40",
  },
  {
    firstName: "clever",
    description: "08103612164",
    createdAt: "4th, jul 2021",
    time: "10:40",
  },
  {
    firstName: "clever",
    description: "08103612164",
    createdAt: "4th, jul 2021",
    time: "10:40",
  },
  {
    firstName: "clever",
    description: "08103612164",
    createdAt: "4th, jul 2021",
    time: "10:40",
  },
];

const AdvertsComponent = () => {
  const matclasses = useMatStyles();
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({
    count: 0,
    totalInitiator: 0,
    totalSuperAdmin: 0,
  });

  const [headers] = useState([
    "Advert Name",
    "Advert Description",
    // 'Images',
    "Date Created",
    "Time",
  ]);

  const [values, setValues] = useState("");

  const handleChange = (e) => {
    setValues(e.target.value);
  };

  const getData = async () => {
    const response = await getRequest(
      "advert/getAdverts?page=1",
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );
    if (!response) return;
    const { data, metaData, status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    setData(data);
    setMetaData(metaData);
  };

  const getPage = async (page) => {
    const response = await getRequest(
      `advert/getAdverts?page=${page}`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );

    const { data, status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    setData(data);
  };

  const deleteAdvert = async ({ id }) => {
    const response = await deleteRequest(
      `advert/deleteAdvert?id=${id}`,
      process.env.REACT_APP_BASE_URL + "/wayagram-service"
    );
    const { status, message } = response.data;
    if (status !== true) {
      return swalService.error(message);
    }
    swalService.success(message);
    getData();
  };

  useEffect(() => {
    getData();
  }, []);
  console.log(data);

  const RenderStat = () => {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
      <Row
        className={classes.cardsContainer}
        wrap
        flexGrow={1}
        horizontal="space-between"
        breakpoints={{ 768: "column" }}
      >
        <Row
          className={classes.cardRow}
          wrap
          flexGrow={1}
          horizontal="space-between"
          breakpoints={{ 384: "column" }}
        >
          <MiniCardComponent
            className={classes.miniCardContainer}
            title="Total Number of Adverts"
            value={metaData.count}
            icon={IconUsers}
            iconColor="#FF5349"
          />
        </Row>
      </Row>
    );
  };

  const filteredAdverts = data?.filter((person) => {
    return (
      person?.firstName?.toLowerCase().includes(values?.toLowerCase()) ||
      person?.description?.toLowerCase().includes(values?.toLowerCase())
    );
  });

  return (
    <Page className={matclasses.root} title="Adverts">
      <Container maxWidth={false}>
        <div className={matclasses.tool}>
          <div className={matclasses.textfield}>
            <TextField
              className={matclasses.text}
              id="outlined-basic"
              label="Search Users by Advert Name"
              variant="outlined"
              onChange={handleChange}
            />
          </div>
        </div>
        <Box mt={3}>
          <RenderStat />
        </Box>
        <Box mt={3} className={matclasses.tableContainer}>
          <DisplayTableComponent
            metaData={metaData}
            getPage={getPage}
            type={"advert"}
            data={datas}
            headers={headers}
            delete={deleteAdvert}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default AdvertsComponent;
