import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Row } from 'simple-flexbox';
import { deleteRequest } from '../../services/request';
import WayaModal from './index';
import WayaCreationForm from './WayaCreationForm';
import { success, error } from '../../services/swal-mixin';
import LoadingComponent from '../../components/loading/LoadingComponent';
import logo from '../../assets/images/waya-logo-pink.png';
import ShowDashboard from './ShowDashbard';
import Popper from '@mui/material/Popper';
import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 160,
    border: '1px solid #FF6634',
    borderRadius: '12px',
    backgroundColor: '#FFF7F4',
    '@media (max-width: 768px)': {
      height: 'auto',
    },
  },
  header: {
    marginTop: -10,
  },
  status: {
    backgroundColor: 'green',
    color: 'white',
    width: 60,
    height: 25,
    borderRadius: 0,
    fontSize: 14,
    marginLeft: -16,
    fontWeight: 800,
  },
  status2: {
    backgroundColor: '#FF6634',
    width: 60,
    height: 25,
    borderRadius: 0,
    fontSize: 14,
    marginLeft: -16,
    fontWeight: 800,
  },

  avatarContainer: {
    position: 'relative',
    // alignItems:"start"
    marginTop: '20px',
  },
  avatarWrapper: {
    marginLeft: 30,
    marginRigth: 30,
  },
  avatar: {
    height: 120,
    width: 120,
    minWidth: 120,
    borderRadius: 120,
  },
  statusColorWrapper: {
    height: 20,
    width: 20,
    minWidth: 20,
    borderRadius: 20,
    background: '#ffffff',
    marginRight: 5,
    marginLeft: -30,
  },
  statusColor: {
    height: 15,
    width: 15,
    minWidth: 15,
    borderRadius: 15,
    background: 'green',
    marginLeft: 2,
    marginTop: 2,
  },
  statusText: {
    marginTop: 2,
    marginLeft: -5,
  },
  StatusWrapper: {
    ...theme.typography.itemTitle,
    fontSize: 12,
    textAlign: 'center',
    position: 'absolute',
    left: 150,
    bottom: -2,
  },
  actionButton: {
    background: '#FFFFFF',
    border: '1px solid #C4C4C4',
    color: '#4F4F4F',
    // marginTop: "30px",
    '&:hover': {
      background: '#FF6634',
      border: '1px solid #FF6634',
    },
  },
  deactivateButton: {
    background: '#FFFFFF',
    border: '1px solid #F2F2F2',
    boxSizing: 'border-box',
    color: '#EB5757',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.1)',
    '&:hover': {
      background: '#FF6634',
      color: '#ffffff',
      border: '1px solid #FF6634',
    },
  },
}));

const WayaOfficialCardComponent = (props) => {
  
  const [show, setShow] = useState(false);
  const { waya, getWayaOfficial } = props;
  const [loading, setLoading] = useState(false);

  const handleWayaOpen = () => {
    setShow(true);
  };
  const handleWayaClose = () => {
    setShow(false);
  };

  const theme = useTheme();
  const classes = useStyles({ theme });
  const [newWayaAccount, setNewWayaAccount] = useState(false);
  
  let [showWaya, setShowWaya] = useState(false);

  const handleCloseShow = () => {
    setShowWaya(false);
  };
  const handleOpenShow = () => {
    setShowWaya(true);
  };

  const handleWayaCreateClose = () => {
    setNewWayaAccount(false);
  };
  const handleWayaCreateOpen = () => {
    setNewWayaAccount(true);
  };

  

  const deActivateUser = async () => {
    setLoading(true);
    const response = await deleteRequest(`api/v1/user/delete/${waya?.userId}`);
   
    setLoading(false);
    const { status, message } = response.data;
    if (status === false) {
      return error(message);
    }
    success(message);
    getWayaOfficial();
  };

  return (
    <>
      {showWaya && waya.length !== 0 ? (
        <ShowDashboard
          showWaya={showWaya}
          setShowWaya={setShowWaya}
          handleCloseClever={handleCloseShow}
          handleOpenShow={handleOpenShow}
        />
      ) : null}
      <LoadingComponent loading={loading} />
      <Card className={classes.root}>
        <>
          {newWayaAccount ? (
            <WayaCreationForm handleWayaCreateClose={handleWayaCreateClose} />
          ) : (
            ''
          )}

          <Row>
            <Box
              width="100%"
              style={{
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                paddingRight: '20px',
              }}
            >
              <Row
                className={classes.cardsContainer}
                breakpoints={{ 768: 'column' }}
              >
                <Row
                  className={classes.cardRow}
                  wrap
                  flexGrow={1}
                  horizontal="space-between"
                  breakpoints={{ 384: 'column' }}
                >
                  <Box display="flex">
                    {waya.length !== 0 ? (
                      <>
                        <Box
                          className={classes.avatarContainer}
                          horizontal="center"
                          vertical="center"
                        >
                          <div className={classes.avatarWrapper}>
                            <img
                              // src="https://www.minervastrategies.com/wp-content/uploads/2016/03/default-avatar.jpg"
                              src={logo}
                              alt="avatar"
                              className={classes.avatar}
                            />
                          </div>
                        </Box>
                      </>
                    ) : null}
                    {waya.length !== 0 ? (
                      <Box marginLeft={3} alignSelf="center">
                        <Box
                          fontWeight={500}
                          fontSize={15}
                          style={{
                            marginTop: '-25px',
                            fontWeight: 800,
                            fontSizee: '25px',
                          }}
                        >
                          Official Waya ({waya.phoneNumber})
                        </Box>
                        <Box
                          fontSize={14}
                          style={{
                            fontWeight: 800,
                            color: '#16B079',
                            fontSizee: '20px',
                            textTransform: 'uppercase',
                          }}
                        >
                          Online
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                  {waya.length !== 0 ? (
                    <>
                      <div
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '12px',
                          marginTop: '30px',
                          color: '#FFCAB7',
                        }}
                      >
                        UserId{' '}
                        <p
                          style={{
                            fontWeight: 800,
                            color: 'black',
                            fontSize: '10px',
                            textTransform: 'lowercase',
                          }}
                        >
                          {waya.userId}
                        </p>
                      </div>
                      <div
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '12px',
                          marginTop: '30px',
                          color: '#FFCAB7',
                        }}
                      >
                        Email{' '}
                        <p
                          style={{
                            fontWeight: 800,
                            color: 'black',
                            fontSize: '12px',
                            textTransform: 'lowercase',
                          }}
                        >
                          {waya.email}
                        </p>
                      </div>
                      <div
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '12px',
                          marginTop: '30px',
                          color: '#FFCAB7',
                        }}
                      >
                        Role
                        <p
                          style={{
                            fontWeight: 800,
                            color: '#FF6634',
                            fontSize: '12px',
                            textTransform: 'lowercase',
                          }}
                        >
                          {waya?.roles?.length === 4 ? "Owner": 'Super Admin'}
                        </p>
                      </div>
                      <div
                        style={{
                          textTransform: 'uppercase',
                          fontSize: '12px',
                          marginTop: '30px',
                          color: '#FFCAB7',
                        }}
                      >
                        Wayagram{' '}
                        <p
                          style={{
                            fontWeight: 800,
                            color: 'black',
                            fontSize: '12px',
                            textTransform: 'lowercase',
                          }}
                        >
                          {waya.firstName}
                        </p>
                      </div>
                    </>
                  ) : (
                    <Box
                      style={{
                        color: 'green',
                        marginTop: '80px',
                      }}
                    >
                      <p
                        style={{
                          color: 'green',
                          marginRight: '400px',
                          fontWeight: 800,
                          textTransform: 'uppercase',
                        }}
                      >
                        No Waya Official Account Yet
                      </p>
                    </Box>
                  )}
                </Row>
              </Row>
            </Box>
          </Row>
        </>
        <Box justifyContent="space-between">
          <Box justifyContent="start" marginRight={3}>
            <div>
              <PopupState variant="popper" popupId="demo-popup-popper">
                {(popupState) => (
                  <div>
                    <Button
                      style={{
                        marginTop: '-1rem',
                        float: 'right',
                        backgroundColor: 'black',
                        color: 'white',
                        textTransform: 'lowercase',
                        position: 'static',
                        height: '25px',
                      }}
                      variant="contained"
                      color="#fff"
                      {...bindToggle(popupState)}
                    >
                      Waya Official Account Settings
                    </Button>
                    <Popper {...bindPopper(popupState)} transition>
                      {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={350}>
                          <Paper>
                            {waya ? (
                              <Typography
                                className={classes.typography}
                                onClick={() => {
                                  handleWayaOpen();
                                }}
                              >
                                manage waya credentials
                              </Typography>
                            ) : null}

                            <Typography
                              className={classes.typography}
                              onClick={() => {
                                handleWayaCreateOpen();
                              }}
                            >
                              Create Waya Account.
                            </Typography>
                            {waya ? (
                              <Typography
                                className={classes.typography}
                                onClick={() => deActivateUser()}
                              >
                                Delete Waya Account.
                              </Typography>
                            ) : null}
                          </Paper>
                        </Fade>
                      )}
                    </Popper>
                  </div>
                )}
              </PopupState>
            </div>
            
          </Box>
        </Box>
      </Card>
      {show ? <WayaModal data={waya} handleWayaClose={handleWayaClose} /> : ''}
    </>
  );
};

export default WayaOfficialCardComponent;
