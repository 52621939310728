import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Column } from 'simple-flexbox';
import { Row } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';

const useStyles = createUseStyles((theme) => ({
  contentWrapper: {
  width: '100%',
},
}));

const Page = forwardRef(({ children, title = '', ...rest }, ref) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  return (
    <Column className={classes.contentWrapper}>
      <Row
        horizontal="space-between"
        breakpoints={{ 1024: 'column' }}
      >
        <div ref={ref} {...rest}>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {children}
        </div>
      </Row>
    </Column>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
