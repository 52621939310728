import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import LoadingComponent from '../../../../components/loading/LoadingComponent';
import {
  getRequest,
  deleteRequest,
  postRequest,
} from '../../../../services/request';
import { getUserData } from '../../../../services/helpers';
import { Modal } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import { success, error } from '../../../../services/swal-mixin';
import CloseIcon from '@material-ui/icons/Close';

const useBankStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    display: 'none',
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: -60,
  },
  tabContent3: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'center',
    marginLeft: -60,
  },

  link: {
    marginRight: 80,
  },
  link2: {
    marginLeft: 55,
    marginBottom: '25px',
  },
  phones: {
    marginBottom: '25px',
  },
  textme: {
    // minWidth: '20%',
  },
  textme2: {
    minWidth: '66%',
  },
  textme3: {
    minWidth: '65%',
  },
  btnp3: {
    marginLeft: 300,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
  btnp4: {
    marginLeft: 330,
    marginBottom: 20,
    backgroundColor: '#FF4B01',
    color: 'white',
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginLeft: 14,
    },
  },
}));

const useStyles = makeStyles((theme) => ({
  roots: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '1rem 1rem',
    justifyContent: 'space-between',
    //   "&:nth-child(0)": {
    //     display: "none"
    //  }
  },

  root: {
    // minWidth: 350,
    display: 'flex',
    position: 'relative',
    background: '#FFFFFF',

    // marginLeft: "2px"
    // display:'none'
  },
  media: {
    position: 'absolute',
    color: 'red',
    height: '30px',
    marginLeft: '140px',
    bottom: '35px',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  types: {
    fontSize: '12px',
  },
  name: {
    fontSize: '10px',
    marginBottom: '15px',
  },
  content: {
    flex: '1 0 auto',
  },
  head: {
    marginLeft: '140px',
  },
}));

export default function RecipeReviewCard({ userId, data, accountNumber }) {
  const user = userId;
  const admin = getUserData();
  const adminId = admin.id;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openCard, setCard] = React.useState(false);
  const [openOtp, setOtp] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [cards, setCards] = React.useState([]);
  const [id, setId] = React.useState(-1);
  // const anchorRef = React.useRef(null);
  console.log(id);
  console.log(user, data.firstName);
  const handleToggle = (index, event) => {
    // if (!anchorRef.current && !anchorRef.current.contains(event.target)) {
    // return;
    setId(index);
    // setOpen(true);
    // }
  };

  const handleOpenCard = () => {
    setCard(true);
  };
  const handleCloseCard = () => {
    setCard(false);
  };
  const handleOpenOtp = () => {
    setOtp(true);
  };
  const handleCloseOtp = () => {
    setOtp(false);
  };
  const handleClose = (event) => {
    // if (anchorRef.current && anchorRef.current.contains(event.target)) {
    //   return;
    // }

    setId(-1);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const getCards = async () => {
    setLoading(true);
    const response = await getRequest(
      `card-service/api/admin/list/card/${userId}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);

    console.log(response);
    if (!response || response.error !== undefined) {
      return;
    }
    const { data } = response?.data;
    setCards(data);
    // setMetaData(data.metaInfo);
  };
  console.log(cards);
  const fullName = `${data.firstName} ${data.lastName}`;
  console.log(fullName);
  const userCard = cards?.find((user) => user.accountName === fullName);
  console.log(userCard);
  const deleteCards = async (index, user, cardNumber) => {
    setLoading(true);

    const response = await deleteRequest(
      `card-service/api/admin/deleteCard/${user}/${adminId}/${cardNumber}`,
      'http://68.183.60.114:9086'
    );
    setLoading(false);
    console.log(response);
    const { data, message, status } = response?.data;
    if (status === false) {
      return error(message);
    }
    success(message);

    getCards();
  };

  useEffect(() => {
    getCards();
  }, []);

  return (
    <>
      {/* <LoadingComponent loading={loading} /> */}
      <div className={classes.roots}>
        {cards ? (
          <>
            {cards?.map((card, index) => (
              <Card className={classes.root} key={card.last4digit}>
                <CardContent>
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.types}
                  >
                    Account Name
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    className={classes.name}
                  >
                    {card.accountName}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.types}
                  >
                    Card Number
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    className={classes.name}
                  >
                    {card.cardNumber}
                  </Typography>
                  <Typography
                    component="h5"
                    variant="h6"
                    className={classes.types}
                  >
                    VALID THRU
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    className={classes.name}
                  >
                    {card.expiryMonth} {card.expiryYear}
                  </Typography>
                </CardContent>
                <div>
                  <div>
                    {id !== index ? (
                      <div
                        // ref={anchorRef}
                        key={card.last4digit}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={() => handleToggle(index, card.cardNumber)}
                      >
                        <CardHeader
                          style={{ marginBottom: '-60px', marginRight: '0px' }}
                          className={classes.head}
                          action={
                            <IconButton key={card.last4digit}>
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                      </div>
                    ) : (
                      <div
                        // ref={anchorRef}
                        key={card.last4digit}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={() => handleClose(index, card.cardNumber)}
                      >
                        <CardHeader
                          style={{ marginBottom: '-60px', marginRight: '0px' }}
                          className={classes.head}
                          action={
                            <IconButton key={card.last4digit}>
                              <CloseIcon />
                            </IconButton>
                          }
                        />
                      </div>
                    )}
                    {id === index ? (
                      <Paper
                        style={{
                          width: '40%',
                          marginTop: '30px',
                          left: '150px',
                          position: 'absolute',
                          zIndex: '9999',
                        }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            onClose={handleClose}
                            key={++index}
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              style={{ color: 'red' }}
                              onClick={() =>
                                deleteCards(index, card.userId, card.cardNumber)
                              }
                            >
                              Delete Card
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                              Make Default
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    ) : null}
                  </div>
                  <Typography className={classes.media}>{card.type}</Typography>
                </div>
              </Card>
            ))}
          </>
        ) : (
          'No card added yet'
        )}
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridGap: '1rem 1rem',
        }}
      >
        <Button
          variant="outlined"
          color="textSecondary"
          style={{
            marginTop: '20px',
            width: '360px',
            height: '85px',
            background: '#FFFFFF',
            position: 'relative',
          }}
          onClick={() => handleOpenCard()}
        >
          ADD CARD{' '}
          <span style={{ position: 'absolute', top: '17.44%' }}>+</span>
        </Button>
        {openCard ? (
          <AccountComponent
            userId={user}
            datas={data}
            handleCloseCard={handleCloseCard}
          />
        ) : (
          ''
        )}
        <Button
          variant="outlined"
          color="textSecondary"
          style={{
            marginTop: '20px',
            width: '360px',
            height: '85px',
            background: '#FFFFFF',
            position: 'relative',
          }}
          onClick={() => handleOpenOtp()}
        >
          VERIFY OTP{' '}
          <span style={{ position: 'absolute', top: '17.44%' }}>+</span>
        </Button>
        {openOtp ? (
          <AccountOtp
            userId={user}
            datas={data}
            handleCloseOtp={handleCloseOtp}
          />
        ) : (
          ''
        )}
      </div>
    </>
  );
}

const AccountComponent = (props) => {
  console.log(props);
  const admin = getUserData();
  const adminId = admin.id;
  const state = {
    accountNo: '',
    cardNumber: '',
    cvv: '',
    email: '',
    expiryMonth: '',
    expiryYear: '',
    last4digit: '',
    name: '',
    pin: '',
    surname: '',
  };
  const classes = useBankStyles();
  const { userId, datas, handleOpenCard, handleCloseCard } = props;

  const [values, setValues] = React.useState(state);
  const [loading, setLoading] = React.useState(false);
  const [wallet, setWallet] = React.useState([]);

  const handleViewClose = () => {
    handleCloseCard();
  };
  const handleViewOpen = () => {
    handleOpenCard();
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setValues((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const getWallet = async () => {
    setLoading(true);
    const response = await getRequest(
      `wallet/accounts/${userId}`,
      'http://157.230.223.54:9009'
    );
    setLoading(false);

    if (!response || response.error !== undefined) {
      return;
    }
    console.log(response);
    const { data } = response.data;
    setWallet(data);
    // setMetaData(data.metaInfo);
  };

  console.log(wallet, datas);
  const fullName = `${datas.firstName} ${datas.lastName}`;
  console.log(fullName);
  const user = wallet?.find((user) => user.accountName === fullName);
  console.log(user);

  const addCards = async () => {
    const datas = { ...values, userId: userId };
    setLoading(true);
    const response = await postRequest(
      `card-service/api/admin/addCard/${userId}/${adminId}`,
      datas,
      'http://157.230.223.54:3020'
    );
    setLoading(false);

    const { data, message, status } = response.data;
    if (status === false) {
      return error(message);
    }
    console.log(response);
    // setBank(data);
    success(message);
    setValues({ ...state, name: '' });
    // getBanks();
  };

  // console.log(bank);
  console.log(values, datas);

  useEffect(() => {
    getWallet();
  }, []);

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 0, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6" style={{ marginLeft: '20px' }}>
                Add bank Card
              </Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Account Number"
                name="accountNo"
                placeholder="please enter account No."
                onChange={handleChange}
                value={values.accountNo.trim()}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Card Number"
                name="cardNumber"
                placeholder="please enter name"
                onChange={handleChange}
                value={values.cardNumber.trim()}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
          </div>
          <div className={classes.tabContent3}>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Email"
                name="email"
                placeholder="please enter name"
                onChange={handleChange}
                value={values.email}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Expiry Month(e.g 09)"
                name="expiryMonth"
                placeholder="please enter number"
                value={values.expiryMonth}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
          </div>
          <div className={classes.tabContent3}>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Expiry Year(e.g 21)"
                name="expiryYear"
                placeholder="please enter year"
                onChange={handleChange}
                value={values.expiryYear}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Last 4 digit"
                name="last4digit"
                placeholder="please enter digit"
                value={values.last4digit}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
          </div>
          <div className={classes.tabContent3}>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Account Name"
                name="name"
                placeholder="please enter name"
                onChange={handleChange}
                value={values.name}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Account Pin"
                name="pin"
                type="password"
                placeholder="please enter pin"
                value={values.pin}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
          </div>
          <div className={classes.tabContent3}>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="Surname"
                name="surname"
                placeholder="please enter surname"
                value={values.surname}
                onChange={handleChange}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="cvv"
                name="cvv"
                placeholder="please enter name"
                onChange={handleChange}
                value={values.cvv}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
          </div>
          <Button
            variant="contained"
            className={classes.btnp3}
            onClick={() => addCards()}
          >
            Add Bank Account
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
};

function AccountOtp(props) {
  const classes = useBankStyles();
  const admin = getUserData();
  const adminId = admin.id;
  const { handleOpenOtp, handleCloseOtp, userId, datas } = props;
  const state = {
    adminId: adminId,
    otp: '',
    reference: '',
    userId: userId,
  };
  const [value, setValue] = useState(state);

  const handleViewOpen = () => {
    handleOpenOtp();
  };
  const handleViewClose = () => {
    handleCloseOtp();
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    const { value, name } = event.target;
    setValue((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const submitOtp = async () => {
    const datas = { ...value, userId: userId };
    // setLoading(true);
    const response = await postRequest(
      `card-service/api/admin/submitOtp`,
      datas,
      'http://157.230.223.54:3020'
    );
    // setLoading(false);

    if (!response || response.error !== undefined) {
      return;
    }
    console.log(response);
    const { data, message } = response.data;
    // setBank(data);
    success(message);
    setValue({ ...state, name: '' });
    // getBanks();
  };

  console.log(value);

  return (
    <div className={classes.root}>
      <Modal
        show={handleViewOpen}
        onHide={handleViewClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        style={{ marginTop: 0, minWidth: '100%' }}
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <Modal.Body>
          <div className={classes.tabContent}>
            <div className={classes.name2}>
              <Typography variant="h6" style={{ marginLeft: '20px' }}>
                Submit OTP to verify Card Linking
              </Typography>
            </div>
          </div>
          <div className={classes.tabContent2}>
            <div className={classes.link2}>
              <TextField
                className={classes.textme3}
                id="outlined-select-currency-native"
                label="OTP"
                name="otp"
                placeholder="please enter OTP"
                onChange={handleChange}
                value={value.otp}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              ></TextField>
            </div>
          </div>
          <Button
            variant="contained"
            className={classes.btnp4}
            onClick={() => submitOtp()}
          >
            Proceed
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}
