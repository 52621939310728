import React from 'react'
import Avatar from "@mui/material/Avatar";
import { FiPhone } from 'react-icons/fi'
import { MdOutlineEmail } from 'react-icons/md'
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  cardAvatar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  
  h2: {
    fontSize: "16px",
    fontWeight: 600,
    
  },
  p: {
    fontSize: '12px',

  }
}));

const UserProfile = () => {
  const theme = useTheme();
  const classes = useStyles({theme})
  return (
    <div className={classes.cardAvatar}>
      <div className={classes.Avatar}>
      <Avatar alt="Remy Sharp" src="https://joeschmoe.io/api/v1/random" 
      sx={{ width: 150, height: 150 }} />
      </div>
       <h2 className={classes.h2}>Zuleiha</h2>
       <p className={classes.p}> <FiPhone/> 09087654321</p>
       <p className={classes.p}> <MdOutlineEmail/> zuleiha@wayapaychat.com</p>
    </div>
  )
}

export default UserProfile
