import { CommentOutlined, EditOutlined, HeartOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Empty } from 'antd'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import LoadingComponent from '../../../components/loading/LoadingComponent';
import config from '../../../services/config';
import { getRequest } from '../../../services/request';

const HashtagDetails = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimits] = useState(20);
  const {id} = useParams();
  const push = useNavigate();

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `admin-get-post-by-hashtag?query=${id?.trim()}&page=${page}&limit=${limit}`,
      config.postUrl
    );
    setLoading(false);
    const { data } = response?.data || {};
    //setMetaData(metadata?.total || 0)
    setPosts(data?.data || []);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Button onClick={()=>push('/management/hashtag')}>Back to Hashtags</Button>
      <LoadingComponent loading={loading} />
      <div className='row'>
        {posts.length === 0 && <Empty />}
        {posts.map(post =>(
          <div className="col-md-4" key={Math.random()}>
            <Card
              style={{ width: 300, marginTop: 16 }}
              actions={[
                <span><EditOutlined key="edit" />{post.repostCount}</span>,
                <span><HeartOutlined key="likes" />{post.likeCount}</span>,
                <span><CommentOutlined key="comment" />{post.commentCount}</span>,
              ]}
            >
              <Card.Meta
                avatar={<Avatar src={post?.Profile?.avatar || "https://joeschmoe.io/api/v1/random"} />}
                title={<span className="play">{post?.Profile?.displayName || 'username'}</span>}
                description={post.description}
              />
            </Card>
          </div>
        ))}
      </div>
    </div>
  )
}

export default HashtagDetails