  import React, { useState, useEffect } from "react";
  import { useNavigate } from "react-router-dom";
  import config from "../../services/config";
  import { Modal, Form, Button, notification, Upload, Input, Table, Space, Pagination } from "antd";
  import { getRequest, postRequest, putRequest } from "../../services/request";
  import { getUserData } from "../../services/helpers";
  import AntDropdown from "../../components/dropdown/AntDropdown";
  import useMatStyles from "../users/UserListView/WebPos/style";
import CsvDownload from "react-json-to-csv";

  const Contests = () => {
    const navigate = useNavigate();
    const styles = useMatStyles();
    const [loading, setLoading] = useState(false);
    const [fullData, setFullData] = useState([]);
    const [createHostModal, setSShowUpdateSettings] = useState(false);
    const [reload, setReload] = useState(false);
    const [selectedUser, setSelectedUser] = useState([])
    const [contestsData, setContestsData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [stat, setStat] = useState({});
    const [inActiveContestData, setInactiveContestsData] = useState({});
    const [closedContestData, setClosedContestsData] = useState({});
    const [isInActive, setIsInActive] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [values, setValues] = useState('');
    const [contestCount, setContestCount] = useState([]);
    const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 1000,
    });

    const getContests = async (page, size) => {
      const pages = page? page : pagination.current;
      const limit = size ? size : pagination.pageSize;
      let userData = await getUserData();
      console.log({userData})
      const resp= await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);
      console.log({ resp})
      let response;
        response = await getRequest(
          `contests?page=${pages}&limit=${limit}&profileId=${resp?.data?.data?.id}`,
          config.contestUrl,
          true
        );
      const response2 = await getRequest(
        `contests?page=${pages}&limit=${limit}&profileId=${resp?.data?.data?.id}&status=INACTIVE`,
        config.contestUrl,
        true
      );
      const vo = await getRequest(
        `contests/votes?profileId=${resp?.data?.data?.id}`,
        config.contestUrl,
        true
      );
      const response3 = await getRequest(
        `contests?page=${pages}&limit=${limit}&profileId=${resp?.data?.data?.id}&status=CLOSED`,
        config.contestUrl,
        true
      );
        const { data, status, metadata } = response?.data || [];
        if (status) {
          setStat({
            ...stat,
            totalContest: metadata?.total,
            actveContest: metadata?.total,
            inActiveContest: response2?.data?.metadata?.total,
            closed: response3?.data?.metadata?.total,
            totalVote: vo?.data?.metadata?.total,
          })
          setContestsData(data);
          setInactiveContestsData(response2?.data?.data);
          setClosedContestsData(response3?.data?.data);
        }    
    };
    const handleSearch = async (e) => {
      setValues(e.target.value);
      if (e.target.value !== '') {
        const response = await getRequest(
          `contests/search-contest?searchTerm=${e.target.value}`,
          config.contestUrl,
          true
        );
        const { data, status, metadata } = response || [];
        console.log({ hh: response.data});
        if (response.status) {
          setSearchData(response?.data?.data || response?.data || []);
        }
      }    
    }
    // const getInactiveContests = async () => {
    //   let userData = await getUserData();
    //   console.log({userData})
    //   const resp= await getRequest(`get-user-data?user_id=${userData?.id}`, config.roleUrl);
    //   console.log({ resp})
    //   let response;
    //     response = await getRequest(
    //       `contests?profileId=${resp?.data?.data?.id}&status=INACTIVE`,
    //       config.contestUrl,
    //       true
    //     );
    //     const { data, status } = response?.data || [];
    //     if (status) {
    //       setContestsData(data);
    //     }    
    // };
    const handleDropdownAction = (item, row) => {
      if (item === "View more") {
        navigate(`/contest/details/${row?.contest?.id ?? row?.id}`);
      }
    };
    const handleUpdateSettings = async () => {
      setLoading(true);
      let postData = {
          profileId: selectedData?.id,
          uploadLimit: selectedData?.uploadLimit,
          voteLimit: selectedData?.voteLimit
        }
      const url = '/users/update/settings'
      const res = await putRequest(url, postData, config.contestUrl);
      const { status, data, message } = res?.data || {};
      if (status) {
        notification.success({ description: message, message: "success!" });
        setSShowUpdateSettings(false);
        setReload(!reload);
      } else {
        notification.error({ description: message, message: "Oops!" });
      }
      setLoading(false);
    };

    const columns = [
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        render: (_, record) => record?.contest?.title ||  record?.title   },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (_, record) => record?.contest?.description || record?.description
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) => record?.contest?.status || record?.status
      },
      {
        title: 'Stage',
        dataIndex: 'stage',
        key: 'stage',
        render: (_, record) => record?.contest?.stage || record?.stage
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (_, record) => record?.contest?.type || record?.type
      },
      {
        title: 'Winner Prize',
        dataIndex: 'winnerPrize',
        key: 'winnerPrize',
        render: (_, record) => record?.contest?.winnerPrize || record?.winnerPrize
      },
      {
          title: 'Registration Starting date',
          dataIndex: 'registrationStartDate',
          key: 'registrationStartDate',
          render: (_, record) => record?.contest?.registrationStartDate || record?.registrationStartDate
      },
      {
          title: 'Registration Ending Date',
          dataIndex: 'registrationEndDate',
          key: 'registrationEndDate',
          render: (_, record) => record?.contest?.registrationEndDate || record?.registrationStartDate
      },
      {
          title: 'Stage 1 Starting date',
          dataIndex: 'stageOneStartDate',
          key: 'stageOneStartDate',
          render: (_, record) => record?.contest?.stageOneStartDate || record?.stageOneStartDate || 'NA'

      },
      {
          title: 'Stage 1 Ending Date',
          dataIndex: 'stageOneEndDate',
          key: 'stageOneEndDate',
          render: (_, record) => record?.contest?.stageOneEndDate || record?.contest?.stageOneEndDate || 'NA'
      },
      {
        title: 'Stage 2 Starting date',
        dataIndex: 'stageTwoStartDate',
        key: 'stageTwoStartDate',
        render: (_, record) => record?.contest?.stageTwoStartDate || record?.stageTwoStartDate || 'NA'
      },
      {
          title: 'Stage 2 Ending Date',
          dataIndex: 'stageTwoEndDate',
          key: 'stageTwoEndDate',
          render: (_, record) => record?.contest?.stageTwoEndDate || record?.contest?.stageTwoEndDate || 'NA'
      },
      {
        title: 'Stage 3 Starting date',
        dataIndex: 'stageThreeStartDate',
        key: 'stageThreeStartDate',
        render: (_, record) => record?.contest?.stageThreeStartDate || record?.stageThreeStartDate || 'NA'

      },
      {
          title: 'Stage 3 Ending Date',
          dataIndex: 'stageThreeEndDate',
          key: 'stageThreeEndDate',
          render: (_, record) => record?.contest?.stageThreeEndDate || record?.contest?.stageThreeEndDate || 'NA'
      },
      {
        title: 'Action',
        key: 'action',
        render: (_, record) => (
          <div className="row">
            <AntDropdown
              dropDownOptions={['View more']}
              handleDropdownAction={(item) => handleDropdownAction(item, record)}
              isArrow
            />
            {/* <AntDrop
            <EditFilled />
            <Switch
              checked={record?.status === 'ACTIVE' ? true : false}
              onClick={() => handleActivateDeactivate(record?.profileId, record?.status === 'ACTIVE' ? 'DISABLE' : 'ENABLE')}
            /> */}
          </div>
        ),
      },
    ];

    const handleChangePage = async (newPage) => {
      console.log(newPage);
      setPagination({ ...pagination, current: newPage-1 });
      getContests(newPage, pagination.pageSize);
    };

    const handleChangeRowsPerPage = async (page, size) => {
      //alert(size)
      setPagination({ ...pagination, current: 0, pageSize: size });
      getContests(0, size);
    };

    useEffect(() => {
      getContests();
    }, []);
    return (
      <div className="w-100">
        {/* <Button
          size="large"
          loading={loading}
          htmlType="submit"
          type="primary"
          className="rounded px-5"
          onClick={() => setSShowUpdateSettings(true)}
      >
          Create Settings
      </Button> */}
      <Modal
          visible={createHostModal}
          onCancel={() => {
            setSShowUpdateSettings(false)
          }}
          footer={false}
          title={<p className="text-center">Update Settings </p>}
        >
          <div className="my-2">
          <label>Vote Limit</label>

            <input
              placeholder="Vote Limit"
              type='text'
              class='form-control'
              id='validationCustom01'
              value={selectedData?.voteLimit}
              required
              onChange={(e) => setSelectedData({...selectedData, voteLimit:e?.target.value})}
            />
          </div>
          <div className="my-2">
              <label>Upload Limit</label>
            <input
              placeholder="Upload Limit"
              type='text'
              class='form-control'
              id='validationCustom01'
              value={selectedData?.uploadLimit}
              required
              onChange={(e) => setSelectedData({...selectedData, uploadLimit:e?.target.value})}
            />
          </div>
          <div className="text-center">
          <Button
              size="large"
              loading={loading}
              htmlType="submit"
              type="primary"
              className="rounded px-5"
              onClick={handleUpdateSettings}
          >
              Update
          </Button>
          </div>
      </Modal>
      <div>
      <div className={styles.dashboardItem}>
          <div className={`${styles.dashItem} mx-1`}>
            <div className={styles.diTop}>Total Contests</div>
            <div className={styles.dibot}>{stat?.actveContest + stat?.inActiveContest + stat?.closed}</div>
          </div>
          <div className={`${styles.dashItem} mx-1`}>
            <div className={styles.diTop}>Active Contests</div>
            <div className={styles.dibot}>{stat?.actveContest}</div>
          </div>
          <div className={`${styles.dashItem} mx-1`}>
            <div className={styles.diTop}>Inactive Contests</div>
            <div className={styles.dibot}>{stat?.inActiveContest}</div>
          </div>
          <div className={`${styles.dashItem} mx-1`}>
            <div className={styles.diTop}>Closed Contests</div>
            <div className={styles.dibot}>{stat?.closed}</div>
          </div>
          <div className={`${styles.dashItem} mx-1`}>
            <div className={styles.diTop}>Vote Count</div>
            <div className={styles.dibot}>{stat?.totalVote}</div>
          </div>
        </div>
        <div className="text-start">
          <Button
            size="large"
            htmlType="submit"
            type={!isInActive && !isClosed ? 'primary' : ''}
            className="rounded px-5 my-3"
            onClick={() => {
              setIsClosed(false);
              setIsInActive(false);
            }}
          >
            Active Contests
          </Button>
          <Button
            size="large"
            htmlType="submit"
            type={isInActive ? 'primary' : ''}
            className="rounded px-5 mx-2 my-3"
            onClick={() => {
              setIsClosed(false);
              setIsInActive(true);
            }}
          >
            Inactive Contests
          </Button>
          <Button
            size="large"
            htmlType="submit"
            type={isClosed ? 'primary' : ''}
            className="rounded px-5 mx-2 my-3"
            onClick={() => {
              setIsClosed(true);
              setIsInActive(false);
              
            }}
          >
            Closed Contests
          </Button>
            {/* <CsvDownload
              data={isInActive ? inActiveContestData : isClosed ? closedContestData : values.length > 0 ? searchData : contestsData.contest}
              filename='contests.csv'
              style={{
              background: '#ff6700',
              color: '#000',
              fontSize: '15px',
              height: '40px',
              fontWeight: 'bold',
              padding: '6px 24px',
              textDecoration: 'none',
            
            }}
          >
            Export contest
            </CsvDownload> */}
          <div style={{ position: 'relative', margin:20 }} class="col-md-4">
            <div className="my-2">
              <input
                placeholder="Search by title"
                type='text'
                class='form-control'
                id='validationCustom01'
                width={500}
                value={values}
                required
                onChange={handleSearch}
              />
          </div>
        </div>
      </div>
        <Table 
        pagination={false}
        columns={columns} dataSource={isInActive ? inActiveContestData : isClosed ? closedContestData : values.length > 0 ? searchData : contestsData} />
        <Pagination  onShowSizeChange={handleChangeRowsPerPage} pageSize={pagination.pageSize} defaultCurrent={pagination.current} onChange={handleChangePage}
          total={isInActive ? stat.inActiveContest : isClosed ? stat.closed : stat?.actveContest}
        />

      </div>

      </div>
    );
  };

  export default Contests;
