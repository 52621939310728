import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const pageType = [
  {
    value: '',
    label: '--',
  },
  {
    value: 'user',
    label: 'public',
  },
  {
    value: 'group',
    label: 'group',
  },
  {
    value: 'page',
    label: 'page',
  },
];
const Reposted = [
  {
    value: '',
    label: '--',
  },
  {
    value: true,
    label: 'repost',
  },
  {
    value: false,
    label: 'Not Rpost',
  },
];
const Polling = [
  {
    value: '',
    label: '--',
  },
  {
    value: true,
    label: 'poll',
  },
  {
    value: false,
    label: 'Not poll',
  },
];
const Status = [
  {
    value: '',
    label: '--',
  },
  {
    value: true,
    label: 'active',
  },
  {
    value: false,
    label: 'Inactive',
  },
];

const UserFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();

  

  return (
    <>
      <Formik
        initialValues={{
          type: data.type,
          description: data.description,
          isDeleted: data.isDeleted,
          isRepost: data.isRepost,
          profileId: data.ProfileId,
          isPoll: data.isPoll,
          date: data.createdAt,
          repostedPost: data.repostedPost,
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().max(255).required('name is required'),
          isDeleted: Yup.boolean().required('status  is required'),
          description: Yup.string().max(255).required('description is required'),
          isPoll: Yup.boolean().required('Poll is required'),
        })}
        onSubmit={async (values) => {
         
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
                <p style={{marginLeft: '330px', marginBottom: '30px', fontWeight: 800}}> View Post Details</p>
              <div>
                <Grid container spacing={3}>
                 
                  
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Type"
                      name="type"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.type && errors.type)}
                      helperText={touched.type && errors.type}
                      value={values.type}
                      variant="outlined"
                      
                    >
                     {pageType.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Status"
                      name="isDeleted"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.isDeleted && errors.isDeleted)}
                      helperText={touched.isDeleted && errors.isDeleted}
                      value={values.isDeleted}
                      variant="outlined"
                      
                    >
                     {Status.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Repost"
                      name="isRepost"
                      
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.isRepost && errors.isRepost)}
                      helperText={touched.isRepost && errors.isRepost}
                      value={values.repostedPost}
                      variant="outlined"
                      
                    >
                     
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Poll"
                      name="isPoll"
                      select
                      SelectProps={{ native: true }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                     
                      error={Boolean(touched.isPoll && errors.isPoll)}
                      helperText={touched.isPoll && errors.isPoll}
                      value={values.isPoll}
                      variant="outlined"
                      
                    >
                     {Polling.map((option) => (
                      <option key={option.label} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Date Created"
                      name="date"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      value={moment(values.date).format('MM-DD-YYYY')}
                      variant="outlined"
                    />
                  </Grid>
                  
                  
                </Grid>
                {/* <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.createButton}
                    variant="contained"
                    type="submit"
                    disablexd={isSubmitting}
                  >
                    create Page
                  </Button>
                </Box> */}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
