import React from "react";
import Messagepreview from "./Messagepreview";
import { Card, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { createUseStyles, useTheme } from "react-jss";

const useStyles = createUseStyles((theme) => ({
  container: {
    width: 305,
    height: "100%",
    borderRadius: "10px",
  },
  messageContainer: {
    marginTop: "30px",
  },
  messagePreviews: {
    height: "50vh",
    overflowY: "scroll",
    paddingRight: "10px",
  },
  header1: {
    fontSize: "16px",
    color: theme.color.brightOrange,
    paddingBottom: "30px",
    fontWeight: 700,
  },
  header2: {
    fontSize: "16px",
    color: theme.color.textGrey,
    paddingBottom: "30px",
    fontWeight: 700,
  },
}));

const Sidebar = () => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <Card className={classes.container} bodyStyle={{ padding: "12px" }}>
      <Input
        placeholder="Search for a user and start chatting"
        prefix={<SearchOutlined />}
      />

      <div className={classes.messageContainer}>
        <h2 className={classes.header1}>ONLINE</h2>
        <div className={classes.messagePreviews}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((a) => (
            <Messagepreview type="online" />
          ))}
        </div>
      </div>

      <div className={classes.messageContainer}>
        <h2 className={classes.header2}>OFFLINE</h2>
        <div className={classes.messagePreviews}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((a) => (
            <Messagepreview type="offline" />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Sidebar;
