import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from '@mui/material';


const withStaticModal = (WrappedComponent, title, type = 'link', size) => {
  class WayaStaticBackdropModalComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        show: false,
      };
    }

    render() {
      const { show } = this.state;
      // const classes = useStyles();

      const handleClose = () => {
        this.setState({
          ...this.state,
          show: false,
        });
      };

      const handleShow = () => {
        this.setState({
          ...this.state,
          show: true,
        });
      };

      return (
        <>
          {type === 'link' && (
            <div
              onClick={handleShow}
              style={{
                color: 'inherit',
                cursor: 'pointer',
              }}
            >
              {title}
            </div>
          )}
          {type === 'button' && (
            <Button
              color="primary"
              variant="contained"
              onClick={handleShow}
              style={{
                background: '#FF6634',
                color: '#ffffff',
                // padding: '8px 16px',
                '&:hover': {
                  background: '#2D9CDB',
                },
              }}
            >
              {title}
            </Button>
          )}
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size={ size ? size : null }
          >
            <Modal.Header
              style={{
                borderBottom: 'none',
                padding: '0 1rem',
                borderTop: '5px solid #FF6634',
              }}
              closeButton
            ></Modal.Header>
            <Modal.Body>
              <WrappedComponent {...this.props}/>
            </Modal.Body>
          </Modal>
        </>
      );
    }
  }

  return WayaStaticBackdropModalComponent;
};

// export default withStyles(useStyles, {injectTheme: true})(withStaticModal);
export default withStaticModal;
