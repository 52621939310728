import { createTheme , colors } from '@mui/material';
import shadows from './shadows';
import typography from './typography';
import color from './color';

const theme = createTheme ({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      
      main: color.wayaPink,
    },
    secondary: {
      
      main: color.wayaPink,
    },
    danger: {
      main: colors.red[500],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    }
  },
  shadows,
  typography,
  color,
});

export default theme;
