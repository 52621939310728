import React, { useEffect, useState } from "react";
import {
  getRequest,
  // deleteRequestCat,
  postRequest,
  patchRequest,
} from "../../../services/request";
import CsvDownload from "react-json-to-csv";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import config from "../../../services/config";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Pagination,
  Select,
  // Space,
  Table,
  Upload,
} from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { groupColumn } from "../../../utils/dummyData";
import SearchBar from "../../../components/SearchBar";
// import PollImagePreview from '../PollImagePreview';
import { getPageCategories, getUsers } from "../../../services/helpers";
import { InboxOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

const ManageGroupsComponent = ({createButton = false, showRange = false, showSearch = true }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [filterType, setFilterType] = useState("username");
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("a");
  const [users, setUsers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  const [mode, setMode] = useState("");
  const [image, setImage] = useState(null);
  const [headerImage, setHeaderImage] = useState(null);
  const { confirm } = Modal;
  const [form] = Form.useForm();
  const push = useNavigate();
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState('')
  const [createManyPostModal, setcreateManyPostModal] = useState(false);
  const [excelFile, setExcelFile] = useState("");
  const { Dragger } = Upload;
  const [range, setRange] = useState({
    startDate: '',
    endDate: '',
  });

  const selectOptions = [
    {
      value: "username",
      label: "Username",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "phone",
      label: "Phone",
    },
  ];

  const handleChange = (e) => {
    const { value } = e.target;
    if (value) {
      setQuery(value);
    }
  };

  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   if (value === "") {
  //     setData(filteredData);
  //   } else {
  //     const filtered = filteredData.filter((post) =>
  //       post?.description?.toLowerCase().includes(value?.toLowerCase())
  //     );
  //     setData(filtered);
  //   }
  // };

  const handleSubmitPost = async (value) => {
    setLoading(true);
    const newForm = new FormData();

    let url;
    if (mode === 'single') {
      // newForm.append("image", image);
      //newForm.append("headerImage", headerImage);
      newForm.append("userId", value.user_id);
      newForm.append("category", value.category);
      value.category === 'others' && newForm.append("customCategory", value.customCategory);
      newForm.append("description", value.description);
      newForm.append("name", value.title);
      newForm.append("isPublic", value.isPublic);
      newForm.append("mute", value.mute);
      //const url = `admin-group/create-group`;
      // newForm.append();
      url = `groups/admin/create`
      // return;
    } 
    if(mode === 'bulk'){
      newForm.append('excelGroupRecords', excelFile);
      url = `groups/admin/excel-upload`
    }
    const res = await postRequest(url, newForm, config.mainUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      await addPollOptions(data.id);
      notification.success({ description: message, message: "success!" });
      setcreatePostModal(false);
      setcreateManyPostModal(false);
    } 
    else {
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };

  const addPollOptions = async (id) => {
    options?.forEach(async (opt, i) => {
      const newFormOpt = new FormData();
      newFormOpt.append("pollId", id);
      mode === "update" && newFormOpt.append("pollOptionId", opt.pollId);
      newFormOpt.append("option", opt.option);
      newFormOpt.append("position", i + 1);
      newFormOpt.append("short_desc", opt.description);
      opt.file && newFormOpt.append("file", opt.file);
      if (mode === "create")
        await postRequest(
          "admin-poll-option-create",
          newFormOpt,
          config.postUrl
        );
      else
        await patchRequest(
          "admin-poll-option-update",
          newFormOpt,
          config.postUrl
        );
    });
    getData();
  };

  const getData = async (q) => {
    let response;
    // if (q) {
    //   response = await getRequest(
    //     `admin-group/admin-search-all-groups?query=$query=${q}`,
    //     config.mainUrl
    //   );
    // } else {
      let rangeQuery='';
      const filter = q ? `&${filterType}=${q}` : '';
      if (range.startDate && range.endDate) {
        rangeQuery = `&startDate=${range.startDate}&endDate=${range.endDate}`;
      }
      response = await getRequest(
        `admin-group/admin-get-all-groups?page=${pages}${rangeQuery}${filter}`,
        config.mainUrl
      );

    const response1 = await getRequest(
      `admin-group/admin-get-group-analytics`,
      config.mainUrl
    );

    const { data, metadata, totalGroups } = response?.data || {};
    const total = response1?.data?.data || {};

    setTotal(metadata?.total || 0);
    setMetaData({
      total_group: total?.total_group,
      total_public_group: total?.total_public_group,
      total_private_group: total?.total_private_group,
    });
    setFilteredData(data);
    setData(data);
    setLoading(false);
  };

  // const getOverview = async () => {
  //   const response = await getRequest(
  //     "admin-get-poll-overview",
  //     config.postUrl
  //   );
  //   const {data} = response?.data || {};
  //   setMetaData(data)
  // };

  const handleManage = async (action, id) => {
    const payload = {
      action,
      poll_id: id,
    };
    setLoading(true);
    const res = await patchRequest(
      `admin-manage-poll-post`,
      payload,
      config.postUrl
    );
    const { status, message } = res?.data || {};
    if (status) {
      notification.success({ description: message, message: "success!" });
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    getData();
  };

  const handleConfirm = (status, id) => {
    confirm({
      title: `Do you want to ${status} this poll?`,
      icon: <ExclamationCircleOutlined />,
      content: "Click OK to Continue",
      onOk() {
        handleManage(status, id);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    getUsers(value, setUsers);
  }, [value]);

  useEffect(() => {
    getData(query);
  }, [pages, query, range]);

  useEffect(() => {
    getPageCategories(setCategories);
  }, []);
  console.log(data);
  const Conversion = () => {
    return (
      <CsvDownload
        data={data}
        filename="groups.csv"
        style={{
          boxShadow: "inset 0px 1px 0px 0px #e184f3",
          backgroundColor: "#27AE60",
          borderRadius: "6px",
          display: "inline-block",
          cursor: "pointer",
          color: "#ffffff",
          fontSize: "15px",
          height: "40px",
          fontWeight: "bold",
          padding: "6px 24px",
          textDecoration: "none",
        }}
      >
        Export Groups
      </CsvDownload>
    );
  };

  const handleModals = (type, value) => {
    if (type === "single") {
      setcreatePostModal(true);
      setMode("single");
    }
    if(type==='bulk'){
      setcreateManyPostModal(true);
      setMode("bulk")
    }
    if (type === "editPost") {
      setcreatePostModal(true);
      setMode("update");
    }
    if (type === "view") {
      push(`/management/group/posts/${value}`);
    }
  };

  const handlePreview = (e, type) => {
    const { files } = e.target;
    if (type === "image") setImage(files);
    else setHeaderImage(files);
  };
  const props = {
    name: 'file',
    multiple: false,
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    customRequest({ onSuccess }){
      setTimeout(() => {
        onSuccess("ok");
      }, 0)
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        setExcelFile(info.file.originFileObj)
        console.log(info.file)
      } else if (status === 'error') {
        console.log(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files[0]);
    },
  };

  const column = groupColumn(handleModals, handleConfirm);
  return (
    <div className="w-100">
      <SearchBar
        page="groups"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        hideSelect={true}
        placeholder="Search by Group name or Description"
        selectOptions={selectOptions}
        createButton={createButton}
        showRange={showRange}
        showSearch={showSearch}
        setRange={setRange}
      />
      <LoadingComponent loading={loading} />
      <Table
        scroll={{ x: 1500 }}
        columns={column}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
      <Pagination
        total={total}
        responsive={true}
        defaultCurrent={1}
        onChange={(e) => setPages(e)}
        defaultPageSize={20}
        showSizeChanger={false}
      />
      {/* create and update group */}
      <Modal
        visible={createPostModal}
        onCancel={() => setcreatePostModal(false)}
        footer={false}
        title={<p className="text-center">{mode} group</p>}
        width={600}
        >
        <Form
          layout="vertical"
          onFinish={handleSubmitPost}
          form={form}
          className="w-100"
        >
          <div className="row">
            <Form.Item
              name="image"
              label="Profile Picture"
              className="col-md-6 px-3"
            >
              <div className="input-group">
                <input
                  onChange={(e) => handlePreview(e, "image")}
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
              </div>
            </Form.Item>
            <Form.Item
              name="header_image"
              label="Header Image"
              className="col-md-6 px-3"
            >
              <div className="input-group">
                <input
                  onChange={(e) => handlePreview(e, "imageHeader")}
                  type="file"
                  className="form-control"
                  id="inputGroupFile04"
                  aria-describedby="inputGroupFileAddon04"
                  aria-label="Upload"
                />
              </div>
            </Form.Item>
            {/* <Form.Item hidden name="page_id">
              <Input />
            </Form.Item> */}
            <Form.Item
              name="category"
              label="Group Category"
              className="col-md-6 px-3"
            >
              <Select onChange={(e)=>setCategory(e)}>
                <Select.Option>Select Category</Select.Option>
                {categories.map((cat, id) => (
                  <Select.Option key={id} value={cat.title}>
                    {cat.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="customCategory"
              label="Enter Category"
              className="col-md-6 px-3"
              hidden={category !== 'others'}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="user_id"
              label="Username"
              className="col-md-6 px-3"
            >
              <Select
                showSearch
                value={value}
                placeholder="Search for user"
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(e) => setValue(e)}
                // onChange={(e) => setValue(e)}
                notFoundContent={null}
              >
                {users.map((user) => (
                  <Select.Option key={user.id}>
                    {user.displayName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="title"
              label="Group Title"
              className="col-md-6 px-3"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="description"
              label="Group Description"
              className="col-md-6 px-3"
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item name="mute" label="MUTE" className="col-md-6 px-3">
              <Select>
                <Select.Option value={true}>TRUE</Select.Option>
                <Select.Option value={false}>FALSE</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="isPublic"
              label="Group Type"
              className="col-md-6 px-3"
            >
              <Select>
                <Select.Option value={true}>Public</Select.Option>
                <Select.Option value={false}>Private</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <Form.Item className="text-end mt-4">
            <Button
              htmlType="submit"
              type="primary"
              className="rounded px-5"
              loading={loading}
            >
              {mode} Group
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={createManyPostModal}
        onCancel={()=> setcreateManyPostModal(false)}
        footer={false}
        title={<p className='text-center'>Upload Excel Users</p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click or drag file to this area to upload</p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              band files
            </p>
          </Dragger>
           <Form.Item className="text-end mt-4">
            <Button
              htmlType="submit"
              type="primary"
              className="rounded px-5"
              loading={loading}
            >
              {mode} Group
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ManageGroupsComponent;
