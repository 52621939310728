import React from 'react';
import { makeStyles } from '@mui/styles';
import { Modal } from 'react-bootstrap';
import GroupFormComponent from './updateProduct';
import { patchRequest, postRequest } from '../../../services/request';
import { error, success } from '../../../services/swal-mixin';
import LoadingComponent from '../../../components/loading/LoadingComponent'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: '25px 0',
  },

  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 20,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: '5px auto',
  },
  avatar: {
    height: 150,
    width: 150,
    minWidth: 150,
    borderRadius: 100,
    // border: `1px solid ${theme.color.lightGrayishBlue2}`,
  },
}));

const CreateNewPageComponent = (props) => {
  const classes = useStyles();
  const { show, data, handleEditClose, getData} = props;
  const [value, setValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
 
  const handleClose = () => {
    handleEditClose();
  };
  
console.log(data)
  const updateProduct = async (productData) => {
   console.log(productData)
   const formdata = new FormData();
    formdata.append('image', productData.image);
    formdata.append('name', productData.name);
    formdata.append('description', productData.description);
    formdata.append('amount', productData.amount);
    formdata.append('profile_id', productData.profile_id);
    formdata.append('product_id', productData.product_id);
    setLoading(true)
    const response = await patchRequest(
      `admin-update-product`,
      formdata,
      'https://wayagram-gateway.loca.lt/all-posts'
    );
    setLoading(false)
    console.log(response)
    const { status, message } = response.data;
    if (status !== true) {
    return error(message);
    }
    productData = ''
    handleClose()
    getData()
    success(message);
  };

  

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        id="disp"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading}/>
        <Modal.Body>
          <div className={classes.tabContent}>
            <GroupFormComponent value={value} data={data} submitForm={updateProduct} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateNewPageComponent;
