import React, { useState, useEffect } from "react";
import config from "../../services/config";
import { Modal, Form, Button, notification, Upload, Input, Switch, Table, Image } from "antd";
import { getRequest, patchRequest, postRequest, putRequest } from "../../services/request";
import { hideLoader, showLoader } from "../../utils/loader";
// import WayaTable from "../management/WayagramUsers/wayagramTable";

const Flyers = () => {
  const [loading, setLoading] = useState(false);
  const [createFlyerModal, setCreateFlyerModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [categories, setCategories] = useState([]);
  const [flyerName, setFlyerName] = useState('');
  const [images, setImages] = useState([]);
  const [preview, setPreviewImages] = useState('');
  const [showImageModal, setShowImageModal] = useState(false);
  const [form] = Form.useForm()


  const columns = [
    {
      title: 'Flyer Name',
      dataIndex: 'name',
      key: 'category',
      render: (text) => <a>{text}</a>,
    },
    {
        title: 'Flyer',
        dataIndex: 'design',
        key: 'category',
        render: (text) => <Image
        preview={{ visible: false }}
        alt="flyer"
        onClick={() => {
          setPreviewImages(text);
          setShowImageModal(true);
        }}
        src={text}
        width={50}
        height={50}
      />,
      },
  ];
  const handleSubmitPost = async(values) =>{
    setLoading(true);
    showLoader();
    const newForm = new FormData();
    const file = Object.values(images);
    newForm.append('design', file[0]);
    newForm.append('name', values.name);
    const url = `add-flyer`;

    const  res = await postRequest(url, newForm, config.postUrl,true);
    const {status, data, message} = res?.data || {}
    if (status) {
        hideLoader();
      notification.success({description:message, message:'success!'});
      setCreateFlyerModal(false)
      setReload(!reload);
    } else {
        hideLoader();
      notification.error({description:message, message:'Oops!'});
    }
    setLoading(false);
  };
  
  const getFlyers = async () => {
    const response = await getRequest(
        `flyers?page=1&limit=100`,
        config.postUrl,
        true
      );
    const { data, status } = response?.data || [];
    console.log({ response });
    if (status) {
      setCategories(data?.data);
    }
    setLoading(false);
    return status;
  };

  const handlePreview = (e) => {
    const { files } = e.target;
    if (files.length > 10) {
      notification.warning('Oops!', `Upload exceeded limit of 10`, 'error');
      return;
    }
    setImages(files)
  };
  useEffect(() => {
    getFlyers();
  }, [reload]);
  return (
    <div className="w-100">
        <Modal
         visible={showImageModal}
          onCancel={() => {
            setShowImageModal(false)
          }}
          footer={false}
          centered
        >
            <div style={{ textAlign: 'center' }}>
                <Image
                    alt="flyer"
                    src={preview}
                    width={200}
                    height={200}
                />
            </div>
        </Modal>
      <div className="text-end">
      <Button
        size="large"
        loading={loading}
        htmlType="submit"
        type="primary"
        className="rounded px-5 my-3"
        onClick={() => setCreateFlyerModal(true)}
    >
        Add New Flyer
    </Button>
    </div>
    <Modal
        visible={createFlyerModal}
        onCancel={() => {
          setCreateFlyerModal(false)
          setFlyerName('');
        }}
        footer={false}
        title={<p className="text-center">Create Category </p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Form.Item label="Images">
            <div className="input-group">
              <input type="file"
                className="form-control"
                id="inputGroupFile04"
                aria-describedby="inputGroupFileAddon04"
                onChange={handlePreview}
                accept="image/*"
                multiple="multiple"
                aria-label="Upload" />
              <button className="btn btn-outline-secondary" type="button" id="inputGroupFileAddon04">Browse</button>
            </div>
          </Form.Item>
          <Form.Item label="name" name="name">
            <Input.TextArea />
          </Form.Item>      
          <Form.Item className='text-center mt-4'>
            <Button size='large' loading={loading} htmlType='submit' type='primary' className='rounded px-5'>
              Add
            </Button>
          </Form.Item>
        </Form>        
    </Modal>
    <Table columns={columns} dataSource={categories} />
    </div>
  );
};

export default Flyers;
