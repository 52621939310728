import React from 'react'
import { Modal } from 'react-bootstrap';
import { makeStyles } from '@mui/styles';
import UpdateWayaUserComponent from './UpdatewayaModal'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  tabContent2: {
    padding: '25px 0',
    display: 'flex',
    justifyContent: 'space-around',
    marginRight: 50,
  },

  link: {
    marginRight: 80,
  },
  textme: {
    minWidth: '170%',
  },
  textme2: {
    minWidth: '350%',
  },
  
}));






export default function WayaUpdate(props){
  const classes = useStyles();
 
  const {wayaShow, userId, handleCloseWayaUser, getWayaUsers } = props;

  
  const handleViewClose =()=>{
    handleCloseWayaUser()
  }
  return(

    <div className={classes.root}>
        <Modal
          show={wayaShow}
          onHide={handleViewClose}
          backdrop="none"
          keyboard={false}
          size="lg"
          style={{ marginTop: 50, minWidth: '100%' }}
        >
          <Modal.Header
            style={{
              borderBottom: 'none',
              padding: '0 1rem',
              borderTop: '5px solid #FF6634',
            }}
            closeButton
          ></Modal.Header>
          <Modal.Body>
            <UpdateWayaUserComponent data={userId} hideModal={handleViewClose} reload={getWayaUsers}/>
          </Modal.Body>
        </Modal>
      </div>
  )
}