import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getUserData } from '../../../services/helpers';
import Box from '@mui/material/Box';


const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar:{
    width: "300px"
  },
}));

const pageType = [
  {
    value: '',
    label: '--',
  },
  {
    value: 'user',
    label: 'public',
  },
  
];
const Reposted = [
  {
    value: '',
    label: '--',
  },
  {
    value: true,
    label: 'terms',
  },
  {
    value: false,
    label: 'No terms',
  },
];
const Polling = [
  {
    value: '',
    label: '--',
  },
  
  {
    value: false,
    label: 'Not poll',
  },
];
const Status = [
  {
    value: '',
    label: '--',
  },
  {
    value: true,
    label: 'active',
  },
  {
    value: false,
    label: 'Inactive',
  },
];

const UserFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();

  return (
    <>
      <Formik
        initialValues={{
          content: '',
          millisecCreatedAt: '',
          millisecUpdatedAt: '',
          file: '',
          profile_id: data.ProfileId,
        }}
        validationSchema={Yup.object().shape({
          // name: Yup.string().max(255).required('name is required'),
          file: Yup.mixed()
          .nullable()
          .notRequired()
          .test("FILE_SIZE", "Uploaded file is too big."),
          content: Yup.string().max(255).required('description is required'),
          
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
                <p style={{marginLeft: '330px', marginBottom: '30px', fontWeight: 800}}> Create Post</p>
              <div>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="content"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      
                      error={Boolean(touched.content && errors.content)}
                      helperText={touched.content && errors.content}
                      value={values.content}
                      variant="outlined"
                    />
                  </Grid>
                  
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Moment Image"
                      name="file"
                      
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="file"
                      error={Boolean(touched.file && errors.file)}
                      helperText={touched.file && errors.file}
                      value={values.file}
                      variant="outlined"
                      
                    >
                     
                    </TextField>
                  </Grid>
                  
                </Grid>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.createButton}
                    variant="contained"
                    type="submit"
                    disablexd={isSubmitting}
                  >
                    create Moment
                  </Button>
                </Box>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
