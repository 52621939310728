import { clearStorage } from '../../services/helpers';
import {
  LOGIN,
  LOGOUT,
  USER_UPDATED,
  SET_MODAL_TYPE,
  POST_UPDATE,
  REFER_UPDATE,
  CORP_ID,
} from '../actionTypes';

export function loginUser() {
  return { type: LOGIN };
}

export function logoutUser() {
  clearStorage();
  return { type: LOGOUT };
}

export function userUpdated() {
  return { type: USER_UPDATED };
}

export function setModalType(payload) {
  return { type: SET_MODAL_TYPE, payload };
}
export function showSmsModal() {
  return { type: SET_MODAL_TYPE};
}
export function showWayaModal() {
  return { type: SET_MODAL_TYPE};
}
export function showWayaPost(payload) {
  return { type: POST_UPDATE, payload};
}
export function showWayaRefer(payload) {
  return { type: REFER_UPDATE, payload};
}
export function showCorpId(payload) {
  return { type: CORP_ID, payload};
}
