import React from 'react';

const usersIcon = (props) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={props.opacity || ''}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.75 15.1318C8.75 15.1318 7.5 15.1318 7.5 13.8708C7.5 12.6099 8.75 8.8269 13.75 8.8269C18.75 8.8269 20 12.6099 20 13.8708C20 15.1318 18.75 15.1318 18.75 15.1318H8.75ZM13.75 7.56592C14.7446 7.56592 15.6984 7.16736 16.4017 6.45791C17.1049 5.74847 17.5 4.78626 17.5 3.78296C17.5 2.77966 17.1049 1.81745 16.4017 1.108C15.6984 0.398561 14.7446 0 13.75 0C12.7554 0 11.8016 0.398561 11.0983 1.108C10.3951 1.81745 10 2.77966 10 3.78296C10 4.78626 10.3951 5.74847 11.0983 6.45791C11.8016 7.16736 12.7554 7.56592 13.75 7.56592ZM6.52 15.1318C6.33469 14.7382 6.24228 14.3066 6.25 13.8708C6.25 12.1622 7.1 10.4031 8.67 9.17998C7.88636 8.9364 7.06994 8.81729 6.25 8.8269C1.25 8.8269 0 12.6099 0 13.8708C0 15.1318 1.25 15.1318 1.25 15.1318H6.52ZM5.625 7.56592C6.4538 7.56592 7.24866 7.23378 7.83471 6.64258C8.42076 6.05138 8.75 5.24954 8.75 4.41345C8.75 3.57737 8.42076 2.77552 7.83471 2.18432C7.24866 1.59312 6.4538 1.26099 5.625 1.26099C4.7962 1.26099 4.00134 1.59312 3.41529 2.18432C2.82924 2.77552 2.5 3.57737 2.5 4.41345C2.5 5.24954 2.82924 6.05138 3.41529 6.64258C4.00134 7.23378 4.7962 7.56592 5.625 7.56592Z"
      fill={props.fill || '#828282'}
    />
  </svg>
);
export default usersIcon;
