import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Table, Pagination } from "antd";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { promotionCol } from "../../../utils/dummyData";
import { getRequest, deleteRequestData, patchRequest } from "../../../services/request";
import config from "../../../services/config";
import SearchBar from '../../../components/SearchBar';
import CsvDownload from 'react-json-to-csv';

const  ManagePromotion = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [currentTab, setCurrentTab] = useState("ManagePromotion");
  const [filterType, setFilterType] = useState("username");
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [query, setQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [value, setValue] = useState("a");
  const [values, setValues] = useState("");


  const selectOptions = [
    {
      value: "username",
      label: "Username",
    },
    {
      value: "email",
      label: "Email",
    },
    {
      value: "phone",
      label: "Phone",
    },
  ];

  const handleChange = (e) => {
    setValues(e.target.value);
    setQuery(e.target.value);
  };




  const getData = async (q) => {
    let response
    const filter = q ? `&${filterType}=${q}` : '';
    // if(q) {
    //   response = await getRequest(
    //     `admin-search-promotions?query=${query}&filterBy=${filterType}&page=${pages}&limit=20`,
    //     config.postUrl
    //   );
    // }else{
      setLoading(true);
      response  = await getRequest(
        `admin-get-all-promotions?page=${pages}&limit=${limits}&${filter}`,
        config.postUrl
      );
    // };
    setLoading(false);
    const { data, metadata } = response?.data?.data || [];
    setPosts(data || []);
    setTotal(metadata?.total || 0);
    
   
    setFilteredPosts(data?.data || []);
  };
  

  const getPromotionOverview = async () => {
    const response = await getRequest(
      "admin-get-promotion-statistics",
      config.postUrl
    );
    const { data } = response?.data || {};
    setMetaData(data);
  };

  useEffect(() => {
    getData(query);
  }, [currentTab, reload, query]);

  useEffect(() => {
    getPromotionOverview();
  }, []);

  const onActivate =async (datum)=>{
    const formData = new FormData()
    formData.append("promotion_id", datum.id)
    await patchRequest (
      `admin-promotion-activate`,
      formData,
      config.postUrl
    )
   await getData()
   await getPromotionOverview()
  }

  const onClose = async(datum)=> {
    const formData = new FormData()
    formData.append("promotion_id", datum.id)
    await patchRequest (
      `admin-promotion-deactivate`,
      formData,
      config.postUrl
      )
      await getData()
      await getPromotionOverview()
  }

  const onDelete= async (datum)=>{
    const formData = new FormData()
    formData.append("promotion_id", datum.id)
    await deleteRequestData (
      `admin-promotion-delete`,
      formData,
      config.postUrl
    )
   await getData()
   await getPromotionOverview()
  }



  const column = promotionCol(onActivate, onClose, onDelete);


  return (
    <Column>
      <Row horizontal="space-between">
      </Row>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
      <SearchBar
        page="promotion"
        total={metaData}
        showButtons={false}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        handleChange={handleChange}

      />
        <Table
          columns={column}
          dataSource={posts}
          rowKey="id"
          loading={loading}
          pagination={false}
        />
        <Pagination
        total={total}
        responsive={true}
        defaultCurrent={1}
        onChange={(e) => setPages(e)}
        defaultPageSize={limits}
        showSizeChanger={false}
      />
       
      </div>
    </Column>
  );
};

export default ManagePromotion;
