import React, { useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getRequest } from '../../services/request';

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
}));

const Gender = [
  {
    value: '--',
    label: 'none',
  },
  {
    value: 'MALE',
    label: 'Male',
  },

  {
    value: 'FEMALE',
    label: 'Female',
  },
];

const UserFormComponent = ({ className, data, submitForm, ...rest }) => {
  const classes = useStyles();
  const id = data ? data.userId : '';
  const email = data ? data.email : '';
  const firstName = data ? data.firstName : '';
  const surname = data ? data.lastName : '';
  const phoneNumber = data ? data.phoneNumber : '';
  const district = data ? data.district : '';
  const dateOfBirth = data ? data.dateOfBirth : '';
  const gender = data ? data.gender : '';
  const middleName = data ? data.middleName : '';
  console.log(data);
  const getRoles = async () => {
    // const response = await getRequest('auth');
    const response = await getRequest(
      'role-service/all',
      'http://68.183.60.114:9086'
    );

    if (!response || response.error !== undefined) {
      return;
    }
    const { data } = response.data;
    console.log(data);
    // setMetaData(data.metaInfo);
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          email,
          firstName,
          surname,
          phoneNumber,
          district,
          dateOfBirth,
          gender,
          middleName,
          address: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required'),
          firstName: Yup.string().max(255).required('First name is required'),
          surname: Yup.string().max(255).required('Last name is required'),
          phoneNumber: Yup.number().required('Phone number is required'),
          gender: Yup.string().required('gender is required'),
          address: Yup.string().required('address is required'),
        })}
        onSubmit={async (values) => {
          console.log(values);
          await submitForm(values, id);
          values = '';
          console.log(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
              <div>
                {/* <Grid container spacing={3}>
                  {roles.map((role) => {
                    return <CheckBox {...role} />;
                  })}
                </Grid> */}
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      SelectProps={{ native: true }}
                      value={values.firstName}
                      variant="outlined"
                      error={Boolean(touched.firstName && errors.firstName)}
                      helperText={touched.firstName && errors.firstName}
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      label="Middle Name"
                      name="middleName"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.middleName && errors.middleName)}
                      helperText={touched.middleName && errors.middleName}
                      value={values.middleName}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Surname"
                      name="surname"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.surname && errors.surname)}
                      helperText={touched.surname && errors.surname}
                      required
                      value={values.surname}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                      required
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Phone Number"
                      name="phoneNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      // type="number"
                      value={values.phoneNumber}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Date of Birth"
                      name="dateOfBirth"
                      placeholder="YYYY-MM-DD"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
                      helperText={touched.dateOfBirth && errors.dateOfBirth}
                      value={values.dateOfBirth}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Gender"
                      name="gender"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.gender && errors.gender)}
                      helperText={touched.gender && errors.gender}
                      required
                      select
                      SelectProps={{ native: true }}
                      variant="outlined"
                    >
                      {Gender.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                    {/* <TextField
                      fullWidth
                      label="Gender"
                      name="gender"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.gender && errors.gender)}
                      helperText={touched.gender && errors.gender}
                      required
                      
                      SelectProps={{ native: true }}
                      value={values.gender}
                      variant="outlined"
                    >
                      
                    </TextField> */}
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Enter district"
                      name="district"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.district && errors.district)}
                      helperText={touched.district && errors.district}
                      value={values.district}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      fullWidth
                      label="Address"
                      name="address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.address && errors.address)}
                      helperText={touched.address && errors.address}
                      value={values.address}
                      variant="outlined"
                    ></TextField>
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    className={classes.createButton}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Update User
                  </Button>
                </Box>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
