import React, { useState, useEffect } from "react";
import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { createUseStyles, useTheme } from "react-jss";
import { Column, Row } from "simple-flexbox";
import PricingDonation from "./Donation";
import PaidPolls from "./PaidPolls";
import Events from "./Events";
import Promotion from "./Promotion";
import Product from "./Product";
import config from "../../../services/config";
import { getRequest, postRequest } from "../../../services/request";



const useStyles = createUseStyles({
  container: {
    padding: "0px 35px",
  },
  back: {
    cursor: "pointer",
  },
});

const EditPricing = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const classes = useStyles({ theme });


  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState([]);

  const getData = async () => {
    setLoading(true);
    const response = await getRequest(
      `admin-get-all-percent-prices`,
      config.postUrl
    );
    
    setLoading(false);
    const { data } = response?.data?.data || [];
    setPost(data || []);
  };

  useEffect(() => {
    getData();
  }, []);




  return (
    <Column className={classes.container}>
      <Column className={classes.back} onClick={() => navigate(-1)}>
        <Row alignItems="center">
          <IoMdArrowBack style={{ marginRight: "23px" }} />
          Back
        </Row>
      </Column>

      <PricingDonation data={post} />
      <PaidPolls data={post} />
      <Events data={post} />
      <Product data={post} />
      <Promotion data={post} />
    </Column>
  );
};

export default EditPricing;
