import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import WayaOfficialCardComponent from '../../components/cards/WayaOfficialCardComponent';
import { Row } from 'simple-flexbox';
import ActionCardComponent from '../../components/cards/ActionCardComponent';
import { IconDollar, IconEditAlt, IconMail, IconProcess, IconTransaction, IconUsers } from '../../assets/icons';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabContent: {
    padding: '10px 0',
  },
  cardsContainer: {
    marginRight: -30,
    marginTop: -30,
    '@media (max-width: 768px)': {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  cardRow: {
    marginTop: 30,
    '@media (max-width: 768px)': {
      marginTop: 0,
    },
  },
  miniCardContainer: {
    flexGrow: 1,
    marginTop: 50,
    '@media (max-width: 768px)': {
      maxWidth: 'none',
    },
  },
}));

const WayaGramOfficialComponent = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const RenderOverview = () => {
    return (
      <Row
        className={classes.cardsContainer}
        wrap
        flexGrow={1}
        horizontal="space-between"
        breakpoints={{ 768: 'column' }}
      >
        <Row
          className={classes.cardRow}
          wrap
          flexGrow={1}
          horizontal="space-between"
          breakpoints={{ 384: 'column' }}
        >
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Generate Users"
            subheader="Generate users for similations"
            icon={IconUsers}
            iconColor="#FF6634"
            buttonTitle="Generate Users"
          />
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Fund Users"
            subheader="Fund simulated users"
            icon={IconDollar}
            iconColor="#27AE60"
            buttonTitle="Fund Users"
          />
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Process Transactions"
            subheader="Process simulated transactions"
            icon={IconProcess}
            iconColor="#F2C94C"
            buttonTitle="Process Fund"
          />
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Debit Users"
            subheader="Debit wallet balance of simulated users"
            icon={IconTransaction}
            iconColor="#2D9CDB"
            buttonTitle="Debit User"
          />
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Create User"
            subheader="Mass creation of users"
            icon={IconEditAlt}
            iconColor="#F2994A"
            buttonTitle="Create User"
          />
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Email Mass Fund"
            subheader="Mass fund users via Email"
            icon={IconMail}
            iconColor="#27AE60"
            buttonTitle="Mass Fund"
          />
        </Row>
      </Row>
    );
  };

  const RenderRecurring = () => {
    return (
      <Row
        className={classes.cardsContainer}
        wrap
        flexGrow={1}
        horizontal="space-between"
        breakpoints={{ 768: 'column' }}
      >
        <Row
          className={classes.cardRow}
          wrap
          flexGrow={1}
          horizontal="space-between"
          breakpoints={{ 384: 'column' }}
        >
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Mass Sign Up"
            subheader="Generate mass accounts for private users and merchants"
            icon={IconUsers}
            iconColor="#FF5349"
            buttonTitle="Register User"
          />
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Credit"
            subheader="Fund simulated users"
            icon={IconDollar}
            iconColor="#27AE60"
            buttonTitle="Credit User"
          />
          <ActionCardComponent
            className={classes.miniCardContainer}
            title="Transact"
            subheader="Simulate transactions between private users and merchants"
            icon={IconProcess}
            iconColor="#F2994A"
            buttonTitle="Process"
          />
        </Row>
      </Row>
    );
  };

  return (
    <div className={classes.root}>
      <div>
        <Box marginBottom={3}>
          <WayaOfficialCardComponent />
        </Box>
        <Tabs value={value} onChange={handleChange} aria-label="Promotions">
          <Tab label="Overview" {...a11yProps(0)} />
          <Tab label="Recurring" {...a11yProps(1)} />
        </Tabs>
      </div>
      <div className={classes.tabContent}>
        <TabPanel value={value} index={0}>
          <RenderOverview />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <RenderRecurring />
        </TabPanel>
      </div>
    </div>
  );
};

export default WayaGramOfficialComponent;
