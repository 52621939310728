import React, { useEffect, useState } from 'react';
import { getRequest, postRequest, patchRequest, deleteRequestData } from '../../../services/request';
import CsvDownload from 'react-json-to-csv';
import config from '../../../services/config';
import { Avatar, Button, Form, Input, List, Modal, notification, Pagination, Select, Table } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { interestColumn } from '../../../utils/dummyData';
import SearchBar from '../../../components/SearchBar';
import LoadingComponent from '../../../components/loading/LoadingComponent';

const ManageInterestsComponent = () => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [singleData, setSingleData] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [metaData, setMetaData] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [userPages, setUserPages] = useState(1);
  const [filterType, setFilterType] = useState("DESCRIPTION");
  const [createPostModal, setcreatePostModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [mode, setMode] = useState("");
  const [viewUsers, setViewUsers] = useState(false);
  const { confirm } = Modal;
  const [form] = Form.useForm()
  const [query, setQuery] = useState("")


  const selectOptions = [
    {
      value: "DESCRIPTION",
      label: "Description",
    },
    {
      value: "NAME",
      label: "Name",
    },
  ];

  const handleChange = (e) => {
    const {value} = e.target
    if (value){
      setQuery(value);
    }
  };

  const handleSubmitPost = async(values) =>{
    setLoading(true);
    const newForm = new FormData();
    mode === 'update' && newForm.append('id', values.id );
    newForm.append('description', values.description);
    newForm.append('title', values.title);
    const url = `admin-interest/${mode === 'create' ? 'createInterest' : 'updateInterest'}`;
    let  res = {}
    if (mode === 'create') {
      res = await postRequest(url, newForm, config.graphUrl);
    }else{
      res = await patchRequest(url, newForm, config.graphUrl);
    }
    const {status, message} = res?.data || {}
    if (status) {
      notification.success({description:message, message:'success!'});
      setcreatePostModal(false)
      setReload(!reload);
    } else {
      notification.error({description:message, message:'Oops!'});
    }
    setLoading(false);
  };

  const getData = async (q) => {
    let response;
    if(q) {
      response = await getRequest(
        `admin-interest/search?page=${pages}&limit=${limits}&query=${q}&filterBy=${filterType}`,
        config.graphUrl
      );
    } else {
      setLoading(true)
      response = await getRequest(
        `admin-interest/interests?page=${pages}&limit=${limits}`,
        config.graphUrl
      );
    }
    const {data, metadata} = response?.data?.data || {};
    setMetaData(metadata?.total || 0)
    setFilteredData(data)
    setData(data);
    setLoading(false)
  };
 
  const handleFetchUsers = async (id) => {
    setLoading(true);
    const response = await getRequest(
      `admin-interest/users-belonging-to-interest?interestId=${id}&page=${userPages}`,
      config.graphUrl
    );
    const { data } = response?.data || {};
    setTotalUser(data?.length || 0)
    setUsers(data || []);
    setLoading(false);
  };

  const handleConfirm = (id) =>{
    confirm({
      title: `Do you want to delete this interest?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Click OK to Continue',
      onOk() {
        deActivate(id)
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const deActivate = async (id) => {
    const datas = {
      id: id
    };
    setLoading(true)
    const response = await deleteRequestData(
      `admin-interest/deleteInterest`,
      datas,
      config.graphUrl
    );
    const { status, message } = response?.data || {};
    if (status) {
      notification.success({message, description:"interest deleted"})
    }else{
      notification.warning({message, description:"delete failed"})
    }
    getData();
  };

  useEffect(() => {
    getData(query);
  }, [pages, reload, query]);



  const Conversion = () => {
    return (
      <CsvDownload
        data={data}
        filename="interest.csv"
        style={{
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
        }}
      >
        Export Interest
      </CsvDownload>
    );
  };

  const handleModals=(type, value)=>{
    if(type==='newPost'){
      setcreatePostModal(true)
      setMode("create")
    }
    if(type==='view'){
      setTotalUser(0);
      setUsers([]);
      setViewUsers(true);
      handleFetchUsers(value.id);
      setSingleData(value);
    }
    if(type==='editPost'){
      setcreatePostModal(true)
      setMode("update")
      form.setFieldsValue({
        description:value.description,
        title:value.title,
        id: value.id
      })
    }
  }

  const column = interestColumn(handleModals, handleConfirm)
  return (
    <div className="w-100">
      <SearchBar
        page="interest"
        total={metaData}
        handleModals={handleModals}
        Conversion={Conversion}
        handleChange={handleChange}
        setFilterType={setFilterType}
        placeholder="Search by Interest and Description"
        selectOptions={selectOptions}
      />
      <LoadingComponent loading={loading} />
      <Table scroll={{ x: 1500 }} columns={column} dataSource={data} rowKey="id" loading={loading} pagination={false} />
      <Pagination
        total={metaData}
        responsive={true}
        defaultCurrent={1}
        onChange={(e)=>setPages(e)}
        defaultPageSize={limits}
        showSizeChanger={false}
      />
      <Modal
        visible={createPostModal}
        onCancel={()=> setcreatePostModal(false)}
        footer={false}
        title={<p className='text-center'>Create new interest</p>}
      >
        <Form layout='vertical' onFinish={handleSubmitPost} form={form}>
          <Form.Item label="Title" name="title">
            <Input />
          </Form.Item>
          {mode === 'update' &&(
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
          )}
          <Form.Item label="Description" name="description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item className='text-center mt-4'>
            <Button size='large' loading={loading} htmlType='submit' type='primary' className='rounded px-5'>
              {mode} interest
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={viewUsers}
        onCancel={()=> setViewUsers(false)}
        footer={false}
        title={<p className='text-center'>{totalUser} Users with {singleData.title} interest</p>}
      >
        <List
          dataSource={users}
          renderItem={item => (
            <List.Item key={item.id}>
              <List.Item.Meta
                avatar={<Avatar src={item.avatar} />}
                title={item.displayName}
                // description={`@${item.username}`}
              />
              <div>{`@${item.username}`}</div>
            </List.Item>
          )}
        />
      </Modal>
    </div>
  );
};

export default ManageInterestsComponent;
