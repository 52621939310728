const color = {
  white: '#ffffff',
  brightBlue: '#3498db',
  darkGrayishBlue: '#8b8d94',
  darkGray: '#E5E5E5',
  darkerGray: '#4F4F4F',
  gray: '#333333',
  lightGray: '#828282',
  lighterGray: '#F2F2F2',
  darkRed: '#a90000',
  wayaPink: '#FF6634',
  grayishBlue: '#A4A6B3',
  grayishBlue2: '#9fa2b4',
  grayishBlue3: '#bdc3c7',
  lightBlue: '#3751FF',
  lightGrayishBlue: '#F7F8FC',
  lightGrayishBlue2: '#DFE0EB',
  paleBlue: '#DDE2FF',
  paleBlueTransparent: 'rgba(221, 226, 255, 0.08)',
  veryDarkGrayishBlue: '#373a47',
  black: '#000000',
  textGrey: '#737171',
  violet: '#9B51E0',
  lightViolet: '#BB6BD9',
  orange: '#F2994A',
  lighterBlue: '#56CCF2',
  yellow: '#F2C94C',
  customWayaRed: '#ff4400',
  wayaLightPink: '#FDECE5',
  darkestGrey: '#898989',
  brightOrange: '#FF4B01',
  lightestGray: '#E0E0E0',
  newLightOrange: '#FFEFDF'
};

export default color;
