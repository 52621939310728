/* eslint-disable no-unreachable */
import React, { useState } from "react";
import { Grid, TextField, Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  getRequest,
  patchRequest,
  putRequest,
  sendWithAuth,
} from "../../../services/request";
import { error, success } from "../../../services/swal-mixin";

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: "#FF6634",
    color: "#ffffff",
    "&:hover": {
      background: "#2D9CDB",
    },
  },
}));

const InterestFormComponent = (props) => {
  const classes = useStyles();
  const { data, refreshData, handleEditClose } = props;

  const state = {
    id: data.id,
    title: data.title,
    description: data.description,
  };
  const [datas, setDatas] = useState(state);

  // const interestTitle = data ? data.title : '';
  // const description = data ? data.description : '';
  // const id = data ? data.id : '';

  // const getData = async () => {
  //   const response = await getRequest(
  //     'admin-interest/interests?page=1&limit=5',
  //     process.env.REACT_APP_BASE_URL + '/wayagram-service/graph/'
  //   );

  //   console.log(response);
  //   const { data, status, message } = response.data;
  //   if (status !== true) {
  //     // return;
  //     error(message);
  //   }
  //   setData(data.data);
  //   success(message)
  // };

  // getData()

  const handleChange = (event) => {
    event.preventDefault();
    // const values = data.find(item => item.id === key)
    const { value, name } = event.target;
    setDatas({ ...datas, [name]: value });
  };

  const updateInterest = async () => {
    const response = await sendWithAuth(
      `admin-interest/updateInterest`,
      JSON.stringify(datas),
      "PATCH",
      process.env.REACT_APP_BASE_URL + "/wayagram-service/graph"
    );

    const { status } = response;
    if (status !== 200) {
      return error("status");
    }
    // await refreshData();
    setDatas(state);
    success("successfully updated");
    handleEditClose();
    refreshData();
    // store.dispatch({ type: 'userUpdated' });
  };

  return (
    <>
      <form
        autoComplete="off"
        // onSubmit={()=>updateInterest()}
        noValidate
        action=""
      >
        <div>
          <div>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  label="Title"
                  name="title"
                  onChange={handleChange}
                  value={datas.title}
                  variant="outlined"
                  required
                  fullWidth
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Description"
                  name="description"
                  onChange={handleChange}
                  required
                  value={datas.description}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                className={classes.createButton}
                variant="contained"
                onClick={() => updateInterest()}
              >
                Update Interest
              </Button>
            </Box>
          </div>
        </div>
      </form>
    </>
  );
};

export default InterestFormComponent;
