import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Grid, TextField, Box, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getUserData } from '../../../services/helpers';

const groupTypes = [
  {
    value: false,
    label: 'false',
  },
  {
    value: true,
    label: 'true',
  },
];

const isMuted = [
  {
    value: false,
    label: 'false',
  },
  {
    value: true,
    label: 'false',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  createButton: {
    background: '#FF6634',
    color: '#ffffff',
    '&:hover': {
      background: '#2D9CDB',
    },
  },
  avatar: {
    width: '300px',
  },
}));

const UserFormComponent = ({
  className,
  data,
  submitForm,
  title = 'Create Group',
  ...rest
}) => {
  const classes = useStyles();

  const userData = getUserData();
  const name = data ? data.name : '';
  const description = data ? data.description : '';
  const isPublic = data ? data.isPublic : true;
  const mute = data.mute ? true : false;
  const headerImage = '';
  const image =  '';
  const userId = data ? data.userId : userData.id.toString();
  const groupId = data ? data.id : null;

  return (
    <>
      <Formik
        initialValues={{
          name,
          description,
          isPublic,
          mute,
          headerImage,
          image,
          userId,
          groupId,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(255).required('First name is required'),
          description: Yup.string().max(255).required('Last name is required'),
          isPublic: Yup.boolean().required('Group type is required'),
        })}
        onSubmit={async (values) => {
          await submitForm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form
            autoComplete="off"
            onSubmit={handleSubmit}
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <div>
              <div>
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <div className={classes.profileCard}>
                      Upload Header Image
                      <TextField
                        className={classes.textme}
                        id="outlined-select-currency-native"
                        type="file"
                        name='headerImage'
                        onChange={handleChange}
                        value={values.headerImage}
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                      ></TextField>
                      {/* {values.headerImage && (
                        <div className={classes.avatarWrapper}>
                          <img
                            src={values.headerImage}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div>
                      )} */}
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <div className={classes.profileCard}>
                      Upload Group Image
                      <TextField
                        className={classes.textme}
                        id="outlined-select-currency-native"
                        type="file"
                        name='image'
                        value={values.image}
                        onChange={handleChange}
                        SelectProps={{
                          native: true,
                        }}
                        variant="outlined"
                      ></TextField>
                      {/* {values.image && (
                        <div className={classes.avatarWrapper}>
                          <img
                            src={values.image}
                            alt="Header"
                            className={classes.avatar}
                          />
                        </div> */}
                      {/* )} */}
                    </div>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    {/* <Button variant="contained" component="label">
                      Upload Header Image
                      <input type="file" hidden /> */}
                    {/* </Button> */}
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    {/* <Button variant="contained" component="label">
                      Upload Group Image
                      <input type="file" hidden />
                    </Button> */}
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      label="Group Name"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      value={values.name}
                      variant="outlined"
                      required
                      fullWidth
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      name="description"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      required
                      value={values.description}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Group Type"
                      name="isPublic"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.isPublic}
                      variant="outlined"
                      error={Boolean(touched.isPublic && errors.isPublic)}
                      helperText={touched.isPublic && errors.isPublic}
                    >
                      {groupTypes.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item lg={6} md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Status"
                      name="mute"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={values.mute}
                      variant="outlined"
                      error={Boolean(touched.mute && errors.mute)}
                      helperText={touched.mute && errors.mute}
                    >
                      {isMuted.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
                <Box display="flex" justifyContent="flex-end" p={2}>
                  
                </Box>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

UserFormComponent.propTypes = {
  className: PropTypes.string,
};

export default UserFormComponent;
