import React, { useState, useEffect } from 'react';
import { Box, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from '../../components/Page';
import LoadingComponent from '../../components/loading/LoadingComponent';
import { getRequest } from '../../services/request';
import LoginTableComponent from './LoginTrackerTable';
import { Row } from 'simple-flexbox';
import swalService from '../../services/swal-mixin';

const useMatStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    width: '100%',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '100%',
  },
}));



const PaymentRequestsComponent = ({
  title = 'Login Tracker',
  userId,
  type = 'sent',
}) => {
  const matclasses = useMatStyles();
  const [loginTracker, setLoginTracker] = useState([]);
  const [metaData, setMetaData] = useState({
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [headers] = useState([
    'Province',
    'Location',
    'Login Device',
    'Login City',
  ]);

  // const getPaymentRequests = async () => {
  //   if (type === 'sent') {
  //     return await getSentPaymentRequest();
  //   }
  //   return await getReceivedPaymentRequest();
  // };

  // const getSentPaymentRequest = async () => {
  //   setLoading(true);
  //   const response = await getRequest(
  //     `payment-request-service/payment-request-sent/${userId}`,
  //     'http://157.230.223.54:9300'
  //   );
  //   setLoading(false);

  //   if (!response) return;
  //   const { data, status, message } = response.data;

  //   if (status === false) {
  //     return;
  //     swalService.error(message);
  //   }
  //   setPaymentRequests(data.request);
  //   data.count = data.totalNumber;
  //   setMetaData(data);
  // };
  const getHistory = async () => {
    setLoading(true);
    const response = await getRequest(
      `api/v1/history/user-history/${userId}`,
      'http://68.183.60.114:9086'
      );
      setLoading(false);

      console.log(response);

    const { data, status, message } = response?.data;

    if (status === false) {
      return swalService.error(message);
    }
    console.log(data);
    setLoginTracker(data)
    // setPaymentRequests(data.request);
    // data.count = data.totalNumber;
    // setMetaData(data);
  };

  // const getPage = async (page) => {
  //   setLoading(true);
  //   const response = await getRequest(`wallet/all-wallets}?page=${page}`);
  //   setLoading(false);

  //   if (!response || response.error !== undefined) {
  //     return;
  //   }
  //   const { data } = response.data;
  //   setPaymentRequests(data.results);
  //   setMetaData(data.metaInfo);
  // };

  // const filterData = async (key, value) => {
  //   const response = await getRequest(`wallets?${key}=${value}`);

  //   if (!response || response.error !== undefined) {
  //     return;
  //   }
  //   const { data } = response.data;
  //   setPaymentRequests(data.results);
  //   setMetaData(data.metaInfo);
  // };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <Page className={matclasses.root} title="Users">
      <LoadingComponent loading={loading} />
      <Container maxWidth={false}>
        <Row>
          <Box marginBottom={2} fontWeight={600}>
            <Row vertical="center" breakpoints={{ 384: 'column' }}>
              {title}
            </Row>
          </Box>
        </Row>
        <Box mt={3} className={matclasses.tableContainer}>
          <LoginTableComponent
            // getPage={getPage}
            data={loginTracker}
            metaData={metaData}
            headers={headers}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default PaymentRequestsComponent;
