import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getFullName } from '../../services/helpers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import { blue, green, red } from '@mui/material/colors';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ViewQRCodeComponent from '../../views/QrCodeComponent/ViewQRCodeComponent';
import ViewPageComponent from '../../views/management/ManagePages/ViewPageComponent';
import ViewDonationComponent from '../../views/management/ViewDonation';
import EditQRCodeComponent from '../../views/QrCodeComponent/EditQRCodeComponent';
import EditPageComponent from '../../views/management/ManagePages/EditPageComponent';
import EditDonationComponent from '../../views/management/ViewEditDonation';
import EditInterestComponent from '../../views/management/ManageInterestsComponent/EditInterestComponent';
import EditAdvertComponent from '../../views/promotions/Adverts/EditAdvertComponent';
import ViewMomentComponent from '../../views/management/Moment/viewMoment';
import CreateNewPageComponent from '../../views/management/ManagePages/PageIndex';
import CreatePostComponent from '../../views/management/ManagePost/CreateIndex';
import ViewPostComponent from '../../views/management/ManagePost/postIndex';
import AddBoxIcon from '@material-ui/icons/AddBox';
import ViewPostImage from '../../views/management/ManagePost/viewPost';
import ViewProducts from '../../views/management/Manage Product/productIndex';
import EditProductComponent from '../../views/management/Manage Product/updateIndex';
class DisplayTableComponent extends React.Component {
  state = {
    anchorEl: null,
    menus: [],
    headers: [],
    data: [],
    metaData: 0,
    limit: 15,
    page: 0,
    selecteduserIds: [],
    showQrCode: false,
    editQrCode: false,
    showPage: false,
    showPost: false,
    showDonation: false,
    showCreate: false,
    createPost: false,
    showMoment: false,
    showImage: false,
    editInterest: false,
    editAdvert: false,
    editPage: false,
    editProduct: false,
    editDonation: false,
    showProduct: false,
    item: {
      qrString: '',
    },
    index: null,
  };

  componentDidMount() {
    this.initializeView();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.metaData !== this.props.metaData
    ) {
      this.initializeView();
    }
  }

  initializeView = () => {
    const data = this.props.data || [];
    const type = this.props.type || '';
    const metaData = this.props.metaData || this.state.metaData;
    const actionCol = 'actionCol' in this.props ? this.props.actionCol : true;
    // const menus = data.map((m) => false);
    this.setState({
      ...this.state,
      // menus,
      data,
      metaData,
      actionCol,
      type,
      headers: this.props.headers || [],
    });
  };

  handleClick = (index) => (event) => {
    const { menus } = this.state;
    menus[index] = true;
    this.setState({ anchorEl: event.currentTarget, menus });
  };

  handleClose = (index) => () => {
    const { menus } = this.state;
    menus[index] = false;
    this.setState({ anchorEl: null, menus });
  };

  handleView = (item, index) => (event) => {
    this.handleClose(index);
    return this.setState({
      ...this.state,

      showQrCode: true,

      showCreate: true,

      // showDonation: true,
      item: item,
      index,
    });
  };

  handleViewClose = () => {
    console.log(this.props);
    const { index } = this.state;
    this.handleClose(index);
    this.setState({
      ...this.state,
      showQrCode: false,
      showPage: false,
      showDonation: false,

      showCreate: false,
    });
  };

  handleViewDonation = (item, index) => (event) => {
    this.handleClose(index);
    return this.setState({
      ...this.state,

      showDonation: true,
      item: item,
      index,
    });
  };
  handleViewProduct = (item, index) => (event) => {
    this.handleClose(index);
    return this.setState({
      ...this.state,

      showProduct: true,
      item: item,
      index,
    });
  };
  handleViewPage = (item, index) => (event) => {
    this.handleClose(index);
    return this.setState({
      ...this.state,

      showPage: true,
      item: item,
      index,
    });
  };
  handleViewPost = (item, index) => (event) => {
    this.handleClose(index);
    return this.setState({
      ...this.state,

      showPost: true,
      item: item,
      index,
    });
  };
  handleCreatePost = (item, index) => (event) => {
    this.handleClose(index);
    return this.setState({
      ...this.state,

      createPost: true,
      item: item,
      index,
    });
  };
  handleViewMoment = (item, index) => (event) => {
    this.handleClose(index);
    return this.setState({
      ...this.state,

      showMoment: true,
      item: item,
      index,
    });
  };
  handleViewPostImage = (item, index) => (event) => {
    console.log('mjbsh', item, index);
    this.handleClose(index);
    return this.setState({
      ...this.state,

      showImage: true,
      item: item,
      index,
    });
  };

  handleViewDonationClose = () => {
    console.log(this.props);
    const { index } = this.state;
    this.handleClose(index);
    this.setState({
      ...this.state,

      showDonation: false,
    });
  };
  handleViewCloseMoment = () => {
    console.log(this.props);
    const { index } = this.state;
    this.handleClose(index);
    this.setState({
      ...this.state,

      showMoment: false,
    });
  };
  handleClosePostImage = () => {
    console.log(this.props);
    const { index } = this.state;
    this.handleClose(index);
    this.setState({
      ...this.state,

      showImage: false,
    });
  };

  handleViewClosePost = () => {
    console.log(this.props);
    const { index } = this.state;
    this.handleClose(index);
    this.setState({
      ...this.state,

      showPost: false,
    });
  };

  handleCloseProduct = () => {
    console.log(this.props);
    const { index } = this.state;
    this.handleClose(index);
    this.setState({
      ...this.state,

      showProduct: false,
    });
  };

  handleViewCloseCreatePost = () => {
    console.log(this.props);
    const { index } = this.state;
    this.handleClose(index);
    this.setState({
      ...this.state,

      createPost: false,
    });
  };

  handleEdit = (item, index) => (event) => {
    this.handleClose(index);
    if (this.state.type === 'interests') {
      return this.setState({ ...this.state, editInterest: true, item, index });
    }
    if (this.state.type === 'advert') {
      return this.setState({ ...this.state, editAdvert: true, item, index });
    }
    if (this.state.type === 'qrCode') {
      this.setState({ ...this.state, editQrCode: true, item: item, index });
    }
    if (this.state.type === 'donation') {
      this.setState({ ...this.state, editDonation: true, item: item, index });
    }
    if (this.state.type === 'page') {
      this.setState({ ...this.state, editPage: true, item, index });
    }
    return this.setState({ ...this.state, editProduct: true, item, index });
  };

  handleEditClose = () => {
    const { index } = this.state;
    this.handleClose(index);
    if (this.state.type === 'interests') {
      return this.setState({ ...this.state, editInterest: false });
    }
    if (this.state.type === 'advert') {
      return this.setState({ ...this.state, editAdvert: false });
    }
    if (this.state.type === 'qrCode') {
      this.setState({ ...this.state, editQrCode: false });
    }
    if (this.state.type === 'donation') {
      this.setState({ ...this.state, editDonation: false });
    }
    if (this.state.type === 'products') {
      this.setState({ ...this.state, editPage: false });
    }
    return this.setState({ ...this.state, editProduct: false });
  };

  handleCreateDonation = (item, index) => (event) => {
    this.handleClose(index);

    if (this.state.type === 'donation') {
      this.setState({ ...this.state, editDonation: true, item: item, index });
    }
    return;
  };

  handleCreateDonationClose = () => {
    const { index } = this.state;
    this.handleClose(index);

    if (this.state.type === 'donation') {
      this.setState({ ...this.state, editDonation: false });
    }
    return;
  };
  handleActivate = (item) => {
    // alert(item.name);
    console.log('**********', item)
    if ('activate' in this.props) {
      return this.props.activate(item);
    }
    alert('No function passed');
  };

  handleDelete = (item) => {
    console.log(this.props);
    if ('delete' in this.props) {
      return this.props.delete(item);
    }
    alert('No function passed');
  };

  handProductDelete = (profileId, productId) => {
    console.log(this.props);
    if ('delete' in this.props) {
      return this.props.delete(profileId, productId);
    }
    alert('No function passed');
  };

  handleMomentDelete = (user) => {
    console.log(user);

    if ('delete' in this.props) {
      return this.props.delete(user);
    }
    alert('No function passed');
  };

  pageDelete = (item) => {
    if ('delete' in this.props) {
      return this.props.delete(item);
    }
    alert('not working');
  };
  pageActivate = (item) => {
    if ('activate' in this.props) {
      return this.props.activate(item);
    }
    alert('not working');
    // console.log(this.props.activate)
  };

  
  donationDeactivate = (item, profileId) => {
    console.log('***********', item, profileId, this.props);
    if ('deactivate' in this.props) {
      return this.props.deactivate(item, profileId);
    }
    alert('not working');
    // console.log(this.props.activate)
  };
  productDeactivate = (item) => {
    console.log('***********', item, this.props);
    if ('deactivate' in this.props) {
      return this.props.deactivate(item);
    }
    alert('not working');
    // console.log(this.props.activate)
  };
  handDelete = async (id) => {
    // return this.props.delete(id);
    if ('delete' in this.props) {
      return this.props.delete(id);
    }
    alert('not matched');
  };

  handleLimitChange = (event) => {
    this.setState({ ...this.state, limit: event.target.value });
  };

  handlePageChange = (event, newPage) => {
    const page = this.state.page + 1;
    let limit = this.state.limit;
    console.log(this.props);
    this.props.getPage(page, limit);
    this.setState({ ...this.state, page: newPage });
  };

  render() {
    const {
      headers,
      data,
      metaData,
      limit,
      page,
      selecteduserIds,
      actionCol = false,
      item,
      showQrCode,
      showPage,
      showPost,
      showCreate,
      createPost,
      showDonation,
      editQrCode,
      editInterest,
      editPage,
      editProduct,
      editDonation,
      editAdvert,
      showMoment,
      showImage,
      showProduct,
      type,
    } = this.state;

    return (
      <Card>
        <PerfectScrollbar>
          <Box overflow="auto">
            <Table>
              <TableHead>
                <TableRow>
                  {headers.map((header, index) => (
                    <TableCell
                      key={index}
                      align="left"
                      style={{ backgroundColor: '#fbe2dd', color: '#e7472e' }}
                    >
                      {' '}
                      <pre style={{ color: '#e7472e', fontSize: '14px' }}>
                        {header}
                      </pre>
                    </TableCell>
                  ))}

                  {actionCol ? (
                    <TableCell
                      align="right"
                      style={{ backgroundColor: '#fbe2dd', color: '#e7472e' }}
                    >
                      <pre style={{ color: '#e7472e', fontSize: '14px' }}>
                        Action
                      </pre>
                    </TableCell>
                  ) : (
                    ''
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.slice(0, limit).map((user, index) => (
                  <TableRow
                    hover
                    key={user.id}
                    selected={selecteduserIds.indexOf(user.userId) !== -1}
                  >
                    {/* <TableCell>{user.userName}</TableCell> */}
                    {user.action && (
                      <TableCell>{user.action.toUpperCase()}</TableCell>
                    )}
                    {user.firstName && (
                      <TableCell>
                        {user.firstName ? (
                          <Box marginBottom={1}>{user.firstName}</Box>
                        ) : (
                          <Box>N/A</Box>
                        )}
                        {/* {user.user && (
                      <TableCell>
                        {user.user.firstName ? (
                          <Box marginBottom={1}>{getFullName(user.user)}</Box>
                        ) : (
                          <Box>N/A</Box>
                        )} */}
                      </TableCell>
                    )}

                    {user.profile && (
                      <TableCell>
                        {user.profile.user ? (
                          <Box marginBottom={1}>
                            {getFullName(user.profile.user)}
                          </Box>
                        ) : (
                          <Box>N/A</Box>
                        )}
                      </TableCell>
                    )}
                    {/* {user.qrKey && (
                      <TableCell>
                        {user.user ? (
                          <>
                            <Box marginBottom={1}>
                              <span
                                style={{
                                  color: '#FF6634',
                                }}
                              >
                                Assigned to:{' '}
                              </span>
                              {getFullName(user.user)}
                            </Box>
                            <Box marginBottom={1}>
                              <span
                                style={{
                                  color: '#FF6634',
                                }}
                              >
                                Agent:{' '}
                              </span>
                              {getFullName(user.agent)}
                            </Box>
                          </>
                        ) : (
                          <Box color="error.main" marginBottom={1}>
                            Not Assigned
                          </Box>
                        )}
                      </TableCell>
                    )} */}
                    {user.detail && (
                      <TableCell>
                        {user.detail.SMSMessageData && (
                          <>
                            <Box marginBottom={1}>
                              <span
                                style={{
                                  color: '#FF6634',
                                }}
                              >
                                To:{' '}
                              </span>
                              {user.detail.SMSMessageData.Recipients.length > 1
                                ? user.detail.SMSMessageData.Recipients[0]
                                    .number
                                : 'Multiple Recipients.'}
                            </Box>
                            <Box marginBottom={1}>
                              <span
                                style={{
                                  color: '#FF6634',
                                }}
                              >
                                Message:{' '}
                              </span>
                              {user.detail.SMSMessageData.Message}
                            </Box>
                          </>
                        )}
                        {user.detail.role && (
                          <Box marginBottom={1}>
                            <span
                              style={{
                                color: '#FF6634',
                              }}
                            >
                              Role:{' '}
                            </span>
                            {user.detail.role}
                          </Box>
                        )}
                        {user.detail.email && (
                          <Box marginBottom={1}>
                            <span
                              style={{
                                color: '#FF6634',
                              }}
                            >
                              Email:{' '}
                            </span>
                            {user.detail.email}
                          </Box>
                        )}
                        {user.detail.phone && (
                          <Box marginBottom={1}>
                            <span
                              style={{
                                color: '#FF6634',
                              }}
                            >
                              Phone:{' '}
                            </span>
                            {user.detail.phone}
                          </Box>
                        )}
                        {user.detail.info && (
                          <>
                            <Box marginBottom={1}>
                              <span
                                style={{
                                  color: '#FF6634',
                                }}
                              >
                                From:{' '}
                              </span>
                              {user.detail.info.envelope.from && (
                                <span>{user.detail.info.envelope.from}</span>
                              )}
                            </Box>
                            <Box marginBottom={1}>
                              <span
                                style={{
                                  color: '#FF6634',
                                }}
                              >
                                To:{' '}
                              </span>
                              {user.detail.info.envelope.to && (
                                <span>
                                  {user.detail.info.envelope.to.join(',')}
                                </span>
                              )}
                            </Box>
                          </>
                        )}
                        {user.detail.html && (
                          <Box marginBottom={1}>
                            <span
                              style={{
                                color: '#FF6634',
                              }}
                            >
                              Message:{' '}
                            </span>
                            {user.detail.html}
                          </Box>
                        )}
                      </TableCell>
                    )}

                    {headers.includes('Username') && (
                      <TableCell>{user.username || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Creator') && (
                      <TableCell>{user.userName || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Fullname') &&
                      user.Profile.displayName &&
                      type === 'moment' && (
                        <TableCell style={{ fontSize: '12px' }}>
                          {user.Profile.displayName || 'N/A'}
                        </TableCell>
                      )}
                    {headers.includes('Moment') && (
                      <TableCell>
                        <Button
                          variant="outlined"
                          style={{ backgroundColor: 'red' }}
                          onClick={this.handleViewMoment(user, index)}
                        >
                          view
                        </Button>
                      </TableCell>
                    )}
                    {user.viewCount && headers.includes('Views') && (
                      <TableCell>{user.viewCount || 'N/A'}</TableCell>
                    )}

                    {/* {user.status && <TableCell>{user.status}</TableCell>} */}
                    {user.AccountNumber && (
                      <TableCell>{user.AccountNumber}</TableCell>
                    )}

                    {/* {user.isPublic && headers.includes('Type') && (
                      <TableCell>{user.isPublic ?  'Public' : 'Private'}</TableCell>
                    )} */}
                    {user.qrKey && <TableCell>{user.qrKey}</TableCell>}
                    {/* {user.content && (
                      <TableCell>{user.content || 'N/A'}</TableCell>
                    )} */}

                    {/* {user.imageUrl && (
                      <TableCell>{user.imageUrl || 'N/A'}</TableCell>
                    )} */}
                    {/* {user.mainImage && (
                      <TableCell>{user.mainImage || 'N/A'}</TableCell>
                    )} */}
                    {/* {user.estimatedAmount && (
                      <TableCell>{user.estimatedAmount || 'N/A'}</TableCell>
                    )}
                    {user.currentAmount && (
                      <TableCell>{user.currentAmount || 'N/A'}</TableCell>
                    )} */}

                    {/* {user.commentCount && (
                      <TableCell>{user.commentCount || 'N/A'}</TableCell>
                    )}
                    {user.likesCount && (
                      <TableCell>{user.likesCount || 'N/A'}</TableCell>
                    )} */}
                    {/* {user.createdAt && (
                      <TableCell>
                        {user.createdAt}
                        </TableCell>
                      )} */}
                    {/* {headers.includes('Time') && user.time && (
                      <TableCell>{user.time}</TableCell>
                    )} */}

                    {/* {headers.includes('Time') && user.time && (
                      <TableCell>
                        {moment(user.time).format('hh:mm:ss A')}
                      </TableCell>
                    )} */}
                    {headers.includes('Page Name') && (
                      <TableCell>{user.title || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Product Name') && (
                      <TableCell>{user.name || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Title') && type !== 'page' && (
                      <TableCell style={{ fontSize: '12px' }}>
                        <pre style={{ fontFamily: 'san-serif' }}>
                          {user.title || 'N/A'}
                        </pre>
                      </TableCell>
                    )}

                    {user.description && type === 'interests' && (
                      <TableCell style={{ fontSize: '12px' }}>
                        {user.description || 'N/A'}
                      </TableCell>
                    )}
                    {user.description && type === 'products' && (
                      <TableCell style={{ fontSize: '12px' }}>
                        {user.description || 'N/A'}
                      </TableCell>
                    )}

                    {user.amount && type === 'products' && (
                      <TableCell align="left">{user.amount || 'N/A'}</TableCell>
                    )}

                    {user?.Profile?.displayName && type === 'post' && (
                      <TableCell align="left" style={{ fontSize: '12px' }}>
                        <pre style={{ fontFamily: 'san-serif' }}>
                          {user.Profile ? user?.Profile?.displayName : 'N/A'}
                        </pre>
                      </TableCell>
                    )}
                    {user.username && type !== 'page' && (
                      <TableCell style={{ fontSize: '12px' }}>
                        <pre style={{ fontFamily: 'san-serif' }}>
                          {user.username || 'N/A'}
                        </pre>
                      </TableCell>
                    )}

                    {user.username && type === 'page' && (
                      <TableCell style={{ fontSize: '12px' }}>
                        {user.username || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('No. of Followers') && (
                      <TableCell style={{ fontSize: '12px' }}>
                        {' '}
                        {user.numberOfFollowers || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('No. of Likes') && (
                      <TableCell style={{ fontSize: '12px' }}>
                        {' '}
                        {user.numberOfLIkes || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('No. of Post') && (
                      <TableCell style={{ fontSize: '12px' }}>
                        {' '}
                        {user.numberOfPosts || 'N/A'}
                      </TableCell>
                    )}
                    {headers.includes('No. of Post') && (
                      <TableCell style={{ fontSize: '12px' }}>
                        {' '}
                        {user.numberOfPosts || 'N/A'}
                      </TableCell>
                    )}

                    {headers.includes('Images') && (
                      <TableCell align="left">
                        <Button
                          style={{
                            fontSize: '7px',
                            backgroundColor: '#fdf1ec',
                            color: '#ed462f',
                            border: '1px solid #ed462f',
                          }}
                          variant="outlined"
                          onClick={this.handleViewPostImage(user, index)}
                        >
                          view
                        </Button>
                      </TableCell>
                    )}

                    {user.likeCount && type === 'post' && (
                      <TableCell>
                        <pre>{user.likeCount || 'N/A'}</pre>
                      </TableCell>
                    )}
                    {user.commentCount && type === 'post' && (
                      <TableCell>{user.commentCount || 'N/A'}</TableCell>
                    )}

                    {/* {user.createdAt && type === 'post' && (
                      <TableCell align="left" style={{fontSize: "12px"}}>
                        <pre {moment(user.createdAt).format('MMM, Do YYYY')}</pre>
                      </TableCell>
                    )} */}
                    {headers.includes('Description') && type === 'donation' && (
                      <TableCell style={{ fontSize: '13px' }}>
                        <pre style={{ fontFamily: 'san-serif' }}>
                          {user.description || 'N/A'}
                        </pre>
                      </TableCell>
                    )}
                    {user.estimatedAmount && (
                      <TableCell>{user.estimatedAmount || 'N/A'}</TableCell>
                    )}
                    {user.currentAmount && (
                      <TableCell>{user.currentAmount || 'N/A'}</TableCell>
                    )}
                    {headers.includes('Status') && type === 'products' && (
                      <TableCell>
                        {user.currentState === 'deactivate' ? (
                          <Button
                            variant="outlined"
                            style={{ color: 'red', fontSize: '10px' }}
                            onClick={() => this.handleActivate(user.id)}
                          >
                            Inactive
                          </Button>
                          // <Chip color="primary" label="Active" size="small" />
                        ): 
                        <Button
                            variant="outlined"
                            style={{ color: 'green', fontSize: '10px' }}
                            onClick={() => this.productDeactivate(user.id)}
                          >
                            active
                          </Button>
                        }
                        {/* {user.currentState === 'activate' && (
                          <Button
                            variant="outlined"
                            style={{ color: 'green', fontSize: '10px' }}
                            onClick={() => this.productDeactivate(user.id)}
                          >
                            active
                          </Button>
                          // <Chip color="primary" label="In Active" size="small" />
                        )} */}
                      </TableCell>
                    )}

                    {headers.includes('Status') &&
                      type !== 'moment' &&
                      type !== 'products' && (
                        <TableCell>
                          {!user.isDeleted && (
                            <Box color="success.main">Active</Box>
                            // <Chip color="primary" label="Active" size="small" />
                          )}
                          {user.isDeleted && (
                            <Box color="warning.main">In Active</Box>
                            // <Chip color="primary" label="In Active" size="small" />
                          )}
                        </TableCell>
                      )}
                    {headers.includes('Status') && type === 'moment' && (
                      <TableCell>
                        {!user.isExpire && (
                          <Box color="success.main">Active</Box>
                          // <Chip color="primary" label="Active" size="small" />
                        )}
                        {user.isExpire && (
                          <Box color="warning.main">In Active</Box>
                          // <Chip color="primary" label="In Active" size="small" />
                        )}
                      </TableCell>
                    )}
                    {type === 'group' &&
                      type !== 'donation' &&
                      type !== 'interests' && (
                        <TableCell>
                          {user.isPublic ? 'Public' : 'Private'}
                        </TableCell>
                      )}
                    {type === 'page' &&
                    type !== 'donation' &&
                    type !== 'interests' ? (
                      <TableCell>
                        {user.isPublic ? 'Public' : 'Private'}
                      </TableCell>
                    ) : null}
                    {user.updatedAt &&
                      type !== 'post' &&
                      type !== 'donation' &&
                      type !== 'interests' &&
                      type !== 'products' &&
                      type !== 'moment' && (
                        <TableCell style={{ fontSize: '12px' }} align="left">
                          {moment(user.updatedAt).format('MMM, Do YYYY')}
                        </TableCell>
                      )}
                    {user.createdAt && type !== 'post' && (
                      <TableCell style={{ fontSize: '12px' }} align="left">
                        {moment(user.createdAt).format('MMM, Do YYYY')}
                      </TableCell>
                    )}

                    {/* {headers.includes('Time') && user.time && (
                      <TableCell>
                        {moment(user.time).format('hh:mm:ss A')}
                      </TableCell>
                    )} */}
                    {actionCol && (
                      <TableCell
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Box color="#FF6634">
                          {type === 'qrCode' &&
                            type !== 'moment' &&
                            type !== 'products' && (
                              <IconButton
                                edge="end"
                                size="small"
                                color="primary"
                              >
                                <VisibilityIcon
                                  onClick={this.handleView(user, index)}
                                />
                              </IconButton>
                            )}
                          {type !== 'interests' &&
                            type !== 'moment' &&
                            type !== 'donation' &&
                            type !== 'page' &&
                            type !== 'products' &&
                            type !== 'post' && (
                              <IconButton
                                edge="end"
                                size="small"
                                color="primary"
                              >
                                <VisibilityIcon
                                  onClick={this.handleView(user, index)}
                                />
                              </IconButton>
                            )}
                          {type === 'donation' && type !== 'products' && (
                            <IconButton edge="end" size="small" color="primary">
                              <VisibilityIcon
                                onClick={this.handleViewDonation(user, index)}
                              />
                            </IconButton>
                          )}
                          {type === 'products' && (
                            <IconButton edge="end" size="small" color="primary">
                              <VisibilityIcon
                                onClick={this.handleViewProduct(user, index)}
                              />
                            </IconButton>
                          )}
                          {type === 'page' && type !== 'products' && (
                            <IconButton edge="end" size="small" color="primary">
                              <VisibilityIcon
                                onClick={this.handleViewPage(user, index)}
                              />
                            </IconButton>
                          )}
                          {type === 'post' && type !== 'products' && (
                            <IconButton edge="end" size="small" color="primary">
                              <VisibilityIcon
                                onClick={this.handleViewPost(user, index)}
                              />
                            </IconButton>
                          )}
                          {type === 'page' && type !== 'products' && (
                            <IconButton edge="end" size="small" color="inherit">
                              <AddBoxIcon onClick={this.handleView(user)} />
                            </IconButton>
                          )}
                          {type === 'post' && type !== 'products' && (
                            <IconButton edge="end" size="small" color="inherit">
                              <AddBoxIcon
                                onClick={this.handleCreatePost(user)}
                              />
                            </IconButton>
                          )}
                          {type === 'donation' && (
                            <IconButton edge="end" size="small" color="inherit">
                              <AddBoxIcon
                                onClick={this.handleCreateDonation(user)}
                              />
                            </IconButton>
                          )}

                          {!user.isDeleted && type === 'page' ? (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon
                                onClick={() =>
                                  this.pageDelete(user.id, user.userId)
                                }
                              />
                            </IconButton>
                          ) : user.isDeleted && type === 'page' ? (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: green[500] }}
                            >
                              <CheckIcon
                                onClick={() =>
                                  this.pageActivate(user.id, user.userId)
                                }
                              />
                            </IconButton>
                          ) : (
                            ''
                          )}

                          {type === 'qrCode' && (
                            <IconButton edge="end" size="small" color="inherit">
                              <EditIcon onClick={this.handleEdit(user)} />
                            </IconButton>
                          )}
                          {type === 'products' && (
                            <IconButton edge="end" size="small" color="inherit">
                              <EditIcon onClick={this.handleEdit(user)} />
                            </IconButton>
                          )}
                          {type === 'interests' && (
                            <IconButton edge="end" size="small" color="inherit">
                              <EditIcon onClick={this.handleEdit(user)} />
                            </IconButton>
                          )}
                          {/* {type === 'donation'  && (
                          <IconButton edge="end" size="small" color="inherit">
                            <EditIcon onClick={this.handleEditDonation(user)} />
                          </IconButton>
                          )} */}

                          {type === 'qrCode' && (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: blue[500] }}
                            >
                              <a href={user.qrString} download={user.qrKey}>
                                <CloudDownloadIcon />
                              </a>
                            </IconButton>
                          )}
                          {type === 'interests' && (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon
                                onClick={() => this.handDelete(user.id)}
                              />
                            </IconButton>
                          )}
                          {type === 'products' && (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon
                                onClick={() =>
                                  this.handProductDelete(
                                    user.ProfileId,
                                    user.id
                                  )
                                }
                              />
                            </IconButton>
                          )}
                          {type === 'donation' && (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon
                                onClick={() =>
                                  this.donationDeactivate(
                                    user.id,
                                    user.ProfileId
                                  )
                                }
                              />
                            </IconButton>
                          )}

                          {!user.isDeleted && type === 'post' ? (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon
                                onClick={() => this.handleDelete(user)}
                              />
                            </IconButton>
                          ) : user.isDeleted && type === 'post' ? (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: green[500] }}
                            >
                              <CheckIcon
                                onClick={() => this.handleActivate(user)}
                              />
                            </IconButton>
                          ) : (
                            ''
                          )}
                          {type === 'moment' && (
                            <IconButton
                              edge="end"
                              size="small"
                              style={{ color: red[500] }}
                            >
                              <DeleteIcon
                                onClick={() => this.handleMomentDelete(user)}
                              />
                            </IconButton>
                          )}
                        </Box>
                        {/* <IconButton edge="end" size="small">
                        <MoreHoriz
                          aria-owns={
                            anchorEl ? `simple-menu-${user.userId}` : null
                          }
                          aria-haspopup="true"
                          onClick={this.handleClick(index)}
                        />
                      </IconButton>
                      <Menu
                        id={`simple-menu-${user.userId}`}
                        anchorEl={anchorEl}
                        open={menus[index]}
                        onClose={this.handleClose(index)}
                      >
                        <MenuItem onClick={this.handleView(user, index)}>
                          View
                        </MenuItem>
                        <MenuItem onClick={this.handleEdit(user)}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={this.handleDelete(user)}>
                          Delete
                        </MenuItem>
                      </Menu> */}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {data.length === 0 && (
                  <TableRow colSpan={headers.length}>
                    <Box display="flex" justifyContent="center" m={5}>
                      No data found.
                    </Box>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Box>
          <ViewPageComponent
            data={item}
            show={showPage}
            handleViewClose={this.handleViewClose}
          />
        </PerfectScrollbar>
        <ViewPostImage
          data={item}
          show={showImage}
          handleClosePostImage={this.handleClosePostImage}
        />
        <ViewProducts
          data={item}
          show={showProduct}
          handleCloseProduct={this.handleCloseProduct}
        />
        <ViewQRCodeComponent
          data={item}
          show={showQrCode}
          handleViewClose={this.handleViewClose}
        />
        <ViewMomentComponent
          data={item}
          show={showMoment}
          handleViewCloseMoment={this.handleViewCloseMoment}
        />

        <ViewDonationComponent
          data={item}
          show={showDonation}
          handleViewDonationClose={this.handleViewDonationClose}
        />

        <EditQRCodeComponent
          data={item}
          show={editQrCode}
          handleEditClose={this.handleEditClose}
        />
        <EditPageComponent
          data={item}
          show={editPage}
          handleEditClose={this.handleEditClose}
        />
        <EditProductComponent
          data={item}
          show={editProduct}
          // getData={getPage}
          handleEditClose={this.handleEditClose}
        />
        <CreatePostComponent
          data={item}
          show={createPost}
          handleViewCloseCreatePost={this.handleViewCloseCreatePost}
        />
        <ViewPostComponent
          data={item}
          show={showPost}
          handleViewClosePost={this.handleViewClosePost}
        />
        <EditDonationComponent
          data={item}
          show={editDonation}
          handleCreateDonationClose={this.handleCreateDonationClose}
        />
        <EditInterestComponent
          data={item}
          show={editInterest}
          handleEditClose={this.handleEditClose}
          refreshData={this.props.refreshData}
        />
        <EditAdvertComponent
          data={item}
          show={editAdvert}
          handleEditClose={this.handleEditClose}
        />
        <CreateNewPageComponent
          data={item}
          show={showCreate}
          handleViewClose={this.handleViewClose}
        />

        <TablePagination
          style={{ backgroundColor: '#fbe2dd', color: '#e7472e' }}
          component="div"
          count={metaData}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
        />
      </Card>
    );
  }
}

export default DisplayTableComponent;
