import {
  DeleteOutlined,
  EditFilled,
  EditOutlined,
  EyeFilled,
  MoreOutlined
} from "@ant-design/icons";
import { Image, Space, Button } from "antd";
import moment from "moment";
import { sumObjectData } from "../../src/services/helpers";
import { BsThreeDotsVertical } from "react-icons/bs";
import ActionDropdown from "../components/dropdown/ActionDropdown";
import ActivityLog from "../views/management/Logs/ActivityLog";
import { Action } from "@devexpress/dx-react-core";
import { retrieveFileType } from "./helpers";

// const dummyUsers = [
//   {
//     userId: 8781876,
//     name: 'Tijani Femi',
//     phoneNumber: '08057661075',
//     email: 'olutimedia@gmail.com',
//     city: 'Lagos',
//     district: 'Lagos',
//     cardLinked: true,
//     status: 'active',
//     corporate: true,
//     createdAt: '12/07/2021',
//     wallet: true,
//     webPos: false,
//     termindalPos: true,
//   },
//   {
//     userId: 8781876,
//     name: 'Tijani Femi',
//     phoneNumber: '08057661075',
//     email: 'olutimedia@gmail.com',
//     city: 'Lagos',
//     district: 'Lagos',
//     cardLinked: false,
//     status: 'active',
//     corporate: false,
//     createdAt: '12/07/2021',
//     wallet: true,
//     webPos: false,
//     termindalPos: true,
//   },
// ];
const userColumn = (handleConfirm, handleModals) => [
  {
    title: "USERNAME",
    dataIndex: "displayName",
    key: "displayName",
    render: (profile) => <p>{profile}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "CONTACT",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "WAYAGRAM",
    dataIndex: "simulatedUser",
    key: "simulatedUser",
    render: (profile) => (
      <p>{profile.simulatedUser ? "simulated" : "non-simulated"}</p>
    ),
  },
  {
    title: "FOLLOWERS",
    dataIndex: "followers",
    key: "followers",
    render: (e, datum) => (
      <Space className="w-mx-200p">
        {e}
        <button
          onClick={() => handleModals("followers", datum)}
          className="pause mx-2"
        >
          View All
        </button>
      </Space>
    ),
  },
  {
    title: "FOLLOWINGS",
    dataIndex: "followings",
    key: "followings",
    render: (e, datum) => (
      <Space className="w-mx-200p">
        {e}
        <button
          onClick={() => handleModals("following", datum)}
          className="pause mx-2"
        >
          View All
        </button>
      </Space>
    ),
  },
  {
    title: "POSTS",
    dataIndex: "posts",
    key: "posts",
    render: (e, datum) => (
      <Space className="w-mx-200p">
        {e}
        <button
          onClick={() => handleModals("view-post", datum.id)}
          className="pause mx-2"
        >
          View All
        </button>
      </Space>
    ),
  },
  {
    title: "LIKED PAGES",
    dataIndex: "lokeCount",
    key: "lokeCount",
    render: (e, datum) => (
      <Space className="w-mx-200p">
        {e}
        <button
          onClick={() => handleModals("view-page", datum)}
          className="pause mx-2"
        >
          View All
        </button>
      </Space>
    ),
  },
  {
    title: "GROUPS",
    dataIndex: "group",
    key: "group",
    render: (e, datum) => (
      <Space className="w-mx-200p">
        {e}
        <button
          onClick={() => handleModals("view-page", datum)}
          className="pause mx-2"
        >
          View All
        </button>
      </Space>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    dataIndex: "isDeactivate",
    key: "isDeactivate",
    render: (e, datum) => (
      <Space className="fs-5">
        <EditOutlined
          className="mx-2"
          onClick={() => handleModals("editPost", datum)}
        />
        <button
          onClick={() => handleConfirm(datum.id, e)}
          className={`${e ? "pause" : "play"} mx-2`}
        >
          {e ? "Deactivate" : "Activate"}
        </button>
      </Space>
    ),
  },
];

const postColumn = (
  handleModals,
  handleConfirm,
  setShowImageModal,
  setPreviewImages,
  userCanManage
) => [
  {
    title: "POST CREATOR",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.displayName}</p>,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "POST CAPTION",
    dataIndex: "description",
    key: "description",
    render: (e) => (
      <div className="w-mx-200p">
        {e?.length > 20 ? `${e?.slice(0, 20)}...` : e}
      </div>
    ),
  },
  {
    title: "SYNCHRONIZED PLATFORM",
    dataIndex: "likeCount",
    key: "likeCount",
  },
  {
    title: "IMAGES",
    dataIndex: "PostImages",
    key: "PostImages",
    render: (e, data) => {
      const images =
        data.kind === "donation" ? data?.Donation?.DonationImages : e;
      return images?.length > 0 ? (
        <Image
          preview={{ visible: false }}
          alt="posts"
          onClick={() => {
            setPreviewImages(images);
            setShowImageModal(true);
          }}
          src={images[0]?.imageURL || images[0]?.imageUrl}
          width={50}
          height={50}
        />
      ) : (
        <span className="text-secondary">No image</span>
      );
    },
  },
  {
    title: "LIKES",
    dataIndex: "likeCount",
    key: "likeCount",
  },
  {
    title: "REPOSTS",
    dataIndex: "repostCount",
    key: "repostCount",
  },
  {
    title: "COMMENTS",
    dataIndex: "commentCount",
    key: "commentCount",
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  userCanManage ? {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <EditOutlined
          className="mx-2"
          onClick={() => handleModals("editPost", datum)}
        />
        <DeleteOutlined
          className="mx-2 text-primary"
          onClick={() => handleConfirm(e)}
        />
      </span>
    ),
  } : {},
];


const donationColumn = (handleModals, handleConfirm) => [
  {
    title: "CREATOR’S NAME",
    dataIndex: "DonatorProfile",
    key: "DonatorProfile",
    render: (profile) => <p>{profile?.displayName}</p>,
    width: 120,
  },
  {
    title: "USERNAME",
    dataIndex: "DonatorProfile",
    key: "DonatorProfile",
    render: (profile) => <p>{profile?.username}</p>,
    width: 120,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description",
    width: 125,
  },
  {
    title: "NO OF DONATION RECIEVED",
    dataIndex: "Donate",
    key: "Donate",
    render: (donate) => <p>{donate?.length}</p>,
    width: 100,
  },
  {
    title: "AMOUNT RECIEVED",
    dataIndex: "Donate",
    key: "Donate",
    width: 70,
    render: (donate) => (
      <p>{donate?.reduce((sum, amt) => (sum += amt?.amount), 0)}</p>
    ),
  },
  {
    title: "STATUS",
    dataIndex: "isClosed",
    key: "isClosed",
    render: (isClosed, d) =>
      isClosed ? (
        <span className={`status text-danger`}>Ended</span>
      ) : (
        <Space>
          <span className={`status text-success`}>Live</span>
          <div className={d?.currentState === "activate" ? "play " : "pause"}>
            <img
              className="mr-2"
              src={`/assets/icons/power-${
                d?.currentState === "activate" ? "on" : "off"
              }.svg`}
              alt="power-icons"
            />
            {d?.currentState?.toUpperCase()}
          </div>
          <Button
            onClick={() => handleConfirm("close", d?.id, d?.ProfileId)}
            icon={<img src="/assets/icons/stop.svg" alt="manage-icons" />}
          />
          <Button
            onClick={() =>
              handleConfirm(
                d?.currentState === "activate" ? "deactivate" : "activate",
                d?.id,
                d?.ProfileId
              )
            }
            icon={
              <img
                src={`/assets/icons/${
                  d?.currentState === "activate" ? "pause" : "play"
                }.svg`}
              />
            }
          />
        </Space>
      ),
    width: 200,
  },
  {
    title: "LIKES",
    dataIndex: "likeCount",
    key: "likeCount",
    render: (e) => <b>{e}</b>,
    width: 70,
  },
  {
    title: "SHARE",
    dataIndex: "shareCount",
    key: "shareCount",
    render: (e) => <b>{e}</b>,
    width: 70,
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date) => <p>{new Date(date).toDateString()}</p>,
    width: 70,
    fixed: "right",
  },
  {
    title: "TIME",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <p className="m-0 fs-6">{moment(e).format("h:mm a")}</p>,
    width: 70,
    fixed: "right",
  },
  {
    title: "ACTION",
    dataIndex: "isClosed",
    key: "isClosed",
    render: (isClosed, datum) => (
      <Space>
        {!isClosed && (
          <EditFilled onClick={() => handleModals("editPost", datum)} />
        )}
        <button onClick={() => handleModals("view", datum)} className="play">
          View More
        </button>
      </Space>
    ),
    width: 100,
    fixed: "right",
  },
];

const pollsColumn = (handleModals, handleConfirm) => [
  {
    title: "CREATORS NAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => profile?.displayName,
    width: 120,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "POST DETAILS",
    dataIndex: "description",
    key: "description",
    render: (e) => <p className="m-0 w-mx-200p">{e}</p>,
    width: 125,
  },
  {
    title: "TYPE",
    dataIndex: "isPaid",
    key: "isPaid",
    render: (e) => (
      <span className={`fw-bold text-${e ? "danger" : "success"}`}>
        {e ? "PAID" : "FREE"}
      </span>
    ),
    width: 70,
  },
  {
    title: "CATEGORY",
    dataIndex: "pollCategory",
    key: "pollCategory",
    render: (e) => <p className="m-0 w-mx-200p">{e}</p>,
    width: 125,
  },
  {
    title: "NO OF VOTERS",
    dataIndex: "PollVotes",
    key: "PollVotes",
    render: (votes) => votes?.length,
    width: 100,
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
    render: (e) => <b>{e}</b>,
    width: 70,
  },
  {
    title: "STATUS",
    dataIndex: "isClosed",
    key: "isClosed",
    render: (e, d) =>
      e ? (
        <span className="status text-danger">Ended</span>
      ) : (
        <Space>
          <span className="status text-success">Live</span>
          <div className={d?.currentState === "activate" ? "play" : "pause"}>
            <img
              className="mr-5"
              src={`/assets/icons/power-${
                d?.currentState === "activate" ? "on" : "off"
              }.svg`}
              alt="power-icons"
            />
            {d?.currentState?.toUpperCase()}
          </div>
          <Button
            onClick={() => handleConfirm("close", d?.id, d?.ProfileId)}
            icon={<img src="/assets/icons/stop.svg" alt="manage-icons" />}
          />
          <Button
            onClick={() =>
              handleConfirm(
                d?.currentState === "activate" ? "deactivate" : "activate",
                d?.id,
                d?.ProfileId
              )
            }
            icon={
              <img
                src={`/assets/icons/${
                  d?.currentState === "activate" ? "pause" : "play"
                }.svg`}
              />
            }
          />
        </Space>
      ),
    width: 200,
  },
  {
    title: "LIKES",
    dataIndex: "likeCount",
    key: "likeCount",
    render: (e) => <b>{e}</b>,
    width: 70,
  },
  {
    title: "SHARE",
    dataIndex: "shareCount",
    key: "shareCount",
    render: (e) => <b>{e}</b>,
    width: 70,
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => (
      <p className="m-0 fs-6">{new Date(e).toLocaleDateString()}</p>
    ),
    width: 70,
    fixed: "right",
  },
  {
    title: "TIME",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <p className="m-0 fs-6">{moment(e).format("h:mm a")}</p>,
    width: 70,
    fixed: "right",
  },
  {
    title: "ACTION",
    dataIndex: "isClosed",
    key: "isClosed",
    render: (isClosed, datum) => (
      <Space>
        {!isClosed && (
          <EditFilled onClick={() => handleModals("editPost", datum)} />
        )}
        <button onClick={() => handleModals("view", datum)} className="play">
          View More
        </button>
      </Space>
    ),
    width: 100,
    fixed: "right",
  },
];

const eventsColumn = (handleModals, handleConfirm) => [
  {
    title: "CREATOR",
    dataIndex: "eventOrganizer",
    key: "eventOrganizer",
    width: 120,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "TYPE",
    dataIndex: "isPrivate",
    key: "isPrivate",
    render: (e) => (
      <span className={`fw-bold text-${e ? "danger" : "success"}`}>
        {e ? "PUBLIC" : "PRIVATE"}
      </span>
    ),
    width: 120,
  },
  {
    title: "PRICE",
    dataIndex: "amount",
    key: "amount",
    render: (e, datum) => (
      <span className={`fw-bold text-${e ? "danger" : "success"}`}>
        {e ? datum?.amount : "FREE"}
      </span>
    ),
    width: 120,
  },
  {
    title: "DESCRIPTION",
    dataIndex: "details",
    key: "details",
    render: (e) => <p className="m-0 w-mx-200p">{e}</p>,
    width: 120,
  },
  {
    title: "STATUS",
    dataIndex: "isClosed",
    key: "isClosed",
    render: (e, d) =>
      e ? (
        <span className="status text-danger">Ended</span>
      ) : (
        <Space>
          <span className="status text-success">Live</span>
          <div className={d?.currentState === "activate" ? "play" : "pause"}>
            <img
              className="mr-5"
              src={`/assets/icons/power-${
                d?.currentState === "activate" ? "on" : "off"
              }.svg`}
              alt="power-icons"
            />
            {d?.currentState?.toUpperCase()}
          </div>
          <Button
            onClick={() => handleConfirm("close", d?.id)}
            icon={<img src="/assets/icons/stop.svg" alt="manage-icons" />}
          />
          <Button
            onClick={() =>
              handleConfirm(
                d?.currentState === "activate" ? "deactivate" : "activate",
                d?.id
              )
            }
            icon={
              <img
                src={`/assets/icons/${
                  d?.currentState === "activate" ? "pause" : "play"
                }.svg`}
              />
            }
          />
        </Space>
      ),
    width: 120,
  },
  {
    title: "FOLLOWERS",
    dataIndex: "followers",
    key: "followers",
    render: (e, datum) => (
      <Space className="w-mx-200p">
        {e}
        <button
          onClick={() => handleModals("followers", datum)}
          className="pause mx-2"
        >
          View All
        </button>
      </Space>
    ),
    width: 120,
  },
  {
    title: "LIKES",
    dataIndex: "shareCount",
    key: "shareCount",
    render: (e) => <p className="m-0 w-mx-200p">{e?.length}</p>,
    width: 120,
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => (
      <p className="m-0 fs-6">{new Date(e).toLocaleDateString()}</p>
    ),
    width: 120,
    // fixed: "right",
  },
  {
    title: "TIME",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <p className="m-0 fs-6">{moment(e).format("h:mm a")}</p>,
    width: 120,
    // fixed: "right",
  },
  {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <EditOutlined
          className="mx-2"
          onClick={() => handleModals("editPost", datum)}
        />
        <DeleteOutlined
          className="mx-2 text-primary"
          onClick={() => handleConfirm(e)}
        />
      </span>
    ),
  },
];

const productColumn = (
  handleModals,
  handleConfirm,
  setShowImageModal,
  setPreviewImages
) => [
  {
    title: "CREATOR’s NAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.displayName}</p>,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "POST DETAILS",
    dataIndex: "description",
    key: "description",
    render: (e) => (
      <div className="w-mx-200p">
        {e.length > 20 ? `${e.slice(0, 20)}...` : e}
      </div>
    ),
  },
  {
    title: "NO OF PURCHASE",
    dataIndex: "no_of_purchase",
    key: "no_of_purchase",
  },
  {
    title: "TRANSACTION AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "IMAGES",
    dataIndex: "ProductImages",
    key: "ProductImages",
    render: (e) =>
      e?.length > 0 ? (
        <Image
          preview={{ visible: false }}
          alt="products"
          onClick={() => {
            setPreviewImages(e);
            setShowImageModal(true);
          }}
          src={e[0].imageUrl}
          width={50}
          height={50}
        />
      ) : (
        <span className="text-secondary">No image</span>
      ),
  },
  {
    title: "STATUS",
    dataIndex: "currentState",
    key: "currentState",
    render: (e) => (
      <p className={e === "activate" ? "text-success" : "text-dager"}>
        {e?.toUpperCase()}
      </p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "LIKES",
    dataIndex: "likes",
    key: "likes",
  },
  // {
  //   title: 'SHARES',
  //   dataIndex: 'repostCount',
  //   key: 'repostCount'
  // },
  {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <EditOutlined
          className="mx-2"
          onClick={() => handleModals("editPost", datum)}
        />
        <DeleteOutlined
          className="mx-2 text-primary"
          onClick={() => handleConfirm(datum)}
        />
      </span>
    ),
  },
];
const requestColumn = (handleModals, handleConfirm) => [
  {
    title: "USERNAME",
    dataIndex: "RecipientProfile",
    key: "RecipientProfile",
    render: (profile) => <p>@{profile?.username}</p>,
  },
  {
    title: "TYPES",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "RAISED AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "WAYA PERCENT",
    dataIndex: "wayagramPercent",
    key: "wayagramPercent",
  },
  {
    title: "BALANCE",
    dataIndex: "paymentBalance",
    key: "paymentBalance",
  },
  {
    title: "STATUS",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (e) => (
      <p
        className={`text-${
          e === "Pending" ? "warning" : e === "Rejected" ? "danger" : "success"
        }`}
      >
        {e}
      </p>
    ),
  },
  {
    title: "KYC STATUS",
    dataIndex: "kyc_status",
    key: "kyc_status",
    render: (e) => (
      <p className={`text-${e !== "tier4" ? "danger" : "success"}`}>{e}</p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e)?.toDateString()}</p>,
  },
  {
    title: "ADMIN",
    dataIndex: "AdminProfile",
    key: "AdminProfile",
    render: (admin) => <p>{admin?.displayName}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (e, datum) =>
      e === "Pending" ? (
        <span className="fs-5">
          <button
            onClick={() => handleConfirm("Disbursed", datum)}
            className="play mx-2"
          >
            DISBURSE
          </button>
          <button
            onClick={() => handleModals("Rejected", datum)}
            className="pause mx-2"
          >
            REJECT
          </button>
        </span>
      ) : e === "Rejected" ? (
        <span className="fs-5">
          <button
            onClick={() => handleModals("reason", datum)}
            className="reason mx-2"
          >
            Show Reason
          </button>
          <button
            onClick={() => handleConfirm("Disbursed", datum)}
            className="play mx-2"
          >
            Resolve
          </button>
        </span>
      ) : (
        <button
          onClick={() => handleModals("view", datum)}
          className="play mx-2"
        >
          Show Receipt
        </button>
      ),
  },
];

const interestColumn = (handleModals, handleConfirm) => [
  {
    title: "INTEREST",
    dataIndex: "title",
    key: "title",
    width: 250,
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description",
    width: 250,
  },
  {
    title: "USERS",
    dataIndex: "id",
    key: "id",
    width: 200,
    render: (e, datum) => (
      <button
        onClick={() => handleModals("view", datum)}
        className="pause mx-2"
      >
        View all
      </button>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e)?.toDateString()}</p>,
    width: 200,
  },
  {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <EditOutlined
          className="mx-2"
          onClick={() => handleModals("editPost", datum)}
        />
        <DeleteOutlined
          className="mx-2 text-primary"
          onClick={() => handleConfirm(e)}
        />
      </span>
    ),
  },
];

const momentColumn = (handleConfirm) => [
  {
    title: "POST CREATOR",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "IMAGES",
    dataIndex: "content",
    key: "content",
    render: (e) =>
      e?.length > 0 ? (
        <Image alt="moments" src={e} width={50} height={50} />
      ) : (
        <span className="text-secondary">No image</span>
      ),
  },
  {
    title: "STATUS",
    dataIndex: "isExpire",
    key: "isExpire",
    render: (e) => (
      <span className={e ? "pause" : "play"}>{e ? "Expired" : "Active"}</span>
    ),
  },
  {
    title: "VIEWS",
    dataIndex: "viewCount",
    key: "viewCount",
  },
  {
    title: "NO OF POST",
    dataIndex: "Profile",
    key: "Profile",
    render: (Profile) => <p>{Profile?.posts}</p>,
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "ACTION",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        {/* <EditOutlined className='mx-2' onClick={()=> handleModals('editPost', datum)} /> */}
        <DeleteOutlined
          className="mx-2 text-danger"
          onClick={() => handleConfirm(datum.ProfileId, e)}
        />
      </span>
    ),
  },
];

const hashTagColumn = (handleConfirm, handleModals) => [
  {
    title: "HASHTAG",
    dataIndex: "hashtag",
    key: "hashtag",
  },
  {
    title: "CREATED BY",
    dataIndex: "createdBy",
    key: "createdBy",
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "NUMBER OF TIMES USED",
    dataIndex: "PostToHashtags",
    key: "PostToHashtags",
    render: (e, datum) => (
      <>
        <span>{e?.length}</span>
        <button
          onClick={() => handleModals("view", datum)}
          className="pause mx-2"
        >
          View all
        </button>
      </>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "ACTION",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <DeleteOutlined
          className="mx-2 text-danger"
          onClick={() => handleConfirm(datum.ProfileId, e)}
        />
      </span>
    ),
  },
];

const pageColumn = (handleModals, handleConfirm) => [
  {
    title: "PAGE CREATOR",
    dataIndex: "username",
    key: "username",
    // render: profile => <p>{profile?.displayName}</p>
  },
  {
    title: "PAGE NAME",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "TYPE",
    dataIndex: "isPublic",
    key: "isPublic",
    render: (e) => <p>{e ? "PUBLIC" : "PRIVATE"}</p>,
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description",
    render: (e) => (
      <div className="w-mx-200p">
        {e?.length > 30 ? `${e?.slice(0, 30)}...` : e}
      </div>
    ),
  },
  {
    title: "FOLLOWERS",
    dataIndex: "numberOfFollowers",
    key: "numberOfFollowers",
    render: (e, datum) => (
      <span className="w-mx-200p">
        {e}
        <button
          onClick={() => handleModals("view-follower", datum)}
          className="pause mx-2"
        >
          View all
        </button>
      </span>
    ),
  },
  {
    title: "NO OF POST",
    dataIndex: "numberOfPosts",
    key: "numberOfPosts",
  },
  {
    title: "NO OF LIKES",
    dataIndex: "numberOfLIkes",
    key: "numberOfLIkes",
  },
  {
    title: "LAST ACTIVITY DATE",
    dataIndex: "updatedAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <EditOutlined
          className="mx-2"
          onClick={() => handleModals("editPost", datum)}
        />
        <DeleteOutlined
          className="mx-2 text-primary"
          onClick={() => handleConfirm(datum)}
        />
      </span>
    ),
  },
];

const groupColumn = (handleModals, handleConfirm) => [
  {
    title: "GROUP NAME",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "GROUP CREATOR",
    dataIndex: "WAYAGramProfile",
    key: "WAYAGramProfile",
    render: (profile) => profile?.username,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "GROUP TYPE",
    dataIndex: "isPublic",
    key: "isPublic",
    render: (e) => (
      <span className={`fw-bold text-${e ? "danger" : "success"}`}>
        {e ? "PUBLIC" : "PRIVATE"}
      </span>
    ),
  },
  {
    title: "CATEGORY",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description",
    render: (e) => (
      <div className="w-mx-300p">
        {e?.length > 100 ? `${e?.slice(0, 100)}...` : e}
      </div>
    ),
  },
  {
    title: "GROUP MEMBER",
    dataIndex: "following",
    key: "following",
    render: (following) => following,
  },
  {
    title: "POSTS",
    dataIndex: "postCount",
    key: "postCount",
  },
  {
    title: "LIKES",
    dataIndex: "likedPostCount",
    key: "likedPostCount",
    render: (likedPostCount) => likedPostCount,
  },
  {
    title: "LAST ACTIVITY DATE",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toLocaleString()}</p>,
  },
  {
    title: "STATUS",
    dataIndex: "isDeleted",
    key: "isDeleted",
    render: (e) =>
      e ? (
        <span className="status text-danger">INACTIVE</span>
      ) : (
        <Space>
          <span className="status text-success">ACTIVE</span>
        </Space>
      ),
  },
  {
    title: "DATE OF CREATION",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toLocaleString()}</p>,
  },
  // {
  //   dataIndex: "id",
  //   key: "id",
  //   render: (id) => <EyeFilled onClick={() => handleModals("view", id)} />,
  // },
];

const likesCol = [
  {
    title: "USER",
    dataIndex: "Profile",
    key: "Profile",
    render: (e) => <span>@{e?.username}</span>,
  },
  {
    title: "POST",
    dataIndex: "description",
    key: "description",
    render: (e) => (
      <div className="w-mx-200p">
        {e.length > 20 ? `${e?.slice(0, 20)}...` : e}
      </div>
    ),
  },
  {
    title: "LIKES",
    dataIndex: "likeCount",
    key: "likeCount",
  },
  {
    title: "DATE CREATED",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <span>{new Date(e).toLocaleString()}</span>,
  },
];

const postsCol = [
  {
    title: "USER",
    dataIndex: "Profile",
    key: "Profile",
    render: (e) => <span>@{e?.username}</span>,
  },
  {
    title: "POST",
    dataIndex: "description",
    key: "description",
    render: (e) => (
      <div className="w-mx-200p">
        {e?.length > 20 ? `${e?.slice(0, 20)}...` : e}
      </div>
    ),
  },
  {
    title: "LIKES",
    dataIndex: "likeCount",
    key: "likeCount",
  },
  {
    title: "COMMENTS",
    dataIndex: "commentCount",
    key: "commentCount",
  },
  {
    title: "DATE CREATED",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <span>{new Date(e).toLocaleString()}</span>,
  },
];

const commentsCol = [
  {
    title: "USER",
    dataIndex: "Profile",
    key: "Profile",
    render: (e) => <span>@{e?.username}</span>,
  },
  {
    title: "COMMENTS",
    dataIndex: "comment",
    key: "comment",
    render: (e) => (
      <div className="w-mx-200p">
        {e?.length > 20 ? `${e?.slice(0, 20)}...` : e}
      </div>
    ),
  },
  {
    title: "DATE CREATED",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <span>{new Date(e).toLocaleString()}</span>,
  },
];

const hashtagCol = [
  {
    title: "CREATOR",
    dataIndex: "createdBy",
    key: "createdBy",
  },
  {
    title: "HASHTAG",
    dataIndex: "hashtag",
    key: "hashtag",
  },
  {
    title: "NO OF TIMES USED",
    dataIndex: "frequency",
    key: "frequency",
  },
  {
    title: "DATE CREATED",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <span>{new Date(e).toLocaleString()}</span>,
  },
];

const momentCol = [
  {
    title: "USER",
    dataIndex: "Profile",
    key: "Profile",
    render: (e) => <span>@{e?.username}</span>,
  },
  {
    title: "IMAGES",
    dataIndex: "image",
    key: "image",
    render: (e) =>
      e?.length > 0 ? (
        <Image alt="moments" src={e} width={50} height={50} />
      ) : (
        <span className="text-secondary">No image</span>
      ),
  },
  {
    title: "NO OF VIEWS",
    dataIndex: "viewCount",
    key: "viewCount",
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
];

const accountCol = [
  {
    title: "USER",
    dataIndex: "user",
    key: "user",
  },
  {
    title: "LIKES",
    dataIndex: "like",
    key: "like",
  },
  {
    title: "DATE JOINED",
    render: (e) => (
      <span>
        {e.date}
        <br /> {e.time}
      </span>
    ),
  },
];

const pricingCol = [
  {
    title: "ADMIN",
    dataIndex: "displayName",
    key: "displayName",
    render: (Profile) => <p>@Admin{Profile?.username}</p>,
  },
  {
    title: "PRODUCTS",
    dataIndex: "product_type",
    key: "product_type",
  },
  {
    title: "RATE",
    dataIndex: "percent",
    key: "percent",
  },
  {
    title: "DATE",
    dataIndex: "updatedAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
];
const promotionCol = (onActivate, onClose, onDelete) => [
  {
    title: "CREATOR’s NAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (Profile) => <p>@{Profile?.username}</p>,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "POST DETAILS",
    dataIndex: "description",
    key: "description",
    render: (e) => (
      <div className="w-mx-200p">
        {e.length > 60 ? `${e.slice(0, 60)}...` : e}
      </div>
    ),
  },
  {
    title: "TYPE",
    dataIndex: "PromotionPriceConfig",
    key: "PromotionPriceConfig",
    render: (Profile) => <p>{Profile?.interval}</p>,
  },
  {
    title: "QTY",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "AMOUNT",
    dataIndex: "PromotionPriceConfig",
    key: "PromotionPriceConfig",
    render: (Profile) => <p>{Profile?.price}</p>,
  },

  {
    title: "STATUS",
    dataIndex: "currentState",
    key: "currentState",
    render: (e) => (
      <p className={e === "activate" ? "text-success" : "text-dager"}>
        {e?.toUpperCase()}
      </p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "TOTAL REACH",
    dataIndex: "estimated_reach",
    key: "estimated_reach",
  },
  {
    title: "TOTAL ENGAGEMENT",
    dataIndex: "Engagement",
    key: "Engagement",
    render: (e) => <p className="m-0 fs-6">{sumObjectData(e)}</p>,
  },

  {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <ActionDropdown
          onActivate={() => onActivate(datum)}
          onClose={() => onClose(datum)}
          onDelete={() => onDelete(datum)}
        />
      </span>
    ),
  },
];
const paymentColumn = (handleModals, handleConfirm) => [
  {
    title: "USER ID",
    dataIndex: "RecipientProfile",
    key: "RecipientProfile",
    render: (profile) => <p>@{profile?.username}</p>,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "TYPES",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },

  {
    title: "STATUS",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (e) => (
      <p
        className={`text-${
          e.toLowerCase() === "pending"
            ? "warning"
            : e === "Rejected"
            ? "danger"
            : "success"
        }`}
      >
        {e}
      </p>
    ),
  },
  {
    title: "KYC STATUS",
    dataIndex: "kyc_status",
    key: "kyc_status",
    render: (e) => (
      <p className={`text-${e !== "tier4" ? "danger" : "success"}`}>{e}</p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e)?.toDateString()}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (e, datum) =>
      e.toLowerCase() === "pending" ? (
        <span className="fs-5">
          <button
            onClick={() => handleConfirm("approve", datum)}
            className="play mx-2"
          >
            APPROVE
          </button>
          <button
            onClick={() => handleModals("Rejected", datum)}
            className="pause mx-2"
          >
            REJECT
          </button>
        </span>
      ) : (
        ""
      ),
  },
];
const disburseColumn = (handleModals, handleConfirm) => [
  {
    title: "USER ID",
    dataIndex: "RecipientProfile",
    key: "RecipientProfile",
    render: (profile) => <p>@{profile?.username}</p>,
  },
  {
    title: "USERNAME",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.username}</p>,
  },
  {
    title: "EMAIL",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.email}</p>,
  },
  {
    title: "PHONE",
    dataIndex: "Profile",
    key: "Profile",
    render: (profile) => <p>{profile?.phone}</p>,
  },
  {
    title: "TYPES",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },

  {
    title: "STATUS",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (e) => (
      <p
        className={`text-${
          e.toLowerCase() === "pending"
            ? "warning"
            : e === "Rejected"
            ? "danger"
            : "success"
        }`}
      >
        {e}
      </p>
    ),
  },
  {
    title: "KYC STATUS",
    dataIndex: "kyc_status",
    key: "kyc_status",
    render: (e) => (
      <p className={`text-${e !== "tier4" ? "danger" : "success"}`}>{e}</p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e)?.toDateString()}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (e, datum) =>
      e.toLowerCase() === "approved" ? (
        <span className="fs-5">
          <button
            onClick={() => handleConfirm("disburse", datum)}
            className="play mx-2"
          >
            DISBURSE
          </button>
          <button
            onClick={() => handleModals("Rejected", datum)}
            className="pause mx-2"
          >
            REJECT
          </button>
        </span>
      ) : (
        ""
      ),
  },
];
const accountColumn = (handleModals, handleConfirm) => [
  {
    title: "USER ID",
    dataIndex: "RecipientProfile",
    key: "RecipientProfile",
    render: (profile) => <p>@{profile?.username}</p>,
  },
  {
    title: "TYPES",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "WAYA PERCENT",
    dataIndex: "wayagramPercent",
    key: "wayagramPercent",
  },
  {
    title: "BALANCE",
    dataIndex: "paymentBalance",
    key: "paymentBalance",
  },

  {
    title: "STATUS",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (e) => (
      <p
        className={`text-${
          e.toLowerCase() === "pending"
            ? "warning"
            : e.toLowerCase() === "rejected"
            ? "danger"
            : "success"
        }`}
      >
        {e}
      </p>
    ),
  },
  {
    title: "Approved By",
    dataIndex: "ApprovalProfile",
    key: "ApprovalProfile",
    render: (profile) => <p>{profile?.displayName}</p>,
  },
  {
    title: "Disbursed By",
    dataIndex: "DisburserProfile",
    key: "DisburserProfile",
    render: (profile) => <p>{profile?.displayName}</p>,
  },
  {
    title: "Rejected By",
    dataIndex: "RejecterProfile",
    key: "RejecterProfile",
    render: (profile) => <p>{profile?.displayName}</p>,
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e)?.toDateString()}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    render: (e, datum) =>
      e.toLowerCase() === "approved" ? (
        <span className="fs-5">
          <button
            onClick={() => handleConfirm("disburse", datum)}
            className="play mx-2"
          >
            Disbursed
          </button>
          <button
            onClick={() => handleModals("Rejected", datum)}
            className="pause mx-2"
          >
            Reject
          </button>
        </span>
      ) : e.toLowerCase() === "rejected" ? (
        <button
          onClick={() => handleModals("reason", datum)}
          className="pause mx-2"
        >
          Show Reason
        </button>
      ) : (
        <button
          onClick={() => handleModals("view", datum)}
          className="play mx-2"
        >
          Show Receipt
        </button>
      ),
  },
];
const reportCol = (handleConfirm, setPreviewImages, setShowImageModal) => [
  {
    title: "REPORTER'S NAME",
    dataIndex: "reporterName",
    key: "reporterName",
  },
  {
    title: "TYPE",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "REPORTEE'S NAME",
    dataIndex: "ReporteeProfile",
    key: "ReporteeProfile",
    render: (Profile) => <p>{Profile?.displayName}</p>,
  },
  {
    title: "CATEGORY",
    dataIndex: "category",
    key: "category",
  },
  {
    title: "CAPTION",
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: "IMAGES",
    dataIndex: "fileUrl",
    key: "fileUrl",
    render: (e) => {
      return e ? (
        <Image
          preview={{ visible: false }}
          alt="dispute-image"
          onClick={() => {
            setPreviewImages(e);
            setShowImageModal(true);
          }}
          src={e}
          width={50}
          height={50}
        />
      ) : (
        <span className="text-secondary">No image</span>
      );
    },
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "Profile",
    key: "Profile",
    render: (e, datum) => {
      let ban = e?.isBan ? "unban" : "ban";
      let disable = e?.isDisabled ? "enable" : "disable";
      return (
        <span className="fs-5">
          <ActionDropdown
            onActivate={() => handleConfirm(ban, datum)}
            onClose={() => handleConfirm(disable, datum)}
            activateText={ban}
            closeText={disable}
            deleteText={null}
          />
        </span>
      );
    },
  },
];
const emailCol = (onActivate, onDelete) => [
  {
    title: "TYPE",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "EMAIL ADDRESS",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "MESSAGE",
    dataIndex: "subject",
    key: "subject",
  },
  {
    title: "STATUS",
    dataIndex: "delivered",
    key: "delivered",
    render: (e) => <p className="m-0 fs-6">{e ? "DELIVERED" : "FAILED"}</p>,
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <ActionDropdown
          onActivate={() => onActivate(datum)}
          onDelete={() => onDelete(datum)}
          activateText="Resend"
          closeText={null}
        />
      </span>
    ),
  },
];

const pushCol = () => [
  {
    title: "RECEIVER",
    dataIndex: "RecipientId",
    key: "RecipientId",
  },
  {
    title: "NOTIFICATION SENT",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "STATUS",
    dataIndex: "delivered_by_log",
    key: "delivered_by_log",
    render: (e) => (
      <p className="m-0 fs-6">
        {e === null ? "PENDING" : e ? "DELIVERED" : "FAILED"}
      </p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
];
const smsCol = (onActivate, onDelete) => [
  {
    title: "PHONE",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "MESSAGE",
    dataIndex: "message",
    key: "message",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (e) => (
      <p className="m-0 fs-6">
        {e === null ? "PENDING" : e ? "DELIVERED" : "FAILED"}
      </p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (e, datum) => (
      <span className="fs-5">
        <ActionDropdown
          onActivate={() => onActivate(datum)}
          onDelete={() => onDelete(datum)}
          activateText="Resend"
          closeText={null}
        />
      </span>
    ),
  },
];
const activityCol = (handleDelete) => [
  // {
  //   title: "ADMIN ID",
  //   dataIndex: "Profile",
  //   key: "Profile",
  //   render: (Profile) => <p>{Profile?.username}</p>,
  // },
  {
    title: "FirstName",
    dataIndex: "firstName",
    key: "firstName",
    //render: (Profile) => <p>{Profile?.firstName}</p>,
  },
  {
    title: "LastName",
    dataIndex: "lastName",
    key: "lastName",
    //render: (Profile) => <p>{Profile?.lastName}</p>,
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
   // render: (Profile) => <p>{Profile?.lastName}</p>,
  },
  {
    title: "Phone",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "Role",
    dataIndex: "roleType",
    key: "roleType",
   //render: (e) => <p className="m-0 fs-6">{e?.roleType}</p>,
  },
  {
    title: "ACTIVITY",
    dataIndex: "description",
    key: "description",
    width: "40%",
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  // {
  //   title: "ACTION",
  //   render: (d) => <DeleteOutlined onClick={()=>handleDelete(d.id)} />
  // },
];
const requestCol = (handleAction) => [
  {
    title: "REQUEST ID",
    dataIndex: "id",
    key: "id",
    render: (id) => <p>{id.slice(0, 8)}</p>,
  },
  {
    title: "REQUEST BY",
    dataIndex: "requestBy",
    key: "requestBy",
    render: (profile) => <p>{profile?.firstName}</p>,
  },
  {
    title: "ROLE",
    dataIndex: "requestBy",
    key: "requestBy",
    render: (e) => <p className="m-0 fs-6">
      {e?.roles ? e?.roles[0] :  e?.SSO_ROLES ? e?.SSO_ROLES[0] : ''}
    </p>,
  },
  {
    title: "APPROVED BY",
    dataIndex: "rejectedBy",
    key: "rejectedBy",
    render: (profile) => <p>{profile?.firstName}</p>,
  },
  {
    title: "REQUEST TYPE",
    dataIndex: "requestType",
    key: "type",
  },
  {
    title: "DATE",
    dataIndex: "updatedAt",
    render: (e) => <p className="m-0 fs-6 ">{new Date(e).toDateString()}</p>,
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (e) => <p className="m-0 fs-6">{e}</p>,
  },
  {
    title: "ACTION",
    dataIndex: "id",
    render: (id) =>
    <>
      <button
        onClick={() => handleAction(id, "approve")}
        className="play mx-2"
      >
        Accept
      </button>
      <button
        onClick={() => handleAction(id, "reject")}
        className="pause mx-2"
      >
        Reject
      </button>
    </>
  },
];
const callCol = () => [
  {
    title: "CALLER",
    dataIndex: "RecipientId",
    key: "RecipientId",
  },
  {
    title: "RECEIVER",
    dataIndex: "RecipientId",
    key: "RecipientId",
  },
  {
    title: "IP ADDRESS",
    dataIndex: "content",
    key: "content",
  },
  {
    title: " CALL TYPE",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "DURATION",
    dataIndex: "content",
    key: "content",
  },
  {
    title: "STATUS",
    dataIndex: "delivered_by_log",
    key: "delivered_by_log",
    render: (e) => (
      <p className="m-0 fs-6">
        {e === null ? "PENDING" : e ? "DELIVERED" : "FAILED"}
      </p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
];
const liveColumn = (handleModals, handleConfirm) => [
  {
    title: "CREATOR",
    dataIndex: "eventOrganizer",
    key: "eventOrganizer",
    width: 120,
  },
  {
    title: "TITLE",
    dataIndex: "isPrivate",
    key: "isPrivate",
    render: (e) => (
      <span className={`fw-bold text-${e ? "danger" : "success"}`}>
        {e ? "PUBLIC" : "PRIVATE"}
      </span>
    ),
    width: 120,
  },
  {
    title: "DESCRIPTION",
    dataIndex: "details",
    key: "details",
    render: (e) => <p className="m-0 w-mx-200p">{e}</p>,
    width: 120,
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
    render: (e) => (
      <span className={`fw-bold text-${e ? "danger" : "success"}`}>
        {e ? "PAID" : "FREE"}
      </span>
    ),
    width: 120,
  },
  {
    title: "STATUS",
    dataIndex: "isClosed",
    key: "isClosed",
    render: (e, d) =>
      e ? (
        <span className="status text-danger">Ended</span>
      ) : (
        <Space>
          <span className="status text-success">Live</span>
          <div className={d?.currentState === "activate" ? "play" : "pause"}>
            <img
              className="mr-5"
              src={`/assets/icons/power-${
                d?.currentState === "activate" ? "on" : "off"
              }.svg`}
              alt="power-icons"
            />
            {d?.currentState?.toUpperCase()}
          </div>
          <Button
            onClick={() => handleConfirm("close", d?.id)}
            icon={<img src="/assets/icons/stop.svg" alt="manage-icons" />}
          />
          <Button
            onClick={() =>
              handleConfirm(
                d?.currentState === "activate" ? "deactivate" : "activate",
                d?.id
              )
            }
            icon={
              <img
                src={`/assets/icons/${
                  d?.currentState === "activate" ? "pause" : "play"
                }.svg`}
              />
            }
          />
        </Space>
      ),
    width: 120,
  },
  {
    title: "FOLLOWERS",
    dataIndex: "followers",
    key: "followers",
    render: (e, datum) => (
      <Space className="w-mx-200p">
        {e}
        <button
          onClick={() => handleModals("followers", datum)}
          className="pause mx-2"
        >
          View All
        </button>
      </Space>
    ),
    width: 120,
  },
  {
    title: "PAYMENT STATUS",
    dataIndex: "delivered_by_log",
    key: "delivered_by_log",
    render: (e) => (
      <p className="m-0 fs-6">
        {e === null ? "PAID" : e ? "DELIVERED" : "FAILED"}
      </p>
    ),
  },
  {
    title: "DATE",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => (
      <p className="m-0 fs-6">{new Date(e).toLocaleDateString()}</p>
    ),
    width: 120,
    fixed: "right",
  },
  {
    title: "TIME",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (e) => <p className="m-0 fs-6">{moment(e).format("h:mm a")}</p>,
    width: 120,
    fixed: "right",
  },
  // {
  //   title: "ACTIONS",
  //   dataIndex: "id",
  //   key: "id",
  //   render: (e, datum) => (
  //     <span className="fs-5">
  //       <EditOutlined
  //         className="mx-2"
  //         onClick={() => handleModals("editPost", datum)}
  //       />
  //       <DeleteOutlined
  //         className="mx-2 text-primary"
  //         onClick={() => handleConfirm(e)}
  //       />
  //     </span>
  //   ),
  // },
];
const crmColumn = (handleModals, handleConfirm, setPreviewImages) => [
  {
    title: "MEDIA",
    dataIndex: "fileUrl",
    key: "fileUrl",
    render: (e) =>
      e?.length > 0 ? (
        retrieveFileType(e) === "img" ? (
          <img alt="image" src={e} width={50} height={50} />
        ) : retrieveFileType(e) === "video" ? (
          <video preload="metadata" width="100" height="100">
            <source src={e} type="video/mp4" />
            <source src={e} type="video/ogg" />
          </video>
        ) : (
          <span className="text-secondary">Invalid Media</span>
        )
      ) : (
        <span className="text-secondary">No image</span>
      ),
  },
  {
    title: "UPLOADEDBY",
    dataIndex: "Profile",
    key: "Profile",
    width: 100,
    render: (Profile) => <p>{Profile?.username}</p>,
  }, 
  {
  title: "EMAIL",
    dataIndex: "email",
    key: "email",
    width: 120,
  },
  {
    title: "MEDIA TYPE",
    dataIndex:"item_type",
    Key: "item_type",
  },

  {
    title: "DATE OF CREATION",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toLocaleString()}</p>,
  },
  {
    title: "ACTIONS",
    dataIndex: "id",
    key: "id",
    render: (id, e, datum) => (
      <span className="fs-5">
        <EyeFilled
          onClick={() => {
            handleModals("view", id);
            setPreviewImages([{ imageURL: e?.fileUrl }]);
          }}
        />

        <DeleteOutlined
          className="mx-2 text-primary"
          onClick={() => handleConfirm(id)}
        />
      </span>
    ),
  },
];

const adminColumn = (handleModals, handleConfirm) => [
  {
    title: "FIRSTNAME",
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: "LASTNAME",
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: "EMAIL",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "PHONE NUMBER",
    dataIndex: "phoneNumber",
    key: "phoneNumber",
  },
  {
    title: "LOCATION",
    dataIndex: "userLocation",
    key: "userLocation",
  },
  {
    title: "ROLE",
    dataIndex: "Role",
    key: "Role",
    render: (Role) => <p>{Role?.userRole}</p>,
  },
  {
    title: "DATE",
     dataIndex: "createdAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toDateString()}</p>,
  },
  {
    title: "ACTION",
     dataIndex: "id",
    key: "id",
    render: (e, id,  datum) => (
      <>
        {/* <span>{e?.length}</span> */}
        <button
          onClick={() => handleModals("changePost", id)}
          className="pause mx-2"
        >
          Change Role
        </button>

        <DeleteOutlined
          className="mx-2 text-danger"
          onClick={() => handleConfirm(id)}
        />
        <button
          onClick={() => handleModals("change2FA", id)}
          className="pause mx-2"
        >
          Change 2FA
        </button>
      </>
    ),
  },
];
const feedbackColumn = () => [
  {
    title: "NAME",
    dataIndex: "name",
    key: "name",
    //width: 100,
    //render: (Profile) => <p>{Profile?.username}</p>,
  }, 
  {
  title: "EMAIL",
    dataIndex: "email",
    key: "email",
   // width: 120,
  },
  {
    title: "PHONE NUMBER",
    dataIndex:"phone",
    Key: "phone",
  },
  {
    title: "COMMENT/FEEDBACK",
      dataIndex: "feedback",
      key: "feedback",
     // width: 120,
    },

  {
    title: "DATE",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (e) => <p className="m-0 fs-6">{new Date(e).toLocaleString()}</p>,
  },
 
];

export {
  userColumn,
  postColumn,
  donationColumn,
  pollsColumn,
  productColumn,
  requestColumn,
  interestColumn,
  momentColumn,
  hashTagColumn,
  pageColumn,
  groupColumn,
  eventsColumn,
  paymentColumn,
  disburseColumn,
  accountColumn,
  liveColumn,
  crmColumn,
  adminColumn,
  feedbackColumn,
  likesCol,
  postsCol,
  commentsCol,
  hashtagCol,
  momentCol,
  accountCol,
  pricingCol,
  promotionCol,
  reportCol,
  emailCol,
  pushCol,
  smsCol,
  activityCol,
  requestCol,
  callCol,
};
