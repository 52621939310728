import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { sendWithAuth, getRequest } from '../../services/request';
import { error, success } from '../../services/swal-mixin';
import UserFormComponent from './MerchantUserUpdate';
import LoadingComponent from '../../components/loading/LoadingComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
  },
  tabContent: {
    padding: '25px 0',
  },

  profileCard: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundClip: 'border-box',
    marginBottom: 20,
    borderRadius: '.25rem',
    width: '100%',
  },
  avatarWrapper: {
    margin: '5px auto',
  },
  avatar: {
    height: 150,
    width: 150,
    minWidth: 150,
    borderRadius: 100,
    // border: `1px solid ${theme.color.lightGrayishBlue2}`,
  },
}));

const EditMerchantComponent = (props) => {
  const classes = useStyles();
  const { data, show, handleEditClose } = props;
  const [business, setBusiness] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    handleEditClose();
  };



  const updateUser = async (userData, id) => {
    setLoading(true)
    console.log(id)
    const response = await sendWithAuth(
      `api/v1/admin/update-corporate-profile/${id}`,
      userData,
      'put'
    );
    console.log(response)
    setLoading(false)
    const { message } = response;
    if (response.status === false) {
      return error(message);
    }
    userData = ''
    handleClose()
    success(message);

  };

  const getBusiness = async () => {
    setLoading(true)
    const response = await getRequest(
      'api/v1/business/type/find/all',
      'http://68.183.60.114:9086'
    );

    console.log(response);
    setLoading(false)
    const { data, message, status } = response || {};
    setBusiness(data);

    if (status === false) {
      return error(message);
    }
  };

  useEffect(() => {
    getBusiness()
  }, [])

  return (
    <div className={classes.root}>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header
          style={{
            borderBottom: 'none',
            padding: '0 1rem',
            borderTop: '5px solid #FF6634',
          }}
          closeButton
        ></Modal.Header>
        <LoadingComponent loading={loading} />
        <Modal.Body>
          <div className={classes.tabContent}>
            <UserFormComponent data={data} business={business} submitForm={updateUser} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditMerchantComponent;
