import React from "react";
import { Image } from "antd";
import { retrieveFileType } from "../utils/helpers";

const ImagePreview = ({ setVisible, visible, data }) => {
  return (
    <div style={{ display: "none" }}>
      <Image.PreviewGroup
        preview={{
          visible,
          onVisibleChange: (vis) => setVisible(vis),
        }}
      >
        {data?.map((d) => (
          <Image key={d.id} src={d.imageURL || d.content || d.imageUrl} />
        ))}
      </Image.PreviewGroup>
    </div>
  );
};

export default ImagePreview;
