import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SLUGS from '../resources/slugs';
import changePasswordFromEmail from '../views/ChangePasswordUsingEmail';
import ChangePasswordFromEmail from '../views/ChangePasswordUsingEmail';
import ForgotPasswordComponent from '../views/ForgotPasswordComponet';
import LoginComponent from '../views/LoginComponent';
import VerifyOtpComponent from '../views/VerifyOtpComponent';

function PublicRoutes() {
  return (
    <Routes>
      <Route path={SLUGS.login} element={<LoginComponent />} />
      <Route path={SLUGS.forgotPassword} element={<ForgotPasswordComponent />} />
      <Route
        path={SLUGS.changePasswordFromEmail}
        element={<changePasswordFromEmail />}
      />
      <Route path={SLUGS.verify_otp} element={<VerifyOtpComponent />} />
      <Route path={SLUGS.forgotPassword} element={<ForgotPasswordComponent />} />
      <Route
        path={SLUGS.changePasswordFromEmail}
        element={<ChangePasswordFromEmail />}
      />
      <Route
        path="*"
        element={<Navigate to={SLUGS.login} />}
      />
      
    </Routes>
  );
}

export default PublicRoutes;
