import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Table, Pagination, Button, Modal, Form, Input, notification, Select } from "antd";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { callCol } from "../../../utils/dummyData";
import { getRequest, postRequest, patchRequest, deleteRequest } from "../../../services/request";
import config from "../../../services/config";
import SearchBar from '../../../components/SearchBar';
import CsvDownload from 'react-json-to-csv';
import color from '../../../theme/color'
import  MultiInput from '../../../components/CustomInput/MultiInput'
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Popper from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AddIcon from "@material-ui/icons/Add";

const  WayaChat = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [currentTab, setCurrentTab] = useState("EmailManagement");
  const [filterType, setFilterType] = useState("SENDER");
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
  const [query, setQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [value, setValue] = useState("a");
  const [values, setValues] = useState("");
  const [createModal, setCreateModal] = useState(false)
  const [mode, setMode] = useState('')
  const [images, setImages] = useState([]);
  const [formdata, setFormdata] = useState({});
  const [users, setUsers] = useState([])
  const [options, setOptions] = useState([])


  const selectOptions = [
    {
      value: "SENDER",
      label: "Initiator",
    },
    {
      value: "RECIPIENT",
      label: "Reciever",
    },
    {
      value: "SUBJECT",
      label: "Message",
    },
  ];

  const handleChange = (e) => {
    setValues(e.target.value);
    setQuery(e.target.value);
  };




  const getData = async (q) => {
    let response
    if(q) {
      response = await getRequest(
        `admin-search-notification-logs?query=${query}&filterBy=${filterType}&page=${pages}&limit=20`,
        config.notificationUrl
      );
    }else{
      setLoading(true);
      response  = await getRequest(
        `admin-get-notifications?page=${pages}&limit=${limits}`,
        config.notificationUrl
      );
    };
    setLoading(false);
    const { data, metadata } = response?.data?.data || [];
    setPosts(data || []);
    setTotal(metadata?.total || 0);
    setFilteredPosts(data?.data || []);
  };
  

  const getPushOverview = async () => {
    const response = await getRequest(
      "admin-get-notification-stats",
      config.notificationUrl
    );
    const { data } = response?.data || {};
    setMetaData(data);
  };

  useEffect(() => {
    getData(query);
  }, [currentTab, reload, query, pages]);

  useEffect(() => {
    getPushOverview();
  }, [pages]);

  const handleSubmit = async () => {
    let action = mode === 'bulk' ? 'SEND_TO_WAYAGRAM_PROFILES' : 'SEND_TO_ONE_PROFILE_2'
    formdata.type = 'general'
    formdata.to = formdata.to ? formdata.to  : ['']

    const response =await postRequest(
      `fcm-push-notify-send?action=${action}`,
      formdata,
      config.notificationUrl
    );

    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message,
        description:"Notification sent"
      })
      await getData();
      await getPushOverview();
      setCreateModal(false)
    } else {
      notification.warning({
        message,
        description:"Failed"
      })
    }
  }

  const getUsers = async () => {
    const response = await getRequest(
      `admin-profile/all?page=${pages}&limit=1000000`,
      config.roleUrl
    );
    const data = response?.data?.data.AllWayAGramProfiles || [];
    setUsers(data);
    setOptions(data.map((d) => { return { value: d.email, label: d.email }}))
  }

  useEffect(() => {
    getUsers();
  }, []);

  
const handleModals=(type)=>{
  setCreateModal(true)
  setMode(type)
}

const Buttons = () => {
  return(
    <div>
      <PopupState variant="popper" popupId="demo-popup-popper">
        {(popupState) => (
          <div>
            <Button
              style={{
                marginTop: "15px",
                backgroundColor: "#FF4B01",
                color: "white",
                fontWeight: 600,
                marginRight: "10px",
              }}
              variant="contained"
              {...bindToggle(popupState)}
            >
              <AddIcon />
              New Push Notification
            </Button>
            <Popper {...bindPopper(popupState)} transition>
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <Paper>
                    <Typography
                      onClick={() => handleModals("single")}
                      style = {{
                        padding: 10,
                        cursor: "pointer",
                      }}
                    >
                      Send to Single User
                    </Typography>
                    <Typography
                      onClick={() => handleModals("bulk")}
                      style = {{
                        padding: 10,
                        cursor: "pointer",
                      }}
                    >
                       Send to All Users
                    </Typography>
                  </Paper>
                </Fade>
              )}
            </Popper>
          </div>
        )}
      </PopupState>
    </div>
  )
}

const column = callCol();


  return (
    <Column>
      <Row horizontal="space-between">
      </Row>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
      {/* <SearchBar
        page="call"
        total={metaData}
        showButtons={true}
        setFilterType={setFilterType}
        selectOptions={selectOptions}
        handleChange={handleChange}
        Conversion={Buttons}
        createButton={true}
      /> */}
      <Table
        columns={column}
        dataSource={posts}
        rowKey="id"
        loading={loading}
        pagination={false}
      />
        <Pagination
        total={total}
        responsive={true}
        defaultCurrent={1}
        onChange={(e) => setPages(e)}
        defaultPageSize={limits}
        showSizeChanger={false}
      />
      </div>
      <Modal
        visible={createModal}
        onCancel={()=> setCreateModal(false)}
        footer={false}
        title={<p className='text-center'>Send Notification Message to {mode === 'bulk' ? 'All Users' : 'Single User'}</p>}
      >
        <Form layout='vertical' onFinish={handleSubmit}>

          <Form.Item label="User" name="user" hidden={mode==='bulk'}>
            <Select
              showSearch
              value={value}
              placeholder="Search for user"
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              onChange={(e)=> setFormdata({...formdata, to: [e]})}
              notFoundContent={null}
            >
              {users.map((user) => (
                <Select.Option key={user.id} value={user.id} >
                  {user.displayName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Title" name="title">
            <Input onChange={(e)=>setFormdata({...formdata, title: e.target.value})} />
          </Form.Item>

          <Form.Item label="Description" name="Description">
            <Input.TextArea onChange={(e)=>setFormdata({...formdata, content: e.target.value})}/>
          </Form.Item>

          <Form.Item className='text-center mt-4'>
            <Button 
              size='large' 
              loading={loading} 
              htmlType='submit' 
              className='rounded px-5 '
              style={{ backgroundColor: color.brightOrange, color: color.white }}
            >
              Send Notification Message
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Column>
  );
};

export default WayaChat;
