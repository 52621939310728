import React, { useEffect, useState } from "react";
import { Column, Row } from "simple-flexbox";
import { Table, Pagination, notification, Tag, Space } from "antd";
import LoadingComponent from "../../../components/loading/LoadingComponent";
import { activityCol } from "../../../utils/dummyData";
import {
  getRequestNoSso,
  deleteRequestData,
  // patchRequest,
  // putRequest,
} from "../../../services/request";
import config from "../../../services/config";
import OptionSearch from "../../../components/CustomInput/OptionSearch";
// import moment from "moment";

const ActivityLog = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [reload, setReload] = useState(false);
  // const [currentTab, setCurrentTab] = useState();
  const [filterType, setFilterType] = useState("firstName");
  // const [data, setData] = useState([]);
  // const [metaData, setMetaData] = useState({});
  const [total, setTotal] = useState(0);
  const [pages, setPages] = useState(1);
  const [limits, setLimits] = useState(20);
   const [query, setQuery] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  // const [value, setValue] = useState("a");
  // const [values, setValues] = useState("");
  // const [rejectModal, setRejectModal] = useState(false);
  // const { confirm } = Modal;
  // const [poll, setPoll] = useState({
  //   startDate: Date.now(),
  //   endDate: Date.now(),
  // });
  // const { RangePicker } = DatePicker;
  const [selectedTags, setSelectedTags] = useState('ADMIN');

  const selectOptions = [
    {
      value: "firstName",
      label: "First Name",
    },
    {
      value: "phoneNumber",
      label: "Phone Number",
    },
    {
      value: "email",
      label: "Email",
    },
  ];

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  // const dateFormat = "YYYY/MM/DD";
  const { CheckableTag } = Tag;
  const tagsData = ['ADMIN', 'USER']; 
  const getData = async () => {
    setLoading(true);
    let searchQuery = '';
    if(filterType && query){
      searchQuery = `&${filterType}=${query}`;
    }
    const response = await getRequestNoSso(
      `activities?roleType=${selectedTags}${searchQuery}`,
      config.mainUrl
    );
    setLoading(false);
    const { data, metadata } = response?.data || [];
    setPosts(data || []);
    setTotal(metadata?.total || 0);
    setFilteredPosts(data?.data || []);
  };

  useEffect(() => {
    getData();
  }, [pages, selectedTags, query]);

  const deleteLog = async (id) => {
    setLoading(true);
    const response = await deleteRequestData(
      `delete-activity-log`,
      { activity_id: id },
      config.mainUrl
    );
    setLoading(false);
    const { status, message } = response.data;
    if (status === true) {
      notification.success({
        message,
        description: "deleted",
      });
      getData();
    } else {
      notification.warning({
        message,
        description: "error",
      });
    }
  };

  const column = activityCol(deleteLog);

  return (
    <Column>
      <h1 className="fs-2">Activity Log</h1>
      <Row className="mt-5 mb-5">
        <OptionSearch
          handleChange={handleChange}
          setFilterType={setFilterType}
          selectOptions={selectOptions}
          placeholder="Search activity logs by..."
        />
        {/* <label className="pe-2">Duration</label>
        <RangePicker
          onChange={(dates, dateStrings) =>
            setPoll({
              startDate: dateStrings[0],
              endDate: dateStrings[1],
            })
          }
          // value={[
          //   moment(poll?.startDate, dateFormat),
          //   moment(poll?.endDate, dateFormat),
          // ]}
          format={dateFormat} 
        /> */}
      </Row>
      <Space size={[0, 8]} wrap>
        {tagsData.map((tag) => (
          <CheckableTag
            key={tag}
            checked={selectedTags ===tag}
            onChange={() => setSelectedTags(tag)}
          >
            {tag}
          </CheckableTag>
        ))}
      </Space>
      <LoadingComponent loading={loading} />
      <div className="w-100 my-3">
        <Table
          columns={column}
          dataSource={posts}
          rowKey="id"
          loading={loading}
          pagination={false}
        />
        <Pagination
          total={total}
          responsive={true}
          defaultCurrent={1}
          onChange={(e) => setPages(e)}
          defaultPageSize={limits}
          showSizeChanger={false}
        />
      </div>
    </Column>
  );
};

export default ActivityLog;
