import React from 'react';
import { any, arrayOf, func, string } from 'prop-types';
import { Column, Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import CollapsibleContent from '../collapsible/CollapsibleContent';
import { useSidebar } from '../../hooks/useSidebar';
import { lightenColor } from '../../services/helpers';

const useStyles = createUseStyles({
  activeContainer: {
    backgroundColor: ({ theme }) => theme.color.wayaLightPink,
  },
  container: {
    display: 'flex',
    height: 56,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: ({ theme }) => theme.color.lightGrayishBlue,
    },
    paddingLeft: ({ level }) => 32,
    transition: 'all 0.2s ease-in-out',
  },
  leftBar: {
    color: ({ theme, level }) => (level > 1 ? theme.color.wayaPink : 'none'),
    backgroundColor: ({ theme }) => lightenColor(theme.color.wayaPink),
    borderLeft: ({ theme, level }) =>
      level > 1 ? 'none' : `3px solid ${theme.color.wayaPink}`,
  },
  title: {
    fontSize: 14,
    lineHeight: '17px',
    letterSpacing: '0.2px',
    fontWeight: 'bold',
    color: ({ theme, isActive }) =>
      isActive ? theme.color.customWayaRed : theme.color.darkerGray,
    marginLeft: 24,
    marginRight: 5,
  },
});

function MenuItemComponent({
  children,
  icon: Icon,
  id,
  items = [],
  level = 1,
  onClick,
  title,
  rightIcon: RIcon,
  iconSize,
  hidden = false
}) {
  const theme = useTheme();
  const isCollapsible = children && children.length > 0;
  const { isExpanded, isActive, onItemClick } = useSidebar({
    isCollapsible,
    item: id,
    items,
  });
  const classes = useStyles({ theme, level, isActive });
  const classNameColumn = isActive ? classes.leftBar : '';
  const classNameContainer = [
    classes.container,
    isActive && classes.activeContainer,
  ].join(' ');
  const iconColor = isActive ? '#ff4400' : '#000000';

  function onItemClicked(e) {
    if (onClick) {
      onClick(e);
    }
    onItemClick();
  }

  return (
    <Column key={id} className={classNameColumn} hidden={hidden}>
      <Row
        vertical="center"
        onClick={onItemClicked}
        className={classNameContainer}
      >
        {Icon && <Icon fill={iconColor} size={iconSize || 20} />}
        <span className={classes.title}>{title}</span>
        {RIcon && <RIcon />}
      </Row>
      {isCollapsible && (
        <CollapsibleContent
          expanded={isExpanded}
          style={{ marginLeft: '15px' }}
        >
          {children.map((child) => child.type({ ...child.props }))}
        </CollapsibleContent>
      )}
    </Column>
  );
}

MenuItemComponent.defaultProps = {};

MenuItemComponent.propTypes = {
  children: any,
  icon: any,
  id: string,
  onClick: func,
  items: arrayOf(string),
  title: string,
};

export default MenuItemComponent;
