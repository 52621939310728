import React, { useEffect, useState } from 'react'
import CsvDownload from 'react-json-to-csv';
import { CommentOutlined, EditOutlined, HeartOutlined } from '@ant-design/icons'
import { Avatar, Button, Card, Empty } from 'antd'
import LoadingComponent from '../../../components/loading';
import SearchBar from '../../../components/SearchBar';
import config from '../../../services/config';
import { getRequest } from '../../../services/request';
import { useNavigate, useParams } from 'react-router-dom';

const GroupPosts = () => {
  const [loading, setLoading] = useState(false);
  // const [currentTab, setCurrentTab] = useState("all");
  const [filterType, setFilterType] = useState("description");
  const [metaData, setMetaData] = useState(0);
  const [page, setPage] = useState(1);
  const [limits, setLimits] = useState(20);
  const [posts, setPosts] = useState([]);
  const {id, type} = useParams();
  const push = useNavigate();
  // const [users, setUsers] = useState([]);
  // const [groups, setGroups] = useState([]);
  // const [pages, setPages] = useState([]);
  // const [images, setImages] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [createPostModal, setcreatePostModal] = useState(false);
  // const [imageModal, setShowImageModal] = useState(false);
  // const [previewImages, setPreviewImages] = useState([]);
  // const [mode, setMode] = useState("");
  // const [userLoginModal, setuserLoginModal] = useState(false);
  // const [value, setValue] = useState("a");
  // const [type, setType] = useState("user");
  // const { confirm } = Modal;
  // const [form] = Form.useForm()

  const getData = async () => {
    setLoading(true);
    const url = type === 'group'
      ? `admin-get-group-posts?profile_id=cfd2f35d-701d-495e-ac63-b886a8b78b5e&group_id=${id}`
      : `admin-get-user-posts?profile_id=${id}&page=1`
    const response = await getRequest(
      url,
      config.postUrl
    );
    setLoading(false);
    const { data } = response?.data || {};
    const { metadata } = response?.data?.data || {};
    
    setMetaData(metadata?.total || 0)
    setPosts(data?.data || []);
    setFilteredPosts(data?.data || []);
  };
  
  const handleChange = (event) => {
    const {value} = event.target
    if (value === ''){
      setPosts(filteredPosts)
    }else{
      let filtered = []
      if (filterType === 'name'){
        filtered = filteredPosts.filter(post => post?.Profile?.displayName?.toLowerCase().includes(value?.toLowerCase()))
      }else if (filterType === "email"){
        filtered = filteredPosts.filter(post => post?.Profile?.email?.toLowerCase().includes(value?.toLowerCase()))
      }else{
        filtered = filteredPosts.filter(post => post?.description?.toLowerCase().includes(value?.toLowerCase()))
      }
      setPosts(filtered)
    }
  };

  const handleModals=(type, value)=>{
    if(type==='newPost'){
      setcreatePostModal(true);
    }
  }

  const Conversion = () => {
    return (
      <CsvDownload
        data={posts}
        filename="private_User.csv"
        style={{
          //pass other props, like styles
          boxShadow: 'inset 0px 1px 0px 0px #e184f3',
          backgroundColor: '#27AE60',
          borderRadius: '6px',
          // border: '1px solid #a511c0',
          display: 'inline-block',
          cursor: 'pointer',
          color: '#ffffff',
          fontSize: '15px',
          // marginTop: '16px',
          height: '40px',
          fontWeight: 'bold',
          padding: '6px 24px',
          textDecoration: 'none',
          // textShadow: '0px 1px 0px #9b14b3',
        }}
      >
        Export Group
      </CsvDownload>
    );
  };

  useEffect(() => {
    getData();
  }, [page]);

  return (
    <div>
      <Button className="my-3" onClick={()=>push(`/management/${type === 'group'? 'group_accounts' : 'waya_users'}`)}>
        Back to {type}
      </Button>
      <LoadingComponent loading={loading} />
      {type === '' && (
        <SearchBar
          page="post"
          total={metaData}
          handleModals={handleModals}
          Conversion={Conversion}
          handleChange={handleChange}
          setFilterType={setFilterType}
        />
      )}
      <div className='row'>
        {posts.length === 0 && <Empty />}
        {posts.map(post =>(
          <div className="col-md-4" key={Math.random()}>
            <Card
              style={{ width: 300, marginTop: 16 }}
              actions={[
                <span><EditOutlined key="edit" />{post.repostCount}</span>,
                <span><HeartOutlined key="likes" />{post.likeCount}</span>,
                <span><CommentOutlined key="comment" />{post.commentCount}</span>,
              ]}
            >
              <Card.Meta
                avatar={<Avatar src={post?.Profile?.avatar || "https://joeschmoe.io/api/v1/random"} />}
                title={<span className="play">{post?.Profile?.displayName || 'username'}</span>}
                description={post.description}
              />
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GroupPosts