import React from 'react';
// import { Redirect } from 'react-router-dom';
import { createUseStyles, useTheme } from 'react-jss';
import { Column, Row } from 'simple-flexbox';
import { SidebarComponent, SidebarContext } from '../components/sidebar';
import HeaderComponent from '../components/header/HeaderComponent';
import PrivateRoutes from '../routes/PrivateRoutes';
import { getUserData } from '../services/helpers';
// import SLUGS from '../resources/slugs';

const useStyles = createUseStyles({
  container: {
    backgroundColor: '#f5f5f5',
    height: '100%',
    minHeight: 850,
  },
  mainBlock: {
    paddingLeft: 10,
    marginLeft: 230,
    width: '70%',
    '@media (max-width: 1080px)': {
      marginLeft: 0,
      paddingLeft: 0,
    },
  },
  contentBlock: {
    backgroundColor: '#f5f5f5',
    padding: '30px 15px',
    '@media (max-width: 1080px)': {
      padding: '50px 20px',
    },
  },
});

// console.log(getUserData());

function PrivateSection() {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const user = getUserData();
  return (
    <SidebarContext>
      <Row className={classes.container}>
        <SidebarComponent />
        <Column flexGrow={1} className={classes.mainBlock}>
          <div className={classes.headerBlock}>
            <HeaderComponent />
          </div>
          <div className={classes.contentBlock}>
            <PrivateRoutes />
          </div>
        </Column>
      </Row>
      {/* <Redirect to={SLUGS.page404} /> */}
    </SidebarContext>
  );
}

export default PrivateSection;
