import React, { useState, useEffect } from "react";
import config from "../../services/config";
import { Modal, Form, Button, notification, Upload, Input, Switch, Table } from "antd";
import { getRequest, patchRequest, postRequest, putRequest } from "../../services/request";
import SearchDropdown from "../../components/CustomInput/searchDropdown";
import { Avatar } from "@mui/material";
import Edit from "@material-ui/icons/Edit";
import { EditFilled } from "@ant-design/icons";
import AntDropdown from "../../components/CustomInput/antDropdown";
import ContestSettings from "./settings";
import { hideLoader, showLoader } from "../../utils/loader";
// import WayaTable from "../management/WayagramUsers/wayagramTable";

const ManageHosts = () => {
  const [loading, setLoading] = useState(false);
  const [fullData, setFullData] = useState([]);
  const [createHostModal, setcreateHostModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [values, setValues] = useState('');
  const [selectedUser, setSelectedUser] = useState([])
  const [options, setOptions] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState('')
  const [organizers, setOrganizers] = useState([]);
  const [selectedRow, setSelectedRow] = useState([])
  const [showSettings, setShowSettings] = useState(false);
  
  const handleSelectOption = async (selectedUser) => {
    const otherDetais = await getFullData(selectedUser?.email);

    if (otherDetais === true) {
        setSelectedUser(selectedUser);
        setSelectedUserId(selectedUser.userId);
        setOptions([]);
    } else {
      setOptions([]);
    }
    
  };
  const handleDropdownAction = (item, row) => {
    if (item === "Settings") {
      setSelectedRow(row);
      setShowSettings(true);
    }
    if (item === "Deactivate") {
      handleActivateDeactivate(row?.profileId, 'DISABLE');
    }
    if (item === "Activate") {
      handleActivateDeactivate(row?.profileId, 'ENABLE');

    }
    // if(roles.indexOf('ROLE_ADMIN_OWNER')!==-1  ||roles.indexOf('ROLE_ADMIN_SUPER') !==-1){
    //   if (item === "Reverse") reverseTransaction(row);
    // }else{
    //   NotificationManager.error('not authorize')
    // }
  };
  const columns = [
    {
      title: 'Firstname',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Lastname',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Date Created',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Date Modified',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <div className="row">
          <AntDropdown
             dropDownOptions={['Settings', record?.status === 'ACTIVE' ? 'Deactivate' : 'Activate']}
             handleDropdownAction={(item) => handleDropdownAction(item, record)}
             isArrow
          />
          {/* <AntDrop
          <EditFilled />
          <Switch
            checked={record?.status === 'ACTIVE' ? true : false}
            onClick={() => handleActivateDeactivate(record?.profileId, record?.status === 'ACTIVE' ? 'DISABLE' : 'ENABLE')}
          /> */}
        </div>
      ),
    },
  ];

  const handleSearch = async (e) => {
    setSelectedUser({});
    setValues(e.target.value);
    if (e.target.value !== '') {
      const result = await fetchUsers(e.target.value);
      setOptions(result?.data?.data?.AllWayAGramProfiles);
    }
  
  }

  const getFullData = async (email) => {
    setLoading(true);
    const response = await getRequest(
      `search/search-profile-email/${email}`,
      config.bankAuthUrl
    );
    const { data, status } = response?.data || [];
    if (status) {
      setFullData(data[0]);
    }
    setLoading(false);
    return status;
  };

  const fetchUsers = async (q, page) => {
    let response;
    if (q) {
      response = await getRequest(
        `admin-profile/all?page=1&username=${q}`,
        config.roleUrl
      );
    }
    if (!response || response.error !== undefined) {
      return;
    }
    return response;
  };
  const getOrganizers = async (page) => {
    const response = await getRequest(
        `users/organisers`,
        config.contestUrl,
        true
      );
    const { data, status } = response?.data || [];
    if (status) {
      setOrganizers(data);
    }
    setLoading(false);
    return status;
  };

  const handleCreateHost = async () => {
    setLoading(true);
    let postData = {
      profileId: selectedUser?.id,
      email: selectedUser?.email,
      firstName: fullData?.firstName,
      lastName:  fullData?.surname,
      username:  selectedUser?.username,
      coverPhoto: selectedUser?.avatar
    }
    // return console.log({ postData });
    const url = '/users/organiser/setup'
    const res = await postRequest(url, postData, config.contestUrl);
    const { status, data, message } = res?.data || {};
    if (status) {
      notification.success({ description: message, message: "success!" });
      setcreateHostModal(false);
      setReload(!reload);
    } else {
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };

  const handleActivateDeactivate = async (id, action) => {
    setLoading(true);
    let postData = {
      profileId: id,
      action,
    }
    // return console.log({ postData });
    const url = 'users/manage/organiser'
    const res = await patchRequest(url, postData, config.contestUrl);
    showLoader();
    const { status, data, message } = res?.data || {};
    if (status) {
      hideLoader();
      notification.success({ description: message, message: "success!" });
      setcreateHostModal(false);
      getOrganizers();
      setReload(!reload);
    } else {
      hideLoader();
      notification.error({ description: message, message: "Oops!" });
    }
    setLoading(false);
  };


  useEffect(() => {
    getOrganizers();
  }, []);
  return (
    <div className="w-100">
      <div className="text-end">
      <Button
        size="large"
        loading={loading}
        htmlType="submit"
        type="primary"
        className="rounded px-5 my-3"
        onClick={() => setcreateHostModal(true)}
    >
        Activate New Host
    </Button>
    </div>
    <Modal
        visible={createHostModal}
        onCancel={() => {
          setcreateHostModal(false)
          setSelectedUser({});
          setOptions([]);
          setValues('');
        }}
        footer={false}
        title={<p className="text-center">Manage Host </p>}
      >
        <div style={{ position: 'relative' }} class="col-md-12">
          <div className="my-2">
          <input
            placeholder="Search by username"
            type='text'
            class='form-control'
            id='validationCustom01'
            value={values}
            required
            onChange={handleSearch}
          />
          </div>
         
          <div style={{marginTop: 10}}>
              <SearchDropdown options={options} handleClick={handleSelectOption} />
              </div>

        </div>
          {selectedUser?.displayName ? (
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div className='image-div'>
                {selectedUser?.avatar ? (
                <img
                  className="img-fluid"
                  src={selectedUser?.avatar}
                  alt='placeholder'
                  style={{ borderRadius: '50%', width: 30, height: 30 }}
                /> 
                ) : (
                <Avatar />
                )}
              </div>
              <div className='text-div mx-5'>
                <span>{selectedUser.displayName}</span>
              </div>
              <div>
                <Button
                  size="large"
                  loading={loading}
                  htmlType="submit"
                  type="primary"
                  className="rounded px-5"
                  onClick={handleCreateHost}
                >
                  Activate contest
                </Button>
              </div>
            </div>
          ) : (
            ''
          )}
        
    </Modal>
    <Table columns={columns} dataSource={organizers} />

    {showSettings ? (
      <ContestSettings record={selectedRow} hideModal={setShowSettings} showModal={showSettings} />
    ) : (
      ''
    )

    }
    </div>
  );
};

export default ManageHosts;
