import React from 'react';

const invitedIcon = (props) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity={props.opacity || ''} d="M5 0V2H11.59L0 13.59L1.41 15L13 3.41V10H15V0H5Z" fill={props.fill || '#828282'} />
  </svg>
);
export default invitedIcon;
